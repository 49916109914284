import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { ClipLoader } from 'react-spinners'

import altoonCMSBackendApis from '../../utils/altoonCMSBackendApis'
import NovelChapterManagementTab from './components/NovelChapterManagementTab'
import NovelFileUploadTab from './components/NovelFileUploadTab'
import NovelManagementTab from './components/NovelManagementTab'

const NovelAdminScreen = observer(() => {
  const [activeTab, setActiveTab] = useState('works')
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(30)
  const [filterCurrentNovelData, setFilterCurrentNovelData] = useState(false)
  const [tempData, setTempData] = useState([])
  const [sortBy, setSortBy] = useState('createdAt')
  const [sortOrder, setSortOrder] = useState('desc')
  const [isReordering, setIsReordering] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [activeSearch, setActiveSearch] = useState({ query: '', type: 'title' })

  const fetchNovels = async () => {
    setIsLoading(true)
    try {
      const result = await altoonCMSBackendApis.getNovelData()
      if (result?.status === 200) {
        let filteredData = result?.data

        // 현재 제공중인 작품 필터
        if (filterCurrentNovelData) {
          filteredData = filteredData.filter(
            (item) => item.isDisplayed === true && item.isAdmin !== true,
          )
        }

        setTempData(filteredData)
      }
    } catch (error) {
      console.error('작품 데이터 로딩 중 오류 발생:', error)
      alert('작품 데이터를 불러오는데 실패했습니다.')
    } finally {
      setIsLoading(false)
    }
  }

  // 정렬된 데이터를 반환하는 함수
  const getSortedData = (data) => {
    if (!data) return []

    return [...data].sort((a, b) => {
      let comparison = 0
      switch (sortBy) {
        case 'createdAt':
          comparison = new Date(b.createdAt) - new Date(a.createdAt)
          break
        case 'priority':
          comparison = (b.priority || 0) - (a.priority || 0)
          break
        case 'publishedAt':
          comparison =
            new Date(b.publishedAt || 0) - new Date(a.publishedAt || 0)
          break
        case 'displayStartedAt':
          comparison =
            new Date(b.displayStartedAt || 0) -
            new Date(a.displayStartedAt || 0)
          break
        default:
          comparison = 0
      }
      return sortOrder === 'asc' ? -comparison : comparison
    })
  }

  const getFilteredData = (data) => {
    if (!activeSearch.query || !data) return data

    return data.filter((item) => {
      const query = activeSearch.query.toLowerCase()
      switch (activeSearch.type) {
        case 'title':
          return item.title?.toLowerCase().includes(query)
        case 'id':
          return item._id?.toString().includes(query)
        case 'provider':
          return item.providerInfo?.providerDisplayName
            ?.toLowerCase()
            .includes(query)
        default:
          return true
      }
    })
  }

  // 페이지네이션을 위한 데이터 처리
  const getPaginatedData = (data) => {
    const startIndex = (currentPage - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage
    return data?.slice(startIndex, endIndex)
  }

  useEffect(() => {
    fetchNovels()
  }, [filterCurrentNovelData])

  const LoadingIndicator = () => {
    return (
      <div className='fixed inset-0 z-30 w-full h-full bg-gray-800 bg-opacity-70 flex items-center justify-center'>
        <ClipLoader
          color='#ff3e3e'
          loading
          size={40}
          aria-label='Loading Spinner'
          data-testid='loader'
        />
      </div>
    )
  }

  return (
    <div className='w-full my-10 min-w-7xl max-w-10xl select-text'>
      {/* 탭 네비게이션 */}
      <div className='flex space-x-4 mb-6 border-b'>
        <button
          type='button'
          onClick={() => setActiveTab('works')}
          className={`px-4 py-2 font-medium ${
            activeTab === 'works'
              ? 'border-b-2 border-blue-500 text-blue-500'
              : 'text-gray-500 hover:text-gray-700'
          }`}
        >
          작품 정보 관리
        </button>
        <button
          type='button'
          onClick={() => setActiveTab('chapters')}
          className={`px-4 py-2 font-medium ${
            activeTab === 'chapters'
              ? 'border-b-2 border-blue-500 text-blue-500'
              : 'text-gray-500 hover:text-gray-700'
          }`}
        >
          회차 정보 관리
        </button>
        <button
          type='button'
          onClick={() => setActiveTab('files')}
          className={`px-4 py-2 font-medium ${
            activeTab === 'files'
              ? 'border-b-2 border-blue-500 text-blue-500'
              : 'text-gray-500 hover:text-gray-700'
          }`}
        >
          회차 등록(파일업로드)
        </button>
      </div>

      {/* 탭 컨텐츠 */}
      <div className='space-y-6'>
        {activeTab === 'works' && (
          <NovelManagementTab
            tempData={tempData}
            filterCurrentNovelData={filterCurrentNovelData}
            setFilterCurrentNovelData={setFilterCurrentNovelData}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            sortBy={sortBy}
            setSortBy={setSortBy}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            isReordering={isReordering}
            setIsReordering={setIsReordering}
            activeSearch={activeSearch}
            setActiveSearch={setActiveSearch}
            fetchNovels={fetchNovels}
            getPaginatedData={getPaginatedData}
            getSortedData={getSortedData}
            getFilteredData={getFilteredData}
          />
        )}

        {activeTab === 'chapters' && <NovelChapterManagementTab />}

        {activeTab === 'files' && <NovelFileUploadTab />}
      </div>

      {isLoading && <LoadingIndicator />}
    </div>
  )
})

export default NovelAdminScreen
