import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSearchParam } from 'react-use'

import AuthStore from '../../stores/AuthStore'
import UserStore from '../../stores/UserStore'
import backendApis from '../../utils/backendApis.ts'
import { safeCodepushVersionCompare } from '../../utils/useConvertCodePushVersion'
import Button from '../atoms/button'

const SubMenuButtonGroup = ({ setBottomSheetOpen, missionConfig }) => {
  const navigate = useNavigate()
  const token = useSearchParam('token')

  return (
    <div className='w-full flex flex-col text-sm font-semibold pb-[4vw]'>
      <div className='flex flex-col mx-4 overflow-hidden text-sm font-bold bg-white rounded-lg'>
        <div className='flex items-center justify-between px-6 mt-6'>
          <div className='text-[4.5vw]'>미션하고 포인트받기</div>
          <div
            className='flex flex-row items-center text-[3.8vw] font-normal text-[#5D5F68] border-[0.5vw] px-3 py-[1.5vw] rounded-full border-[#eeeff3] mt-1'
            onClick={() => {
              navigate(`/exchange-store?token=${token}&isFrom=web`)
            }}
          >
            포인트 교환소
            <img
              src='/images/dailyCheckIn/Arrow_Right.svg'
              alt='arrow-right'
              className='ml-2'
            />
          </div>
        </div>
        {safeCodepushVersionCompare(AuthStore.codePushVersion, '5.9.0') >=
          0 && (
          <>
            <button
              type='button'
              className='flex items-center justify-start px-6 py-6 duration-200 box-shadow outline-0 active:brightness-90 active:scale-95'
              onClick={() => {
                navigate(`/offerwall?token=${token}&isFrom=web`)
                backendApis.recordScreenLog(
                  UserStore.sessionId,
                  'OfferWall_CheckIn',
                )
              }}
            >
              <img
                src='/images/dailyCheckIn/Point.svg'
                alt='pig'
                className='mr-4 w-9'
              />
              <div className='flex flex-col items-start justify-center w-full'>
                <span className='text-[4.5vw] mb-1'>포인트 더 모으기 미션</span>
                <span className='text-[4vw] font-normal text-[#9C9DA4]'>
                  {UserStore.offerwallInfo.totalReward > 10000
                    ? `최대 ${UserStore.offerwallInfo.totalReward?.toLocaleString()}원 더 받을 수 있어요`
                    : '모으기 미션하고 올포인트 더 받기'}
                </span>
              </div>
              <img
                src='/images/dailyCheckIn/arrow.svg'
                alt='arrow'
                className='w-3'
              />
            </button>
            <div className='mx-4 h-[0.3vw] bg-[#f6f6fa]' />
          </>
        )}
        <button
          type='button'
          className='flex items-center justify-start px-6 py-6 duration-200 box-shadow outline-0 active:brightness-90 active:scale-95'
          onClick={async () => {
            window.location.href = `#navigate.${JSON.stringify({
              screen: 'DailyCheckInCashBackDealScreen',
            })}`
          }}
        >
          <img
            src='/images/dailyCheckIn/장바구니.svg'
            alt='pig'
            className='mr-4 w-9'
          />
          <div className='flex flex-col items-start justify-center w-full'>
            <span className='text-[4.5vw] mb-1'>캐시백 쇼핑</span>
            <span className='text-[4vw] font-normal text-[#9C9DA4]'>
              캐시백 상품 사고 포인트를 받아요
            </span>
          </div>
          <img
            src='/images/dailyCheckIn/arrow.svg'
            alt='arrow'
            className='w-3'
          />
        </button>
        <div className='mx-4 h-[0.3vw] bg-[#f6f6fa]' />

        {/* {safeCodepushVersionCompare(AuthStore.codePushVersion, '6.8.15') >= 0 &&
          AB_V3(UserStore.userInfo?._id, 'checkInManbogi0213', [100, 0]) ===
            'b' && (
            <button
              type='button'
              className='flex items-center justify-start px-6 py-6 duration-200 box-shadow outline-0 active:brightness-90 active:scale-95'
              onClick={async () => {
                await utility.sleep(50)
                navigate(`/check-in-manbogi?token=${token}&isFrom=web`)
              }}
            >
              <img
                src='/images/dailyCheckIn/만보기.svg'
                alt='pig'
                className='mr-2 -ml-2'
              />
              <div className='flex flex-col items-start justify-center w-full'>
                <span className='text-[4.5vw] mb-1'>돈버는 만보기</span>
                <span className='text-[4vw] font-normal text-[#9C9DA4]'>
                  걸음수만큼 보상을 받아요
                </span>
              </div>
              <img
                src='/images/dailyCheckIn/arrow.svg'
                alt='arrow'
                className='w-3'
              />
            </button>
          )} */}

        <Button
          appearance=' '
          className='relative mx-[4vw] mb-4 duration-200 bg-[#FFEDEE] rounded-xl h-14 outline-0 active:brightness-90 active:scale-95'
          onClick={() => {
            setBottomSheetOpen(true)
          }}
        >
          {Object.values(missionConfig ?? {})?.filter(
            (el) => el?.isDisable === false && el?.isShow === true,
          )?.length > 0 && (
            <div
              style={{
                borderWidth: 2,
                borderRadius: 99,
                height: 30,
                width: 30,
                position: 'absolute',
                left: '93%',
                top: '-15%',
                backgroundColor: '#E93E47',
                borderColor: '#E93E47',
              }}
              className='flex items-center justify-center text-[5vw] font-semibold text-white '
            >
              {
                Object.values(missionConfig)?.filter(
                  (el) => el?.isDisable === false && el?.isShow === true,
                )?.length
              }
            </div>
          )}
          <span className='text-xl font-bold text-red-500'>
            다양한 미션 확인하기
          </span>
        </Button>
      </div>
    </div>
  )
}

export default SubMenuButtonGroup
