import { Button, Spinner } from '@material-tailwind/react'
import { observer } from 'mobx-react-lite'
import React, { memo, useEffect, useState } from 'react'

import useInterval from '../../../hooks/useInterval'
import dealImages from '../../../images/deal'
import AuthStore from '../../../stores/AuthStore'
import ItemStore from '../../../stores/ItemStore'
import UserStore from '../../../stores/UserStore'
import backendApis from '../../../utils/backendApis'
import insertDealHandler from '../../../utils/insertDealHandler'
import GaugeTimer from '../../gaugeTimer'
import HorizontalList from '../item/horizontalList'
import TimeDealItemComponent from '../item/timeDealItemComponent'
import VerticalList from '../item/verticalList'
import { getNextTimeText, getThisTimeText } from '../text/timeText'
import InsertDealSection from './insertDealSection'

const categoryIds = [-1, 3, 5, 0, 1, 6, 8, 9]
const CategoryName = (categoryId) => {
  if (categoryId === -1) {
    return '전체'
  }
  if (categoryId === 3) {
    return '식품'
  }
  if (categoryId === 5) {
    return '생활'
  }
  if (categoryId === 0) {
    return '패션'
  }
  if (categoryId === 1) {
    return '뷰티'
  }
  if (categoryId === 6) {
    return '건강'
  }
  if (categoryId === 8) {
    return '가전'
  }
  if (categoryId === 9) {
    return '리빙'
  }
}

function getNextHour() {
  const now = new Date()
  const nextHour = new Date(now)
  nextHour.setHours(nextHour.getHours() + 1)
  nextHour.setMinutes(0)
  nextHour.setSeconds(0)

  return nextHour
}
const MainTimeDealName = 'mainTimeDeal'
const ForecastedTimeDealName = 'forecastedTimeDeal'

const TimeDealSection = ({ focused }) => {
  const [dealItemsInfo, setDealItemsInfo] = useState([])
  const [indices, setIndices] = useState([])
  const [version, setVersion] = useState(MainTimeDealName)
  const [categoryId, setCategoryId] = useState(-1)
  const [loadNum, setLoadNum] = useState(0)
  const [reloadToggle, setReloadToggle] = useState(false)
  const condition = {
    categoryId,
  }
  const underlineStyle = 'font-bold underline underline-offset-18 decoration-4'
  const dealType = 'timeDeal'
  const SCREEN_HEIGHT = window.innerHeight
  const SCREEN_WIDTH = window.innerWidth
  const currentHour = new Date().getHours()
  const laterHour = currentHour + 1

  const loadMore = async () => {
    const itemIndices = indices.slice(0, 32)
    setIndices((arr) => arr.slice(32))
    await fetchDealItem(
      dealType,
      loadNum,
      version,
      itemIndices,
      condition,
      false,
    )
  }

  const initialFetch = async (version) => {
    await fetchDealItem(dealType, loadNum, version, [], condition, true)
  }

  const fetchDealItem = async (
    dealType,
    loadNum,
    version,
    indices,
    condition,
    first = false,
  ) => {
    const itemIndices = indices.slice(0, 32)
    setIndices((arr) => arr.slice(32))
    const result = await backendApis.getDealItemsV3(
      dealType,
      loadNum,
      version,
      itemIndices,
      condition,
    )
    dealStateSetter(result, first)
  }

  const dealStateSetter = (result, first) => {
    if (result?.status === 200) {
      const dealItems = result?.data
      const cheapDealItems = dealItems.filter(
        (item) => parseInt(item?.minDealTeamPurchasePrice, 10) < 10000,
      )
      insertDealHandler.dataSetter('priceSortDeal', cheapDealItems)
      // const mixedDealItems = useInsertDeal
      //   ? insertDealHandler.dataMixer(dealItems, ItemStore?.insertDealInfo)
      //   : dealItems
      const mixedDealItems = dealItems

      if (first) {
        setDealItemsInfo(mixedDealItems)
        setLoadNum(loadNum + dealItems?.length)
      } else {
        setDealItemsInfo((arr) => [...arr, ...mixedDealItems])
        setLoadNum(loadNum + dealItems?.length)
      }
      if (result?.itemIndices?.length > 0) {
        setIndices(result?.itemIndices)
      }
    }
  }

  const TimeSelectSection = memo(() => {
    const handleClick = async (selectedVersion) => {
      if (version !== selectedVersion && dealItemsInfo?.length > 0) {
        setDealItemsInfo([])
        setVersion(selectedVersion)
        setIndices([...indices?.reverse()])
        await fetchDealItem(
          dealType,
          0,
          selectedVersion,
          [...indices?.reverse()],
          condition,
          true,
        )
      }
    }

    return (
      <div className='flex flex-row justify-between flex-1 w-full h-16 py-3 border-t border-gray-300'>
        <div
          aria-hidden
          onClick={() => handleClick(MainTimeDealName)}
          className={`flex flex-row items-center justify-center flex-1 ${
            version === MainTimeDealName && underlineStyle
          }`}
        >
          {getThisTimeText(currentHour)}
        </div>
        {laterHour < 23 && laterHour > 7 && (
          <div
            aria-hidden
            onClick={() => handleClick(ForecastedTimeDealName)}
            className={`flex flex-row items-center justify-center flex-1 ${
              version === ForecastedTimeDealName && underlineStyle
            }`}
          >
            {getNextTimeText(laterHour)}
          </div>
        )}
      </div>
    )
  })

  const CategorySelectSection = () => {
    const renderItem = memo(({ index, style, data }) => {
      return (
        <div
          key={`${index}`}
          aria-hidden
          onClick={async () => {
            if (categoryId !== data[index]) {
              setCategoryId(data[index])
              const condition = {
                categoryId: data[index],
              }
              await fetchDealItem(
                'timeDeal',
                0,
                MainTimeDealName,
                [],
                condition,
                true,
              )
            }
          }}
          className={`flex flex-1 text-sm py-2 ${
            categoryId === data[index]
              ? 'bg-red-100 text-[#EB4F47] font-bold'
              : 'bg-[#F7F7F7] text-gray-500'
          }  rounded-lg items-center justify-center mx-4`}
          style={{ ...style, width: style?.width - 8 }}
        >
          {CategoryName(data[index])}
        </div>
      )
    })
    return (
      <div className='flex flex-col items-center justify-center flex-1 h-10 mt-2 mr-4'>
        <HorizontalList
          screenWidth={SCREEN_WIDTH}
          itemHeight={28}
          itemWidth={60}
          itemCount={categoryIds?.length || 0}
          itemData={categoryIds}
          renderItem={renderItem}
          isItemList={false}
        />
      </div>
    )
  }

  // const Skeleton = () => {
  //   return (
  //     <>
  //       <TimeDealItemComponent empty />
  //       <TimeDealItemComponent empty />
  //       <TimeDealItemComponent empty />
  //       <TimeDealItemComponent empty />
  //       <TimeDealItemComponent empty />
  //       <TimeDealItemComponent empty />
  //     </>
  //   )
  // }
  const renderItem = (index, data, isScrolling) => {
    const hour = new Date(new Date().getTime()).getHours()
    if (data?.inserted === true) {
      return <InsertDealSection key={`${index}`} data={data} />
    }
    if (hour >= 7 && hour < 23) {
      return (
        <TimeDealItemComponent
          key={`${index}`}
          itemInfo={data}
          version={version}
          isFrom={`TimeDealSection_${version}`}
          isScrolling={isScrolling}
        />
      )
    }
    return (
      // 11시가 넘어가면 예상딜만 보여준다.
      <TimeDealItemComponent
        key={`${index}`}
        itemInfo={data}
        version={ForecastedTimeDealName}
        isFrom={`TimeDealSection_${ForecastedTimeDealName}`}
        isScrolling={isScrolling}
      />
    )
  }

  useEffect(() => {
    if (AuthStore.token) {
      initialFetch(MainTimeDealName)
    }
  }, [AuthStore.token, reloadToggle])

  useInterval(() => {
    if (focused) {
      const hour = new Date(new Date().getTime()).getHours()
      if (currentHour !== hour && hour >= 7 && hour <= 23) {
        // 시간이 바뀌고 & 스크린 onFocus면 다시 fetch
        setVersion(MainTimeDealName)
        initialFetch(MainTimeDealName)
        setCategoryId(-1)
      }
    }
  }, 1000)

  return (
    <>
      <TimeSelectSection />
      <CategorySelectSection />
      {dealItemsInfo?.length > 0 ? (
        <VerticalList
          itemData={dealItemsInfo}
          height={SCREEN_HEIGHT}
          renderItem={renderItem}
          loadMore={loadMore}
          isFrom={`TimeDealSection_${version}`}
          isItemList
        />
      ) : (
        <div className='flex flex-col items-center justify-start h-screen'>
          {/* <Skeleton /> */}
          <div className='flex flex-row items-center justify-center mt-20 text-lg font-bold text-blue-500 animate-pulse'>
            <Spinner className='w-4 h-4' />
            <span className='ml-2'>상품 정보를 불러오는 중입니다.</span>
          </div>
          <Button
            onClick={() => setReloadToggle(!!reloadToggle)}
            className='mt-2'
            color='gray'
          >
            새로고침
          </Button>
        </div>
      )}
    </>
  )
}
export default observer(TimeDealSection)
