import moment from 'moment'
import React, { useState } from 'react'

import altoonCMSBackendApis from '../../../utils/altoonCMSBackendApis'

const PromotionEditor = ({
  promotionInfo,
  setPromotionInfo,
  isReadOnly = false,
  toonId,
  handleSave,
}) => {
  const [promotionType, setPromotionType] = useState('') // waitForFree, bulkPurchase, themeSale
  const [tempData, setTempData] = useState({})

  const handlePromotionChange = async (type, data) => {
    // Create a new object with only the promotion type properties
    const newPromotionInfo = {}

    // Copy existing promotions except the current type
    if (promotionInfo) {
      Object.entries(promotionInfo).forEach(([key, value]) => {
        if (typeof value === 'object' && value !== null) {
          newPromotionInfo[key] = value
        }
      })
    }

    // Add or remove the current promotion type
    if (data === null) {
      delete newPromotionInfo[type]
    } else {
      // Convert dates to Date objects
      const processedData = { ...data }
      newPromotionInfo[type] = processedData
    }

    try {
      const result = await altoonCMSBackendApis.updateToonInfo({
        toonId,
        field: 'promotionInfo',
        value: JSON.stringify(newPromotionInfo),
        isDate: false,
      })
      if (result?.status === 200) {
        setPromotionInfo(newPromotionInfo)
        setPromotionType('') // 등록 후 선택된 프로모션 타입 초기화
        if (data === null) {
          alert('프로모션이 삭제되었습니다.')
        } else {
          alert('프로모션이 등록되었습니다.')
        }
        handleSave()
      } else {
        alert('프로모션 업데이트 중 오류가 발생했습니다.')
      }
    } catch (error) {
      alert('프로모션 업데이트 중 오류가 발생했습니다.')
    }
  }

  const handleTempDataChange = (type, field, value) => {
    setTempData((prev) => {
      const newData = {
        ...prev,
        [type]: {
          ...(prev[type] || {}),
          [field]: value,
        },
      }

      return newData
    })
  }

  const renderWaitForFreeSection = ({ type }) => {
    return (
      <div className='space-y-4 p-4 border rounded'>
        <h3 className='font-bold'>기다리면 무료</h3>
        <div className='grid grid-cols-2 gap-4'>
          <div>
            <div className='block text-sm font-medium mb-1'>
              시작일 <span className='text-red-500'>*</span>
            </div>
            <input
              id='wff-start-date'
              type='datetime-local'
              value={tempData.eventStartedAt}
              onChange={(e) =>
                handleTempDataChange(
                  'waitForFree',
                  'eventStartedAt',
                  new Date(e.target.value),
                )
              }
              className='w-full p-2 border rounded'
            />
          </div>
          <div>
            <div className='block text-sm font-medium mb-1'>
              종료일 <span className='text-red-500'>*</span>
            </div>
            <input
              id='wff-end-date'
              type='datetime-local'
              value={tempData.eventEndedAt}
              onChange={(e) =>
                handleTempDataChange(
                  'waitForFree',
                  'eventEndedAt',
                  new Date(e.target.value),
                )
              }
              className='w-full p-2 border rounded'
            />
          </div>
        </div>
        <div className='grid grid-cols-2 gap-4'>
          <div>
            <div className='block text-sm font-medium mb-1'>
              기다무 시간
              <span className='text-red-500'> *</span>
            </div>
            <input
              id='wff-waiting-time'
              type='number'
              value={tempData.waitingTime}
              onChange={(e) =>
                handleTempDataChange(
                  'waitForFree',
                  'waitingTime',
                  parseInt(e.target.value, 10),
                )
              }
              className='w-full p-2 border rounded'
              min='0'
            />
          </div>
          <div>
            <div className='block text-sm font-medium mb-1'>
              기다무 제한 최신 회차수
              <span className='text-red-500'> *</span>
            </div>
            <input
              id='wff-chapter-limit'
              type='number'
              value={tempData.recentChapterLimit}
              onChange={(e) =>
                handleTempDataChange(
                  'waitForFree',
                  'recentChapterLimit',
                  parseInt(e.target.value, 10),
                )
              }
              className='w-full p-2 border rounded'
              min='0'
            />
          </div>
        </div>
        <div className='flex justify-end space-x-2'>
          <button
            type='button'
            onClick={() => {
              const currentData = tempData.waitForFree || {}
              if (
                !currentData.eventStartedAt ||
                !currentData.eventEndedAt ||
                !currentData.waitingTime ||
                !currentData.recentChapterLimit
              ) {
                alert('모든 필드를 입력해주세요.')
                return
              }
              handlePromotionChange('waitForFree', currentData)
              setTempData((prev) => ({ ...prev, waitForFree: null }))
            }}
            className='px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600'
          >
            {type === 'new' ? '등록' : '수정'}
          </button>
          <button
            type='button'
            onClick={() => {
              handlePromotionChange('waitForFree', null)
              setTempData((prev) => ({ ...prev, waitForFree: null }))
              setPromotionType('')
            }}
            className='px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600'
          >
            삭제
          </button>
        </div>
      </div>
    )
  }

  const renderBulkPurchaseSection = ({ type }) => {
    return (
      <div className='space-y-4 p-4 border rounded'>
        <h3 className='font-bold'>전권대여</h3>
        <div className='grid grid-cols-2 gap-4'>
          <div>
            <div className='block text-sm font-medium mb-1'>
              시작일 <span className='text-red-500'>*</span>
            </div>
            <input
              id='bp-start-date'
              type='datetime-local'
              value={tempData.eventStartedAt}
              onChange={(e) =>
                handleTempDataChange(
                  'bulkPurchase',
                  'eventStartedAt',
                  new Date(e.target.value),
                )
              }
              className='w-full p-2 border rounded'
            />
          </div>
          <div>
            <div className='block text-sm font-medium mb-1'>
              종료일 <span className='text-red-500'>*</span>
            </div>
            <input
              id='bp-end-date'
              type='datetime-local'
              value={tempData.eventEndedAt}
              onChange={(e) =>
                handleTempDataChange(
                  'bulkPurchase',
                  'eventEndedAt',
                  new Date(e.target.value),
                )
              }
              className='w-full p-2 border rounded'
            />
          </div>
        </div>
        <div>
          <div className='block text-sm font-medium mb-1'>
            전체 가격
            <span className='text-red-500'> *</span>
          </div>
          <input
            id='bp-total-price'
            type='number'
            value={tempData.totalPrice}
            onChange={(e) =>
              handleTempDataChange(
                'bulkPurchase',
                'totalPrice',
                parseInt(e.target.value, 10),
              )
            }
            className='w-full p-2 border rounded'
            min='0'
          />
        </div>
        <div className='flex justify-end space-x-2'>
          <button
            type='button'
            onClick={() => {
              const currentData = tempData.bulkPurchase || {}
              if (
                !currentData.eventStartedAt ||
                !currentData.eventEndedAt ||
                !currentData.totalPrice
              ) {
                alert('모든 필드를 입력해주세요.')
                return
              }
              handlePromotionChange('bulkPurchase', currentData)
              setTempData((prev) => ({ ...prev, bulkPurchase: null }))
            }}
            className='px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600'
          >
            {type === 'new' ? '등록' : '수정'}
          </button>
          <button
            type='button'
            onClick={() => {
              handlePromotionChange('bulkPurchase', null)
              setTempData((prev) => ({ ...prev, bulkPurchase: null }))
              setPromotionType('')
            }}
            className='px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600'
          >
            삭제
          </button>
        </div>
      </div>
    )
  }

  const renderThemeSaleSection = ({ type }) => {
    return (
      <div className='space-y-4 p-4 border rounded'>
        <div className='flex items-center justify-between'>
          <h3 className='font-bold'>기획전 </h3>
          <div className='text-sm text-gray-500'>
            * 1)단순문구 혹은 2)할인율&할인가 중 하나는 필수
          </div>
        </div>
        <div>
          <div
            className='block text-sm font-medium mb-1'
            htmlFor='ts-more-free'
          >
            단순 문구
          </div>
          <input
            id='ts-more-free'
            type='text'
            value={tempData.moreFree}
            onChange={(e) =>
              handleTempDataChange('themeSale', 'moreFree', e.target.value)
            }
            className='w-full p-2 border rounded'
            placeholder='예: 무료 3화 → 6화'
          />
        </div>
        <div className='grid grid-cols-2 gap-4'>
          <div>
            <div className='block text-sm font-medium mb-1'>
              시작일 <span className='text-red-500'>*</span>
            </div>
            <input
              id='ts-start-date'
              type='datetime-local'
              value={tempData.eventStartedAt}
              onChange={(e) =>
                handleTempDataChange(
                  'themeSale',
                  'eventStartedAt',
                  new Date(e.target.value),
                )
              }
              className='w-full p-2 border rounded'
            />
          </div>
          <div>
            <div className='block text-sm font-medium mb-1'>
              종료일 <span className='text-red-500'>*</span>
            </div>
            <input
              id='ts-end-date'
              type='datetime-local'
              value={tempData.eventEndedAt}
              onChange={(e) =>
                handleTempDataChange(
                  'themeSale',
                  'eventEndedAt',
                  new Date(e.target.value),
                )
              }
              className='w-full p-2 border rounded'
            />
          </div>
        </div>
        <div className='grid grid-cols-2 gap-4'>
          <div>
            <div className='block text-sm font-medium mb-1'>할인율 (%)</div>
            <input
              id='ts-discount-rate'
              type='number'
              value={tempData.discountRate}
              onChange={(e) =>
                handleTempDataChange(
                  'themeSale',
                  'discountRate',
                  parseInt(e.target.value, 10),
                )
              }
              className='w-full p-2 border rounded'
              min='0'
              max='100'
            />
          </div>
          <div>
            <div className='block text-sm font-medium mb-1'>할인가</div>
            <input
              id='ts-sale-price'
              type='number'
              value={tempData.salePrice}
              onChange={(e) =>
                handleTempDataChange(
                  'themeSale',
                  'salePrice',
                  parseInt(e.target.value, 10),
                )
              }
              className='w-full p-2 border rounded'
              min='0'
            />
          </div>
        </div>
        <div className='flex justify-end space-x-2'>
          <button
            type='button'
            onClick={() => {
              const currentData = tempData.themeSale || {}
              if (currentData.moreFree) {
                handlePromotionChange('themeSale', {
                  moreFree: currentData.moreFree,
                })
                setTempData((prev) => ({ ...prev, themeSale: null }))
                return
              }

              if (
                !currentData.eventStartedAt ||
                !currentData.eventEndedAt ||
                !currentData.discountRate ||
                !currentData.salePrice
              ) {
                alert('단순 문구를 입력하거나, 모든 할인 정보를 입력해주세요.')
                return
              }
              handlePromotionChange('themeSale', currentData)
              setTempData((prev) => ({ ...prev, themeSale: null }))
            }}
            className='px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600'
          >
            {type === 'new' ? '등록' : '수정'}
          </button>
          <button
            type='button'
            onClick={() => {
              handlePromotionChange('themeSale', null)
              setTempData((prev) => ({ ...prev, themeSale: null }))
              setPromotionType('')
            }}
            className='px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600'
          >
            삭제
          </button>
        </div>
      </div>
    )
  }

  if (isReadOnly) {
    const isOngoing = ({ promotionType }) => {
      if (promotionInfo[promotionType]) {
        return (
          moment().isAfter(promotionInfo[promotionType].eventStartedAt) &&
          moment().isBefore(promotionInfo[promotionType].eventEndedAt)
        )
      }
      return false
    }

    return (
      <div className='space-y-4'>
        {promotionInfo?.waitForFree && (
          <div
            className={`${
              isOngoing({ promotionType: 'waitForFree' })
                ? 'space-y-4 p-4 border rounded bg-[#2FD314]'
                : 'space-y-4 p-4 border rounded'
            }`}
          >
            <h3 className='font-bold'>기다리면 무료</h3>
            <div className='space-y-2'>
              <p>
                시작일:{' '}
                {promotionInfo.waitForFree.eventStartedAt
                  ? moment(promotionInfo.waitForFree.eventStartedAt).format(
                      'YYYY-MM-DD HH:mm',
                    )
                  : '-'}
              </p>
              <p>
                종료일:{' '}
                {promotionInfo.waitForFree.eventEndedAt
                  ? moment(promotionInfo.waitForFree.eventEndedAt).format(
                      'YYYY-MM-DD HH:mm',
                    )
                  : '-'}
              </p>
              <p>
                기다무 시간: {promotionInfo.waitForFree.waitingTime || '-'}일
              </p>
              <p>
                최신화 제한:{' '}
                {promotionInfo.waitForFree.recentChapterLimit || '-'}화
              </p>
            </div>
          </div>
        )}
        {promotionInfo?.bulkPurchase && (
          <div
            className={`${
              isOngoing({ promotionType: 'bulkPurchase' })
                ? 'space-y-4 p-4 border rounded bg-[#2FD314]'
                : 'space-y-4 p-4 border rounded'
            }`}
          >
            <h3 className='font-bold'>전권대여</h3>
            <div className='space-y-2'>
              <p>
                시작일:{' '}
                {promotionInfo.bulkPurchase.eventStartedAt
                  ? moment(promotionInfo.bulkPurchase.eventStartedAt).format(
                      'YYYY-MM-DD HH:mm',
                    )
                  : '-'}
              </p>
              <p>
                종료일:{' '}
                {promotionInfo.bulkPurchase.eventEndedAt
                  ? moment(promotionInfo.bulkPurchase.eventEndedAt).format(
                      'YYYY-MM-DD HH:mm',
                    )
                  : '-'}
              </p>
              <p>
                전체 가격:{' '}
                {promotionInfo.bulkPurchase.totalPrice
                  ? `${promotionInfo.bulkPurchase.totalPrice.toLocaleString()}원`
                  : '-'}
              </p>
            </div>
          </div>
        )}
        {promotionInfo?.themeSale && (
          <div
            className={`${
              isOngoing({ promotionType: 'themeSale' })
                ? 'space-y-4 p-4 border rounded bg-[#2FD314]'
                : 'space-y-4 p-4 border rounded'
            }`}
          >
            <h3 className='font-bold'>기획전</h3>
            <div className='space-y-2'>
              {promotionInfo.themeSale.moreFree && (
                <p>단순 문구: {promotionInfo.themeSale.moreFree}</p>
              )}
              {promotionInfo.themeSale.eventStartedAt && (
                <>
                  <p>
                    시작일:{' '}
                    {moment(promotionInfo.themeSale.eventStartedAt).format(
                      'YYYY-MM-DD HH:mm',
                    )}
                  </p>
                  <p>
                    종료일:{' '}
                    {moment(promotionInfo.themeSale.eventEndedAt).format(
                      'YYYY-MM-DD HH:mm',
                    )}
                  </p>
                  <p>할인율: {promotionInfo.themeSale.discountRate}%</p>
                  <p>
                    할인가:{' '}
                    {promotionInfo.themeSale.salePrice
                      ? `${promotionInfo.themeSale.salePrice.toLocaleString()}원`
                      : '-'}
                  </p>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    )
  }

  return (
    <div className='space-y-4'>
      {/* 기존 프로모션 표시 */}
      {promotionInfo && Object.keys(promotionInfo).length > 0 && (
        <div className='mb-8'>
          <div className='text-lg font-bold'>기존 프로모션</div>
          {promotionInfo?.waitForFree &&
            renderWaitForFreeSection({ type: 'existing' })}
          {promotionInfo?.bulkPurchase &&
            renderBulkPurchaseSection({ type: 'existing' })}
          {promotionInfo?.themeSale &&
            renderThemeSaleSection({ type: 'existing' })}
        </div>
      )}

      <div className='flex items-center space-x-4'>
        <select
          value={promotionType}
          onChange={(e) => {
            const selectedType = e.target.value
            setPromotionType(selectedType)
          }}
          className='pl-2 border rounded'
        >
          <option value=''>추가할 프로모션 선택</option>
          {!promotionInfo?.waitForFree && (
            <option value='waitForFree'>기다리면 무료</option>
          )}
          {!promotionInfo?.bulkPurchase && (
            <option value='bulkPurchase'>전권대여</option>
          )}
          {!promotionInfo?.themeSale && (
            <option value='themeSale'>기획전</option>
          )}
        </select>
      </div>

      {promotionType === 'waitForFree' &&
        renderWaitForFreeSection({ type: 'new' })}
      {promotionType === 'bulkPurchase' &&
        renderBulkPurchaseSection({ type: 'new' })}
      {promotionType === 'themeSale' && renderThemeSaleSection({ type: 'new' })}
    </div>
  )
}

export default PromotionEditor
