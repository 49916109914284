import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import AltoonAction from '../../actions/AltoonAction'
import { useMyFlow } from '../../hooks/altoon/useMyFlow.ts'
import AltoonUserStore from '../../stores/AltoonUserStore'
import ToastStore from '../../stores/ToastStore'
import UserStore from '../../stores/UserStore'
import THModalStore from '../../stores/treasureHunt/THModalStore'
import backendApis from '../../utils/backendApis.ts'

/**
 * 올툰 이벤트 페이지 (id기반 이벤트 정보 조회)
 */
let isPressed = false

const sleep = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms)
  })

const MissionEventPage = observer(({ eventInfo }) => {
  const { push } = useMyFlow()

  const [myMissionData, setMyMissionData] = useState()
  const [isScreenFocused, setIsScreenFocused] = useState(true)
  const [isAnimated, setIsAnimated] = useState(false)
  // const seolFollowEvent = window.localStorage.getItem('seol_follow_event')

  const goodsIcon = eventInfo?.iconUri // 재화 아이콘 e.g. '/images/toon/mainEventMission/heartIcon.png'
  const goodsName = eventInfo?.goodsName // 재화 이름 e.g. 'heart'
  const goodsNameKor = eventInfo?.goodsNameKor // 재화 한국명 e.g. '하트'
  const backgroundColor = eventInfo?.backgroundColor // 미션 배경색 e.g. '#FE6564'
  const keyColor = eventInfo?.keyColor // 키컬러 e.g. '#FE6564'
  const subColor = eventInfo?.subColor // 서브키컬러 e.g. '#FFC548'
  const backgroundImageUri = eventInfo?.backgroundImageUri // 메인이미지 url e.g. 'https://assets.ilevit.com/b8803334-067a-4d33-ae7c-c3c338666e34.png'
  const goodsNormalGifUri = eventInfo?.goodsNormalGifUri // 재화 GIF (일반, 클릭) e.g. '/images/toon/mainEventMission/heartIcon_opening.gif'
  const goodsPopGifUri = eventInfo?.goodsPopGifUri // 재화 GIF (팝업, 클릭) e.g. '/images/toon/mainEventMission/heartIcon_opening.gif'

  // 한글 조사 처리 함수
  const getJosa = (str, type) => {
    const hasJongseong = (str) => {
      const lastChar = str[str.length - 1]
      return (lastChar.charCodeAt(0) - 0xac00) % 28 > 0
    }

    const hasJong = hasJongseong(str)
    switch (type) {
      case '이/가':
        return hasJong ? '이' : '가'
      case '은/는':
        return hasJong ? '은' : '는'
      case '을/를':
        return hasJong ? '을' : '를'
      default:
        return ''
    }
  }

  const handleVisibilityChange = async () => {
    if (document.hidden) {
      setIsScreenFocused(false)
    } else {
      setIsScreenFocused(true)
    }
  }

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange)
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  useEffect(() => {
    const fetchMissionData = async () => {
      const missionData = await backendApis.loadMissionData(eventInfo?._id)
      if (missionData?.status === 200) {
        setMyMissionData(missionData?.data)

        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

        const showModalSequentially = async (initModalList) => {
          for (const init of initModalList) {
            await delay(800)
            THModalStore.setType(init)
            await backendApis.setMissionInitModal({ initModalList: [] })
          }
        }

        if (missionData?.data?.initModalList) {
          showModalSequentially(missionData.data.initModalList)
        }
      }
    }
    fetchMissionData()
  }, [isScreenFocused])

  const openRewardHandler = async () => {
    if (
      !myMissionData ||
      myMissionData?.receivedReward - myMissionData?.usedReward < 1
    ) {
      THModalStore.setType({
        type: 'reward',
        config: {
          rewardName: `${goodsNameKor} 부족`,
          description: `${goodsNameKor}${getJosa(
            goodsNameKor,
            '이/가',
          )} 부족해요. 미션을 완료해보세요!`,
          src: goodsIcon,
          text: '확인',
          forceOpen: true,
        },
      })
      return
    }

    const result = await backendApis.openMissionReward({
      eventId: eventInfo?._id,
    })

    if (result?.status === 200) {
      THModalStore.setType({
        type: 'reward',
        config: {
          rewardName: `${goodsNameKor} 열기`,
          description: `${goodsNameKor}${getJosa(goodsNameKor, '을/를')} 열고 ${
            result?.data?.rewardAmount
          }C을 받았어요!`,
          src: `/images/toon/toonCoin.png`,
          text: '확인',
          forceOpen: true,
        },
      })
      setMyMissionData(result?.data)
    } else if (result?.status === 204) {
      THModalStore.setType({
        type: 'reward',
        config: {
          rewardName: `${goodsNameKor} 부족`,
          description: `${goodsNameKor}${getJosa(
            goodsNameKor,
            '이/가',
          )} 부족해요. 미션을 완료해보세요!`,
          src: goodsIcon,
          text: '확인',
          forceOpen: true,
        },
      })
      setMyMissionData(result?.data)
    } else {
      ToastStore.networkError()
    }
  }

  const missionRewardHandler = async ({ missionType, missionReward }) => {
    const result = await backendApis.updateMissionData({
      eventId: eventInfo?._id,
      missionType,
      missionReward,
    })
    if (result?.status === 200) {
      ToastStore.toastOn({
        type: 'emoji',
        message: `${goodsNameKor}${getJosa(
          goodsNameKor,
          '을/를',
        )} ${missionReward}개 받았어요!`,
        duration: 2000,
        emoji: '🎁',
      })
      setMyMissionData(result?.data)
    } else if (result?.status === 204) {
      ToastStore.toastOn({
        type: 'emoji',
        message: `보상이 이미 지급되었어요!`,
        duration: 2000,
        emoji: '🎁',
      })
    } else if (result?.status === 400) {
      ToastStore.networkError()
    } else {
      ToastStore.networkError()
    }
  }

  const MainImageComponent = () => {
    return (
      <div
        className='w-[100vw] h-[140vw] flexCol justify-around items-center px-4 pt-[36vw] pb-4 font-bold text-[4.8vw]'
        style={{
          backgroundImage: `url(${backgroundImageUri})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className='w-[38vw] py-[3vw] rounded-lg text-center bg-white border-[0.8vw] border-[#FFC548]'>
          <div className='text-[3.8vw]'>나의 {goodsNameKor}</div>
          <div className='text-[6vw] text-[#FE6564]'>
            {myMissionData?.receivedReward - myMissionData?.usedReward || 0}개
          </div>
        </div>
        <img
          src={isAnimated ? goodsPopGifUri : goodsNormalGifUri}
          className='w-[50vw] h-auto'
          alt=''
        />

        <div className='flexCol items-center pt-[2vw]'>
          <div className='text-[3.6vw] text-[#3D000020]'>
            최대 300코인 랜덤 지급 (매일 초기화)
          </div>
          <button
            type='button'
            className={
              myMissionData?.receivedReward - myMissionData?.usedReward < 1
                ? 'w-[70vw] py-[5vw] text-[#BCBDC3]'
                : 'w-[70vw] py-[5vw] text-white'
            }
            style={{
              backgroundImage:
                myMissionData?.receivedReward - myMissionData?.usedReward < 1
                  ? "url('/images/toon/eventPage/grayButton.png')"
                  : "url('/images/toon/eventPage/redButton.png')",
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
            onClick={async () => {
              isPressed = true

              if (isAnimated) return

              setIsAnimated(true)
              setTimeout(() => {
                setIsAnimated(false)
              }, 1500)
              await sleep(1300)
              openRewardHandler()

              isPressed = false
            }}
          >
            {goodsNameKor} 열기
          </button>
        </div>
      </div>
    )
  }
  const MissionComponent = ({
    missionType,
    missionTitle,
    missionCount,
    missionRewardType = goodsName,
    missionRewardAmount,
    missionDescription,
    buttonText,
    buttonClick,
  }) => {
    // missionCount가 있는 미션이면 미션 카운트 가져오고 없는 미션이면 999로 설정
    const myMissionCount =
      missionCount > 0
        ? myMissionData?.missionLog?.[missionType]?.missionCount || 0
        : 999
    const isDisabled =
      myMissionCount < missionCount ||
      myMissionData?.missionLog?.[missionType]?.status === 'finished'

    return (
      <div
        className='w-full px-[4vw] py-[4vw] mb-[4vw] flexRow justify-between items-center bg-[#FFFCF1] text-[#411308] rounded-lg'
        style={{
          backgroundColor: `#${subColor}`,
        }}
      >
        <div className='flexRow items-center'>
          <img
            src={`/images/toon/mainEventMission/${missionType}MissionIcon.png`}
            alt='check'
            className='w-[10vw] h-[10vw] mr-[3vw]'
          />
          <div className='flexCol items-start font-bold'>
            <div className='pb-[1vw] text-[4.2vw]'>{missionTitle}</div>
            <div className='flexRow items-center text-[3.6vw]'>
              <img
                src={
                  missionRewardType === goodsName
                    ? goodsIcon
                    : '/images/toon/toonCoin.png'
                }
                alt='coin'
                className='w-[5.2vw] h-auto mr-[1vw]'
              />
              {missionRewardAmount}
              {missionRewardType === goodsName ? '개' : 'C'}{' '}
              {missionDescription}
              {missionCount && (
                <div
                  className='ml-[4vw] text-[3.6vw]'
                  style={{ color: `#${keyColor}` }}
                >
                  ({Math.min(myMissionCount, 3)}/{missionCount}회 읽음)
                </div>
              )}
            </div>
          </div>
        </div>
        <button
          type='button'
          className='px-[4vw] py-[2vw] text-white text-[4vw] font-semibold rounded-full'
          style={
            !isDisabled
              ? { backgroundColor: `#${keyColor}` }
              : { backgroundColor: '#FFE2C5' }
          }
          disabled={isDisabled}
          onClick={() => {
            if (isPressed) return

            buttonClick()
          }}
        >
          {(() => {
            if (isDisabled) {
              return myMissionData?.missionLog?.[missionType]?.status ===
                'finished'
                ? '미션완료'
                : '감상하기'
            }
            return buttonText
          })()}
        </button>
      </div>
    )
  }

  const MissionListComponent = () => {
    return (
      <div className='px-[4vw]'>
        <div className='mt-[2vw] py-[4vw] text-[4.6vw] font-bold'>
          오늘의 {goodsNameKor} 미션
        </div>
        <div className='flexCol text-white'>
          {myMissionData?.missionLog?.checkIn?.status !== 'finished' && (
            <div
              className='w-full px-[4vw] py-[4vw] mb-[4vw] flexCol justify-between items-center text-[4vw] text-[#411308] font-bold rounded-lg'
              style={{
                backgroundColor: `#${subColor}`,
              }}
            >
              <img
                src={goodsIcon}
                alt=''
                className='w-[10vw] h-auto mb-[2vw]'
              />
              출석하기 미션을 완료해야 오늘의 미션이 보여요!
            </div>
          )}

          <MissionComponent
            missionType='checkIn'
            missionTitle='매일 출석하기'
            missionRewardAmount={1}
            buttonText='출석하기'
            buttonClick={() => {
              isPressed = true

              missionRewardHandler({ missionType: 'checkIn', missionReward: 1 })

              isPressed = false
            }}
          />
          {myMissionData?.missionLog?.checkIn?.status === 'finished' && (
            <MissionComponent
              missionType='view'
              missionTitle='웹툰 유료회차 3화 감상하기'
              missionCount={3}
              missionRewardAmount={3}
              buttonText='보상받기'
              buttonClick={() => {
                isPressed = true

                missionRewardHandler({ missionType: 'view', missionReward: 3 })

                isPressed = false
              }}
            />
          )}
          {myMissionData?.missionLog?.checkIn?.status === 'finished' && (
            <MissionComponent
              missionType='share'
              missionTitle='친구에게 이벤트 공유하기'
              missionDescription='(매일 최대 10회)'
              missionRewardAmount={1}
              buttonText='공유하기'
              buttonClick={() => {
                isPressed = true

                AltoonAction.kakaoLinkShare({
                  shareType: 'Altoon_heartShare',
                  title: `[올툰] ${
                    UserStore?.userInfo?.userName
                      ? `${UserStore?.userInfo?.userName}님이`
                      : '친구가'
                  } 추천해요!`,
                  description: '무료로 즐기는 꿀잼 웹툰, 올툰',
                  imageUrl:
                    'https://assets.ilevit.com/ed6ab945-2f49-4eda-905c-8c13a299fb7b.png',
                  payload: {},
                  templateId: 104208,
                })

                isPressed = false
              }}
            />
          )}

          <MissionComponent
            missionType='inviteNew'
            missionTitle='새친구 초대하기'
            missionDescription=''
            missionRewardAmount={1000}
            missionRewardType='coin'
            buttonText='초대하기'
            buttonClick={() => {
              isPressed = true
              window.scrollTo(0, 0)
              push('AltoonTicketPage')
              isPressed = false
            }}
          />

          {/* {AltoonUserStore?.userData?.isAdmin === true &&
            !seolFollowEvent &&
            myMissionData?.missionLog?.checkIn?.status === 'finished' && (
              <MissionComponent
                missionType='followX'
                missionTitle='올툰 X 계정 팔로우하기'
                missionDescription=', 최초 1회'
                missionRewardAmount={1}
                buttonText='팔로우하기'
                buttonClick={() => {
                  isPressed = true

                  window.open('https://x.com/AlwayzKOR', '_blank')

                  missionRewardHandler({
                    missionType: 'followX',
                    missionReward: 1,
                  })

                  window.localStorage.setItem('seol_follow_event', new Date())

                  THModalStore.setType({
                    type: 'reward',
                    config: {
                      rewardName: `올툰 X 페이지 방문 성공!`,
                      src: `/images/toon/mainEventMission/heartIcon.png`,
                      description: '하트를 1개 받았어요!',
                      forceOpen: true,
                    },
                  })

                  isPressed = false
                }}
              />
            )} */}
        </div>
      </div>
    )
  }

  return (
    <div
      className='pb-10 bg-[#FFF5D5]'
      style={{ backgroundColor: `#${backgroundColor}` }}
    >
      <MainImageComponent />
      <MissionListComponent />
    </div>
  )
})

export default MissionEventPage
