import moment from 'moment'
import React, { useEffect, useState } from 'react'

import altoonCMSBackendApis from '../../../utils/altoonCMSBackendApis'

const FileUploadSection = ({
  title,
  description,
  accept,
  onUpload,
  fileInfo,
  isUploading,
}) => {
  const [selectedFile, setSelectedFile] = useState(null)

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0])
  }

  const handleUpload = async () => {
    if (!selectedFile) {
      alert('파일을 선택해주세요.')
      return
    }

    try {
      const result = await onUpload(selectedFile)
      if (result?.status === 200) {
        alert('업로드가 완료되었습니다.')
        setSelectedFile(null)
      } else {
        alert(result?.message)
      }
    } catch (error) {
      console.error('파일 업로드 중 오류 발생:', error)
      alert('파일 업로드 중 오류가 발생했습니다.')
    }
  }

  return (
    <div className='border rounded-lg p-4'>
      <div className='flex justify-between items-center mb-2'>
        <h3 className='text-lg font-semibold'>{title}</h3>
      </div>
      <p className='text-gray-600 mb-4'>{description}</p>
      <div className='flex items-center space-x-4'>
        <div className='flex-1'>
          <label
            htmlFor={`file-input-${title}`}
            className='block mb-2 font-medium'
          >
            파일 선택
          </label>
          <input
            id={`file-input-${title}`}
            type='file'
            accept={accept}
            onChange={handleFileChange}
            className='w-full p-2 border rounded'
            aria-label='파일 선택'
          />
          {selectedFile && (
            <p className='mt-2 text-sm text-gray-600'>
              선택된 파일: {selectedFile.name}
            </p>
          )}
          {fileInfo && <p className='mt-2 text-sm text-blue-600'>{fileInfo}</p>}
        </div>
        <div className='flex items-end'>
          <button
            type='button'
            onClick={handleUpload}
            disabled={!selectedFile || isUploading}
            className='px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-gray-400 disabled:cursor-not-allowed'
          >
            {isUploading ? '업로드 중...' : '업로드'}
          </button>
        </div>
      </div>
    </div>
  )
}

const NovelFileUploadTab = () => {
  const [selectedNovel, setSelectedNovel] = useState(null)
  const [novelList, setNovelList] = useState([])
  const [activeSearch, setActiveSearch] = useState({ query: '', type: 'title' })
  const [isUploading, setIsUploading] = useState(false)
  const [excelInfo, setExcelInfo] = useState('')
  const [zipInfo, setZipInfo] = useState('')
  const [uploadedChapterIds, setUploadedChapterIds] = useState([])
  const [manualChapterIds, setManualChapterIds] = useState('')
  const [isManualMode, setIsManualMode] = useState(false)

  useEffect(() => {
    const loadNovelList = async () => {
      try {
        const result = await altoonCMSBackendApis.getNovelData()
        if (result?.status === 200) {
          setNovelList(result.data)
        }
      } catch (error) {
        console.error('작품 목록 로딩 중 오류 발생:', error)
      }
    }
    loadNovelList()
  }, [])

  const getFilteredNovels = () => {
    if (!activeSearch.query) return novelList

    return novelList.filter((novel) => {
      const query = activeSearch.query.toLowerCase()
      switch (activeSearch.type) {
        case 'title':
          return novel.title?.toLowerCase().includes(query)
        case 'id':
          return novel._id?.toString().includes(query)
        default:
          return true
      }
    })
  }

  const handleExcelUpload = async (file) => {
    setIsUploading(true)
    try {
      const formData = new FormData()
      formData.append('file', file)

      const uploadResult = await altoonCMSBackendApis.uploadNovelChapterExcel(
        formData,
      )
      if (uploadResult?.status === 200) {
        setExcelInfo('업로드가 완료되었습니다.')
        setUploadedChapterIds(uploadResult?.data)
        return {
          status: 200,
          message: '업로드가 완료되었습니다.',
        }
      }
      setExcelInfo('업로드에 실패했습니다.')
      return {
        status: 400,
        message: '업로드에 실패했습니다.',
      }
    } catch (error) {
      console.error('Excel 파일 업로드 중 오류 발생:', error)
      setExcelInfo('파일 처리 중 오류가 발생했습니다.')
      return {
        status: 500,
        message: '파일 처리 중 오류가 발생했습니다.',
      }
    } finally {
      setIsUploading(false)
    }
  }

  const handleZipUpload = async (file) => {
    setIsUploading(true)
    try {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('chapterIds', JSON.stringify(uploadedChapterIds))

      const uploadResult = await altoonCMSBackendApis.uploadEpubZipFile(
        formData,
      )
      if (uploadResult?.status === 200) {
        setZipInfo('업로드가 완료되었습니다.')
        return {
          status: 200,
          message: '업로드가 완료되었습니다.',
        }
      }
      setZipInfo('업로드에 실패했습니다.')
      return {
        status: 400,
        message: '업로드에 실패했습니다.',
      }
    } catch (error) {
      console.error('ZIP 파일 업로드 중 오류 발생:', error)
      setZipInfo('파일 처리 중 오류가 발생했습니다.')
      return {
        status: 500,
        message: '파일 처리 중 오류가 발생했습니다.',
      }
    } finally {
      setIsUploading(false)
    }
  }

  const handleDownloadExcelTemplate = () => {
    // S3에 업로드된 템플릿 파일 URL
    const templateUrl =
      'https://assets.ilevit.com/templates/novel_chapter_template.xlsx'

    // 새 탭에서 템플릿 파일 다운로드
    alert('준비중입니다')
    // window.open(templateUrl, '_blank')
  }

  const handleManualChapterIdsChange = (e) => {
    setManualChapterIds(e.target.value)
    try {
      const parsedIds = JSON.parse(e.target.value)
      if (Array.isArray(parsedIds)) {
        setUploadedChapterIds(parsedIds)
      }
    } catch (error) {
      console.error('JSON 파싱 오류:', error)
    }
  }

  return (
    <div className='p-4 bg-white rounded-xl shadow-sm'>
      <div className='flex justify-between items-center mb-2'>
        <div className='py-4 font-bold text-[2.2vh]'>회차 파일 업로드</div>
        <button
          type='button'
          onClick={handleDownloadExcelTemplate}
          className='text-sm text-blue-500 hover:text-blue-600 font-bold'
        >
          엑셀 양식 다운로드
        </button>
      </div>

      {/* 작품 검색 섹션 */}
      <div className='mb-6'>
        <div className='flex items-center space-x-4 mb-4'>
          <select
            value={activeSearch.type}
            onChange={(e) =>
              setActiveSearch((prev) => ({ ...prev, type: e.target.value }))
            }
            className='pl-2 border rounded'
            id='search-type'
          >
            <option value='title'>제목</option>
            <option value='id'>ID</option>
          </select>
          <input
            type='text'
            value={activeSearch.query}
            onChange={(e) =>
              setActiveSearch((prev) => ({ ...prev, query: e.target.value }))
            }
            className='flex-1 p-2 border rounded'
            placeholder='작품 검색...'
            id='search-query'
          />
        </div>

        {/* 검색된 작품 목록 */}
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
          {!selectedNovel &&
            getFilteredNovels()
              ?.slice(0, 9)
              .map((novel) => (
                <div
                  key={novel._id}
                  className='border rounded-lg p-4 cursor-pointer hover:bg-gray-50'
                  onClick={() => setSelectedNovel(novel)}
                >
                  <div className='flex items-center'>
                    <img
                      src={novel.thumbnail}
                      alt={novel.title}
                      className='w-20 h-auto rounded mr-4'
                    />
                    <div>
                      <h4 className='font-medium'>{novel.title}</h4>
                      <p className='text-sm text-gray-500'>{novel.author}</p>
                      <div className='h-4' />
                      <p className='text-xs text-gray-400'>ID: {novel._id}</p>
                      <p className='text-xs text-gray-400'>
                        공개일자:{' '}
                        {moment(novel.displayStartedAt).format('YY.MM.DD')}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
        </div>
      </div>

      {/* 선택된 작품 정보 */}
      {selectedNovel && (
        <div className='mb-6 p-4 border rounded-lg'>
          <div className='flex justify-between items-center'>
            <div className='flex items-center'>
              <img
                src={selectedNovel.thumbnail}
                alt={selectedNovel.title}
                className='w-16 h-16 object-cover rounded mr-4'
              />
              <div>
                <h3 className='text-lg font-semibold'>{selectedNovel.title}</h3>
                <p className='text-sm text-gray-500'>{selectedNovel.author}</p>
                <p className='text-xs text-gray-400'>ID: {selectedNovel._id}</p>
              </div>
            </div>
            <button
              type='button'
              onClick={() => {
                setSelectedNovel(null)
                setExcelInfo('')
                setZipInfo('')
              }}
              className='px-4 py-2 bg-gray-200 hover:bg-gray-300 text-gray-700 rounded'
            >
              다른 작품 선택
            </button>
          </div>
        </div>
      )}

      {/* 파일 업로드 섹션 */}
      {selectedNovel && (
        <div className='space-y-6'>
          <div className='flex items-center space-x-4 mb-4'>
            <label className='flex items-center space-x-2'>
              <input
                type='radio'
                checked={!isManualMode}
                onChange={() => setIsManualMode(false)}
                className='form-radio'
              />
              <span>엑셀 파일 업로드</span>
            </label>
            <label className='flex items-center space-x-2'>
              <input
                type='radio'
                checked={isManualMode}
                onChange={() => setIsManualMode(true)}
                className='form-radio'
              />
              <span>수동 입력</span>
            </label>
          </div>

          {!isManualMode ? (
            <FileUploadSection
              title='회차 목록 엑셀 파일 업로드'
              description='회차 목록이 포함된 엑셀 파일을 업로드합니다.'
              accept='.xlsx,.xls'
              onUpload={handleExcelUpload}
              fileInfo={excelInfo}
              isUploading={isUploading}
            />
          ) : (
            <div className='border rounded-lg p-4'>
              <h3 className='text-lg font-semibold mb-2'>회차 ID 수동 입력</h3>
              <p className='text-gray-600 mb-4'>
                JSON 형식으로 회차 ID를 입력해주세요. 예: ["id1", "id2", "id3"]
              </p>
              <textarea
                value={manualChapterIds}
                onChange={handleManualChapterIdsChange}
                className='w-full p-2 border rounded'
                rows={4}
                placeholder='["id1", "id2", "id3"]'
              />
              {uploadedChapterIds.length > 0 && (
                <p className='mt-2 text-sm text-blue-600'>
                  {uploadedChapterIds.length}개의 회차 ID가 준비되었습니다
                </p>
              )}
            </div>
          )}

          <FileUploadSection
            title='EPUB ZIP 파일 업로드'
            description={`EPUB 파일이 포함된 ZIP 파일을 업로드합니다. ${
              uploadedChapterIds.length > 0
                ? `(${uploadedChapterIds.length}개의 회차 ID가 준비되었습니다)`
                : ''
            }`}
            accept='.zip'
            onUpload={handleZipUpload}
            fileInfo={zipInfo}
            isUploading={isUploading}
          />
        </div>
      )}
    </div>
  )
}

export default NovelFileUploadTab
