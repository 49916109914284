import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import Iframe from 'react-iframe'
import { useNavigate } from 'react-router-dom'
import { useSearchParam } from 'react-use'

import TreasureHuntAction from '../../actions/TreasureHuntAction'
import WatermelonAction from '../../actions/WatermelonAction'
import TreasureHuntButton from '../../components/treasureHunt/TreasureHuntButton'
import TreasureHuntTimer from '../../components/treasureHunt/TreasureHuntTimer'
import HeaderComponent from '../../components/treasureHunt/molecules/HeaderComponent'
import AuthStore from '../../stores/AuthStore'
import RouteStore from '../../stores/RouteStore'
import ToastStore from '../../stores/ToastStore'
import WatermelonUserStore from '../../stores/WatermelonUserStore'
import THBottomSheetStore from '../../stores/treasureHunt/THBottomSheetStore'
import THModalStore from '../../stores/treasureHunt/THModalStore'
import backendApis from '../../utils/backendApis'
import { safeCodepushVersionCompare } from '../../utils/useConvertCodePushVersion'
import waitlist from './waitlist'

let visibilityChange
if (typeof document.hidden !== 'undefined') {
  // Opera 12.10 and Firefox 18 and later support
  visibilityChange = 'visibilitychange'
} else if (typeof document.msHidden !== 'undefined') {
  visibilityChange = 'msvisibilitychange'
} else if (typeof document.webkitHidden !== 'undefined') {
  visibilityChange = 'webkitvisibilitychange'
}

const modeForGame = {
  type: 'onion',
  backgroundImage: `url(/watermelonAssets/background/onion.png)`,
  backgroundColor: '#FFCCC9',
  isFirstGame: false,
}

const typeList = ['onion', 'coffee', 'mandarin']
const modeList = ['easy', 'normal', 'hard']
const typeInfo = {
  onion: {
    backgroundColor: '#FFCCC9',
    map: 'map_onion',
  },
  coffee: {
    backgroundColor: '#D9A685',
    map: 'map3',
  },
  mandarin: {
    backgroundColor: '#FFC59C',
    map: 'map6',
  },
  iphone: {
    backgroundColor: '#A4DEFF',
    map: 'map4',
  },
  dyson: {
    backgroundColor: '#FEADDC',
    map: 'map5',
  },
}

const Watermelon = observer(() => {
  const navigate = useNavigate()

  const [status, setStatus] = useState('lobby')
  const [attendanceTimeText, setAttendanceTimeText] = useState('Dawn')
  const [practiceMode, setPracticeMode] = useState(false)
  const token = useSearchParam('token')
  const [buttonText, setButtonText] = useState('게임시작')
  const [missionPingOn, setMissionPingOn] = useState(false)
  const [reviewPingOn, setReviewPingOn] = useState(false)
  const [mode, setMode] = useState({
    type: 'onion',
    backgroundImage: `url(/watermelonAssets/background/onion.png)`,
    backgroundColor: '#FFCCC9',
    map: 'map_onion',
  })
  const [canStartGame, setCanStartGame] = useState(false)
  const [isFirstGame, setIsFirstGame] = useState(false)
  const [steptwo, setSteptwo] = useState(false)
  const [modeIndex, setModeIndex] = useState(1)
  const [typeIndex, setTypeIndex] = useState(0)

  const [didOpenRuleModal, setDidOpenRuleModal] = useState(
    window.localStorage.getItem('didOpenRuleModal2') ?? false,
  )

  // 코인 사용
  const NORMAL_TICKET = 'ticket'
  const ARCADE_TICKET = 'arcadeTicket'
  const coinProductList = [
    {
      name: NORMAL_TICKET,
      nameKOR: '일반 도전권',
      price: 3000,
      isSingularBuy: false,
      itemType: 'ticket',
    },
    {
      name: ARCADE_TICKET,
      nameKOR: '아케이드 티켓',
      price: 2000,
      isSingularBuy: false,
      itemType: 'ticket',
    },
  ]

  const childRef = useRef()

  const typeToKor = (type) => {
    if (type === 'onion') return '양파'
    if (type === 'coffee') return '커피'
    if (type === 'iphone') return '아이폰'
    if (type === 'dyson') return '다이슨'
    if (type === 'mandarin') return '감귤'
  }
  const modeToKor = (mode) => {
    if (mode === 'easy') return '쉬움'
    if (mode === 'normal') return '보통'
    if (mode === 'hard') return '어려움'
  }

  const onPressArrow = ({ type, dir }) => {
    if (type === 'type') {
      const i = (typeIndex + dir + typeList.length) % typeList.length
      setTypeIndex(i)
      WatermelonUserStore.set('gameType', typeList?.[i])
    } else if (type === 'mode') {
      const i = (modeIndex + dir + modeList.length) % modeList.length
      setModeIndex(i)
      WatermelonUserStore.set('gameMode', modeList?.[i])
    }
  }

  const fetchData = async () => {
    const result = await backendApis.getWatermelonGameData({ routeParams: {} })
    if (result?.status === 200) {
      if (result?.data?.initModalList) {
        for (const init of result?.data?.initModalList) {
          THModalStore.setType({
            type: init?.type,
            config: { ...init?.config, forceOpen: true },
          })
        }
        backendApis.commonSet({
          set: {
            initModalList: [],
          },
          collection: 'watermelon_user',
        })
      }
      WatermelonUserStore.set('userData', result?.data)
    } else {
      ToastStore.networkError()
    }
  }
  const emergencyShareReward = async () => {
    const result = await backendApis.emergencyShareReward({ routeParams: {} })
    if (result?.status === 200) {
      WatermelonUserStore.increase('ticket', 2)
      WatermelonUserStore.set('userData', result?.data)
      THModalStore.setType({
        type: 'reward',
        config: {
          rewardName: '공유 보상',
          description: `도전권 <energy> 2개를 받았어요`,
          src: `../watermelonAssets/smile.png`,
          text: '확인',
          forceOpen: true,
        },
      })
    } else if (result?.status === 201) {
      THModalStore.setType({
        type: 'reward',
        config: {
          rewardName: '공유 보상 제한',
          description: `접속량이 폭주하여 공유 보상은 2회로 제한되었어요`,
          src: `../watermelonAssets/sad.png`,
          text: '확인',
          forceOpen: true,
        },
      })
    } else if (result?.status === 202) {
      console.log('sleep')
    } else {
      ToastStore.networkError()
    }
  }

  // useEffect(() => {
  //   const lastCheck = window.localStorage.getItem('lastCheckEndEventWaitList')
  //   if (
  //     waitlist?.includes(WatermelonUserStore?.userData?.userId?.toString()) &&
  //     !lastCheck
  //   ) {
  //     window.localStorage.setItem(
  //       'lastCheckEndEventWaitList',
  //       moment().format('YYYY-MM-DD'),
  //     )
  //     THModalStore.setType({
  //       type: 'reward',
  //       config: {
  //         rewardName: '양파게임 보상 소멸 안내',
  //         description: `경품 지급 이벤트 종료로 인해\n1월 7일이내로 보상을 받지 않으면 소멸되오니\n보상을 받아주세요!`,
  //         src: `../watermelonAssets/sad.png`,
  //         text: '확인',
  //         forceOpen: true,
  //       },
  //     })
  //   }
  // }, [WatermelonUserStore?.userData?.userId])

  useEffect(() => {
    setMode({
      type: WatermelonUserStore?.gameType,
      backgroundImage: `url(/watermelonAssets/background/${WatermelonUserStore?.gameType}.png)`,
      backgroundColor:
        typeInfo?.[WatermelonUserStore?.gameType]?.backgroundColor,
      map: typeInfo?.[WatermelonUserStore?.gameType]?.map,
    })
    modeForGame.type = WatermelonUserStore?.gameType
    modeForGame.backgroundColor =
      typeInfo?.[WatermelonUserStore?.gameType]?.backgroundColor
    WatermelonUserStore.set(
      'backgroundColor',
      typeInfo?.[WatermelonUserStore?.gameType]?.backgroundColor,
    )
    setCanStartGame(WatermelonUserStore?.userData?.ticket > 0)
  }, [WatermelonUserStore?.gameType, WatermelonUserStore?.userData?.ticket])

  useEffect(() => {
    const HHmm = moment().format('HHmm')
    if (HHmm < '0000') {
      setAttendanceTimeText('Dawn')
    } else if (HHmm >= '0000' && HHmm < '1200') {
      setAttendanceTimeText('Morning')
      // } else if (HHmm >= '1100' && HHmm < '1100') {
      //   setAttendanceTimeText('PreDinner')
    } else if (HHmm >= '1200' && HHmm < '2400') {
      setAttendanceTimeText('Dinner')
      // } else if (HHmm >= '2400') {
      //   setAttendanceTimeText('PreMorning')
    } else {
      setAttendanceTimeText('')
    }

    const missionPingAt = window.localStorage.getItem('missionPingAt')
    if (
      WatermelonUserStore?.dailyMission?.[`attendance${attendanceTimeText}`]
        ?.status !== 'finish' &&
      ['Morning', 'Dinner']?.includes(attendanceTimeText)
    ) {
      setMissionPingOn(true)
    } else if (
      moment().format('YYYY-MM-DD') ===
      moment(missionPingAt).format('YYYY-MM-DD')
    ) {
      setMissionPingOn(false)
    } else {
      setMissionPingOn(true)
      window.localStorage.setItem(
        'missionPingAt',
        moment().format('YYYY-MM-DD'),
      )
    }
    const reviewPingAt = window.localStorage.getItem('reviewPingAt3')
    if (
      moment().format('YYYY-MM-DD') ===
      moment(reviewPingAt).format('YYYY-MM-DD')
    ) {
      setReviewPingOn(false)
    } else {
      setReviewPingOn(true)
      window.localStorage.setItem(
        'reviewPingAt3',
        moment().format('YYYY-MM-DD'),
      )
    }
  }, [WatermelonUserStore?.dailyMission])

  useEffect(() => {
    if (process.env.NODE_ENV === 'development' && token) {
      WatermelonAction.initGame({ data: { token, codepushVersion: '6.5.20' } })
    }
  }, [token])

  useEffect(() => {
    if (!didOpenRuleModal) {
      THModalStore.setType({
        type: 'watermelonRule',
        config: {
          forceOpen: true,
        },
      })
      window.localStorage.setItem('didOpenRuleModal2', true)
    }
    emergencyShareReward()
  }, [])

  useEffect(() => {
    document.addEventListener(
      visibilityChange,
      (visibility) => {
        if (document.visibilityState === 'visible') {
          setTimeout(() => {
            if (!WatermelonUserStore.isPlaying) {
              fetchData()
            }
          }, 500)
        }
      },
      false,
    )
  }, [])

  useEffect(() => {
    if (status === 'playing') {
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          type: 'allowiPhoneGesture',
          boolean: false,
        }),
      )
    } else {
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          type: 'allowiPhoneGesture',
          boolean: true,
        }),
      )
    }
  }, [status])

  useEffect(() => {
    if (
      !Number.isNaN(WatermelonUserStore?.userData?.highscore) &&
      WatermelonUserStore?.userData?.highscore >
        (WatermelonUserStore?.userData?.allTimeHighscore ?? 0)
    ) {
      backendApis.setAllTimeHighscore({
        allTimeHighscore: Number(WatermelonUserStore?.userData?.highscore),
      })
      WatermelonUserStore.set(
        'userData.allTimeHighscore',
        WatermelonUserStore?.userData?.highscore,
      )
    }
  }, [WatermelonUserStore?.userData?.highscore])

  useEffect(() => {
    WatermelonUserStore.set(
      'showContactInvite',
      safeCodepushVersionCompare(AuthStore?.codePushVersion, '4.3.33') >= 0,
    )
  }, [AuthStore.codePushVersion])

  useEffect(() => {
    if (!WatermelonUserStore?.isLoading) {
      const handleMessage = async (e) => {
        // e.data가 전달받은 메시지
        if (e.data?.status === 'gameOver') {
          WatermelonUserStore.set('isFixedGame', false)
          WatermelonUserStore.set('hideCloseButton', false)
          setStatus('gameOver')
          WatermelonUserStore.set('isPlaying', false)
          const score = Number(e?.data?.score)
          const gameMode = WatermelonUserStore?.gameMode

          // 게임 점수에 따른 코인 지급 로직
          const gameModeCoinMultipliers = {
            easy: 1,
            normal: 1.5,
            hard: 3,
          }
          const coinMultiple = gameModeCoinMultipliers[gameMode]
          const currentGainedCoins = Math.ceil(score * coinMultiple)

          const result = await backendApis.setWatermelonCoin({
            score,
            gameMode,
          })

          if (result?.status === 200) {
            WatermelonUserStore.set('userData.coin', result?.data)
          }

          if (WatermelonUserStore?.userData?.highscore < score) {
            WatermelonUserStore.set('userData.highscore', score)
            await backendApis.setHighscore({
              highscore: Number(score),
            })
          }

          if (WatermelonUserStore?.userData?.allTimeHighscore < score) {
            WatermelonUserStore.set('userData.allTimeHighscore', score)
            await backendApis.setAllTimeHighscore({
              allTimeHighscore: Number(score),
            })
          }
          let type = '양파'
          const templateId = 100565
          if (WatermelonUserStore.gameType === 'coffee') {
            type = '커피'
          } else if (WatermelonUserStore.gameType === 'iphone') {
            type = '아이폰'
          } else if (WatermelonUserStore.gameType === 'dyson') {
            type = '다이슨'
          } else if (WatermelonUserStore.gameType === 'mandarin') {
            type = '감귤'
          }
          THModalStore.setType({
            type: 'reward',
            config: {
              forceOpen: true,
              rewardName: `코인 획득`,
              src: `../watermelonAssets/smile.png`,
              description: `코인 ${currentGainedCoins}개를 획득했어요!`,
              text: '받기',
            },
          })
          if (WatermelonUserStore?.userData?.ticket < 1) {
            THModalStore.setType({
              type: 'reward',
              config: {
                rewardName: `공유하기`,
                src: `../watermelonAssets/smile.png`,
                description:
                  '친구에게 양파게임을 알려주고\n도전권을 받아보세요!',
                text: '공유하기',
                onClick: () => {
                  backendApis.commonInsert({
                    data: {
                      type: 'clickShareButton',
                      gameType: WatermelonUserStore?.gameType,
                    },
                    collection: 'watermelon_clickLog',
                  })
                  const imageUrl =
                    'https://assets.ilevit.com/0f2fa26a-836b-471c-bb2c-95e724aa5385.png'
                  const title = '[양파게임] 양파 만들기에 도전해보세요!'
                  const description =
                    '작은 작물부터 합쳐서 양파까지 도전해보세요!'

                  WatermelonAction.kakaoLinkShare({
                    shareType: 'Watermelon_LinkShareV2',
                    title,
                    description,
                    imageUrl,
                    payload: {},
                    templateId,
                  })
                },
              },
            })
          }
        } else if (e.data?.status === 'gameInitSuccess') {
          childRef.current = document.getElementById('child') // iframe
          if (childRef.current) {
            childRef.current.contentWindow.postMessage(
              {
                msg: 'startClicked',
                mode: modeForGame.type,
                gameMode: WatermelonUserStore?.gameMode,
                backgroundColor: modeForGame.backgroundColor,
                isFirstGame: modeForGame.isFirstGame,
                isFixedGame: WatermelonUserStore?.isFixedGame,
                isBonuseNewbie: WatermelonUserStore?.userData?.isBonusNewbie,
                isTeamGame: WatermelonUserStore?.isTeamGame,
              },
              '*',
            )
          }
        } else if (e.data?.status === 'tutorialEnd') {
          setIsFirstGame(false)
          modeForGame.isFirstGame = false
          setSteptwo(true)
        } else if (e.data?.status === 'tutorialEnd2') {
          setSteptwo(false)
        } else if (e.data?.status === 'hideCloseButton') {
          WatermelonUserStore.set('hideCloseButton', true)
        } else if (e.data?.status === 'showBoastModal') {
          WatermelonUserStore.set('currentScore', Number(e?.data?.score))
          await WatermelonAction.checkContactPermission()
          await WatermelonAction.getRankingLocal({
            contacts: WatermelonUserStore?.contactList,
            skip: WatermelonUserStore?.contactSkip,
          })

          if (WatermelonUserStore?.usersBelowCurrentRank?.length > 2) {
            THModalStore.setType({
              type: 'watermelonCurrentRank',
              config: {
                forceOpen: true,
              },
            })
          }
        }
      }
      window.addEventListener('message', handleMessage)

      return () => {
        window.removeEventListener('message', handleMessage)
      }
    }
  }, [WatermelonUserStore?.isLoading])

  useEffect(() => {
    if (WatermelonUserStore?.isNewbie && status !== 'playing') {
      modeForGame.isFirstGame = true
      setIsFirstGame(true)
      WatermelonUserStore.set('hideTimer', true)
      startGame()
    }
  }, [WatermelonUserStore?.isNewbie])

  useEffect(() => {
    const lastCheck = window.localStorage.getItem('lastCheckEndEvent')
    if (
      lastCheck !== moment().format('YYYY-MM-DD') &&
      moment().isBefore(moment('2024-01-02'))
    ) {
      THModalStore.setType({
        type: 'reward',
        config: {
          rewardName: '경품 이벤트 종료 안내',
          description: `안녕하세요 여러분, 양파게임팀입니다.\n\n2023년 12월 31일을 마지막으로 응모를 통한 경품 지급 이벤트가 종료됩니다. \n최대한 빠른 시간내에 더 유익하고 통 큰 이벤트로 다시 찾아뵙겠습니다.\n\n 양파게임은 여전히 이용 가능합니다. 앞으로 다양한 기능이 출시될 예정이며 많은 관심과 사랑 부탁드립니다. \n\n양파게임팀 올림`,
          src: `../watermelonAssets/smile.png`,
          text: '확인',
          forceOpen: true,
        },
      })
      window.localStorage.setItem(
        'lastCheckEndEvent',
        moment().format('YYYY-MM-DD'),
      )
    }
  }, [])

  const startGame = async (params) => {
    const isFixedGame = params?.isFixedGame
    WatermelonUserStore.set('isNewbie', false)
    WatermelonUserStore.set('isFixedGame', isFixedGame)
    WatermelonUserStore.set('isTeamGame', false)
    if (process.env.NODE_ENV === 'development') {
      console.log('gameMode: ', WatermelonUserStore.gameMode)
      console.log('gameType: ', WatermelonUserStore.gameType)
      console.log('currentScore: ', WatermelonUserStore.currentScore)
      console.log('isFixedGame: ', WatermelonUserStore.isFixedGame)
      console.log('isTeamGame: ', WatermelonUserStore.isTeamGame)
      console.log('isTimerGame: ', WatermelonUserStore.isTimerGame)
      console.log('timerGameSeconds: ', WatermelonUserStore.timerGameSeconds)
      console.log('onionCount: ', WatermelonUserStore.onionCount)
      console.log('isFixedGame: ', WatermelonUserStore.isFixedGame)
    }
    const result = await backendApis.watermelonStartGame({
      type: modeForGame.type,
      isFixedGame,
      gameMode: WatermelonUserStore?.gameMode,
    })

    if (result?.status === 200) {
      setStatus('playing')
      WatermelonUserStore.set('isPlaying', true)
      WatermelonUserStore.increase('userData.ticket', -1)
    } else if (result?.status === 202) {
      ToastStore.toastOn({
        type: 'error',
        message: '양파가 다 떨어졌어요..',
        duration: 3000,
      })
      setPracticeMode(true)
    } else if (result?.status === 201) {
      ToastStore.toastOn({
        type: 'error',
        message: '도전권이 부족해요!',
        duration: 3000,
      })
    } else {
      ToastStore.networkError()
    }
  }

  if (WatermelonUserStore?.isLoading) {
    return (
      <div className='flex flex-col loadingPage jua'>
        <div className='slowRotateCenter pigNose'>🧅</div>
        <div>로딩 중...</div>
      </div>
    )
  }

  return (
    <div
      id='watermelon'
      className='fixed'
      style={{
        width: '100vw',
        height: '100vh',
        backgroundImage: mode.backgroundImage,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        fontFamily: 'maplestory',
        backgroundColor: mode.backgroundColor,
      }}
    >
      {(status === 'lobby' || status === 'gameOver') && (
        <>
          <HeaderComponent
            title=''
            hideButtons
            backButtonOnClick={() => {
              TreasureHuntAction.navigateRN({
                screen: 'MainStackAMainTabNavigator',
                payload: {},
              })
            }}
            watermelonTicketOn
            watermelonTicket={WatermelonUserStore?.userData?.ticket}
          />
          <div
            style={{
              position: 'absolute',
              // width: '20vw',
              // padding: '2vw 2vw',
              top: '16vw',
              right: '2vw',
              fontSize: '3vw',
            }}
            onClick={() => {
              THBottomSheetStore.setType({
                type: 'watermelonRanking',
              })
            }}
          >
            <img
              className='w-[16vw] h-[20.64vw]'
              src='../watermelonAssets/mainIcon/ranking.png'
              alt='ranking'
            />
          </div>
        </>
      )}
      {status !== 'playing' && (
        <div className='absolute bottom-0 flex flex-col w-full p-4 space-y-4 '>
          <div className='w-full flex flex-row justify-center items-center space-x-4 z-[100] bg-white rounded-xl'>
            <div className='flex w-[45vw] bg-[#FFEDEC] h-[45vw] rounded-2xl relative justify-center items-center flex-col'>
              <img
                className='w-[25%] h-[25%] absolute opacity-40 z-[10] top-[10vw] left-4'
                alt=''
                src={`../watermelonAssets/img/${
                  typeList[(typeIndex - 1 + typeList.length) % typeList.length]
                }/11.png`}
              />
              <img
                className='w-[25%] h-[25%] absolute opacity-40 z-[10] top-[10vw] right-4'
                alt=''
                src={`../watermelonAssets/img/${
                  typeList[(typeIndex + 1 + typeList.length) % typeList.length]
                }/11.png`}
              />
              <img
                className='w-[50%] h-[50%] z-[11] bg-white rounded-full'
                alt=''
                src={`../watermelonAssets/img/${modeForGame.type}/11.png`}
              />
              <div className='text-[3.5vw] mt-2 font-bold text-[#E7807B]'>
                상품
              </div>
              <div className='flex flex-row items-center justify-between w-full px-2'>
                <div
                  onClick={() => {
                    onPressArrow({ type: 'type', dir: -1 })
                  }}
                >
                  <img
                    className='w-[8vw] h-[8vw]'
                    alt=''
                    src='../watermelonAssets/left.png'
                  />
                </div>
                <div className='font-bold text-[4.5vw] text-[#313138]'>
                  {typeToKor(modeForGame.type)}
                </div>
                <div
                  onClick={() => {
                    onPressArrow({ type: 'type', dir: +1 })
                  }}
                >
                  <img
                    className='w-[8vw] h-[8vw]'
                    alt=''
                    src='../watermelonAssets/right.png'
                  />
                </div>
              </div>
            </div>
            <div className='flex w-[45vw] bg-[#FFEDEC] h-[45vw] rounded-2xl relative justify-center items-center flex-col'>
              <img
                className='w-[50%] h-[50%]'
                alt=''
                src={`../watermelonAssets/modeIcon/${modeIndex}.png`}
              />
              <div className='text-[3.5vw] mt-2 font-bold text-[#E7807B]'>
                {`${modeIndex > 2 ? '새로운 ' : ''}모드`}
              </div>
              <div className='flex flex-row items-center justify-between w-full px-2'>
                <div
                  onClick={() => {
                    WatermelonUserStore.set('gameMode', modeList?.[modeIndex])
                    onPressArrow({ type: 'mode', dir: -1 })
                  }}
                >
                  <img
                    className='w-[8vw] h-[8vw]'
                    alt=''
                    src='../watermelonAssets/left.png'
                  />
                </div>
                <div className='font-bold text-[4.5vw] text-[#313138]'>
                  {modeToKor(WatermelonUserStore.gameMode)}
                </div>
                <div
                  onClick={() => {
                    WatermelonUserStore.set('gameMode', modeList?.[modeIndex])
                    onPressArrow({ type: 'mode', dir: +1 })
                  }}
                >
                  <img
                    className='w-[8vw] h-[8vw]'
                    alt=''
                    src='../watermelonAssets/right.png'
                  />
                </div>
              </div>
            </div>{' '}
          </div>
          <div className='flex items-center justify-around w-full bg-white flew-row'>
            {moment().isBefore(
              moment('2023-12-03').startOf('day').add(12, 'hours'),
            ) && (
              <div
                className=''
                onClick={() => {
                  // 화면 변경

                  RouteStore?.navigate(
                    `/watermelon/teamgame?token=${AuthStore?.token}`,
                  )
                }}
              >
                <img
                  className='w-[16vw] h-[20.64vw]'
                  src='../watermelonAssets/mainIcon/teamgame.png'
                  alt='conatacts'
                />
              </div>
            )}
            {/* 코인샵 모달 */}
            <div
              style={{ fontFamily: 'maplestory' }}
              className=''
              onClick={() => {
                THModalStore.setType({
                  type: 'WatermelonEnterCoinShop',
                  config: {
                    title: '도전권 구매',
                    forceOpen: true,
                    isTicketAvailable:
                      WatermelonUserStore?.userData?.drawTicket > 0,
                    src: '',
                    productList: coinProductList,
                    text: '구매하기',
                    onClickBuy: async (productQuantityList, totalPrice) => {
                      // 구매하기 버튼
                      if (process.env.NODE_ENV === 'development') {
                        console.log(
                          'productQuantityList: ',
                          productQuantityList,
                        )
                        console.log('totalPrice: ', totalPrice)
                      }

                      if (WatermelonUserStore?.userData?.coin >= totalPrice) {
                        // 서버 요청
                        // 코인 보유 수 및 가격 비교
                        // const change =
                        //   WatermelonUserStore?.userData?.coin - totalPrice

                        const result =
                          await backendApis.watermelonShopCoinProduct({
                            productQuantityList,
                          })

                        if (result?.status === 200) {
                          // 코인 결과
                          WatermelonUserStore.set(
                            'userData.coin',
                            result?.data?.coin,
                          )
                          // 아이템 결과
                          WatermelonUserStore.set(
                            'userData.ticket',
                            result?.data?.ticket,
                          )
                          WatermelonUserStore.set(
                            'userData.arcadeTicket',
                            result?.data?.arcadeTicket,
                          )

                          THModalStore.setType({
                            type: 'confirm',
                            config: {
                              title: '구매 완료',
                              forceOpen: true,
                              src: `../watermelonAssets/shop/completePurchase.png`,
                              description: '구매되었어요!',
                              clickable: result.status === 200,
                              onConfirm: () => {
                                THModalStore.close()
                              },
                              text: '확인',
                            },
                          })
                        } else if (result?.status === 201) {
                          ToastStore.toastOn({
                            type: 'error',
                            message: result?.data,
                            duration: 2000,
                          })
                        } else {
                          ToastStore.networkError()
                        }
                      }
                    },
                  },
                })
              }}
            >
              <img
                className='w-[16vw] h-[20.64vw]'
                src='../watermelonAssets/shop/ticketShop.png'
                alt='ticketShop'
              />
            </div>
            <div
              className=''
              onClick={() => {
                navigate(`/watermelon-arcade?token=${token}`)
              }}
            >
              <img
                className='w-[16vw] h-[20.64vw]'
                src='../watermelonAssets/mainIcon/watermelonArcade.png'
                alt='contacts'
              />
            </div>

            {/* <div
              className=''
              onClick={() => {
                THBottomSheetStore.setType({
                  type: 'watermelonBounty',
                })
              }}
            >
              <img
                className='w-[16vw] h-[20.64vw]'
                src='../watermelonAssets/mainIcon/bounty.png'
                alt='conatacts'
              />
            </div> */}

            <div
              className='relative '
              onClick={() => {
                setMissionPingOn(false)
                THBottomSheetStore.setType({
                  type: 'watermelonTicket',
                })
              }}
            >
              <img
                className='w-[16vw] h-[20.64vw]'
                src='../watermelonAssets/mainIcon/mission.png'
                alt='mission'
              />
              {missionPingOn && (
                <img
                  className='absolute w-[10vw] h-[10vw] top-[1vw] -right-[4vw] pointer-events-none'
                  src='../watermelonAssets/ping2.png'
                  alt='ping'
                />
              )}
            </div>
          </div>
          {/* {WatermelonUserStore?.userData?.reachedFinalAt && (
            <TreasureHuntButton
              style={{
                width: '100%',
                padding: '4vw 4vw',
              }}
              text={
                !WatermelonUserStore?.userData?.ableToGetPrize
                  ? '무료 상품 받기 (오늘은 이미 받았어요!)'
                  : '무료 상품 받기 (1일 1회)'
              }
              clickable={WatermelonUserStore?.userData?.ableToGetPrize}
              onClick={() => {
                backendApis.commonInsert({
                  data: {
                    type: 'clickGetPrize',
                  },
                  collection: 'watermelon_clickLog',
                })
                let enteringComponent = 'Watermelon_Prize'

                if (WatermelonUserStore.userData?.winningType === 'coffee') {
                  enteringComponent = 'Watermelon_PrizeCoffee'

                  const payload = {
                    enteringComponent,
                    text: '양파게임',
                    source:
                      'https://assets.ilevit.com/cd9aac56-95a5-4968-a98a-1e9f643a00e4.png',
                  }

                  THModalStore.setType({
                    type: 'watermelonRule',
                    config: {
                      title: '안내사항',
                      forceOpen: true,
                      src: 'coffeeAlert',
                      onClick: () => {
                        TreasureHuntAction.navigateRN({
                          screen: 'AlfarmRevenuePurchaseScreen',
                          payload,
                        })
                      },
                      text: '확인',
                    },
                  })
                } else if (
                  WatermelonUserStore.userData?.winningType === 'teamgame'
                ) {
                  enteringComponent = 'Watermelon_PrizeTeamgame'

                  const payload = {
                    enteringComponent,
                    text: '양파게임',
                    source:
                      'https://assets.ilevit.com/cd9aac56-95a5-4968-a98a-1e9f643a00e4.png',
                  }

                  THModalStore.setType({
                    type: 'watermelonRule',
                    config: {
                      title: '안내사항',
                      forceOpen: true,
                      src: 'coffeeAlert',
                      onClick: () => {
                        TreasureHuntAction.navigateRN({
                          screen: 'AlfarmRevenuePurchaseScreen',
                          payload,
                        })
                      },
                      text: '확인',
                    },
                  })
                } else {
                  if (
                    WatermelonUserStore.userData?.winningType === 'mandarin'
                  ) {
                    enteringComponent = 'Watermelon_PrizeMandarin'
                  } else if (
                    WatermelonUserStore.userData?.winningType === 'onion'
                  ) {
                    enteringComponent = 'Watermelon_Prize'
                  } else {
                    enteringComponent = 'Watermelon_Prize'
                  }
                  const payload = {
                    enteringComponent,
                    text: '양파게임',
                    source:
                      'https://assets.ilevit.com/cd9aac56-95a5-4968-a98a-1e9f643a00e4.png',
                  }
                  TreasureHuntAction.navigateRN({
                    screen: 'AlfarmRevenuePurchaseScreen',
                    payload,
                  })
                }
              }}
            />
          )} */}
          <TreasureHuntButton
            style={{
              width: '100%',
              padding: '4vw 4vw',
            }}
            text={buttonText}
            clickable
            onClick={async () => {
              if (canStartGame) {
                startGame({ isFixedGame: false })
              } else {
                ToastStore.toastOn({
                  type: 'emoji',
                  message: `도전권이 없어요. 미션을 하고 받아보세요!`,
                  duration: 2000,
                  emoji: '⚡',
                })
                THBottomSheetStore.setType({
                  type: 'watermelonTicket',
                })
              }
            }}
          />
          {WatermelonUserStore?.showEmergencyShare &&
            safeCodepushVersionCompare(AuthStore?.codePushVersion, '4.3.31') >=
              0 && (
              <TreasureHuntButton
                style={{
                  width: '100%',
                  padding: '4vw 4vw',
                }}
                text='공유하고 도전권 받기 (2회 제한)'
                clickable
                onClick={() => {
                  backendApis.commonInsert({
                    data: {
                      type: 'clickEmergencyShareButton',
                    },
                    collection: 'watermelon_clickLog',
                  })
                  WatermelonAction.linkShare({
                    shareType: 'Watermelon_LinkShareV2',
                    title: '[양파게임] 양파 만들기에 도전해보세요!',
                    description: '작은 작물부터 합쳐서 양파까지 도전해보세요!',
                    imageUrl:
                      'https://assets.ilevit.com/0f2fa26a-836b-471c-bb2c-95e724aa5385.png',
                    payload: {
                      link: 'https://alwayzshop.page.link/jsYwkTF2PcueiXZ39',
                    },
                    templateId: 100565,
                  })
                  WatermelonUserStore.set('clickedEmergencyShare', true)
                }}
              />
            )}
          {moment().isBefore(
            moment(WatermelonUserStore?.userData?.createdAt).add(1, 'd'),
          ) && (
            <img
              className='w-[30vw] absolute bottom-[16vw] right-[8vw] pointer-events-none z-30'
              src='../watermelonAssets/sendingNudge.png'
              alt='sendingNudge'
            />
          )}
          <TreasureHuntButton
            style={{
              width: '100%',
              padding: '4vw 4vw',
            }}
            text='공유하고 도전권 받기'
            clickable={WatermelonUserStore?.showKakaoShare}
            onClick={() => {
              backendApis.commonInsert({
                data: {
                  type: 'clickShareButton',
                  gameType: WatermelonUserStore?.gameType,
                },
                collection: 'watermelon_clickLog',
              })
              const imageUrl =
                'https://assets.ilevit.com/0f2fa26a-836b-471c-bb2c-95e724aa5385.png'
              const title = '[양파게임] 양파 만들기에 도전해보세요!'
              const description = '작은 작물부터 합쳐서 양파까지 도전해보세요!'
              const templateId = 100565

              WatermelonAction.kakaoLinkShare({
                shareType: 'Watermelon_LinkShareV2',
                title,
                description,
                imageUrl,
                payload: {},
                templateId,
              })
            }}
          />
        </div>
      )}

      {status === 'playing' && (
        <div className='relative'>
          {isFirstGame && (
            <div className='absolute top-[30vw] right-[12vw] z-[100] pointer-events-none font-bold whitespace-nowrap flex flex-col justify-center items-center text-center'>
              클릭한 곳에 작물이 떨어져요!
              <img
                className='w-[12vw] h-[12vw] pulsate-bwd mt-4'
                src='../watermelonAssets/finger.png'
                alt=''
              />
            </div>
          )}
          <div className='absolute top-[25vw] left-[4vw] z-[100] pointer-events-none font-bold whitespace-nowrap flex flex-row justify-center items-center text-center text-[#313138]'>
            {`모드: ${modeToKor(WatermelonUserStore.gameMode)}`}
          </div>
          {WatermelonUserStore?.isTimerGame && (
            <div className='absolute top-[25vw] right-[4vw] z-[100] pointer-events-none font-bold whitespace-nowrap flex flex-row justify-center items-center text-center text-[#313138]'>
              남은 시간:
              <TreasureHuntTimer
                style={{
                  marginLeft: '8px',
                  color: '#313138',
                }}
                format='number'
                expiryTimestamp={moment()
                  .add(WatermelonUserStore?.timerGameSeconds, 'seconds')
                  .toDate()}
                onExpire={() => {
                  childRef.current = document.getElementById('child') // iframe
                  if (childRef.current) {
                    childRef.current.contentWindow.postMessage(
                      {
                        msg: 'gameOver',
                      },
                      '*',
                    )
                  }
                }}
              />
            </div>
          )}

          {steptwo && (
            <div className='absolute z-[10] top-[125vw]  right-[12vw] pointer-events-none font-bold whitespace-nowrap flex flex-col justify-center items-center text-center'>
              같은 작물끼리 닿으면 다음 작물로 합쳐져요!
            </div>
          )}
          <div className='absolute z-[10] top-[155vw] px-2 pointer-events-none'>
            <img
              className='w-full'
              src={`../watermelonAssets/${mode.map}.png`}
              alt='map'
            />
          </div>

          {!WatermelonUserStore?.hideCloseButton && (
            <div
              className='absolute right-4 top-4 z-[10]'
              onClick={() => {
                THModalStore?.setType({
                  type: 'confirm',
                  config: {
                    forceOpen: true,
                    title: '게임 종료',
                    src: '../watermelonAssets/smile.png',
                    description: `진행상황이 사라져요\n정말 그만두시겠어요?`,
                    buttonText: '그만하기',
                    onConfirm: async () => {
                      // setStatus('lobby')
                      childRef.current = document.getElementById('child') // iframe
                      if (childRef.current) {
                        childRef.current.contentWindow.postMessage(
                          {
                            msg: 'gameOver',
                          },
                          '*',
                        )
                      }
                      THModalStore.close()
                    },
                    clickable: true,
                  },
                })
              }}
            >
              <img
                className='w-8 h-8'
                src='../watermelonAssets/close.png'
                alt='map'
                style={
                  {
                    // bottom: `${groundPosition}px`,
                  }
                }
              />
            </div>
          )}
          <Iframe
            styles={{ border: 0, margin: 0, overflow: 'hidden' }}
            frameBorder={0}
            url='/Watermelon.html'
            width='100%'
            height='100%'
            id='child'
            display='flex'
            position='fixed'
            allowFullScreen
          />
        </div>
      )}
      <div className='z-[-10] absolute -bottom-4 w-full h-[30vw] bg-white' />
    </div>
  )
})

export default Watermelon
