import { observer } from 'mobx-react-lite'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'

import altoonCMSBackendApis from '../../../utils/altoonCMSBackendApis'
import SearchForm from '../SearchForm'

moment.tz.setDefault('Asia/Seoul')

const ToonChapterInfoManagement = observer(({ setIsLoading }) => {
  const [activeSection, setActiveSection] = useState('search')
  const [chapterData, setChapterData] = useState([])
  const [toonId, setToonId] = useState('')
  const [selectedChapter, setSelectedChapter] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [toonList, setToonList] = useState([])
  const [selectedToon, setSelectedToon] = useState(null)
  const [activeSearch, setActiveSearch] = useState({ query: '', type: 'title' })

  // 페이지네이션 관련 state
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(30)
  const [totalPages, setTotalPages] = useState(1)

  // 회차 등록 관련 state
  const [chapterTitle, setChapterTitle] = useState('')
  const [episode, setEpisode] = useState('')
  const [detailThumbnail, setDetailThumbnail] = useState('')
  const [contents, setContents] = useState([''])
  const [authorComment, setAuthorComment] = useState('')
  const [isFree, setIsFree] = useState(true)
  const [isDisplayed, setIsDisplayed] = useState(true)
  const [publishedAt, setPublishedAt] = useState('')
  const [onlyPaidCoin, setOnlyPaidCoin] = useState(false)

  // 작품 목록 불러오기
  useEffect(() => {
    const fetchToonList = async () => {
      setIsLoading(true)
      const result = await altoonCMSBackendApis.getToonData()
      if (result?.status === 200) {
        setToonList(result.data)
      }
      setIsLoading(false)
    }

    fetchToonList()
  }, [setIsLoading])

  // 작품 필터링
  const getFilteredToons = () => {
    if (!activeSearch.query) return toonList

    return toonList.filter((toon) => {
      const searchField = activeSearch.type === 'title' ? toon.title : toon._id
      return searchField
        .toLowerCase()
        .includes(activeSearch.query.toLowerCase())
    })
  }

  // 회차 목록 조회
  const handleSearchChapters = async () => {
    if (!toonId) {
      alert('작품을 선택해주세요.')
      return
    }

    setIsLoading(true)
    const result = await altoonCMSBackendApis.getChapterDataByToonId({
      toonId,
    })
    if (result?.status === 200) {
      const sortedData = result?.data?.sort((a, b) => a.episode - b.episode)
      setChapterData(sortedData)
      setTotalPages(Math.ceil(sortedData.length / itemsPerPage))
      setCurrentPage(1)
    }
    setIsLoading(false)
  }

  const handleRegisterChapter = async () => {
    if (!selectedToon || !toonId) {
      alert('작품을 선택해주세요.')
      return
    }

    if (!chapterTitle) {
      alert('회차 제목을 입력해주세요.')
      return
    }

    if (!episode) {
      alert('회차 번호를 입력해주세요.')
      return
    }

    if (contents.length === 0 || contents.some((url) => !url)) {
      alert('이미지 URL을 모두 입력해주세요.')
      return
    }

    if (!publishedAt) {
      alert('공개일을 설정해주세요.')
      return
    }

    try {
      setIsLoading(true)
      const response = await altoonCMSBackendApis.registerToonChapter({
        toonId,
        chapterTitle,
        chapterEpisode: Number(episode),
        detailThumbnail,
        contents: JSON.stringify(contents.filter((url) => url.trim() !== '')),
        authorComment,
        publishedAt: moment(publishedAt).format(),
        isFree,
        isDisplayed,
        onlyPaidCoin,
      })

      if (response?.status === 200) {
        alert('회차가 등록되었습니다.')
        // 입력 필드 초기화
        setChapterTitle('')
        setEpisode('')
        setDetailThumbnail('')
        setContents([''])
        setAuthorComment('')
        setPublishedAt('')
        setIsFree(true)
        setIsDisplayed(true)
        setOnlyPaidCoin(false)

        // 회차 목록 새로고침
        handleSearchChapters()
      } else {
        alert(
          `회차 등록에 실패했습니다: ${response?.message || '알 수 없는 오류'}`,
        )
      }
    } catch (error) {
      console.error('회차 등록 오류:', error)
      alert(
        `회차 등록 중 오류가 발생했습니다: ${
          error.message || '알 수 없는 오류'
        }`,
      )
    } finally {
      setIsLoading(false)
    }
  }

  const handleRowClick = (chapter) => {
    setSelectedChapter(chapter)
    setShowModal(true)
  }

  const ToonChapterDetailModal = ({ chapter, onClose }) => {
    const [isEditing, setIsEditing] = useState(false)
    const [editedData, setEditedData] = useState({})
    const [validationErrors, setValidationErrors] = useState({})

    if (!chapter) return null

    useEffect(() => {
      document.body.style.overflow = 'hidden'
      return () => {
        document.body.style.overflow = 'unset'
      }
    }, [])

    const handleBackgroundClick = (e) => {
      if (e.target === e.currentTarget) {
        onClose()
      }
    }

    const validateField = (field, value) => {
      if (field === 'authorComment') return null // 작가의 말은 빈 값 허용
      if (value === undefined || value === '') return '필수 입력 항목입니다.'

      switch (field) {
        case 'chapterTitle':
          if (value.length < 1) return '제목은 1자 이상이어야 합니다.'
          break
        case 'episode':
          if (Number.isNaN(Number(value)) || Number(value) < 0)
            return '0 이상의 숫자만 입력 가능합니다.'
          break
        case 'contents':
          if (!Array.isArray(value) || value.length === 0)
            return '최소 1개 이상의 이미지가 필요합니다.'
          break
      }
      return null
    }

    const handleEdit = (field, value) => {
      const error = validateField(field, value)
      setValidationErrors((prev) => ({
        ...prev,
        [field]: error,
      }))

      setEditedData((prev) => ({
        ...prev,
        [field]: value,
      }))
    }

    const handleSave = async () => {
      const hasErrors = Object.values(validationErrors).some(
        (error) => error !== null,
      )
      if (hasErrors) {
        alert('입력값을 확인해주세요.')
        return
      }

      setIsLoading(true)
      const updates = []

      for (const [field, value] of Object.entries(editedData)) {
        let processedValue = value

        if (field === 'publishedAt') {
          try {
            processedValue = moment(value).format()
          } catch (error) {
            console.error('날짜 변환 오류:', error)
            alert(`날짜 형식이 올바르지 않습니다: ${value}`)
            setIsLoading(false)
            return
          }
        } else if (field === 'episode') {
          processedValue = Number(value)
        } else if (typeof value === 'string') {
          processedValue = value.trim()
        }

        try {
          const result = await altoonCMSBackendApis.updateToonChapterInfo({
            chapterId: chapter._id,
            field,
            value: processedValue,
            isDate: field === 'publishedAt',
          })

          if (result?.status !== 200) {
            alert(
              `${field} 필드 업데이트 실패: ${
                result?.message || '알 수 없는 오류'
              }`,
            )
            setIsLoading(false)
            return
          }
          updates.push(field)
        } catch (error) {
          console.error(`${field} 업데이트 중 오류:`, error)
          alert(
            `${field} 필드 업데이트 중 오류가 발생했습니다: ${
              error.message || '알 수 없는 오류'
            }`,
          )
          setIsLoading(false)
          return
        }
      }

      if (updates.length > 0) {
        alert('수정이 완료되었습니다.')
        handleSearchChapters() // 데이터 새로고침
        onClose()
      }
      setIsLoading(false)
    }

    const renderEditableField = (label, field, value, type = 'text') => {
      const renderEditField = (type, field, value) => {
        if (!isEditing) {
          switch (type) {
            case 'checkbox':
              return value ? '예' : '아니오'
            case 'contents':
              return (
                <div className='max-h-40 overflow-y-auto'>
                  {value.map((url, i) => (
                    <div
                      key={`${field}-${i}`}
                      className='mb-1 text-xs break-all'
                    >
                      {url}
                    </div>
                  ))}
                  <div className='mt-2'>총 {value.length}개의 이미지</div>
                </div>
              )
            case 'datetime-local':
              return moment(value).format('YYYY-MM-DD HH:mm')
            default:
              return value
          }
        }

        const fieldComponents = {
          checkbox: (
            <input
              type='checkbox'
              checked={editedData[field] ?? value}
              onChange={(e) => handleEdit(field, e.target.checked)}
              className='form-checkbox h-5 w-5'
            />
          ),
          textarea: (
            <textarea
              value={editedData[field] ?? value}
              onChange={(e) => handleEdit(field, e.target.value)}
              className='w-full p-2 border rounded'
              rows={4}
            />
          ),
          contents: (
            <div className='space-y-2'>
              <div className='text-xs text-gray-500 mb-1'>
                각 줄에 하나의 이미지 URL을 입력하세요.
              </div>
              <textarea
                value={
                  Array.isArray(editedData[field] ?? value)
                    ? (editedData[field] ?? value).join('\n')
                    : ''
                }
                onChange={(e) => handleEdit(field, e.target.value.split('\n'))}
                className='w-full p-2 border rounded'
                rows={6}
                placeholder='이미지 URL을 입력하세요. 각 줄마다 하나의 URL이 등록됩니다.'
              />
            </div>
          ),
          'datetime-local': (
            <div>
              <input
                type='datetime-local'
                value={moment(editedData[field] ?? value).format(
                  'YYYY-MM-DDTHH:mm',
                )}
                onChange={(e) => handleEdit(field, e.target.value)}
                className='w-full p-2 border rounded'
              />
              <div className='text-xs text-gray-500 mt-1'>
                현재 값:{' '}
                {moment(editedData[field] ?? value).format('YYYY-MM-DD HH:mm')}
              </div>
            </div>
          ),
        }

        return (
          <div>
            {fieldComponents[type] || (
              <input
                type={type}
                value={editedData[field] ?? value}
                onChange={(e) => handleEdit(field, e.target.value)}
                className='w-full p-2 border rounded'
              />
            )}
            {validationErrors[field] && (
              <div className='text-red-500 text-sm mt-1'>
                {validationErrors[field]}
              </div>
            )}
          </div>
        )
      }

      if (field === '_id' || field === 'createdAt') {
        return (
          <tr>
            <td className='px-4 py-2 font-medium'>{label}</td>
            <td className='px-4 py-2'>{value}</td>
          </tr>
        )
      }

      return (
        <tr>
          <td className='px-4 py-2 font-medium'>{label}</td>
          <td className='px-4 py-2'>{renderEditField(type, field, value)}</td>
        </tr>
      )
    }

    return (
      <div
        className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[9999]'
        onClick={handleBackgroundClick}
      >
        <div className='bg-white rounded-xl p-6 w-[80vw] max-w-[1200px] h-[90vh] overflow-y-auto ml-[15vw]'>
          <div className='flex justify-between items-center mb-4'>
            <h2 className='text-2xl font-bold'>
              {chapter.episode}화: {chapter.chapterTitle}
            </h2>
            <button
              type='button'
              onClick={onClose}
              className='text-gray-500 hover:text-gray-700 text-3xl font-bold w-10 h-10 flex items-center justify-center'
            >
              ✕
            </button>
          </div>
          <div className='flex flex-col gap-6'>
            <div className='grid grid-cols-3 gap-6'>
              <div className='col-span-1'>
                <img
                  src={chapter.detailThumbnail}
                  alt={chapter.chapterTitle}
                  className='w-full h-auto object-cover rounded shadow-lg'
                />
              </div>
              <div className='col-span-2 space-y-6'>
                <div className='border rounded-lg overflow-hidden'>
                  <table className='w-full'>
                    <thead className='bg-gray-50'>
                      <tr>
                        <th
                          className='px-4 py-2 text-left font-semibold'
                          colSpan='2'
                        >
                          기본 정보 <span className='text-red-500'>*</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className='divide-y'>
                      {renderEditableField('회차 ID', '_id', chapter._id)}
                      {renderEditableField('작품 ID', 'toonId', chapter.toonId)}
                      {renderEditableField(
                        '회차 제목',
                        'chapterTitle',
                        chapter.chapterTitle,
                      )}
                      {renderEditableField(
                        '회차 번호',
                        'episode',
                        chapter.episode,
                        'number',
                      )}
                      {renderEditableField(
                        '공개 일시',
                        'publishedAt',
                        moment(chapter.publishedAt),
                        'datetime-local',
                      )}
                      {renderEditableField(
                        '썸네일 URL',
                        'detailThumbnail',
                        chapter.detailThumbnail,
                      )}
                      {renderEditableField(
                        '이미지 목록',
                        'contents',
                        chapter.contents,
                        'contents',
                      )}
                      {renderEditableField(
                        '작가의 말',
                        'authorComment',
                        chapter.authorComment || '',
                        'textarea',
                      )}
                    </tbody>
                  </table>
                </div>

                <div className='border rounded-lg overflow-hidden'>
                  <table className='w-full'>
                    <thead className='bg-gray-50'>
                      <tr>
                        <th
                          className='px-4 py-2 text-left font-semibold'
                          colSpan='2'
                        >
                          상태 정보
                        </th>
                      </tr>
                    </thead>
                    <tbody className='divide-y'>
                      {renderEditableField(
                        '무료 여부',
                        'isFree',
                        chapter.isFree,
                        'checkbox',
                      )}
                      {renderEditableField(
                        '공개 여부',
                        'isDisplayed',
                        chapter.isDisplayed,
                        'checkbox',
                      )}
                      {renderEditableField(
                        '유료코인 전용',
                        'onlyPaidCoin',
                        chapter.onlyPaidCoin,
                        'checkbox',
                      )}
                      {renderEditableField(
                        '검토 여부',
                        'isReviewed',
                        chapter.isReviewed,
                        'checkbox',
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className='flex justify-end space-x-4 mt-8'>
            {isEditing && (
              <button
                type='button'
                onClick={() => {
                  setIsEditing(false)
                  setEditedData({})
                  setValidationErrors({})
                }}
                className='px-6 py-2 bg-gray-200 hover:bg-gray-300 text-gray-700 rounded-lg'
              >
                취소
              </button>
            )}
            <button
              type='button'
              onClick={() => {
                if (isEditing) {
                  handleSave()
                } else {
                  setIsEditing(true)
                  setEditedData({})
                  setValidationErrors({})
                }
              }}
              className='px-6 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg'
            >
              {isEditing ? '수정 완료' : '수정하기'}
            </button>
            <button
              type='button'
              onClick={async () => {
                if (
                  window.confirm(
                    '정말로 이 회차를 삭제하시겠습니까? 이 작업은 되돌릴 수 없습니다.',
                  )
                ) {
                  try {
                    setIsLoading(true)
                    const result = await altoonCMSBackendApis.deleteChapter(
                      chapter._id,
                    )
                    if (result?.status === 200) {
                      alert('회차가 성공적으로 삭제되었습니다.')
                      handleSearchChapters() // 목록 새로고침
                      onClose() // 모달 닫기
                    } else {
                      alert(
                        `회차 삭제 실패: ${
                          result?.message || '알 수 없는 오류'
                        }`,
                      )
                    }
                  } catch (error) {
                    console.error('회차 삭제 중 오류:', error)
                    alert(
                      `회차 삭제 중 오류가 발생했습니다: ${
                        error.message || '알 수 없는 오류'
                      }`,
                    )
                  } finally {
                    setIsLoading(false)
                  }
                }
              }}
              className='px-6 py-2 bg-red-500 hover:bg-red-600 text-white rounded-lg'
            >
              삭제하기
            </button>
          </div>
        </div>
      </div>
    )
  }

  const renderSearchSection = () => {
    const filteredToons = getFilteredToons()

    // 현재 페이지에 표시할 회차 데이터
    const indexOfLastItem = currentPage * itemsPerPage
    const indexOfFirstItem = indexOfLastItem - itemsPerPage
    const currentItems = chapterData.slice(indexOfFirstItem, indexOfLastItem)

    // 페이지 변경 핸들러
    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber)
    }

    // 페이지당 항목 수 변경 핸들러
    const handleItemsPerPageChange = (e) => {
      const newItemsPerPage = parseInt(e.target.value, 10)
      setItemsPerPage(newItemsPerPage)
      setTotalPages(Math.ceil(chapterData.length / newItemsPerPage))
      setCurrentPage(1)
    }

    return (
      <div className='w-full p-4'>
        <div className='py-4 font-bold text-[2.6vh]'>회차 목록</div>

        <div className='mb-6'>
          <div className='py-4 flexRow justify-between items-center'>
            <SearchForm
              onSearch={({ searchQuery, searchType }) => {
                setActiveSearch({ query: searchQuery, type: searchType })
                setSelectedToon(null)
                setToonId('')
                setChapterData([])
              }}
              activeSearchQuery={activeSearch.query}
              activeSearchType={activeSearch.type}
            />
          </div>

          {!selectedToon ? (
            <div className='mt-4'>
              <h3 className='text-lg font-semibold mb-2'>작품 선택</h3>
              <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
                {filteredToons?.map((toon) => (
                  <div
                    key={toon._id}
                    className='border rounded-lg p-4 cursor-pointer hover:bg-gray-50'
                    onClick={() => {
                      console.log('작품 선택:', toon.title, toon._id)
                      setSelectedToon(toon)
                      setToonId(toon._id)
                    }}
                  >
                    <div className='flex items-center'>
                      <img
                        src={toon.thumbnail}
                        alt={toon.title}
                        className='w-16 h-16 object-cover rounded mr-4'
                      />
                      <div>
                        <h4 className='font-medium'>{toon.title}</h4>
                        <p className='text-sm text-gray-500'>{toon.author}</p>
                        <p className='text-xs text-gray-400'>ID: {toon._id}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className='mt-4 mb-6'>
              <div className='flex justify-between items-center'>
                <div className='flex items-center'>
                  <img
                    src={selectedToon.thumbnail}
                    alt={selectedToon.title}
                    className='w-16 h-16 object-cover rounded mr-4'
                  />
                  <div>
                    <h3 className='text-lg font-semibold'>
                      {selectedToon.title}
                    </h3>
                    <p className='text-sm text-gray-500'>
                      {selectedToon.author}
                    </p>
                    <p className='text-xs text-gray-400'>
                      ID: {selectedToon._id}
                    </p>
                  </div>
                </div>
                <button
                  type='button'
                  className='px-4 py-2 bg-gray-200 hover:bg-gray-300 text-gray-700 rounded'
                  onClick={() => {
                    setSelectedToon(null)
                    setToonId('')
                    setChapterData([])
                  }}
                >
                  다른 작품 선택
                </button>
              </div>
            </div>
          )}
        </div>

        {selectedToon && (
          <div className='flex items-center justify-end mb-4'>
            <div className='flex items-center space-x-4'>
              <div className='text-sm'>페이지당 항목 수:</div>
              <select
                id='items-per-page'
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
                className='pl-2 border rounded'
              >
                <option value={10}>10개</option>
                <option value={30}>30개</option>
                <option value={50}>50개</option>
                <option value={100}>100개</option>
              </select>
            </div>
          </div>
        )}

        {chapterData?.length > 0 ? (
          <div className='mt-6 overflow-x-auto'>
            <table className='w-full border-collapse'>
              <thead className='whitespace-nowrap text-[1.4vh] border-b bg-gray-100'>
                <tr>
                  <th className='px-4 py-3 text-center'>회차번호</th>
                  <th className='px-4 py-3 text-left'>제목</th>
                  <th className='px-4 py-3 text-center'>썸네일</th>
                  <th className='px-4 py-3 text-center'>공개일</th>
                  <th className='px-4 py-3 text-center'>유/무료</th>
                  <th className='px-4 py-3 text-center'>공개여부</th>
                  <th className='px-4 py-3 text-center'>이미지 개수</th>
                  <th className='px-4 py-3 text-center'>검토여부</th>
                  <th className='px-4 py-3 text-center'>유료코인 전용</th>
                </tr>
              </thead>
              <tbody className='text-sm divide-y divide-gray-100'>
                {currentItems.map((item, index) => (
                  <tr
                    key={item._id}
                    className={`cursor-pointer hover:bg-gray-50 ${
                      index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                    }`}
                    onClick={() => handleRowClick(item)}
                  >
                    <td className='px-4 py-3 text-center'>{item.episode}</td>
                    <td className='px-4 py-3'>
                      <div className='font-medium'>{item.chapterTitle}</div>
                    </td>
                    <td className='px-4 py-3 text-center'>
                      <img
                        src={item.detailThumbnail}
                        alt='thumbnail'
                        className='w-[50px] h-[50px] object-cover rounded mx-auto'
                      />
                    </td>
                    <td className='px-4 py-3 text-center whitespace-nowrap'>
                      {moment(item.publishedAt).format('YY.MM.DD')}
                      <br />
                      {moment(item.publishedAt).format('HH:mm')}
                    </td>
                    <td
                      className={`px-4 py-3 text-center font-medium ${
                        item.isFree ? 'text-green-500' : 'text-red-500'
                      }`}
                    >
                      {item.isFree ? '무료' : '유료'}
                    </td>
                    <td className='px-4 py-3 text-center'>
                      {item.isDisplayed ? (
                        <span className='text-green-600 font-medium'>Y</span>
                      ) : (
                        <span className='text-red-600 font-medium'>N</span>
                      )}
                    </td>
                    <td className='px-4 py-3 text-center'>
                      {item.contents?.length}개
                    </td>
                    <td className='px-4 py-3 text-center'>
                      {item.isReviewed ? (
                        <span className='text-green-600 font-medium'>Y</span>
                      ) : (
                        <span className='text-red-600 font-medium'>N</span>
                      )}
                    </td>
                    <td className='px-4 py-3 text-center'>
                      {item.onlyPaidCoin ? (
                        <span className='text-blue-600 font-medium'>Y</span>
                      ) : (
                        <span className='text-gray-600 font-medium'>N</span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* 페이지네이션 UI */}
            <div className='flex justify-center mt-6'>
              <div className='flex space-x-2'>
                <button
                  onClick={() => handlePageChange(1)}
                  disabled={currentPage === 1}
                  className={`px-3 py-1 rounded ${
                    currentPage === 1
                      ? 'bg-gray-200 text-gray-500'
                      : 'bg-gray-300 hover:bg-gray-400 text-gray-700'
                  }`}
                >
                  처음
                </button>
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className={`px-3 py-1 rounded ${
                    currentPage === 1
                      ? 'bg-gray-200 text-gray-500'
                      : 'bg-gray-300 hover:bg-gray-400 text-gray-700'
                  }`}
                >
                  이전
                </button>

                {/* 페이지 번호 */}
                {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
                  let pageNum
                  if (totalPages <= 5) {
                    pageNum = i + 1
                  } else if (currentPage <= 3) {
                    pageNum = i + 1
                  } else if (currentPage >= totalPages - 2) {
                    pageNum = totalPages - 4 + i
                  } else {
                    pageNum = currentPage - 2 + i
                  }

                  if (pageNum > 0 && pageNum <= totalPages) {
                    return (
                      <button
                        key={pageNum}
                        onClick={() => handlePageChange(pageNum)}
                        className={`px-3 py-1 rounded ${
                          currentPage === pageNum
                            ? 'bg-blue-500 text-white'
                            : 'bg-gray-300 hover:bg-gray-400 text-gray-700'
                        }`}
                      >
                        {pageNum}
                      </button>
                    )
                  }
                  return null
                })}

                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className={`px-3 py-1 rounded ${
                    currentPage === totalPages
                      ? 'bg-gray-200 text-gray-500'
                      : 'bg-gray-300 hover:bg-gray-400 text-gray-700'
                  }`}
                >
                  다음
                </button>
                <button
                  onClick={() => handlePageChange(totalPages)}
                  disabled={currentPage === totalPages}
                  className={`px-3 py-1 rounded ${
                    currentPage === totalPages
                      ? 'bg-gray-200 text-gray-500'
                      : 'bg-gray-300 hover:bg-gray-400 text-gray-700'
                  }`}
                >
                  마지막
                </button>
              </div>
            </div>

            <div className='text-center text-sm text-gray-500 mt-2'>
              총 {chapterData.length}개 중 {indexOfFirstItem + 1}-
              {Math.min(indexOfLastItem, chapterData.length)}개 표시
            </div>
          </div>
        ) : selectedToon ? (
          <div className='flexCol justify-center items-center h-40 bg-gray-50 rounded-lg mt-6'>
            <div className='text-gray-500'>회차 데이터가 없습니다.</div>
            <button
              type='button'
              className='px-4 py-2 mt-2 bg-blue-500 hover:bg-blue-600 text-white rounded'
              onClick={handleSearchChapters}
            >
              회차 불러오기
            </button>
          </div>
        ) : (
          <div className='flex justify-center items-center h-40 bg-gray-50 rounded-lg mt-6'>
            <div className='text-gray-500'>
              작품을 선택하면 회차 목록이 표시됩니다.
            </div>
          </div>
        )}

        {/* 회차 상세 모달 */}
        {showModal && selectedChapter && (
          <ToonChapterDetailModal
            chapter={selectedChapter}
            onClose={() => {
              setShowModal(false)
              setSelectedChapter(null)
            }}
          />
        )}
      </div>
    )
  }

  const renderCreateSection = () => {
    const filteredToons = getFilteredToons()

    return (
      <div className='p-4'>
        <div className='py-4 font-bold text-[2.6vh]'>회차 등록</div>

        {!selectedToon ? (
          <div className='mb-6'>
            <div className='py-4 flexRow justify-between items-center'>
              <SearchForm
                onSearch={({ searchQuery, searchType }) => {
                  setActiveSearch({ query: searchQuery, type: searchType })
                  setSelectedToon(null)
                  setToonId('')
                }}
                activeSearchQuery={activeSearch.query}
                activeSearchType={activeSearch.type}
              />
            </div>

            <div className='mt-4'>
              <h3 className='text-lg font-semibold mb-2'>작품 선택</h3>
              <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
                {filteredToons?.map((toon) => (
                  <div
                    key={toon._id}
                    className='border rounded-lg p-4 cursor-pointer hover:bg-gray-50'
                    onClick={() => {
                      console.log('작품 선택:', toon.title, toon._id)
                      setSelectedToon(toon)
                      setToonId(toon._id)
                    }}
                  >
                    <div className='flex items-center'>
                      <img
                        src={toon.thumbnail}
                        alt={toon.title}
                        className='w-16 h-16 object-cover rounded mr-4'
                      />
                      <div>
                        <h4 className='font-medium'>{toon.title}</h4>
                        <p className='text-sm text-gray-500'>{toon.author}</p>
                        <p className='text-xs text-gray-400'>ID: {toon._id}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className='flex flex-col gap-6'>
            <div className='mb-6'>
              <div className='flex justify-between items-center'>
                <div className='flex items-center'>
                  <img
                    src={selectedToon.thumbnail}
                    alt={selectedToon.title}
                    className='w-16 h-16 object-cover rounded mr-4'
                  />
                  <div>
                    <h3 className='text-lg font-semibold'>
                      {selectedToon.title}
                    </h3>
                    <p className='text-sm text-gray-500'>
                      {selectedToon.author}
                    </p>
                    <p className='text-xs text-gray-400'>
                      ID: {selectedToon._id}
                    </p>
                  </div>
                </div>
                <button
                  type='button'
                  className='px-4 py-2 bg-gray-200 hover:bg-gray-300 text-gray-700 rounded'
                  onClick={() => {
                    setSelectedToon(null)
                    setToonId('')
                  }}
                >
                  다른 작품 선택
                </button>
              </div>
            </div>

            <div className='grid grid-cols-3 gap-6'>
              <div className='col-span-1'>
                <div className='space-y-4'>
                  <div>
                    <div className='block mb-2 font-medium'>
                      썸네일 URL
                      <span className='text-red-500'> *</span>
                    </div>
                    <input
                      type='text'
                      value={detailThumbnail}
                      onChange={(e) => setDetailThumbnail(e.target.value)}
                      className='w-full p-2 border rounded'
                      id='thumbnail-input'
                    />
                  </div>
                  {detailThumbnail && (
                    <div className='mt-2'>
                      <img
                        src={detailThumbnail}
                        alt='썸네일 미리보기'
                        className='w-full h-auto object-cover rounded shadow-lg'
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className='col-span-2 space-y-6'>
                <div className='border rounded-lg overflow-hidden'>
                  <table className='w-full'>
                    <thead className='bg-gray-50'>
                      <tr>
                        <th
                          className='px-4 py-2 text-left font-semibold'
                          colSpan='2'
                        >
                          회차 정보<span className='text-red-500'> *</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className='divide-y'>
                      <tr>
                        <td className='px-4 py-2 font-medium'>회차 제목</td>
                        <td className='px-4 py-2'>
                          <input
                            type='text'
                            value={chapterTitle}
                            onChange={(e) => setChapterTitle(e.target.value)}
                            className='w-full p-2 border rounded'
                            id='chapter-title-input'
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='px-4 py-2 font-medium'>회차 번호</td>
                        <td className='px-4 py-2'>
                          <input
                            type='number'
                            value={episode}
                            onChange={(e) => setEpisode(e.target.value)}
                            className='w-24 p-2 border rounded'
                            id='episode-input'
                            min='0'
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='px-4 py-2 font-medium'>이미지 URL</td>
                        <td className='px-4 py-2'>
                          <div className='space-y-2'>
                            <div className='text-xs text-gray-500 mb-1'>
                              각 줄에 하나의 이미지 URL을 입력하세요.
                            </div>
                            <textarea
                              value={contents.join('\n')}
                              onChange={(e) => {
                                const urls = e.target.value.split('\n')
                                setContents(urls)
                              }}
                              className='w-full p-2 border rounded'
                              rows={6}
                              placeholder='이미지 URL을 입력하세요. 각 줄마다 하나의 URL이 등록됩니다.'
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className='px-4 py-2 font-medium'>작가의 말</td>
                        <td className='px-4 py-2'>
                          <textarea
                            value={authorComment}
                            onChange={(e) => setAuthorComment(e.target.value)}
                            className='w-full p-2 border rounded'
                            rows={4}
                            id='author-comment-textarea'
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='px-4 py-2 font-medium'>공개일</td>
                        <td className='px-4 py-2'>
                          <input
                            type='datetime-local'
                            value={publishedAt}
                            onChange={(e) => setPublishedAt(e.target.value)}
                            className='w-full p-2 border rounded'
                            id='published-at-input'
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='px-4 py-2 font-medium'>무료 여부</td>
                        <td className='px-4 py-2'>
                          <input
                            type='checkbox'
                            checked={isFree}
                            onChange={(e) => setIsFree(e.target.checked)}
                            className='form-checkbox h-5 w-5'
                            id='is-free-checkbox'
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='px-4 py-2 font-medium'>노출 여부</td>
                        <td className='px-4 py-2'>
                          <input
                            type='checkbox'
                            checked={isDisplayed}
                            onChange={(e) => setIsDisplayed(e.target.checked)}
                            className='form-checkbox h-5 w-5'
                            id='is-displayed-checkbox'
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='px-4 py-2 font-medium'>유료코인 전용</td>
                        <td className='px-4 py-2'>
                          <input
                            type='checkbox'
                            checked={onlyPaidCoin}
                            onChange={(e) => setOnlyPaidCoin(e.target.checked)}
                            className='form-checkbox h-5 w-5'
                            id='only-paid-coin-checkbox'
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className='flex justify-end mt-8'>
              <button
                type='button'
                onClick={handleRegisterChapter}
                className='px-6 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg'
              >
                회차 등록하기
              </button>
            </div>
          </div>
        )}
      </div>
    )
  }

  return (
    <div className='w-full'>
      <div className='flex space-x-4 mb-6'>
        <button
          type='button'
          className={`px-4 py-2 rounded-lg ${
            activeSection === 'search'
              ? 'bg-blue-500 text-white'
              : 'bg-gray-200 text-gray-700'
          }`}
          onClick={() => setActiveSection('search')}
        >
          회차 조회
        </button>
        <button
          type='button'
          className={`px-4 py-2 rounded-lg ${
            activeSection === 'create'
              ? 'bg-blue-500 text-white'
              : 'bg-gray-200 text-gray-700'
          }`}
          onClick={() => setActiveSection('create')}
        >
          회차 등록
        </button>
      </div>

      {activeSection === 'search' && renderSearchSection()}
      {activeSection === 'create' && renderCreateSection()}
    </div>
  )
})

export default ToonChapterInfoManagement
