import { observer } from 'mobx-react-lite'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'

import AltoonCMSUserStore from '../../stores/AltoonCMS/AltoonCMSUserStore'
import altoonCMSBackendApis from '../../utils/altoonCMSBackendApis'

moment.tz.setDefault('Asia/Seoul')

const BannerAdminScreen = observer(() => {
  const [bannerList, setBannerList] = useState([])
  const [selectedBanner, setSelectedBanner] = useState(null)
  const [filterType, setFilterType] = useState('all')
  const [filterBannerType, setFilterBannerType] = useState('all')
  const [filterDisplayed, setFilterDisplayed] = useState('all')
  const [currentTab, setCurrentTab] = useState('list')
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [searchType, setSearchType] = useState('title')
  const [searchQuery, setSearchQuery] = useState('')
  const [sortBy, setSortBy] = useState('createdAt')
  const [sortOrder, setSortOrder] = useState('desc')

  const fetchData = async () => {
    const result = await altoonCMSBackendApis.loadToonBanner()
    if (result?.status === 200) {
      setBannerList(result?.data)
    }
  }

  useEffect(() => {
    AltoonCMSUserStore.setIsLoading(true)
    fetchData()
    setTimeout(() => {
      AltoonCMSUserStore.setIsLoading(false)
    }, 500)
  }, [])

  const filteredAndSortedBannerList = bannerList
    .filter((banner) => {
      // 검색 필터
      if (searchQuery) {
        if (searchType === 'title') {
          if (
            !banner.toonTitle?.toLowerCase().includes(searchQuery.toLowerCase())
          ) {
            return false
          }
        } else if (searchType === 'id') {
          if (!banner._id?.toLowerCase().includes(searchQuery.toLowerCase())) {
            return false
          }
        }
      }

      // 기존 필터
      if (filterType !== 'all' && banner.type !== filterType) return false
      if (
        filterBannerType !== 'all' &&
        (banner.bannerType || 'main_banner') !== filterBannerType
      )
        return false
      if (
        filterDisplayed !== 'all' &&
        banner.isDisplayed !== (filterDisplayed === 'true')
      )
        return false
      return true
    })
    .sort((a, b) => {
      if (sortBy === 'createdAt') {
        return sortOrder === 'desc'
          ? moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()
          : moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf()
      }
      if (sortBy === 'displayStartedAt') {
        const dateA = a.displayStartedAt
          ? moment(a.displayStartedAt)
          : moment(0)
        const dateB = b.displayStartedAt
          ? moment(b.displayStartedAt)
          : moment(0)
        return sortOrder === 'desc'
          ? dateB.valueOf() - dateA.valueOf()
          : dateA.valueOf() - dateB.valueOf()
      }
      // priority 정렬 (없는 경우 -1로 처리)
      const priorityA = a.priority ?? -1
      const priorityB = b.priority ?? -1
      return sortOrder === 'desc'
        ? priorityB - priorityA
        : priorityA - priorityB
    })

  // 페이지네이션 계산
  const totalPages = Math.ceil(
    filteredAndSortedBannerList.length / itemsPerPage,
  )
  const paginatedBannerList = filteredAndSortedBannerList.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  )

  const bannerTypes = [
    { value: 'main_banner', label: '메인 배너' },
    { value: 'daily_banner', label: '요일연재 배너' },
    { value: 'recommend_banner', label: '완결작 배너' },
    { value: 'novel_banner', label: '웹소설 배너' },
    { value: 'mainShortBanner', label: '메인 띠배너' },
  ]

  const RegisteredBannerList = () => {
    const [tempSearchQuery, setTempSearchQuery] = useState('')
    const [editData, setEditData] = useState(null)
    const [isEditMode, setIsEditMode] = useState(false)

    const handleEdit = () => {
      setIsEditMode(true)
      setEditData({ ...selectedBanner })
    }

    const handleCancel = () => {
      setIsEditMode(false)
      setEditData(null)
    }

    const handleSave = async () => {
      const updates = Object.entries(editData).reduce((acc, [key, value]) => {
        if (value !== selectedBanner[key] && key !== '_id') {
          acc.push({ field: key, value })
        }
        return acc
      }, [])

      if (updates.length === 0) {
        alert('변경된 내용이 없습니다.')
        return
      }
      console.log('updates', JSON.stringify(updates))

      const updatePromises = updates.map(({ field, value }) =>
        altoonCMSBackendApis.updateBannerInfo({
          bannerId: selectedBanner._id,
          field,
          value,
        }),
      )

      try {
        const results = await Promise.all(updatePromises)
        if (results.every((result) => result?.status === 200)) {
          alert('수정되었습니다.')
          fetchData()
          setIsEditMode(false)
          setEditData(null)
          setSelectedBanner(null)
        } else {
          alert('일부 항목 수정 중 오류가 발생했습니다.')
        }
      } catch (error) {
        alert('오류가 발생했습니다.')
        console.error(error)
      }
    }

    return (
      <div className='w-full'>
        {/* 검색 영역 */}
        <div className='mb-6 p-6 bg-white rounded-xl shadow-sm border border-gray-100'>
          <div className='flex gap-6 items-center mb-6'>
            <div className='flex-1'>
              <div className='flex gap-3 items-center'>
                <select
                  value={searchType}
                  onChange={(e) => {
                    setSearchType(e.target.value)
                    setSearchQuery('')
                  }}
                  className='pl-3 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent'
                >
                  <option value='title'>제목</option>
                  <option value='id'>ID</option>
                </select>
                <div className='flex-1 flex gap-2'>
                  <input
                    type='text'
                    value={tempSearchQuery}
                    onChange={(e) => setTempSearchQuery(e.target.value)}
                    placeholder={
                      searchType === 'title'
                        ? '제목으로 검색...'
                        : 'ID로 검색...'
                    }
                    className='flex-1 px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent'
                  />
                  <button
                    type='button'
                    onClick={() => {
                      setSearchQuery(tempSearchQuery)
                      setCurrentPage(1)
                    }}
                    className='px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                  >
                    검색
                  </button>
                </div>
              </div>
            </div>
            <div className='flex gap-3 items-center'>
              <span className='text-gray-600 font-medium'>정렬:</span>
              <select
                value={sortBy}
                onChange={(e) => setSortBy(e.target.value)}
                className='pl-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent'
              >
                <option value='createdAt'>생성일</option>
                <option value='priority'>우선순위</option>
                <option value='displayStartedAt'>노출 시작일</option>
              </select>
              <button
                type='button'
                onClick={() =>
                  setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
                }
                className='px-3 py-2 border border-gray-200 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors'
              >
                {sortOrder === 'asc' ? '↑' : '↓'}
              </button>
            </div>
          </div>

          {/* 필터 영역 */}
          <div className='flex gap-6 flex-wrap items-center'>
            <div className='flex items-center'>
              <label
                htmlFor='typeFilter'
                className='text-gray-600 font-medium mr-3'
              >
                타입:
              </label>
              <select
                id='typeFilter'
                value={filterType}
                onChange={(e) => setFilterType(e.target.value)}
                className='pl-3 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent'
              >
                <option value='all'>전체</option>
                <option value='special'>스페셜</option>
                <option value='toon'>웹툰</option>
                <option value='novel'>웹소설</option>
              </select>
            </div>
            <div className='flex items-center'>
              <label
                htmlFor='bannerTypeFilter'
                className='text-gray-600 font-medium mr-3'
              >
                배너 타입:
              </label>
              <select
                id='bannerTypeFilter'
                value={filterBannerType}
                onChange={(e) => setFilterBannerType(e.target.value)}
                className='pl-3 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent'
              >
                <option value='all'>전체</option>
                {bannerTypes.map((type) => (
                  <option key={type.value} value={type.value}>
                    {type.label}
                  </option>
                ))}
              </select>
            </div>
            <div className='flex items-center'>
              <label
                htmlFor='displayFilter'
                className='text-gray-600 font-medium mr-3'
              >
                노출 여부:
              </label>
              <select
                id='displayFilter'
                value={filterDisplayed}
                onChange={(e) => setFilterDisplayed(e.target.value)}
                className='pl-3 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent'
              >
                <option value='all'>전체</option>
                <option value='true'>노출</option>
                <option value='false'>숨김</option>
              </select>
            </div>
            <div className='flex items-center'>
              <label
                htmlFor='itemsPerPage'
                className='text-gray-600 font-medium mr-3'
              >
                표시 개수:
              </label>
              <select
                id='itemsPerPage'
                value={itemsPerPage}
                onChange={(e) => {
                  setItemsPerPage(Number(e.target.value))
                  setCurrentPage(1)
                }}
                className='pl-3 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent'
              >
                <option value={10}>10개</option>
                <option value={30}>30개</option>
                <option value={50}>50개</option>
              </select>
            </div>
          </div>
        </div>

        {/* 결과 카운트 표시 */}
        <div className='mb-4 text-sm text-gray-600 font-medium'>
          총 {filteredAndSortedBannerList.length}개의 배너 ({currentPage} /{' '}
          {totalPages} 페이지)
        </div>

        {/* 테이블 */}
        <div className='overflow-hidden rounded-xl border border-gray-200 bg-white shadow-sm'>
          <div className='overflow-x-auto'>
            <table className='min-w-full divide-y divide-gray-200'>
              <thead className='bg-gray-50'>
                <tr>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    ID
                  </th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    타입
                  </th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    배너 타입
                  </th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    제목
                  </th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    이미지
                  </th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    노출여부
                  </th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    우선순위
                  </th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    노출 기간
                  </th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    관리
                  </th>
                </tr>
              </thead>
              <tbody className='bg-white divide-y divide-gray-200'>
                {paginatedBannerList.map((item) => (
                  <tr
                    key={item._id}
                    className='hover:bg-gray-50 cursor-pointer transition-colors'
                    onClick={() => setSelectedBanner(item)}
                  >
                    <td
                      key={`${item._id}-id`}
                      className='px-2 py-4 text-[4px] text-gray-900 font-mono cursor-pointer hover:text-blue-500'
                      onClick={(e) => {
                        e.stopPropagation()
                      }}
                    >
                      {item._id}
                    </td>
                    <td
                      key={`${item._id}-type`}
                      className='px-4 py-4 text-xs text-gray-900'
                    >
                      {item.type}
                    </td>
                    <td
                      key={`${item._id}-bannerType`}
                      className='px-4 py-4 text-xs text-gray-900'
                    >
                      {item.bannerType
                        ? bannerTypes.find(
                            (type) => type.value === item.bannerType,
                          )?.label || item.bannerType
                        : '메인 배너'}
                    </td>
                    <td
                      key={`${item._id}-title`}
                      className='px-4 py-4 text-sm text-gray-900'
                    >
                      {item.toonTitle}
                    </td>
                    <td key={`${item._id}-image`} className='px-6 py-4'>
                      <img
                        src={item.imageUri}
                        alt={item.toonTitle}
                        className='w-24 h-14 object-cover rounded-lg shadow-sm'
                      />
                    </td>
                    <td key={`${item._id}-display`} className='px-6 py-4'>
                      <span
                        className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                          item.isDisplayed
                            ? 'bg-green-100 text-green-800'
                            : 'bg-gray-100 text-gray-800'
                        }`}
                      >
                        {item.isDisplayed ? '노출' : '숨김'}
                      </span>
                    </td>
                    <td
                      key={`${item._id}-priority`}
                      className='px-6 py-4 text-sm text-gray-900'
                    >
                      {item.priority || '-'}
                    </td>
                    <td
                      key={`${item._id}-period`}
                      className='px-6 py-4 text-sm text-gray-900'
                    >
                      {item?.displayStartedAt && (
                        <>
                          {item.displayStartedAt
                            ? moment(item.displayStartedAt).format(
                                'YYYY-MM-DD HH:mm:ss',
                              )
                            : '-'}
                          <br />~<br />
                          {item.displayEndedAt
                            ? moment(item.displayEndedAt).format(
                                'YYYY-MM-DD HH:mm:ss',
                              )
                            : '-'}
                        </>
                      )}
                    </td>
                    <td
                      key={`${item._id}-actions`}
                      className='px-6 py-4 text-sm font-medium space-x-2'
                    >
                      <button
                        type='button'
                        className='inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-lg text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
                        onClick={(e) => {
                          e.stopPropagation()
                          const deleteConfirmation =
                            window.confirm('삭제하시겠습니까?')
                          if (deleteConfirmation) {
                            altoonCMSBackendApis
                              .deleteToonPromotion({
                                _id: item._id,
                                promotionType: 'banner',
                              })
                              .then((result) => {
                                if (result?.status === 200) {
                                  alert('삭제되었습니다.')
                                  fetchData()
                                } else {
                                  alert('오류가 발생하였습니다.')
                                }
                              })
                          }
                        }}
                      >
                        삭제
                      </button>
                      <button
                        type='button'
                        className={`inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-lg text-white ${
                          item.isDisplayed
                            ? 'bg-gray-600 hover:bg-gray-700 focus:ring-gray-500'
                            : 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
                        } focus:outline-none focus:ring-2 focus:ring-offset-2`}
                        onClick={(e) => {
                          e.stopPropagation()
                          const newValue = !item.isDisplayed
                          altoonCMSBackendApis
                            .updateBannerInfo({
                              bannerId: item._id,
                              field: 'isDisplayed',
                              value: newValue,
                            })
                            .then((result) => {
                              if (result?.status === 200) {
                                alert('노출 상태가 변경되었습니다.')
                                fetchData()
                              }
                            })
                        }}
                      >
                        {item.isDisplayed ? '숨기기' : '노출'}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* 페이지네이션 컨트롤 */}
        <div className='mt-6 flex justify-center gap-2 items-center'>
          <button
            type='button'
            onClick={() => setCurrentPage(1)}
            disabled={currentPage === 1}
            className='inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed'
          >
            {'<<'}
          </button>
          <button
            type='button'
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
            className='inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed'
          >
            {'<'}
          </button>
          <span className='px-4 py-2 text-sm text-gray-700 font-medium'>
            {currentPage} / {totalPages}
          </span>
          <button
            type='button'
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
            className='inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed'
          >
            {'>'}
          </button>
          <button
            type='button'
            onClick={() => setCurrentPage(totalPages)}
            disabled={currentPage === totalPages}
            className='inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed'
          >
            {'>>'}
          </button>
        </div>

        {/* 모달 */}
        {selectedBanner && (
          <div
            className='fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50'
            onClick={() => !isEditMode && setSelectedBanner(null)}
          >
            <div
              className='bg-white rounded-xl w-[800px] overflow-y-auto shadow-xl'
              style={{ maxHeight: 'calc(100vh - 100px)' }}
              onClick={(e) => e.stopPropagation()}
            >
              <div className='px-6 py-4 border-b border-gray-200 flex justify-between items-center'>
                <h3 className='text-lg font-medium text-gray-900'>
                  배너 상세 정보
                </h3>
                <button
                  type='button'
                  className='text-gray-400 hover:text-gray-500'
                  onClick={() => {
                    setSelectedBanner(null)
                    setIsEditMode(false)
                    setEditData(null)
                  }}
                >
                  <span className='sr-only'>닫기</span>
                  <svg
                    className='h-6 w-6'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth={2}
                      d='M6 18L18 6M6 6l12 12'
                    />
                  </svg>
                </button>
              </div>
              <div className='p-6'>
                {isEditMode && editData ? (
                  <div className='grid grid-cols-2 gap-6'>
                    <div>
                      <img
                        src={editData.imageUri}
                        alt={editData.toonTitle}
                        className='w-full h-auto rounded-lg shadow-lg object-contain'
                      />
                    </div>
                    <div className='space-y-4'>
                      <div className='grid grid-cols-3 gap-4'>
                        <div className='col-span-1 text-sm font-medium text-gray-500'>
                          ID
                        </div>
                        <div className='col-span-2 text-sm text-gray-900 font-mono'>
                          {editData._id}
                        </div>

                        <div className='col-span-1 text-sm font-medium text-gray-500'>
                          타입
                        </div>
                        <div className='col-span-2'>
                          <select
                            value={editData.type}
                            onChange={(e) =>
                              setEditData({ ...editData, type: e.target.value })
                            }
                            className='w-full px-3 py-1 border border-gray-300 rounded-md text-sm text-gray-900'
                          >
                            <option value='toon'>웹툰</option>
                            <option value='special'>스페셜</option>
                            <option value='novel'>웹소설</option>
                          </select>
                        </div>

                        <div className='col-span-1 text-sm font-medium text-gray-500'>
                          배너 타입
                        </div>
                        <div className='col-span-2'>
                          <select
                            value={editData.bannerType || 'main_banner'}
                            onChange={(e) =>
                              setEditData({
                                ...editData,
                                bannerType: e.target.value,
                              })
                            }
                            className='w-full px-3 py-1 border border-gray-300 rounded-md text-sm text-gray-900'
                          >
                            {bannerTypes.map((type) => (
                              <option key={type.value} value={type.value}>
                                {type.label}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className='col-span-1 text-sm font-medium text-gray-500'>
                          제목
                        </div>
                        <div className='col-span-2'>
                          <input
                            type='text'
                            value={editData.toonTitle || ''}
                            onChange={(e) =>
                              setEditData({
                                ...editData,
                                toonTitle: e.target.value,
                              })
                            }
                            className='w-full px-3 py-1 border border-gray-300 rounded-md text-sm text-gray-900'
                          />
                        </div>

                        <div className='col-span-1 text-sm font-medium text-gray-500'>
                          이미지 URL
                        </div>
                        <div className='col-span-2'>
                          <input
                            type='text'
                            value={editData.imageUri || ''}
                            onChange={(e) =>
                              setEditData({
                                ...editData,
                                imageUri: e.target.value,
                              })
                            }
                            className='w-full px-3 py-1 border border-gray-300 rounded-md text-sm text-gray-900'
                          />
                        </div>

                        {(editData.type === 'toon' ||
                          editData.type === 'novel') && (
                          <>
                            <div className='col-span-1 text-sm font-medium text-gray-500'>
                              {editData.type === 'toon'
                                ? '웹툰 ID'
                                : '웹소설 ID'}
                            </div>
                            <div className='col-span-2'>
                              <input
                                type='text'
                                value={editData.toonId || ''}
                                onChange={(e) =>
                                  setEditData({
                                    ...editData,
                                    toonId: e.target.value,
                                  })
                                }
                                className='w-full px-3 py-1 border border-gray-300 rounded-md text-sm text-gray-900 font-mono'
                              />
                            </div>

                            <div className='col-span-1 text-sm font-medium text-gray-500'>
                              회차 ID
                            </div>
                            <div className='col-span-2'>
                              <input
                                type='text'
                                value={editData.chapterId || ''}
                                onChange={(e) =>
                                  setEditData({
                                    ...editData,
                                    chapterId: e.target.value,
                                  })
                                }
                                className='w-full px-3 py-1 border border-gray-300 rounded-md text-sm text-gray-900 font-mono'
                              />
                            </div>

                            <div className='col-span-1 text-sm font-medium text-gray-500'>
                              회차
                            </div>
                            <div className='col-span-2'>
                              <input
                                type='text'
                                value={editData.episode || ''}
                                onChange={(e) =>
                                  setEditData({
                                    ...editData,
                                    episode: e.target.value,
                                  })
                                }
                                className='w-full px-3 py-1 border border-gray-300 rounded-md text-sm text-gray-900'
                              />
                            </div>
                          </>
                        )}

                        {editData.type === 'special' && (
                          <>
                            <div className='col-span-1 text-sm font-medium text-gray-500'>
                              이벤트 ID
                            </div>
                            <div className='col-span-2'>
                              <input
                                type='text'
                                value={editData.eventId || ''}
                                onChange={(e) =>
                                  setEditData({
                                    ...editData,
                                    eventId: e.target.value,
                                  })
                                }
                                className='w-full px-3 py-1 border border-gray-300 rounded-md text-sm text-gray-900 font-mono'
                              />
                            </div>
                          </>
                        )}

                        <div className='col-span-1 text-sm font-medium text-gray-500'>
                          우선순위
                        </div>
                        <div className='col-span-2'>
                          <input
                            type='number'
                            value={editData.priority || ''}
                            onChange={(e) =>
                              setEditData({
                                ...editData,
                                priority: e.target.value,
                              })
                            }
                            className='w-full px-3 py-1 border border-gray-300 rounded-md text-sm text-gray-900'
                          />
                        </div>

                        <div className='col-span-1 text-sm font-medium text-gray-500'>
                          노출 여부
                        </div>
                        <div className='col-span-2'>
                          <select
                            value={editData.isDisplayed ? 'true' : 'false'}
                            onChange={(e) =>
                              setEditData({
                                ...editData,
                                isDisplayed: e.target.value === 'true',
                              })
                            }
                            className='w-full px-3 py-1 border border-gray-300 rounded-md text-sm text-gray-900'
                          >
                            <option value='true'>노출</option>
                            <option value='false'>숨김</option>
                          </select>
                        </div>

                        <div className='col-span-1 text-sm font-medium text-gray-500'>
                          노출 시작일
                        </div>
                        <div className='col-span-2'>
                          <input
                            type='datetime-local'
                            value={
                              editData.displayStartedAt
                                ? moment(editData.displayStartedAt).format(
                                    'YYYY-MM-DDTHH:mm',
                                  )
                                : ''
                            }
                            onChange={(e) =>
                              setEditData({
                                ...editData,
                                displayStartedAt: e.target.value,
                              })
                            }
                            onBlur={(e) => {
                              if (e.target.value) {
                                const date = moment(e.target.value)
                                setEditData((prev) => ({
                                  ...prev,
                                  displayStartedAt: date.format(),
                                }))
                              }
                            }}
                            className='w-full px-3 py-1 border border-gray-300 rounded-md text-sm text-gray-900'
                          />
                        </div>

                        <div className='col-span-1 text-sm font-medium text-gray-500'>
                          노출 종료일
                        </div>
                        <div className='col-span-2'>
                          <input
                            type='datetime-local'
                            value={
                              editData.displayEndedAt
                                ? moment(editData.displayEndedAt).format(
                                    'YYYY-MM-DDTHH:mm',
                                  )
                                : ''
                            }
                            onChange={(e) =>
                              setEditData({
                                ...editData,
                                displayEndedAt: e.target.value,
                              })
                            }
                            onBlur={(e) => {
                              if (e.target.value) {
                                const date = moment(e.target.value)
                                setEditData((prev) => ({
                                  ...prev,
                                  displayEndedAt: date.format(),
                                }))
                              }
                            }}
                            className='w-full px-3 py-1 border border-gray-300 rounded-md text-sm text-gray-900'
                          />
                        </div>

                        <div className='col-span-1 text-sm font-medium text-gray-500'>
                          생성일
                        </div>
                        <div className='col-span-2 text-sm text-gray-900'>
                          {moment(editData.createdAt).format(
                            'YYYY-MM-DD HH:mm:ss',
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : selectedBanner ? (
                  <div className='grid grid-cols-2 gap-6'>
                    <div>
                      <img
                        src={selectedBanner.imageUri}
                        alt={selectedBanner.toonTitle}
                        className='w-full h-auto rounded-lg shadow-lg object-contain'
                      />
                    </div>
                    <div className='space-y-4'>
                      <div className='grid grid-cols-3 gap-4'>
                        <div className='col-span-1 text-sm font-medium text-gray-500'>
                          ID
                        </div>
                        <div className='col-span-2 text-sm text-gray-900 font-mono'>
                          {selectedBanner._id}
                        </div>

                        <div className='col-span-1 text-sm font-medium text-gray-500'>
                          타입
                        </div>
                        <div className='col-span-2 text-sm text-gray-900'>
                          {selectedBanner.type}
                        </div>

                        <div className='col-span-1 text-sm font-medium text-gray-500'>
                          배너 타입
                        </div>
                        <div className='col-span-2 text-sm text-gray-900'>
                          {bannerTypes.find(
                            (type) =>
                              type.value ===
                              (selectedBanner.bannerType || 'main_banner'),
                          )?.label ||
                            selectedBanner.bannerType ||
                            'main_banner'}
                        </div>

                        <div className='col-span-1 text-sm font-medium text-gray-500'>
                          제목
                        </div>
                        <div className='col-span-2 text-sm text-gray-900'>
                          {selectedBanner.toonTitle}
                        </div>

                        {(selectedBanner.type === 'toon' ||
                          selectedBanner.type === 'novel') && (
                          <>
                            <div className='col-span-1 text-sm font-medium text-gray-500'>
                              {selectedBanner.type ? '웹툰 ID' : '웹소설 ID'}
                            </div>
                            <div className='col-span-2 text-sm text-gray-900 font-mono'>
                              {selectedBanner.toonId}
                            </div>

                            <div className='col-span-1 text-sm font-medium text-gray-500'>
                              회차 ID
                            </div>
                            <div className='col-span-2 text-sm text-gray-900 font-mono'>
                              {selectedBanner.chapterId}
                            </div>

                            <div className='col-span-1 text-sm font-medium text-gray-500'>
                              회차
                            </div>
                            <div className='col-span-2 text-sm text-gray-900'>
                              {selectedBanner.episode}
                            </div>
                          </>
                        )}

                        {selectedBanner.type === 'special' && (
                          <>
                            <div className='col-span-1 text-sm font-medium text-gray-500'>
                              이벤트 ID
                            </div>
                            <div className='col-span-2 text-sm text-gray-900 font-mono'>
                              {selectedBanner.eventId}
                            </div>
                          </>
                        )}

                        <div className='col-span-1 text-sm font-medium text-gray-500'>
                          우선순위
                        </div>
                        <div className='col-span-2 text-sm text-gray-900'>
                          {selectedBanner.priority || '-'}
                        </div>

                        <div className='col-span-1 text-sm font-medium text-gray-500'>
                          노출 여부
                        </div>
                        <div className='col-span-2'>
                          <span
                            className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                              selectedBanner.isDisplayed
                                ? 'bg-green-100 text-green-800'
                                : 'bg-gray-100 text-gray-800'
                            }`}
                          >
                            {selectedBanner.isDisplayed ? '노출' : '숨김'}
                          </span>
                        </div>

                        {selectedBanner.displayStartedAt && (
                          <>
                            <div className='col-span-1 text-sm font-medium text-gray-500'>
                              노출 기간
                            </div>
                            <div className='col-span-2 text-sm text-gray-900'>
                              {selectedBanner.displayStartedAt
                                ? moment(
                                    selectedBanner.displayStartedAt,
                                  ).format('YYYY-MM-DD HH:mm:ss')
                                : '-'}
                              <br />~<br />
                              {selectedBanner.displayEndedAt
                                ? moment(selectedBanner.displayEndedAt).format(
                                    'YYYY-MM-DD HH:mm:ss',
                                  )
                                : '-'}
                            </div>
                          </>
                        )}

                        <div className='col-span-1 text-sm font-medium text-gray-500'>
                          생성일
                        </div>
                        <div className='col-span-2 text-sm text-gray-900'>
                          {moment(selectedBanner.createdAt).format(
                            'YYYY-MM-DD HH:mm:ss',
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className='px-6 py-4 border-t border-gray-200 flex justify-end gap-2'>
                {isEditMode ? (
                  <>
                    <button
                      type='button'
                      className='inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                      onClick={handleCancel}
                    >
                      취소
                    </button>
                    <button
                      type='button'
                      className='inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                      onClick={handleSave}
                    >
                      수정완료
                    </button>
                  </>
                ) : (
                  <button
                    type='button'
                    className='inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                    onClick={handleEdit}
                  >
                    수정하기
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }

  const ToonBannerRegisterSection = () => {
    const [registerData, setRegisterData] = useState({
      type: 'toon',
      bannerType: 'main_banner',
      toonId: '',
      toonTitle: '',
      chapterId: '',
      episode: '',
      imageUri: '',
      eventId: '',
      priority: '',
      isDisplayed: true,
      displayStartedAt: '',
      displayEndedAt: '',
      novelId: '',
      novelTitle: '',
    })

    const handleSubmit = async () => {
      const requiredFields =
        registerData.type === 'special'
          ? ['eventId', 'toonTitle', 'imageUri']
          : ['toonId', 'toonTitle', 'chapterId', 'episode', 'imageUri']

      const missingFields = requiredFields.filter(
        (field) => !registerData[field],
      )
      if (missingFields.length > 0) {
        alert(`다음 필드를 입력해주세요: ${missingFields.join(', ')}`)
        return
      }
      console.log('registerData', JSON.stringify(registerData))

      const result = await altoonCMSBackendApis.registerBanner(registerData)
      if (result?.status === 200) {
        alert('등록되었습니다.')
        fetchData()
        setRegisterData({
          type: 'toon',
          bannerType: 'main_banner',
          toonId: '',
          toonTitle: '',
          chapterId: '',
          episode: '',
          imageUri: '',
          eventId: '',
          priority: '',
          isDisplayed: true,
          displayStartedAt: '',
          displayEndedAt: '',
          novelId: '',
          novelTitle: '',
        })
      } else {
        alert('오류가 발생하였습니다. 오기입 위험이 있으니 DB를 확인해주세요.')
      }
    }

    return (
      <div className='p-6 bg-white rounded-xl shadow-lg'>
        <h2 className='text-lg font-medium text-gray-900 mb-6'>배너 등록</h2>
        <div className='flex'>
          <div className='w-1/3 space-y-4'>
            <div className='w-4/5'>
              <div className='pb-4 text-sm font-medium text-gray-500'>
                이미지 URL
              </div>
              <input
                type='text'
                value={registerData.imageUri}
                onChange={(e) =>
                  setRegisterData({
                    ...registerData,
                    imageUri: e.target.value,
                  })
                }
                className='w-full px-3 py-2 border border-gray-300 rounded-md text-sm text-gray-900'
                placeholder='이미지 URL을 입력하세요'
              />
            </div>
            {registerData.imageUri && (
              <img
                src={registerData.imageUri}
                alt='배너 미리보기'
                className='w-4/5 h-auto rounded-lg shadow-lg object-contain'
              />
            )}
          </div>
          <div className='w-2/3 space-y-4'>
            <div className='grid grid-cols-3 gap-4'>
              <div className='col-span-1 text-sm font-medium text-gray-500'>
                타입
              </div>
              <div className='col-span-2'>
                <select
                  value={registerData.type}
                  onChange={(e) =>
                    setRegisterData({
                      ...registerData,
                      type: e.target.value,
                      // Reset type-specific fields
                      toonId: '',
                      chapterId: '',
                      episode: '',
                      eventId: '',
                    })
                  }
                  className='w-full px-3 py-2 border border-gray-300 rounded-md text-sm text-gray-900'
                >
                  <option value='toon'>웹툰</option>
                  <option value='special'>스페셜</option>
                  <option value='novel'>웹소설</option>
                </select>
              </div>

              <div className='col-span-1 text-sm font-medium text-gray-500'>
                배너 타입
              </div>
              <div className='col-span-2'>
                <select
                  value={registerData.bannerType}
                  onChange={(e) =>
                    setRegisterData({
                      ...registerData,
                      bannerType: e.target.value,
                    })
                  }
                  className='w-full px-3 py-2 border border-gray-300 rounded-md text-sm text-gray-900'
                >
                  {bannerTypes.map((type) => (
                    <option key={type.value} value={type.value}>
                      {type.label}
                    </option>
                  ))}
                </select>
              </div>

              <div className='col-span-1 text-sm font-medium text-gray-500'>
                제목
              </div>
              <div className='col-span-2'>
                <input
                  type='text'
                  value={registerData.toonTitle}
                  onChange={(e) =>
                    setRegisterData({
                      ...registerData,
                      toonTitle: e.target.value,
                    })
                  }
                  className='w-full px-3 py-2 border border-gray-300 rounded-md text-sm text-gray-900'
                  placeholder='제목을 입력하세요'
                />
              </div>

              {(registerData.type === 'toon' ||
                registerData.type === 'novel') && (
                <>
                  <div className='col-span-1 text-sm font-medium text-gray-500'>
                    {registerData.type === 'toon' ? '웹툰 ID' : '웹소설 ID'}
                  </div>
                  <div className='col-span-2'>
                    <input
                      type='text'
                      value={registerData.toonId}
                      onChange={(e) =>
                        setRegisterData({
                          ...registerData,
                          toonId: e.target.value,
                        })
                      }
                      className='w-full px-3 py-2 border border-gray-300 rounded-md text-sm text-gray-900 font-mono'
                      placeholder={
                        registerData.type === 'toon'
                          ? '웹툰 ID를 입력하세요'
                          : '웹소설 ID를 입력하세요'
                      }
                    />
                  </div>

                  <div className='col-span-1 text-sm font-medium text-gray-500'>
                    회차 ID
                  </div>
                  <div className='col-span-2'>
                    <input
                      type='text'
                      value={registerData.chapterId}
                      onChange={(e) =>
                        setRegisterData({
                          ...registerData,
                          chapterId: e.target.value,
                        })
                      }
                      className='w-full px-3 py-2 border border-gray-300 rounded-md text-sm text-gray-900 font-mono'
                      placeholder='회차 ID를 입력하세요'
                    />
                  </div>

                  <div className='col-span-1 text-sm font-medium text-gray-500'>
                    회차
                  </div>
                  <div className='col-span-2'>
                    <input
                      type='number'
                      value={registerData.episode}
                      onChange={(e) =>
                        setRegisterData({
                          ...registerData,
                          episode: e.target.value,
                        })
                      }
                      className='w-full px-3 py-2 border border-gray-300 rounded-md text-sm text-gray-900'
                      placeholder='회차를 입력하세요'
                    />
                  </div>
                </>
              )}

              {registerData.type === 'special' && (
                <>
                  <div className='col-span-1 text-sm font-medium text-gray-500'>
                    이벤트 ID
                  </div>
                  <div className='col-span-2'>
                    <input
                      type='text'
                      value={registerData.eventId}
                      onChange={(e) =>
                        setRegisterData({
                          ...registerData,
                          eventId: e.target.value,
                        })
                      }
                      className='w-full px-3 py-2 border border-gray-300 rounded-md text-sm text-gray-900 font-mono'
                      placeholder='이벤트 ID를 입력하세요'
                    />
                  </div>
                </>
              )}

              <div className='col-span-1 text-sm font-medium text-gray-500'>
                우선순위
              </div>
              <div className='col-span-2'>
                <input
                  type='number'
                  value={registerData.priority}
                  onChange={(e) =>
                    setRegisterData({
                      ...registerData,
                      priority: e.target.value,
                    })
                  }
                  className='w-full px-3 py-2 border border-gray-300 rounded-md text-sm text-gray-900'
                  placeholder='우선순위를 입력하세요'
                />
              </div>

              <div className='col-span-1 text-sm font-medium text-gray-500'>
                노출 여부
              </div>
              <div className='col-span-2'>
                <select
                  value={registerData.isDisplayed.toString()}
                  onChange={(e) =>
                    setRegisterData({
                      ...registerData,
                      isDisplayed: e.target.value === 'true',
                    })
                  }
                  className='w-full px-3 py-2 border border-gray-300 rounded-md text-sm text-gray-900'
                >
                  <option value='true'>노출</option>
                  <option value='false'>숨김</option>
                </select>
              </div>

              <div className='col-span-1 text-sm font-medium text-gray-500'>
                노출 시작일
              </div>
              <div className='col-span-2'>
                <input
                  type='datetime-local'
                  value={
                    registerData.displayStartedAt
                      ? moment(registerData.displayStartedAt).format(
                          'YYYY-MM-DDTHH:mm',
                        )
                      : ''
                  }
                  onChange={(e) =>
                    setRegisterData({
                      ...registerData,
                      displayStartedAt: e.target.value,
                    })
                  }
                  onBlur={(e) => {
                    if (e.target.value) {
                      const date = moment(e.target.value)
                      setRegisterData({
                        ...registerData,
                        displayStartedAt: date.format(),
                      })
                    }
                  }}
                  className='w-full px-3 py-2 border border-gray-300 rounded-md text-sm text-gray-900'
                />
              </div>

              <div className='col-span-1 text-sm font-medium text-gray-500'>
                노출 종료일
              </div>
              <div className='col-span-2'>
                <input
                  type='datetime-local'
                  value={
                    registerData.displayEndedAt
                      ? moment(registerData.displayEndedAt).format(
                          'YYYY-MM-DDTHH:mm',
                        )
                      : ''
                  }
                  onChange={(e) =>
                    setRegisterData({
                      ...registerData,
                      displayEndedAt: e.target.value,
                    })
                  }
                  onBlur={(e) => {
                    if (e.target.value) {
                      const date = moment(e.target.value)
                      setRegisterData({
                        ...registerData,
                        displayEndedAt: date.format(),
                      })
                    }
                  }}
                  className='w-full px-3 py-2 border border-gray-300 rounded-md text-sm text-gray-900'
                />
              </div>
            </div>
          </div>
        </div>
        <div className='mt-6 flex justify-end'>
          <button
            type='button'
            className='inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
            onClick={handleSubmit}
          >
            등록하기
          </button>
        </div>
      </div>
    )
  }

  return (
    <div className='w-full my-10 min-w-7xl max-w-10xl select-text'>
      {/* 탭 네비게이션 */}
      <div className='mb-6 border-b'>
        <div className='flex gap-4'>
          <button
            type='button'
            className={`py-2 px-4 ${
              currentTab === 'list'
                ? 'border-b-2 border-blue-500 text-blue-500'
                : 'text-gray-500'
            }`}
            onClick={() => setCurrentTab('list')}
          >
            배너 조회
          </button>
          <button
            type='button'
            className={`py-2 px-4 ${
              currentTab === 'register'
                ? 'border-b-2 border-blue-500 text-blue-500'
                : 'text-gray-500'
            }`}
            onClick={() => setCurrentTab('register')}
          >
            배너 등록
          </button>
        </div>
      </div>

      {/* 탭 컨텐츠 */}
      {currentTab === 'list' ? (
        <div className='p-4 bg-white rounded-xl'>
          <div className='py-4 font-bold text-[2.6vh]'>배너 관리</div>
          {bannerList?.length > 0 && <RegisteredBannerList />}
        </div>
      ) : (
        <ToonBannerRegisterSection />
      )}
    </div>
  )
})

export default BannerAdminScreen
