import AuthStore from '../stores/AuthStore'
import fetchWrapper from './fetchWrapper'

const API_URI = process.env.REACT_APP_ALTOON_API_URI

const fetcher = async (url, token, method = 'GET', params = {}) => {
  const resource =
    method === 'GET' ? `${url}?${new URLSearchParams(params)}` : url
  const init = ['POST', 'PUT', 'DELETE'].includes(method)
    ? {
        body: JSON.stringify(params),
        headers: {},
      }
    : { headers: {} }
  init.method = method
  init.headers['Content-Type'] = 'application/json'
  init.headers['x-access-token'] = token
  try {
    const res = await fetch(API_URI + resource, init)
    const data = await res.json()
    return data
  } catch (err) {
    return null
  }
}

const tempToken = localStorage.getItem('@altoon@provider@token@')
class AltoonCMSBackendApis {
  async attemptLogin(params = {}) {
    const result = await fetcher(
      '/toons/altoon-cms-login',
      tempToken ?? AuthStore?.token,
      'POST',
      params,
    )
    return result
  }

  async changePassword(token, params = {}) {
    const result = await fetcher(
      '/toons/altoon-cms-change-passwords',
      token,
      'POST',
      params,
    )
    return result
  }

  async loadProviderInfo(token, params = {}) {
    const result = await fetcher(
      '/toons/altoon-cms-provider-info',
      token,
      'GET',
      params,
    )
    return result
  }

  async loadProviderList(token, params = {}) {
    const result = await fetcher(
      '/toons/altoon-cms-provider-list',
      token,
      'GET',
      params,
    )
    return result
  }

  async loadDashboardInfo(token, params = {}) {
    const result = await fetcher(
      '/toons/altoon-cms-dashboard-info',
      token,
      'GET',
      params,
    )
    return result
  }

  async loadDashboardInfoInPeriod(token, params = {}) {
    const result = await fetcher(
      '/toons/altoon-cms-dashboard-info-in-period',
      token,
      'GET',
      params,
    )
    return result
  }

  async loadMainpageUserflow(token, params = {}) {
    const result = await fetcher(
      '/toons/mainpage-userflow',
      token,
      'GET',
      params,
    )
    return result
  }

  async getToonData(params = {}) {
    const result = await fetcher(
      '/toons/toon-data',
      tempToken ?? AuthStore?.token,
      'GET',
      params,
    )
    return result
  }

  async loadAllChapter(params = {}) {
    const result = await fetcher(
      '/toons/chapter-data',
      tempToken ?? AuthStore?.token,
      'GET',
      params,
    )
    return result
  }

  async getToonDataByProviderId(params = {}) {
    const result = await fetcher(
      '/toons/toon-data-by-provider-id',
      tempToken ?? AuthStore?.token,

      'GET',
      params,
    )
    return result
  }

  async loadToonBanner(params = {}) {
    const result = await fetcher(
      '/toons/toon-banner',
      tempToken ?? AuthStore?.token,
      'GET',
      params,
    )
    return result
  }

  async loadToonEventPage(params = {}) {
    const result = await fetcher(
      '/toons/toon-event-page',
      tempToken ?? AuthStore?.token,
      'GET',
      params,
    )
    return result
  }

  async getChapterDataByToonId(params = {}) {
    const result = await fetcher(
      '/toons/chapter-data-by-id',
      tempToken ?? AuthStore?.token,
      'GET',
      params,
    )
    return result
  }

  async getChapterDataByNovelId(params = {}) {
    const result = await fetcher(
      '/toons/chapter-data-by-novel-id',
      tempToken ?? AuthStore?.token,
      'GET',
      params,
    )
    return result
  }

  async loadRecommendingToons(params = {}) {
    const result = await fetcher(
      '/toons/load-recommending-toons',
      tempToken ?? AuthStore?.token,
      'GET',
      params,
    )
    return result
  }

  async promoteToon(params = {}) {
    const result = await fetcher(
      '/toons/promote-toon',
      tempToken ?? AuthStore?.token,
      'POST',
      params,
    )
    return result
  }

  async dePromoteToon(params = {}) {
    const result = await fetcher(
      '/toons/depromote-toon',
      tempToken ?? AuthStore?.token,
      'POST',
      params,
    )
    return result
  }

  async updateToonInfo(params = {}) {
    const result = await fetcher(
      '/toons/update-toon-info',
      tempToken ?? AuthStore?.token,
      'POST',
      params,
    )
    return result
  }

  async updateNovelInfo(params = {}) {
    const result = await fetcher(
      '/toons/update-novel-info',
      tempToken ?? AuthStore?.token,
      'POST',
      params,
    )
    return result
  }

  async updateToonChapterInfo(params = {}) {
    console.log('updateToonChapterInfo 호출:', params)
    // 날짜 필드인 경우 특별 처리
    if (params.isDate && params.field === 'publishedAt') {
      // 이미 문자열인 경우 JSON.stringify 하지 않도록
      if (typeof params.value === 'string') {
        params = {
          ...params,
          value: params.value, // 문자열 그대로 전달
        }
      }
    }

    const result = await fetcher(
      '/toons/update-toon-chapter',
      tempToken ?? AuthStore?.token,
      'POST',
      params,
    )
    return result
  }

  async updateNovelChapterInfo(params = {}) {
    console.log('updateNovelChapterInfo 호출:', params)
    // 날짜 필드인 경우 특별 처리
    if (params.isDate && params.field === 'publishedAt') {
      // 이미 문자열인 경우 JSON.stringify 하지 않도록
      if (typeof params.value === 'string') {
        params = {
          ...params,
          value: params.value, // 문자열 그대로 전달
        }
      }
    }

    const result = await fetcher(
      '/toons/update-novel-chapter',
      tempToken ?? AuthStore?.token,
      'POST',
      params,
    )
    return result
  }

  async updateProviderInfo(params = {}) {
    const result = await fetcher(
      '/toons/update-toon-provider',
      tempToken ?? AuthStore?.token,
      'POST',
      params,
    )
    return result
  }

  async updateBannerInfo(params = {}) {
    const result = await fetcher(
      '/toons/update-toon-banner',
      tempToken ?? AuthStore?.token,
      'POST',
      params,
    )
    return result
  }

  async updatePromotionInfo(params = {}) {
    const result = await fetcher(
      '/toons/update-toon-promotion',
      tempToken ?? AuthStore?.token,
      'POST',
      params,
    )
    return result
  }

  async registerProvider(params = {}) {
    const result = await fetcher(
      '/toons/register-provider',
      tempToken ?? AuthStore?.token,
      'POST',
      params,
    )
    return result
  }

  async registerToon(params = {}) {
    const result = await fetcher(
      '/toons/register-toon',
      tempToken ?? AuthStore?.token,
      'POST',
      params,
    )
    return result
  }

  async registerNovel(params = {}) {
    const result = await fetcher(
      '/toons/register-novel',
      tempToken ?? AuthStore?.token,
      'POST',
      params,
    )
    return result
  }

  async deleteToon(toonId) {
    const result = await fetcher(
      `/toons/delete-toon/${toonId}`,
      tempToken ?? AuthStore?.token,
      'DELETE',
    )
    return result
  }

  async deleteChapter(chapterId) {
    const result = await fetcher(
      `/toons/delete-chapter/${chapterId}`,
      tempToken ?? AuthStore?.token,
      'DELETE',
    )
    return result
  }

  async registerToonChapter(params = {}) {
    const result = await fetcher(
      '/toons/register-toon-chapter',
      tempToken ?? AuthStore?.token,
      'POST',
      params,
    )
    return result
  }

  async getNovelData(params = {}) {
    const result = await fetcher(
      '/toons/novel-data',
      tempToken ?? AuthStore?.token,
      'GET',
      params,
    )
    return result
  }

  async getNovelMetaData(params = {}) {
    const result = await fetcher(
      '/toons/novel-meta-data',
      tempToken ?? AuthStore?.token,
      'GET',
      params,
    )
    return result
  }

  async uploadNovelChapterExcel(formData) {
    try {
      const token = tempToken ?? AuthStore?.token
      const response = await fetch(
        `${API_URI}/toons/novel/chapter/excel-upload`,
        {
          method: 'POST',
          body: formData,
          headers: {
            'x-access-token': token,
            // Content-Type은 설정하지 않습니다. 브라우저가 자동으로 설정합니다.
          },
        },
      )

      const result = await response.json()
      return result
    } catch (error) {
      console.error('업로드 중 에러 발생:', error)
      return null
    }
  }

  async uploadEpubZipFile(formData) {
    try {
      const token = tempToken ?? AuthStore?.token
      const response = await fetch(
        `${API_URI}/toons/novel/chapter/epub-upload`,
        {
          method: 'POST',
          body: formData,
          headers: {
            'x-access-token': token,
            // Content-Type은 설정하지 않습니다. 브라우저가 자동으로 설정합니다.
          },
        },
      )

      const result = await response.json()
      return result
    } catch (error) {
      console.error('업로드 중 에러 발생:', error)
      return null
    }
  }

  async registerBanner(params = {}) {
    const result = await fetcher(
      '/toons/register-toon-banner',
      tempToken ?? AuthStore?.token,
      'POST',
      params,
    )
    return result
  }

  async registerToonPromotion(params = {}) {
    const result = await fetcher(
      '/toons/register-toon-promotion',
      tempToken ?? AuthStore?.token,
      'POST',
      params,
    )
    return result
  }

  async addToonCoin(params = {}) {
    const result = await fetcher(
      '/toons/add-toon-coin',
      tempToken ?? AuthStore?.token,
      'POST',
      params,
    )
    return result
  }

  async toonComment(params = {}) {
    const result = await fetcher(
      '/toons/toon-comment',
      tempToken ?? AuthStore?.token,
      'GET',
      params,
    )
    return result
  }

  async loadContentsTabInfo(params = {}) {
    const result = await fetcher(
      '/toons/contents-tab-info',
      tempToken ?? AuthStore?.token,
      'GET',
      params,
    )
    return result
  }

  async registerContentsTabInfo(params = {}) {
    const result = await fetcher(
      '/toons/register-contents-tab-info',
      tempToken ?? AuthStore?.token,
      'POST',
      params,
    )
    return result
  }

  async updateContentsTabInfo(params = {}) {
    const result = await fetcher(
      '/toons/update-contents-tab-info',
      tempToken ?? AuthStore?.token,
      'PUT',
      params,
    )
    return result
  }

  async deleteContentsTabInfo(params = {}) {
    const result = await fetcher(
      '/toons/delete-contents-tab-info',
      tempToken ?? AuthStore?.token,
      'DELETE',
      params,
    )
    return result
  }

  async deleteToonComment(params = {}) {
    const result = await fetcher(
      '/toons/delete-toon-comment',
      tempToken ?? AuthStore?.token,
      'PUT',
      params,
    )
    return result
  }

  async deleteToonPromotion(params = {}) {
    const result = await fetcher(
      '/toons/delete-toon-promotion',
      tempToken ?? AuthStore?.token,
      'PUT',
      params,
    )
    return result
  }

  // 노출구좌 목록 조회
  async loadRecommendSlots() {
    const result = await fetcher(
      '/toons/recommend-slots',
      tempToken ?? AuthStore?.token,
      'GET',
    )
    return result
  }

  // 노출구좌 등록
  async registerRecommendSlot(params = {}) {
    const result = await fetcher(
      '/toons/recommend-slots',
      tempToken ?? AuthStore?.token,
      'POST',
      params,
    )
    return result
  }

  // 노출구좌 수정
  async updateRecommendSlot(slotId, params = {}) {
    const result = await fetcher(
      `/toons/recommend-slots/${slotId}`,
      tempToken ?? AuthStore?.token,
      'PUT',
      params,
    )
    return result
  }

  // 노출구좌 삭제
  async deleteRecommendSlot(slotId) {
    const result = await fetcher(
      `/toons/recommend-slots/${slotId}`,
      tempToken ?? AuthStore?.token,
      'DELETE',
    )
    return result
  }

  // 메인이벤트 목록 조회
  async loadAllMainEvent() {
    const result = await fetcher(
      '/toons/main-event',
      tempToken ?? AuthStore?.token,
      'GET',
    )
    return result
  }

  // 메인이벤트 등록
  async registerMainEvent(params = {}) {
    const result = await fetcher(
      '/toons/main-event',
      tempToken ?? AuthStore?.token,
      'POST',
      params,
    )
    return result
  }

  // 메인이벤트 수정
  async updateMainEvent(eventId, params = {}) {
    const result = await fetcher(
      `/toons/main-event/${eventId}`,
      tempToken ?? AuthStore?.token,
      'PUT',
      params,
    )
    return result
  }

  // 메인이벤트 삭제
  async deleteMainEvent(eventId) {
    const result = await fetcher(
      `/toons/main-event/${eventId}`,
      tempToken ?? AuthStore?.token,
      'DELETE',
    )
    return result
  }
}

export default new AltoonCMSBackendApis()
