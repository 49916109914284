import { observer } from 'mobx-react-lite'
import React, { memo, useEffect, useState } from 'react'
import { MdArrowForwardIos as ArrowRight } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

import AuthStore from '../../../stores/AuthStore'
import ItemImpressionStore from '../../../stores/ItemImpressionStore'
import UserStore from '../../../stores/UserStore'
import backendApis from '../../../utils/backendApis'
import HorizontalList from '../item/horizontalList'
import VisitedTimeDealItemComponent from '../item/visitedTimeDealItemComponent'

const VisitedTimeDealSection = ({ token }) => {
  const navigate = useNavigate()
  const [dealItemsInfo, setDealItemsInfo] = useState([])
  const [loadNum, setLoadNum] = useState(0)
  const dealType = 'timeDeal'
  const version = 'visitedTimeDeal'
  const ITEM_HEIGHT = 240
  const ITEM_WIDTH = 140
  const SCREEN_WIDTH = window.innerWidth

  const fetchDealItem = async (dealType, loadNum, version) => {
    const result = await backendApis.getDealItemsV3(dealType, loadNum, version)
    if (result?.status === 200) {
      setDealItemsInfo((arr) => [...arr, ...result?.data])
      setLoadNum(loadNum + result?.data?.length)
    }
  }

  const renderItem = memo(({ index, style, data }) => {
    if (!data[index])
      return (
        <VisitedTimeDealItemComponent
          key={`${index}`}
          style={style}
          empty
          isFrom='VisitedTimeDealSection'
        />
      )
    return (
      <VisitedTimeDealItemComponent
        key={`${index}`}
        style={style}
        itemInfo={data[index]}
        isFrom='VisitedTimeDealSection'
      />
    )
  })

  useEffect(() => {
    if (AuthStore.token) fetchDealItem(dealType, loadNum, version)
  }, [AuthStore.token])

  return (
    <div>
      {dealItemsInfo && dealItemsInfo.length > 0 && (
        <div className='relative flex flex-col items-start justify-center flex-1 p-3 overflow-hidden select-none'>
          <div className='flex flex-row items-center justify-between flex-1 w-full my-3'>
            <div className='text-lg font-bold text-gray-800'>
              최근 조회하신 상품을 더 저렴하게!
            </div>
            {dealItemsInfo.length > 4 && (
              <div
                aria-hidden
                onClick={async () => {
                  ItemImpressionStore.setPreviousIsFrom(
                    'VisitedTimeDealSection',
                  )
                  navigate('/deal/visited-time-deal')
                }}
                className='flex flex-row items-center text-gray-500'
              >
                더보기
                <ArrowRight />
              </div>
            )}
          </div>
          <div>
            <HorizontalList
              screenWidth={SCREEN_WIDTH}
              itemWidth={ITEM_WIDTH}
              itemHeight={ITEM_HEIGHT}
              itemData={dealItemsInfo}
              itemCount={loadNum === 0 ? 20 : loadNum}
              renderItem={renderItem}
              isFrom='VisitedTimeDealSection'
              isItemList
            />
          </div>
        </div>
      )}
    </div>
  )
}
export default observer(VisitedTimeDealSection)
