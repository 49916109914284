import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { ClipLoader } from 'react-spinners'

import altoonCMSBackendApis from '../../../utils/altoonCMSBackendApis'
import SearchForm from '../SearchForm'

const ToonReviewManagement = observer(({ setIsLoading }) => {
  const [chapterData, setChapterData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [toonId, setToonId] = useState('')
  const [showReviewedOnly, setShowReviewedOnly] = useState(false)
  const [imageCount, setImageCount] = useState(3)
  const [toonList, setToonList] = useState([])
  const [selectedToon, setSelectedToon] = useState(null)
  const [activeSearch, setActiveSearch] = useState({ query: '', type: 'title' })

  // 작품 목록 불러오기
  useEffect(() => {
    const fetchToonList = async () => {
      setIsLoading(true)
      const result = await altoonCMSBackendApis.getToonData()
      if (result?.status === 200) {
        setToonList(result.data)
      }
      setIsLoading(false)
    }

    fetchToonList()
  }, [setIsLoading])

  useEffect(() => {
    if (showReviewedOnly) {
      setFilteredData(chapterData.filter((item) => !item.isReviewed))
    } else {
      setFilteredData(chapterData)
    }
  }, [showReviewedOnly, chapterData])

  // 작품 필터링
  const getFilteredToons = () => {
    if (!activeSearch.query) return toonList

    return toonList.filter((toon) => {
      const searchField = activeSearch.type === 'title' ? toon.title : toon._id
      return searchField
        .toLowerCase()
        .includes(activeSearch.query.toLowerCase())
    })
  }

  const handleToonSelect = (toon) => {
    setSelectedToon(toon)
    setToonId(toon._id)
    handleSearchChapters(toon._id)
  }

  const handleSearchChapters = async (id) => {
    const searchId = id || toonId
    if (!searchId) {
      alert('작품을 선택해주세요.')
      return
    }

    setIsLoading(true)
    const result = await altoonCMSBackendApis.getChapterDataByToonId({
      toonId: searchId,
    })
    if (result?.status === 200) {
      const sortedData = result?.data?.sort((a, b) => a.episode - b.episode)
      setChapterData(sortedData)
      setFilteredData(sortedData)
    }
    setIsLoading(false)
  }

  const handleReviewComplete = async (itemId, chapterTitle) => {
    if (!window.confirm(`[${chapterTitle}] 회차의 검수를 완료하시겠습니까?`)) {
      return
    }

    setIsLoading(true)
    try {
      const result = await altoonCMSBackendApis.updateToonChapterInfo({
        chapterId: itemId,
        field: 'isReviewed',
        value: true,
        isDate: false,
      })

      if (result?.status === 200) {
        const updatedData = chapterData.map((item) =>
          item._id === itemId ? { ...item, isReviewed: true } : item,
        )
        setChapterData(updatedData)
        alert('검수가 완료되었습니다.')
      }
    } catch (error) {
      console.error('검수 완료 처리 실패:', error)
      alert('검수 처리 중 오류가 발생했습니다.')
    }
    setIsLoading(false)
  }

  const renderPreviewImages = (contents) => {
    // 이미지가 없거나 적은 경우 처리
    if (!contents || contents.length === 0) {
      return <div className='text-gray-500'>이미지 없음</div>
    }

    if (contents.length <= imageCount * 2) {
      // 이미지가 적은 경우 모두 표시
      return (
        <div className='flex flex-wrap items-center gap-2'>
          {contents.map((image, index) => (
            <img
              key={`preview-${index}-${image}`}
              src={image}
              alt={`preview-${index}`}
              className='w-20 h-auto object-cover'
            />
          ))}
        </div>
      )
    }

    // 이미지가 많은 경우 앞뒤로 나눠서 표시
    const firstImages = contents.slice(0, imageCount)
    const lastImages = contents.slice(-imageCount)

    // 중복 이미지 제거 (마지막 이미지가 첫 이미지와 겹치는 경우)
    const uniqueLastImages = lastImages.filter(
      (image) => !firstImages.includes(image),
    )

    return (
      <div className='flex flex-wrap items-center gap-2'>
        {firstImages.map((image, index) => (
          <img
            key={`preview-first-${index}-${image}`}
            src={image}
            alt={`preview-${index}`}
            className='w-20 h-auto object-cover'
          />
        ))}
        {contents.length > imageCount * 2 && (
          <div className='mx-2 font-bold'>...</div>
        )}
        {uniqueLastImages.map((image, index) => (
          <img
            key={`preview-last-${index}-${image}`}
            src={image}
            alt={`preview-last-${index}`}
            className='w-20 h-auto object-cover'
          />
        ))}
      </div>
    )
  }

  return (
    <div className='w-full'>
      <div className='flex flex-col gap-4 mb-6'>
        <div className='mb-6'>
          <div className='py-4 flexRow justify-between items-center'>
            <SearchForm
              onSearch={({ searchQuery, searchType }) => {
                setActiveSearch({ query: searchQuery, type: searchType })
                setSelectedToon(null)
                setToonId('')
                setChapterData([])
                setFilteredData([])
              }}
              activeSearchQuery={activeSearch.query}
              activeSearchType={activeSearch.type}
            />
          </div>

          {!selectedToon ? (
            <div className='mt-4'>
              <h3 className='text-lg font-semibold mb-2'>작품 선택</h3>
              <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
                {getFilteredToons()?.map((toon) => (
                  <div
                    key={toon._id}
                    className='border rounded-lg p-4 cursor-pointer hover:bg-gray-50'
                    onClick={() => handleToonSelect(toon)}
                  >
                    <div className='flex items-center'>
                      <img
                        src={toon.thumbnail}
                        alt={toon.title}
                        className='w-16 h-16 object-cover rounded mr-4'
                      />
                      <div>
                        <h4 className='font-medium'>{toon.title}</h4>
                        <p className='text-sm text-gray-500'>{toon.author}</p>
                        <p className='text-xs text-gray-400'>ID: {toon._id}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className='mt-4 mb-6'>
              <div className='flex justify-between items-center'>
                <div className='flex items-center'>
                  <img
                    src={selectedToon.thumbnail}
                    alt={selectedToon.title}
                    className='w-16 h-16 object-cover rounded mr-4'
                  />
                  <div>
                    <h3 className='text-lg font-semibold'>
                      {selectedToon.title}
                    </h3>
                    <p className='text-sm text-gray-500'>
                      {selectedToon.author}
                    </p>
                    <p className='text-xs text-gray-400'>
                      ID: {selectedToon._id}
                    </p>
                  </div>
                </div>
                <button
                  type='button'
                  className='px-4 py-2 bg-gray-200 hover:bg-gray-300 text-gray-700 rounded'
                  onClick={() => {
                    setSelectedToon(null)
                    setToonId('')
                    setChapterData([])
                    setFilteredData([])
                  }}
                >
                  다른 작품 선택
                </button>
              </div>
            </div>
          )}
        </div>

        <div className='flex items-center gap-4'>
          <label htmlFor='showReviewedOnly' className='flex items-center gap-2'>
            <input
              id='showReviewedOnly'
              type='checkbox'
              checked={showReviewedOnly}
              onChange={(e) => setShowReviewedOnly(e.target.checked)}
            />
            검토 안 된 항목만 보기
          </label>
          <div className='flex items-center gap-2'>
            <label htmlFor='imageCount'>미리보기 이미지 개수:</label>
            <input
              id='imageCount'
              type='number'
              min='1'
              max='10'
              value={imageCount}
              onChange={(e) => setImageCount(Number(e.target.value))}
              className='w-16 px-2 py-1 border rounded'
            />
          </div>
        </div>
      </div>

      <div className='w-full bg-white'>
        {selectedToon && (
          <div className='py-10 text-[2.4vh] text-black text-start font-bold'>
            {selectedToon.title}
          </div>
        )}
        {filteredData?.length > 0 ? (
          <table className='w-full table-auto border-collapse border border-slate-200'>
            <thead className='whitespace-nowrap text-[1.4vh] bg-gray-100'>
              <tr>
                <th className='table-cell w-16'>검토</th>
                <th className='table-cell w-20 max-w-[100px]'>ID</th>
                <th className='table-cell w-16'>회차</th>
                <th className='table-cell min-w-[100px]'>제목</th>
                <th className='table-cell w-16'>썸네일</th>
                <th className='table-cell w-24'>공개일</th>
                <th className='table-cell w-16'>유/무료</th>
                <th className='table-cell w-16'>공개</th>
                <th className='table-cell w-20'>이미지</th>
                <th className='table-cell w-20 max-w-[50px]'>작가의말</th>
                <th className='table-cell min-w-[200px]'>이미지 미리보기</th>
                <th className='table-cell w-20'>검토</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item) => (
                <tr
                  key={item._id}
                  className={`border-b ${
                    item.isReviewed ? 'bg-gray-100' : 'bg-white'
                  }`}
                >
                  <td className='table-cell text-center'>
                    {item.isReviewed ? (
                      <span className='text-green-600 font-bold'>Y</span>
                    ) : (
                      <span className='text-red-600 font-bold'>N</span>
                    )}
                  </td>
                  <td
                    className='table-cell text-center text-[4px]'
                    title={item._id}
                  >
                    {item._id}
                  </td>
                  <td className='table-cell text-center'>{item.episode}</td>
                  <td className='table-cell font-medium text-center'>
                    {item.chapterTitle}
                  </td>
                  <td className='table-cell text-center'>
                    <img
                      src={item.detailThumbnail}
                      alt='-'
                      className='w-[60px] h-auto object-cover rounded mx-auto'
                    />
                  </td>
                  <td className='table-cell text-center whitespace-nowrap text-xs'>
                    {moment(item.publishedAt).format('YY.MM.DD')}
                    <br />
                    {moment(item.publishedAt).format('HH:mm')}
                  </td>
                  <td
                    className={`table-cell text-center font-medium ${
                      item.isFree ? 'text-green-500' : 'text-red-500'
                    }`}
                  >
                    {item.isFree ? '무료' : '유료'}
                  </td>
                  <td className='table-cell text-center'>
                    {item.isDisplayed ? (
                      <span className='text-green-600 font-medium'>Y</span>
                    ) : (
                      <span className='text-red-600 font-medium'>N</span>
                    )}
                  </td>
                  <td className='table-cell text-center'>
                    {item.contents?.length}개
                  </td>
                  <td
                    className='table-cell text-center text-xs max-w-[150px] truncate'
                    title={item.authorComment || '-'}
                  >
                    {item.authorComment || '-'}
                  </td>
                  <td className='table-cell p-2'>
                    {renderPreviewImages(item.contents)}
                  </td>
                  <td className='table-cell text-center'>
                    {!item.isReviewed && (
                      <button
                        type='button'
                        className='px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 text-sm'
                        onClick={() =>
                          handleReviewComplete(item._id, item.chapterTitle)
                        }
                      >
                        완료
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : selectedToon ? (
          <div className='flexCol justify-center items-center h-40 bg-gray-50 rounded-lg mt-6'>
            <div className='text-gray-500'>회차 데이터가 없습니다.</div>
          </div>
        ) : (
          <div className='flex justify-center items-center h-40 bg-gray-50 rounded-lg mt-6'>
            <div className='text-gray-500'>
              작품을 선택하면 회차 목록이 표시됩니다.
            </div>
          </div>
        )}
      </div>
    </div>
  )
})

export default ToonReviewManagement
