import moment from 'moment'
import React from 'react'

import MainToonComponent from './toon/mainToonComponent'

const ToonListByWeekday = ({
  toons,
  todayWeekday,
  setTodayWeekday,
  tabRef = null,
  scrollContainerRef = null,
  initialTabOffsetTop = null,
}) => {
  const getButtonStyle = (weekday) => {
    if (weekday === todayWeekday) {
      return 'px-[3.6vw] mr-[1.6vw] py-[0.4vh] font-bold text-white bg-[#FF5951] rounded-full' // 선택된 장르일 때의 스타일
    }
    return 'px-[3.6vw] mr-[1.6vw] py-[0.4vh] text-[#BCBDC3] bg-[#F6F6FA] rounded-full' // 기본 스타일
  }

  const weekdayFilters = [
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat',
    'Sun',
    'Free',
  ].reduce((acc, day) => {
    acc[day] = () => toons.filter((toon) => toon?.weekday?.includes(day))
    return acc
  }, {})

  const filterFunction =
    weekdayFilters[todayWeekday] || weekdayFilters.defaultFilter
  const filteredToons = filterFunction()
  const isToday = todayWeekday === moment().format('ddd')

  const handleWeekdayChange = (weekday) => {
    sessionStorage.setItem('toon_default_weekday', weekday)
    setTodayWeekday(weekday)

    // 스크롤 위치 조정 (기존)
    if (scrollContainerRef) {
      const scrollPosition = scrollContainerRef.current.scrollTop
      if (scrollPosition >= initialTabOffsetTop.current) {
        const offsetAdjustment = 10
        scrollContainerRef.current.scrollTop =
          initialTabOffsetTop.current - offsetAdjustment
      }
    }
    // 자유연재 선택시 가로 스크롤 맨 오른쪽으로 이동
    if (weekday === 'Free' && tabRef) {
      setTimeout(() => {
        tabRef.current.scrollTo({
          left: tabRef.current.scrollWidth,
          behavior: 'smooth',
        })
      }, 0)
    }
  }

  return (
    <div className='flex flex-col items-center overflow-y-scroll'>
      <div
        ref={tabRef}
        className='w-full flex flex-row justify-between px-[4vw] py-[4vw] text-[3.6vw] bg-white relative overflow-x-auto whitespace-nowrap'
      >
        {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun', 'Free'].map(
          (day, index) => (
            <button
              key={day}
              type='button'
              className={getButtonStyle(day)}
              onClick={() => handleWeekdayChange(day)}
            >
              {[...['월', '화', '수', '목', '금', '토', '일'], '자유'][index]}
            </button>
          ),
        )}
      </div>

      <div className='px-4'>
        {filteredToons?.map((toon, index) => (
          <MainToonComponent
            key={toon?._id}
            toon={toon}
            index={index}
            isToday={isToday}
            component='toon_daily'
          />
        ))}
      </div>
    </div>
  )
}
export default ToonListByWeekday
