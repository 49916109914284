import { observer } from 'mobx-react-lite'
import React from 'react'

const FreepassNudgeComponent = observer(({ isFrom, push }) => {
  if (isFrom === 'mainPage') {
    return (
      <button
        type='button'
        onClick={async () => {
          push('AltoonTicketPage', {
            component: `freepassNudgeComponent_${isFrom}`,
          })
        }}
      >
        <img
          src='/images/toon/freepassNudgeShortBanner.png'
          className='w-full mb-[4vw]'
          alt='freepassNudgeMainPage'
        />
      </button>
    )
  }
  return (
    <button
      type='button'
      className='w-full flexRow items-center justify-between px-[4vw] py-[4vw] mt-[4vw] bg-[#FFE3F2] rounded-lg'
      onClick={async () => {
        push('AltoonTicketPage', {
          component: `freepassNudgeComponent_${isFrom}`,
        })
      }}
    >
      <div className='flexCol items-start'>
        <div className='w-[24vw] py-[1vw] text-[3.2vw] font-bold text-[#F13B61] bg-white rounded-xl'>
          신규 전용 혜택
        </div>
        <div className='h-[1vw]' />
        <div className='text-[4.2vw] font-bold'>올툰에 새로 오셨군요?</div>
        <div className='h-[1vw]' />
        <div className='text-[3vw] text-[#71737C]'>
          고객님은 모든 회차를 무료로 볼수 있어요!
        </div>
      </div>
      <div className='flexCol items-center'>
        <div
          className='px-[3vw] py-[2vw] text-white text-[4.4vw] font-bold rounded-lg animate-pulse'
          style={{
            backgroundImage: 'url(/images/toon/purpleGradientButton.png)',
            backgroundSize: '100% 100%',
          }}
        >
          패스 활성화
        </div>
      </div>
    </button>
  )
})
export default FreepassNudgeComponent
