import React from 'react'

const FormInput = React.memo(
  ({
    label,
    value,
    onChange,
    type = 'text',
    placeholder,
    rows,
    id,
    children,
    ...props
  }) => {
    let inputElement
    if (type === 'textarea') {
      inputElement = (
        <textarea
          id={id}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          rows={rows}
          className='w-full px-3 py-2 border border-gray-300 rounded-md text-sm text-gray-900'
          {...props}
        />
      )
    } else if (type === 'select') {
      inputElement = (
        <select
          id={id}
          value={value}
          onChange={onChange}
          className='w-full px-3 py-2 border border-gray-300 rounded-md text-sm text-gray-900'
          {...props}
        >
          {children}
        </select>
      )
    } else if (type === 'checkbox') {
      inputElement = (
        <input
          id={id}
          type='checkbox'
          value={value}
          onChange={onChange}
          className='w-10 h-10 border border-gray-300 text-sm text-gray-900'
          {...props}
        />
      )
    } else {
      inputElement = (
        <input
          id={id}
          type={type}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className='w-full px-3 py-2 border border-gray-300 rounded-md text-sm text-gray-900'
          {...props}
        />
      )
    }

    return (
      <div className='grid grid-cols-2 gap-4 items-start'>
        <label htmlFor={id} className='block text-sm font-medium text-gray-700'>
          {label}
        </label>
        {inputElement}
      </div>
    )
  },
)

export default FormInput
