import { useQuery, useSuspenseQuery } from '@tanstack/react-query'
import moment from 'moment'
import { useLocation } from 'react-router-dom'

import ToonModal from '../../../components/templates/ToonModal'
import AltoonUserStore from '../../../stores/AltoonUserStore'
import { loadNovelDetailQuery } from '../remotes/comment'
import {
  getNovelChapterInfoQuery,
  getNovelInfoQuery,
  logNovelChapterViewQuery,
} from '../remotes/novelChapter'
import { useAsyncOverlay } from '../utils/useAsyncOverlay'

export const useNovelChapterPermission = () => {
  const location = useLocation()
  const { chapterId, novelId } = location.state || {}

  const { data: novelInfo } = useSuspenseQuery(
    getNovelInfoQuery({
      novelId,
    }),
  )

  const { data: novelChapterList } = useQuery(loadNovelDetailQuery({ novelId }))

  const { data: viewLogDoc } = useSuspenseQuery(
    logNovelChapterViewQuery({
      novelId,
      chapterId,
    }),
  )

  const { openAsync } = useAsyncOverlay()

  return {
    checkPermission: async ({
      targetChapterId,
    }: {
      targetChapterId: string
    }) => {
      const targetChapter = novelChapterList?.data?.find(
        (chapter: any) => chapter._id.toString() === targetChapterId.toString(),
      )

      if (!targetChapter) {
        console.error('Chapter not found')
        return false
      }

      const targetChapterViewLog = viewLogDoc?.viewedChapterIdList.find(
        (item: any) => item.chapterId.toString() === targetChapterId.toString(),
      )

      const rentedAt = targetChapterViewLog?.rentedAt
      const isOnWaitForFreePromotion =
        moment().isAfter(
          novelInfo?.promotionInfo?.waitForFree?.eventStartedAt,
        ) &&
        moment().isBefore(novelInfo?.promotionInfo?.waitForFree?.eventEndedAt)
      const waitForFreeAppliableChapter =
        novelInfo?.chapterCount -
          novelInfo?.promotionInfo?.waitForFree?.recentChapterLimit >=
        targetChapter.episode

      const onClickHandler = async () => {
        if (
          isOnWaitForFreePromotion &&
          waitForFreeAppliableChapter &&
          viewLogDoc.waitForFreeAvailable === true
        ) {
          await openAsync(({ isOpen, close }) => {
            return (
              <>
                {isOpen && (
                  <ToonModal
                    modalName='novel_useWaitForFree'
                    modalProp={{
                      novelData: novelInfo,
                      chapterInfo: targetChapter,
                      viewLogData: viewLogDoc,
                      waitForFreeAppliableChapter,
                    }}
                    setOpenTicketModal={close}
                  />
                )}
              </>
            )
          })
        } else if (
          AltoonUserStore?.userData.freeCoin +
            AltoonUserStore?.userData.paidCoin <
          targetChapter.chapterPrice
        ) {
          await openAsync(({ isOpen, close }) => {
            return (
              <>
                {isOpen && (
                  <ToonModal
                    modalName='novel_lackCoin'
                    modalProp={{}}
                    setOpenTicketModal={close}
                  />
                )}
              </>
            )
          })
        } else if (
          AltoonUserStore?.userData.freeCoin +
            AltoonUserStore?.userData.paidCoin >=
          targetChapter.chapterPrice
        ) {
          await openAsync(({ isOpen, close }) => {
            return (
              <>
                {isOpen && (
                  <ToonModal
                    modalName='novel_useCoin'
                    modalProp={{
                      novelData: novelInfo,
                      chapterInfo: targetChapter,
                    }}
                    setOpenTicketModal={close}
                  />
                )}
              </>
            )
          })
        }
      }

      if (targetChapter?.isFree) {
        return true
      }

      if (!targetChapterViewLog) {
        onClickHandler()
        return false
      }

      // rentedAt이 3일 이상 지났는지 확인
      if (!rentedAt) {
        onClickHandler()
        return false
      }

      if (rentedAt) {
        const now = new Date()
        const rentedDate = new Date(rentedAt)
        const diff = now.getTime() - rentedDate.getTime()
        const diffDays = diff / (1000 * 3600 * 24)

        if (diffDays > novelInfo.rentPeriod) {
          onClickHandler()
          return false
        }
      }

      // 3일 내로 본거면
      return true
    },
  }
}
