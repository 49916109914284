import React, { useRef, useState } from 'react'

import MainToonComponent from './toon/mainToonComponent'

const ToonListByGenre = ({
  toons,
  tabRef = null,
  scrollContainerRef = null,
  initialTabOffsetTop = null,
  presetGenre = null,
}) => {
  const [selectedGenre, setSelectedGenre] = useState(presetGenre || 'hot')

  const hotRef = useRef(null)
  const romanceRef = useRef(null)
  const dailyRef = useRef(null)
  const romanceFantasyRef = useRef(null)
  const fantasyRef = useRef(null)
  const allRef = useRef(null)

  const genreFilters = {
    all: () => toons.filter((toon) => !toon.weekday),
    'daily/drama/thriller': () =>
      toons.filter(
        (toon) =>
          !toon.weekday &&
          (toon.genre === 'daily' ||
            toon.genre === 'drama' ||
            toon.genre === 'thriller'),
      ),
    hot: () =>
      toons.filter((toon) => !toon.weekday && toon.tagList?.hot === true),
    defaultFilter: () =>
      toons.filter((toon) => !toon.weekday && toon.genre === selectedGenre),
  }

  const filterFunction =
    genreFilters[selectedGenre] || genreFilters.defaultFilter
  const filteredToons = filterFunction()

  const getButtonStyle = (genre) => {
    if (genre === selectedGenre) {
      return 'px-[3.6vw] mr-[1.6vw] py-[0.4vh] font-bold text-white bg-[#FF5951] rounded-full' // 선택된 장르일 때의 스타일
    }
    return 'px-[3.6vw] mr-[1.6vw] py-[0.4vh] text-[#BCBDC3] bg-[#F6F6FA] rounded-full' // 기본 스타일
  }

  const handleGenreChange = (genre) => {
    sessionStorage.setItem('toon_default_genre', genre)
    setSelectedGenre(genre)

    if (scrollContainerRef) {
      const scrollPosition = scrollContainerRef.current.scrollTop

      if (scrollPosition >= initialTabOffsetTop.current) {
        const offsetAdjustment = 10 // 원하는 만큼의 값을 빼서 스크롤 위치를 조정합니다.
        scrollContainerRef.current.scrollTop =
          initialTabOffsetTop.current - offsetAdjustment
      }
    }
  }

  return (
    <div className='flex flex-col items-center overflow-y-scroll'>
      <div
        ref={tabRef}
        className='w-full flex flex-row justify-between px-[4vw] py-[4vw] text-[3.6vw] bg-white relative overflow-x-scroll whitespace-nowrap'
      >
        <button
          ref={hotRef}
          type='button'
          className={getButtonStyle('hot')}
          onClick={() => {
            handleGenreChange('hot')
          }}
        >
          인기
        </button>
        <button
          ref={allRef}
          type='button'
          className={getButtonStyle('all')}
          onClick={() => {
            handleGenreChange('all')
          }}
        >
          전체
        </button>
        <button
          ref={romanceRef}
          type='button'
          className={getButtonStyle('romance')}
          onClick={() => {
            handleGenreChange('romance')
          }}
        >
          로맨스
        </button>
        <button
          ref={romanceFantasyRef}
          type='button'
          className={getButtonStyle('romanceFantasy')}
          onClick={() => {
            handleGenreChange('romanceFantasy')
          }}
        >
          로맨스판타지
        </button>
        <button
          ref={fantasyRef}
          type='button'
          className={getButtonStyle('fantasy')}
          onClick={() => {
            handleGenreChange('fantasy')
          }}
        >
          판타지
        </button>
        <button
          ref={dailyRef}
          type='button'
          className={getButtonStyle('daily/drama/thriller')}
          onClick={() => {
            handleGenreChange('daily/drama/thriller')
          }}
        >
          일상/드라마
        </button>
      </div>

      <div className='w-full grid grid-cols-2 gap-[2vw] px-[4vw]'>
        {filteredToons?.map((toon, index) => (
          <MainToonComponent
            key={toon?._id}
            toon={toon}
            index={index}
            type='genre'
            component='toon_genre'
          />
        ))}
      </div>
    </div>
  )
}
export default ToonListByGenre
