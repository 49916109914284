import semverCompareBuild from 'semver/functions/compare-build'

export const safeCodepushVersionCompare = (versionA, versionB) => {
  const safeVersion = (ver) => {
    if (!ver || !ver.includes('.')) return '0.0.0'

    return ver
  }

  const safeVersionA = safeVersion(versionA)
  const safeVersionB = safeVersion(versionB)

  const versionComparison = semverCompareBuild(safeVersionA, safeVersionB)
  return versionComparison
}

export default function useCheckCodePushVersion(user, limit) {
  function checkCodePushVersion(x, y) {
    return safeCodepushVersionCompare(x, y) >= 0
  }

  return checkCodePushVersion
}
