import moment from 'moment'
import React, { useEffect, useState } from 'react'

import altoonCMSBackendApis from '../../../utils/altoonCMSBackendApis'

const NovelDetailModal = ({ novel, onClose, onUpdate }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [editedData, setEditedData] = useState({})
  const [validationErrors, setValidationErrors] = useState({})
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false)
  const [providerList, setProviderList] = useState([])
  const [genreList] = useState([
    'BL',
    'comedy',
    'fantasy',
    'daily',
    'drama',
    'thriller',
    'romanceFantasy',
    'romance',
    'action',
  ])

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    const loadProviderList = async () => {
      try {
        const tempToken = localStorage.getItem('@altoon@provider@token@')
        const result = await altoonCMSBackendApis.loadProviderList(
          tempToken ?? localStorage.getItem('@altoon@token@'),
        )

        if (result?.status === 200) {
          setProviderList(result.data)
        }
      } catch (error) {
        console.error('CP사 목록 로딩 중 오류 발생:', error)
      }
    }
    loadProviderList()
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [novel])

  const handleProviderChange = (providerId) => {
    const selectedProvider = providerList.find((p) => p._id === providerId)
    if (selectedProvider) {
      handleEdit('providerInfo.providerId', providerId)
      handleEdit(
        'providerInfo.providerDisplayName',
        selectedProvider.providerDisplayName,
      )
    }
  }

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose()
    }
  }

  const validateField = (field, value) => {
    if (value === undefined || value === '') return '필수 입력 항목입니다.'

    switch (field) {
      case 'title':
        if (value.length < 2) return '제목은 2자 이상이어야 합니다.'
        break
      case 'author':
        if (value.length < 2) return '작가명은 2자 이상이어야 합니다.'
        break
      case 'priority':
        if (Number.isNaN(Number(value)) || Number(value) < 0)
          return '0 이상의 숫자만 입력 가능합니다.'
        break
      case 'rentPeriod':
        if (Number.isNaN(Number(value)) || Number(value) < 0)
          return '0 이상의 정수만 입력 가능합니다.'
        break
      case 'settlementFeeRate':
        if (
          Number.isNaN(Number(value)) ||
          Number(value) < 0 ||
          Number(value) > 1
        )
          return '0~1 사이의 숫자만 입력 가능합니다.'
        break
      default:
        break
    }
    return null
  }

  const handleEdit = (field, value) => {
    setEditedData((prev) => ({ ...prev, [field]: value }))
    setValidationErrors((prev) => ({ ...prev, [field]: null }))
  }

  const handleSave = async () => {
    const errors = {}
    let hasError = false

    Object.keys(editedData).forEach((field) => {
      const error = validateField(field, editedData[field])
      if (error) {
        errors[field] = error
        hasError = true
      }
    })

    if (hasError) {
      setValidationErrors(errors)
      return
    }

    try {
      // 각 필드를 개별적으로 업데이트
      for (const [field, value] of Object.entries(editedData)) {
        // _id 필드는 제외
        // if (field === '_id' || field === 'createdAt') continue

        if (value !== undefined) {
          const isDate = field === 'displayStartedAt'
          const result = await altoonCMSBackendApis.updateNovelInfo({
            novelId: novel._id,
            field,
            value: JSON.stringify(value),
            isDate,
          })

          if (result?.status !== 200) {
            throw new Error(`Failed to update ${field}`)
          }
        }
      }

      alert('수정되었습니다.')
      setIsEditing(false)
      onUpdate()
      onClose()
    } catch (error) {
      console.error('수정 중 오류 발생:', error)
      alert('수정 중 오류가 발생했습니다.')
    }
  }

  const renderEditableField = (label, field, value, type = 'text') => {
    if (field === '_id' || field === 'createdAt') {
      return (
        <tr>
          <td className='px-4 py-2 font-medium'>{label}</td>
          <td className='px-4 py-2'>{value}</td>
        </tr>
      )
    }
    if (field === 'providerInfo.providerId') {
      return (
        <tr>
          <td className='px-4 py-2 font-medium'>{label}</td>
          <td className='px-4 py-2'>
            {editedData['providerInfo.providerId'] ||
              novel.providerInfo?.providerId}
          </td>
        </tr>
      )
    }
    return (
      <tr>
        <td className='px-4 py-2 font-medium'>{label}</td>
        <td className='px-4 py-2'>
          {isEditing ? (
            <div>
              {type === 'checkbox' ? (
                <input
                  type='checkbox'
                  checked={
                    editedData[field] !== undefined
                      ? editedData[field]
                      : value === 'O'
                  }
                  onChange={(e) => handleEdit(field, e.target.checked)}
                  className='form-checkbox h-5 w-5'
                />
              ) : type === 'textarea' ? (
                <textarea
                  value={editedData[field] ?? value}
                  onChange={(e) => handleEdit(field, e.target.value)}
                  className='w-full p-2 border rounded'
                  rows={4}
                />
              ) : field === 'providerInfo.providerDisplayName' ? (
                <div>
                  <select
                    value={editedData[field] ?? value}
                    onChange={(e) => handleProviderChange(e.target.value)}
                    className='w-full p-2 border rounded'
                  >
                    <option value=''>CP사 선택</option>
                    {providerList.map((provider) => (
                      <option key={provider._id} value={provider._id}>
                        {provider.providerDisplayName} ({provider.providerName})
                      </option>
                    ))}
                  </select>
                  {editedData['providerInfo.providerDisplayName'] && (
                    <div className='mt-1 text-sm text-gray-600'>
                      선택된 CP사:{' '}
                      {editedData['providerInfo.providerDisplayName']}
                    </div>
                  )}
                </div>
              ) : field === 'genre' ? (
                <select
                  value={editedData[field] ?? value}
                  onChange={(e) => handleEdit(field, e.target.value)}
                  className='w-full p-2 border rounded'
                >
                  <option value=''>장르 선택</option>
                  {genreList.map((genre) => (
                    <option key={genre} value={genre}>
                      {genre}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  type={type}
                  value={editedData[field] ?? value}
                  onChange={(e) => handleEdit(field, e.target.value)}
                  className='w-full p-2 border rounded'
                />
              )}
              {validationErrors[field] && (
                <div className='text-red-500 text-sm mt-1'>
                  {validationErrors[field]}
                </div>
              )}
            </div>
          ) : (
            value
          )}
        </td>
      </tr>
    )
  }

  if (!novel) return null

  return (
    <div
      className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-4 overflow-y-auto'
      onClick={handleBackgroundClick}
    >
      <div className='bg-white rounded-xl p-6 w-[80vw] max-w-[1200px] max-h-[90vh] overflow-y-auto ml-[15vw]'>
        <div className='flex justify-between items-center mb-4'>
          <h2 className='text-2xl font-bold'>{novel.title}</h2>
          <button
            type='button'
            onClick={onClose}
            className='text-gray-500 hover:text-gray-700 text-3xl font-bold w-10 h-10 flex items-center justify-center'
          >
            ✕
          </button>
        </div>

        <div className='flex flex-col gap-6'>
          <div className='grid grid-cols-3 gap-6'>
            {/* 왼쪽: 썸네일 이미지와 작품 설명 */}
            <div className='col-span-1'>
              {isEditing ? (
                <div className='space-y-4'>
                  <div>
                    <label htmlFor='thumbnail' className='block mb-2'>
                      썸네일 URL
                    </label>
                    <input
                      id='thumbnail'
                      type='text'
                      value={editedData.thumbnail ?? novel.thumbnail}
                      onChange={(e) => handleEdit('thumbnail', e.target.value)}
                      className='w-full p-2 border rounded'
                    />
                  </div>
                  <div className='flex items-center space-x-2'>
                    <input
                      id='verticalThumbnail'
                      type='checkbox'
                      checked={
                        editedData.verticalThumbnail ?? novel.verticalThumbnail
                      }
                      onChange={(e) =>
                        handleEdit('verticalThumbnail', e.target.checked)
                      }
                      className='form-checkbox h-5 w-5'
                    />
                    <label htmlFor='verticalThumbnail'>세로형 썸네일</label>
                  </div>
                </div>
              ) : (
                <img
                  src={novel.thumbnail}
                  alt={novel.title}
                  className='max-w-[16vw] h-auto object-cover rounded shadow-lg'
                />
              )}
              <div className='mt-4 border rounded-lg'>
                <div className='px-4 py-2 bg-gray-50 font-semibold'>
                  작품 설명
                </div>
                <div className='p-4'>
                  {isEditing ? (
                    <textarea
                      value={editedData.description ?? novel.description}
                      onChange={(e) =>
                        handleEdit('description', e.target.value)
                      }
                      className='w-full p-2 border rounded'
                      rows={6}
                    />
                  ) : (
                    <>
                      <div
                        className={`whitespace-pre-wrap ${
                          !isDescriptionExpanded ? 'line-clamp-3' : ''
                        }`}
                      >
                        {novel.description}
                      </div>
                      <button
                        type='button'
                        onClick={() =>
                          setIsDescriptionExpanded(!isDescriptionExpanded)
                        }
                        className='text-blue-500 hover:text-blue-700 mt-2'
                      >
                        {isDescriptionExpanded ? '접기' : '더 보기'}
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>

            {/* 오른쪽: 정보 */}
            <div className='col-span-2 space-y-6'>
              <div className='border rounded-lg overflow-hidden'>
                <table className='w-full'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th
                        className='px-4 py-2 text-left font-semibold'
                        colSpan='2'
                      >
                        기본 정보
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y'>
                    {renderEditableField('작품 ID', '_id', novel._id)}
                    {renderEditableField('제목 *', 'title', novel.title)}
                    {renderEditableField('작가 *', 'author', novel.author)}
                    {renderEditableField(
                      '우선순위 *',
                      'priority',
                      novel.priority,
                    )}
                    {renderEditableField('장르', 'genre', novel.genre)}
                    {renderEditableField(
                      '대여 기간',
                      'rentPeriod',
                      novel.rentPeriod,
                    )}
                  </tbody>
                </table>
              </div>

              <div className='border rounded-lg overflow-hidden'>
                <table className='w-full'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th
                        className='px-4 py-2 text-left font-semibold'
                        colSpan='2'
                      >
                        CP사 정보
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y'>
                    {renderEditableField(
                      'CP사',
                      'providerInfo.providerDisplayName',
                      novel.providerInfo?.providerDisplayName,
                    )}
                    {renderEditableField(
                      'CP사 ID',
                      'providerInfo.providerId',
                      novel.providerInfo?.providerId,
                    )}
                    {renderEditableField(
                      '분배율',
                      'settlementFeeRate',
                      novel.providerInfo?.settlementFeeRate,
                    )}
                  </tbody>
                </table>
              </div>

              <div className='border rounded-lg overflow-hidden'>
                <table className='w-full'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th
                        className='px-4 py-2 text-left font-semibold'
                        colSpan='2'
                      >
                        상태 정보
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y'>
                    {renderEditableField(
                      '노출 여부',
                      'isDisplayed',
                      novel.isDisplayed === true ? 'O' : 'X',
                      'checkbox',
                    )}
                    {renderEditableField(
                      '성인 여부',
                      'isAdultOnly',
                      novel.isAdultOnly === true ? 'O' : 'X',
                      'checkbox',
                    )}
                    {renderEditableField(
                      '등록일',
                      'createdAt',
                      moment(novel.createdAt).format('YYYY-MM-DD HH:mm'),
                    )}
                    {renderEditableField(
                      '노출 시작일',
                      'displayStartedAt',
                      moment(novel.displayStartedAt).format('YYYY-MM-DD HH:mm'),
                      'datetime-local',
                    )}
                  </tbody>
                </table>
              </div>

              {novel.promotionInfo?.waitForFree && (
                <div className='border rounded-lg overflow-hidden'>
                  <table className='w-full'>
                    <thead className='bg-gray-50'>
                      <tr>
                        <th
                          className='px-4 py-2 text-left font-semibold'
                          colSpan='2'
                        >
                          프로모션 정보
                        </th>
                      </tr>
                    </thead>
                    <tbody className='divide-y'>
                      <tr>
                        <td className='px-4 py-2 font-medium'>이벤트 시작일</td>
                        <td className='px-4 py-2'>
                          {moment(
                            novel.promotionInfo.waitForFree.eventStartedAt,
                          ).format('YYYY-MM-DD HH:mm')}
                        </td>
                      </tr>
                      <tr>
                        <td className='px-4 py-2 font-medium'>이벤트 종료일</td>
                        <td className='px-4 py-2'>
                          {moment(
                            novel.promotionInfo.waitForFree.eventEndedAt,
                          ).format('YYYY-MM-DD HH:mm')}
                        </td>
                      </tr>
                      <tr>
                        <td className='px-4 py-2 font-medium'>대기 시간</td>
                        <td className='px-4 py-2'>
                          {novel.promotionInfo.waitForFree.waitingTime}일
                        </td>
                      </tr>
                      <tr>
                        <td className='px-4 py-2 font-medium'>
                          최근 회차 제한
                        </td>
                        <td className='px-4 py-2'>
                          {novel.promotionInfo.waitForFree.recentChapterLimit}화
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='flex justify-end space-x-2 mt-4'>
          {!isEditing && (
            <button
              type='button'
              onClick={() => setIsEditing(true)}
              className='px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600'
            >
              수정
            </button>
          )}
          {isEditing && (
            <>
              <button
                type='button'
                onClick={handleSave}
                className='px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600'
              >
                저장
              </button>
              <button
                type='button'
                onClick={() => {
                  setIsEditing(false)
                  setEditedData({})
                  setValidationErrors({})
                }}
                className='px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600'
              >
                취소
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default NovelDetailModal
