import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { ClipLoader } from 'react-spinners'

import altoonCMSBackendApis from '../../utils/altoonCMSBackendApis'

const MainEventDetailModal = ({ event, onClose, onUpdate }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [editedData, setEditedData] = useState({})
  const [validationErrors, setValidationErrors] = useState({})

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [])

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose()
    }
  }

  const handleEdit = (field, value) => {
    if (field.includes('.')) {
      const [parent, child] = field.split('.')
      setEditedData((prev) => {
        const newData = {
          ...prev,
          [parent]: {
            ...prev[parent],
            [child]: value,
          },
        }
        return newData
      })
    } else {
      setEditedData((prev) => {
        const newData = { ...prev, [field]: value }
        return newData
      })
    }
    setValidationErrors((prev) => ({ ...prev, [field]: null }))
  }

  const validateField = (field, value) => {
    if (value === undefined || value === '') return '필수 입력 항목입니다.'

    switch (field) {
      case 'eventTitle':
        if (value.length < 2) return '이벤트명은 2자 이상이어야 합니다.'
        break
      case 'description':
        if (value.length < 2) return '설명은 2자 이상이어야 합니다.'
        break
      default:
        break
    }
    return null
  }

  const handleSave = async () => {
    const errors = {}
    let hasError = false

    // 중첩된 객체 구조를 평탄화하여 검증
    const flattenObject = (obj, prefix = '') => {
      return Object.keys(obj).reduce((acc, key) => {
        const pre = prefix.length ? prefix + '.' : ''
        if (
          typeof obj[key] === 'object' &&
          obj[key] !== null &&
          !Array.isArray(obj[key])
        ) {
          Object.assign(acc, flattenObject(obj[key], pre + key))
        } else {
          acc[pre + key] = obj[key]
        }
        return acc
      }, {})
    }

    const flattenedData = flattenObject(editedData)
    console.log('평탄화된 데이터:', flattenedData)

    Object.keys(flattenedData).forEach((field) => {
      const error = validateField(field, flattenedData[field])
      if (error) {
        errors[field] = error
        hasError = true
      }
    })

    if (hasError) {
      setValidationErrors(errors)
      return
    }

    try {
      // 기존 데이터와 수정된 데이터를 병합
      const updateData = {
        ...event, // 기존 데이터를 베이스로 사용
        ...editedData, // 수정된 데이터로 덮어쓰기
        quicklinkInfo: {
          ...event.quicklinkInfo, // 기존 퀵링크 정보 유지
          ...editedData.quicklinkInfo, // 수정된 퀵링크 정보 덮어쓰기
        },
        mainPageInfo: {
          ...event.mainPageInfo, // 기존 메인페이지 정보 유지
          ...editedData.mainPageInfo, // 수정된 메인페이지 정보 덮어쓰기
        },
        eventPageInfo: editedData.eventPageInfo || event.eventPageInfo, // 이벤트 페이지 정보
      }

      // 날짜 필드 처리
      if (updateData.displayStartedAt) {
        updateData.displayStartedAt = new Date(
          updateData.displayStartedAt,
        ).toISOString()
      }
      if (updateData.displayEndedAt) {
        updateData.displayEndedAt = new Date(
          updateData.displayEndedAt,
        ).toISOString()
      }

      console.log('업데이트할 데이터:', updateData)
      const result = await altoonCMSBackendApis.updateMainEvent(
        event._id,
        updateData,
      )

      if (result?.status !== 200) {
        throw new Error('Failed to update event')
      }

      alert('수정되었습니다.')
      setIsEditing(false)
      onUpdate()
      onClose()
    } catch (error) {
      console.error('수정 중 오류 발생:', error)
      alert('수정 중 오류가 발생했습니다.')
    }
  }

  const renderEditableField = (label, field, value, type = 'text') => {
    if (field === '_id' || field === 'createdAt') {
      return (
        <tr>
          <td className='px-4 py-2 font-medium'>{label}</td>
          <td className='px-4 py-2'>{value}</td>
        </tr>
      )
    }

    const getValue = () => {
      if (field.includes('.')) {
        const [parent, child] = field.split('.')
        return editedData[parent]?.[child] ?? value
      }
      return editedData[field] ?? value
    }

    return (
      <tr>
        <td className='max-w-[80px] pl-4 py-2 font-medium'>{label}</td>
        <td className='px-4 py-2'>
          {isEditing ? (
            <div>
              {type === 'checkbox' ? (
                <input
                  type='checkbox'
                  checked={getValue()}
                  onChange={(e) => handleEdit(field, e.target.checked)}
                  className='form-checkbox h-5 w-5'
                />
              ) : type === 'textarea' ? (
                <textarea
                  value={getValue()}
                  onChange={(e) => handleEdit(field, e.target.value)}
                  className='w-full p-2 border rounded'
                  rows={4}
                />
              ) : type === 'datetime-local' ? (
                <input
                  type='datetime-local'
                  value={
                    getValue()
                      ? moment(getValue()).format('YYYY-MM-DDTHH:mm')
                      : ''
                  }
                  onChange={(e) => handleEdit(field, e.target.value)}
                  className='w-full p-2 border rounded'
                />
              ) : field === 'quicklinkInfo.imageType' ? (
                <select
                  value={getValue()}
                  onChange={(e) => handleEdit(field, e.target.value)}
                  className='w-full p-2 border rounded'
                >
                  <option value=''>선택하세요</option>
                  <option value='gif'>gif</option>
                  <option value='png'>png</option>
                </select>
              ) : field === 'mainPageInfo.ctaColor' ? (
                <select
                  value={getValue()}
                  onChange={(e) => handleEdit(field, e.target.value)}
                  className='w-full p-2 border rounded'
                >
                  <option value=''>선택하세요</option>
                  <option value='red'>Red</option>
                  <option value='pink'>Pink</option>
                  <option value='white'>White</option>
                  <option value='gold'>Gold</option>
                  <option value='gray'>Gray</option>
                </select>
              ) : field === 'mainPageInfo.ctaTab' ? (
                <input
                  type='number'
                  min='0'
                  value={getValue()}
                  onChange={(e) =>
                    handleEdit(field, parseInt(e.target.value) || 0)
                  }
                  className='w-full p-2 border rounded'
                />
              ) : (
                <input
                  type={type}
                  value={getValue()}
                  onChange={(e) => handleEdit(field, e.target.value)}
                  className='w-full p-2 border rounded'
                />
              )}
              {validationErrors[field] && (
                <div className='text-red-500 text-sm mt-1'>
                  {validationErrors[field]}
                </div>
              )}
            </div>
          ) : (
            <div>
              {type === 'checkbox' ? (
                <div className='flex items-center'>
                  <span
                    className={`px-2 py-1 rounded text-sm ${
                      getValue()
                        ? 'bg-green-100 text-green-800'
                        : 'bg-red-100 text-red-800'
                    }`}
                  >
                    {getValue() ? 'O' : 'X'}
                  </span>
                </div>
              ) : field.includes('imageUri') || field.includes('Image') ? (
                <div className='space-y-2'>
                  <img
                    src={getValue()}
                    alt={label}
                    className='w-48 h-auto object-cover rounded shadow-sm'
                  />
                  <div className='text-sm text-gray-500 break-all'>
                    {getValue()}
                  </div>
                </div>
              ) : field === 'quicklinkInfo.ctaColor' ? (
                <div className='flex items-center gap-2'>
                  <div
                    className='w-6 h-6 rounded-full border'
                    style={{ backgroundColor: getValue() }}
                  />
                  <span>{getValue()}</span>
                </div>
              ) : (
                getValue()
              )}
            </div>
          )}
        </td>
      </tr>
    )
  }

  if (!event) return null

  return (
    <div
      className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-4 overflow-y-auto'
      onClick={handleBackgroundClick}
    >
      <div className='bg-white rounded-xl p-6 w-[80vw] max-w-[1200px] max-h-[90vh] overflow-y-auto ml-[15vw]'>
        <div className='flex justify-between items-center mb-4'>
          <h2 className='text-2xl font-bold'>{event.eventTitle}</h2>
          <button
            type='button'
            onClick={onClose}
            className='text-gray-500 hover:text-gray-700 text-3xl font-bold w-10 h-10 flex items-center justify-center'
          >
            ✕
          </button>
        </div>

        <div className='flex flex-col gap-6'>
          <div className='grid grid-cols-3 gap-6'>
            {/* 왼쪽: 이미지 미리보기 */}
            <div className='col-span-1'>
              {isEditing ? (
                <div className='space-y-4'>
                  {/* <div>
                    <label htmlFor='quicklinkImageUri' className='block mb-2'>
                      퀵링크 이미지 URL
                    </label>
                    <input
                      id='quicklinkImageUri'
                      type='text'
                      value={editedData.quicklinkInfo?.imageUri ?? event.quicklinkInfo?.imageUri}₩
                      className='w-full p-2 border rounded'
                    />
                  </div> */}
                  <div>
                    <label htmlFor='topBannerUri' className='block mb-2'>
                      배너 이미지 URL
                    </label>
                    <input
                      id='topBannerUri'
                      type='text'
                      value={
                        editedData.mainPageInfo?.topBannerUri ??
                        event.mainPageInfo?.topBannerUri
                      }
                      onChange={(e) =>
                        handleEdit('mainPageInfo.topBannerUri', e.target.value)
                      }
                      className='w-full p-2 border rounded'
                    />
                  </div>
                  <div>
                    <label htmlFor='mainImageUri' className='block mb-2'>
                      메인페이지 이미지 URL
                    </label>
                    <input
                      id='mainImageUri'
                      type='text'
                      value={
                        editedData.mainPageInfo?.mainImageUri ??
                        event.mainPageInfo?.mainImageUri
                      }
                      onChange={(e) =>
                        handleEdit('mainPageInfo.mainImageUri', e.target.value)
                      }
                      className='w-full p-2 border rounded'
                    />
                  </div>
                </div>
              ) : (
                <div className='space-y-4'>
                  {event.quicklinkInfo?.imageUri && (
                    <img
                      src={event.quicklinkInfo.imageUri}
                      alt='퀵링크 이미지'
                      className='w-full h-auto object-cover rounded shadow-lg'
                    />
                  )}
                  {event.mainPageInfo?.topBannerUri && (
                    <img
                      src={event.mainPageInfo.topBannerUri}
                      alt='배너 이미지'
                      className='w-full h-auto object-cover rounded shadow-lg'
                    />
                  )}
                  {event.mainPageInfo?.mainImageUri && (
                    <img
                      src={event.mainPageInfo.mainImageUri}
                      alt='메인페이지 이미지'
                      className='w-full h-auto object-cover rounded shadow-lg'
                    />
                  )}
                </div>
              )}
            </div>

            {/* 오른쪽: 정보 */}
            <div className='col-span-2 space-y-6'>
              <div className='border rounded-lg overflow-hidden'>
                <table className='w-full'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th
                        className='px-4 py-2 text-left font-semibold'
                        colSpan='2'
                      >
                        기본 정보
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y'>
                    {renderEditableField('이벤트 ID', '_id', event._id)}
                    {renderEditableField(
                      '이벤트명 *',
                      'eventTitle',
                      event.eventTitle,
                    )}
                    {renderEditableField(
                      '노출 시작일',
                      'displayStartedAt',
                      event.displayStartedAt,
                      'datetime-local',
                    )}
                    {renderEditableField(
                      '노출 종료일',
                      'displayEndedAt',
                      event.displayEndedAt,
                      'datetime-local',
                    )}
                  </tbody>
                </table>
              </div>

              <div className='border rounded-lg overflow-hidden'>
                <table className='w-full'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th
                        className='px-4 py-2 text-left font-semibold'
                        colSpan='2'
                      >
                        퀵링크 정보
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y'>
                    {renderEditableField(
                      '퀵링크 제목',
                      'quicklinkInfo.title',
                      event.quicklinkInfo?.title,
                    )}
                    {renderEditableField(
                      '퀵링크 이미지 URL',
                      'quicklinkInfo.imageUri',
                      event.quicklinkInfo?.imageUri,
                    )}
                    {renderEditableField(
                      '퀵링크 이미지 확장자',
                      'quicklinkInfo.imageType',
                      event.quicklinkInfo?.imageType,
                    )}
                  </tbody>
                </table>
              </div>

              <div className='border rounded-lg overflow-hidden'>
                <table className='w-full'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th
                        className='px-4 py-2 text-left font-semibold'
                        colSpan='2'
                      >
                        메인페이지 정보
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y'>
                    {renderEditableField(
                      '메인페이지 제목',
                      'mainPageInfo.title',
                      event.mainPageInfo?.title,
                    )}
                    {renderEditableField(
                      '코인 이벤트 여부',
                      'mainPageInfo.coinEvent',
                      event.mainPageInfo?.coinEvent,
                      'checkbox',
                    )}
                    {renderEditableField(
                      'CTA 색상',
                      'mainPageInfo.ctaColor',
                      event.mainPageInfo?.ctaColor,
                    )}
                    {renderEditableField(
                      'CTA 탭',
                      'mainPageInfo.ctaTab',
                      event.mainPageInfo?.ctaTab,
                    )}
                    {renderEditableField(
                      'CTA 텍스트',
                      'mainPageInfo.ctaText',
                      event.mainPageInfo?.ctaText,
                    )}
                  </tbody>
                </table>
              </div>

              <div className='border rounded-lg overflow-hidden'>
                <table className='w-full'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th
                        className='px-4 py-2 text-left font-semibold'
                        colSpan='2'
                      >
                        이벤트 페이지 정보
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y'>
                    {(editedData.eventPageInfo || event.eventPageInfo)?.map(
                      (page, index) => (
                        <tr key={page.eventId || index}>
                          <td className='px-4 py-2 font-medium'>
                            이벤트 페이지 {index + 1}
                          </td>
                          <td className='px-4 py-2'>
                            {isEditing ? (
                              <div className='space-y-2'>
                                <input
                                  type='text'
                                  value={
                                    editedData.eventPageInfo?.[index]?.title ??
                                    page.title
                                  }
                                  onChange={(e) => {
                                    const newEventPageInfo = [
                                      ...(editedData.eventPageInfo ||
                                        event.eventPageInfo),
                                    ]
                                    newEventPageInfo[index] = {
                                      ...newEventPageInfo[index],
                                      title: e.target.value,
                                    }
                                    setEditedData((prev) => ({
                                      ...prev,
                                      eventPageInfo: newEventPageInfo,
                                    }))
                                  }}
                                  className='w-full p-2 border rounded'
                                  placeholder='이벤트 페이지 제목'
                                />
                                <input
                                  type='text'
                                  value={
                                    editedData.eventPageInfo?.[index]
                                      ?.eventId ?? page.eventId
                                  }
                                  onChange={(e) => {
                                    const newEventPageInfo = [
                                      ...(editedData.eventPageInfo ||
                                        event.eventPageInfo),
                                    ]
                                    newEventPageInfo[index] = {
                                      ...newEventPageInfo[index],
                                      eventId: e.target.value,
                                    }
                                    setEditedData((prev) => ({
                                      ...prev,
                                      eventPageInfo: newEventPageInfo,
                                    }))
                                  }}
                                  className='w-full p-2 border rounded'
                                  placeholder='이벤트 ID'
                                />
                              </div>
                            ) : (
                              <div>
                                <div className='font-medium'>{page.title}</div>
                                <div className='text-sm text-gray-500'>
                                  {page.eventId}
                                </div>
                              </div>
                            )}
                          </td>
                        </tr>
                      ),
                    )}
                    {isEditing && (
                      <tr>
                        <td colSpan='2' className='px-4 py-2'>
                          <button
                            type='button'
                            onClick={() => {
                              console.log('이벤트 페이지 추가 버튼 클릭')
                              console.log('현재 editedData:', editedData)
                              console.log(
                                '현재 event.eventPageInfo:',
                                event.eventPageInfo,
                              )
                              const currentEventPageInfo =
                                editedData.eventPageInfo ||
                                event.eventPageInfo ||
                                []
                              console.log(
                                'currentEventPageInfo:',
                                currentEventPageInfo,
                              )
                              const newEventPageInfo = [
                                ...currentEventPageInfo,
                                { title: '', eventId: '', type: 'event' },
                              ]
                              console.log('newEventPageInfo:', newEventPageInfo)
                              setEditedData((prev) => {
                                const newData = {
                                  ...prev,
                                  eventPageInfo: newEventPageInfo,
                                }
                                console.log('setEditedData 호출:', newData)
                                return newData
                              })
                            }}
                            className='text-blue-500 hover:text-blue-700'
                          >
                            + 이벤트 페이지 추가
                          </button>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className='flex justify-end space-x-2 mt-4'>
          {!isEditing && (
            <button
              type='button'
              onClick={() => setIsEditing(true)}
              className='px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600'
            >
              수정
            </button>
          )}
          {isEditing && (
            <>
              <button
                type='button'
                onClick={handleSave}
                className='px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600'
              >
                저장
              </button>
              <button
                type='button'
                onClick={() => {
                  setIsEditing(false)
                  setEditedData({})
                  setValidationErrors({})
                }}
                className='px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600'
              >
                취소
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

const MainPromotionAdminScreen = observer(() => {
  const [eventList, setEventList] = useState()
  const [currentTab, setCurrentTab] = useState('list') // list, register
  const [isLoading, setIsLoading] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState(null)

  const fetchData = async () => {
    const result = await altoonCMSBackendApis.loadAllMainEvent()
    console.log('result', result)

    if (result?.status === 200) {
      setEventList(result.data)
    }
  }

  useEffect(() => {
    setIsLoading(true)
    fetchData()
    setTimeout(() => {
      setIsLoading(false)
    }, 500)
  }, [])

  const LoadingIndicator = () => {
    return (
      <div>
        <div className='fixed inset-0 z-30 w-full h-full bg-gray-800 opacity-70' />
        <div style={{ left: '50%', top: '40%' }} className='fixed z-20'>
          <ClipLoader
            color='#ff3e3e'
            loading
            size={40}
            aria-label='Loading Spinner'
            data-testid='loader'
          />
        </div>
      </div>
    )
  }

  const renderContent = () => {
    switch (currentTab) {
      case 'list':
        return (
          <div className='p-4 bg-white rounded-xl'>
            <div className='py-4 font-bold text-[2.6vh]'>메인이벤트 관리</div>
            {eventList && <RegisteredEventList eventList={eventList} />}
          </div>
        )
      case 'register':
        return (
          <div className='p-4 bg-white rounded-xl'>
            <MainEventRegisterSection />
          </div>
        )
      default:
        return null
    }
  }

  const RegisteredEventList = ({ eventList }) => {
    const handleDelete = async (id) => {
      if (!window.confirm('정말 삭제하시겠습니까?')) return

      try {
        const result = await altoonCMSBackendApis.deleteMainEvent(id)
        if (result?.status === 200) {
          alert('삭제되었습니다.')
          fetchData()
        } else {
          alert(result?.message || '삭제 중 오류가 발생했습니다.')
        }
      } catch (error) {
        console.error('Error deleting main event:', error)
        alert('삭제 중 오류가 발생했습니다.')
      }
    }

    return (
      <div className='w-full'>
        <div className='overflow-hidden rounded-xl border border-gray-200 bg-white shadow-sm'>
          <div className='overflow-x-auto'>
            <table className='min-w-full divide-y divide-gray-200'>
              <thead className='bg-gray-50 text-xs text-center'>
                <tr>
                  <th className='px-6 py-3 font-medium text-gray-500 uppercase tracking-wider'>
                    이벤트명
                  </th>
                  <th className='px-6 py-3 font-medium text-gray-500 uppercase tracking-wider'>
                    노출기간
                  </th>
                  <th className='px-6 py-3 font-medium text-gray-500 uppercase tracking-wider'>
                    퀵링크
                  </th>
                  <th className='px-6 py-3 font-medium text-gray-500 uppercase tracking-wider'>
                    메인페이지
                  </th>
                  <th className='px-6 py-3 font-medium text-gray-500 uppercase tracking-wider'>
                    코인이벤트 진행여부
                  </th>
                  <th className='px-6 py-3 font-medium text-gray-500 uppercase tracking-wider'>
                    이벤트페이지
                  </th>
                  <th className='px-6 py-3 font-medium text-gray-500 uppercase tracking-wider'>
                    관리
                  </th>
                </tr>
              </thead>
              <tbody className='bg-white divide-y divide-gray-200'>
                {eventList?.map((item) => (
                  <tr
                    key={item._id}
                    className='hover:bg-gray-50 cursor-pointer'
                    onClick={() => setSelectedEvent(item)}
                  >
                    <td className='px-6 py-4 text-sm text-gray-900'>
                      {item.eventTitle}
                    </td>
                    <td className='px-6 py-4 text-sm text-gray-900 text-center'>
                      {moment(item.displayStartedAt).format('YY.MM.DD')} <br />
                      ~
                      <br />
                      {moment(item.displayEndedAt).format('YY.MM.DD')}
                    </td>
                    <td className='px-6 py-4'>
                      {item.quicklinkInfo?.imageUri && (
                        <img
                          src={item.quicklinkInfo.imageUri}
                          alt=''
                          className='w-24 h-14 object-cover rounded-lg shadow-sm mt-2'
                        />
                      )}
                      <div className='text-sm text-gray-900'>
                        {item.quicklinkInfo?.title}
                      </div>
                    </td>
                    <td className='px-6 py-4'>
                      <div className='text-sm text-gray-900'>
                        탭 제목: {item.mainPageInfo?.title}
                      </div>
                      {item.mainPageInfo?.topBannerUri && (
                        <img
                          src={item.mainPageInfo.topBannerUri}
                          alt=''
                          className='w-24 h-14 object-cover rounded-lg shadow-sm mt-2'
                        />
                      )}
                      {item.mainPageInfo?.mainImageUri && (
                        <img
                          src={item.mainPageInfo.mainImageUri}
                          alt=''
                          className='w-24 h-14 object-cover rounded-lg shadow-sm mt-2'
                        />
                      )}
                    </td>
                    <td className='px-6 py-4 text-sm text-gray-900 text-center'>
                      {item.mainPageInfo?.coinEvent ? 'O' : 'X'}
                    </td>
                    <td className='px-6 py-4 text-sm text-gray-900 text-center'>
                      {item.eventPageInfo?.length}개 (
                      {item.eventPageInfo?.map((page) => page.title).join(', ')}
                      )
                    </td>
                    <td className='px-6 py-4 text-sm font-medium space-x-2'>
                      <button
                        type='button'
                        className='inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-lg text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
                        onClick={(e) => {
                          e.stopPropagation()
                          handleDelete(item._id)
                        }}
                      >
                        삭제
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }

  const MainEventRegisterSection = () => {
    const [description, setDescription] = useState('')
    const [eventTitle, setEventTitle] = useState('')
    const [displayStartedAt, setDisplayStartedAt] = useState('')
    const [displayEndedAt, setDisplayEndedAt] = useState('')
    const [quicklinkInfo, setQuicklinkInfo] = useState({
      imageType: '',
      imageUri: '',
      title: '',
    })
    const [mainPageInfo, setMainPageInfo] = useState({
      topBannerUri: '',
      mainImageUri: '',
      title: '',
      coinEvent: false,
      ctaColor: '',
      ctaTab: 1,
      ctaText: '',
    })
    const [eventPageInfo, setEventPageInfo] = useState([])

    return (
      <div className='p-6 bg-white rounded-xl shadow-lg'>
        <h2 className='text-lg font-medium text-gray-900 mb-6'>
          메인이벤트 등록
        </h2>
        <div className='flex'>
          <div className='w-1/3 flexCol justify-start items-start space-y-10'>
            <div className='w-4/5'>
              <div className='pb-4 text-sm font-medium text-gray-500'>
                퀵링크 이미지
              </div>
              <input
                type='text'
                value={quicklinkInfo.imageUri}
                onChange={(e) =>
                  setQuicklinkInfo({
                    ...quicklinkInfo,
                    imageUri: e.target.value,
                  })
                }
                className='w-full px-3 py-2 border border-gray-300 rounded-md text-sm text-gray-900'
                placeholder='퀵링크 이미지 URL을 입력하세요'
              />
            </div>
            <div className='w-4/5'>
              <div className='pb-4 text-sm font-medium text-gray-500'>
                상단 배너 이미지
              </div>
              <input
                type='text'
                value={mainPageInfo.topBannerUri}
                onChange={(e) =>
                  setMainPageInfo({
                    ...mainPageInfo,
                    topBannerUri: e.target.value,
                  })
                }
                className='w-full px-3 py-2 border border-gray-300 rounded-md text-sm text-gray-900'
                placeholder='상단 배너 이미지 URL을 입력하세요'
              />
              {mainPageInfo.topBannerUri && (
                <img
                  src={mainPageInfo.topBannerUri}
                  alt='상단 배너 이미지 미리보기'
                  className='w-full h-auto rounded-lg shadow-lg object-contain mt-2'
                />
              )}
            </div>
            <div className='w-4/5'>
              <div className='pb-4 text-sm font-medium text-gray-500'>
                메인페이지 이미지
              </div>
              <input
                type='text'
                value={mainPageInfo.mainImageUri}
                onChange={(e) =>
                  setMainPageInfo({
                    ...mainPageInfo,
                    mainImageUri: e.target.value,
                  })
                }
                className='w-full px-3 py-2 border border-gray-300 rounded-md text-sm text-gray-900'
                placeholder='이미지 URL을 입력하세요'
              />
            </div>
            {mainPageInfo.mainImageUri && (
              <img
                src={mainPageInfo.mainImageUri}
                alt='메인페이지 이미지 미리보기'
                className='w-4/5 h-auto rounded-lg shadow-lg object-contain'
              />
            )}
          </div>

          <div className='w-2/3 space-y-4'>
            <div className='grid grid-cols-3 gap-4'>
              <div className='col-span-1 text-sm font-medium text-gray-500'>
                이벤트명
              </div>
              <div className='col-span-2'>
                <input
                  type='text'
                  value={eventTitle}
                  onChange={(e) => setEventTitle(e.target.value)}
                  className='w-full px-3 py-2 border border-gray-300 rounded-md text-sm text-gray-900'
                  placeholder='이벤트명을 입력하세요'
                />
              </div>

              <div className='col-span-1 text-sm font-medium text-gray-500'>
                노출시작일
              </div>
              <div className='col-span-2'>
                <input
                  type='datetime-local'
                  value={displayStartedAt}
                  onChange={(e) => setDisplayStartedAt(e.target.value)}
                  className='w-full px-3 py-2 border border-gray-300 rounded-md text-sm text-gray-900'
                />
              </div>

              <div className='col-span-1 text-sm font-medium text-gray-500'>
                노출종료일
              </div>
              <div className='col-span-2'>
                <input
                  type='datetime-local'
                  value={displayEndedAt}
                  onChange={(e) => setDisplayEndedAt(e.target.value)}
                  className='w-full px-3 py-2 border border-gray-300 rounded-md text-sm text-gray-900'
                />
              </div>

              <div className='col-span-1 text-sm font-medium text-gray-500'>
                퀵링크 제목
              </div>
              <div className='col-span-2'>
                <input
                  type='text'
                  value={quicklinkInfo.title}
                  onChange={(e) =>
                    setQuicklinkInfo({
                      ...quicklinkInfo,
                      title: e.target.value,
                    })
                  }
                  className='w-full px-3 py-2 border border-gray-300 rounded-md text-sm text-gray-900'
                  placeholder='퀵링크 제목을 입력하세요'
                />
              </div>
              <div className='col-span-1 text-sm font-medium text-gray-500'>
                퀵링크 확장자
              </div>
              <div className='col-span-2'>
                <select
                  value={quicklinkInfo.imageType}
                  onChange={(e) =>
                    setQuicklinkInfo({
                      ...quicklinkInfo,
                      imageType: e.target.value,
                    })
                  }
                  className='w-full px-3 py-2 border border-gray-300 rounded-md text-sm text-gray-900'
                >
                  <option value=''>선택하세요</option>
                  <option value='gif'>gif</option>
                  <option value='png'>png</option>
                </select>
              </div>

              <div className='col-span-1 text-sm font-medium text-gray-500'>
                메인페이지 제목
              </div>
              <div className='col-span-2'>
                <input
                  type='text'
                  value={mainPageInfo.title}
                  onChange={(e) =>
                    setMainPageInfo({ ...mainPageInfo, title: e.target.value })
                  }
                  className='w-full px-3 py-2 border border-gray-300 rounded-md text-sm text-gray-900'
                  placeholder='메인페이지 제목을 입력하세요'
                />
              </div>

              <div className='col-span-1 text-sm font-medium text-gray-500'>
                코인 이벤트 여부
              </div>
              <div className='col-span-2'>
                <input
                  type='checkbox'
                  checked={mainPageInfo.coinEvent}
                  onChange={(e) =>
                    setMainPageInfo({
                      ...mainPageInfo,
                      coinEvent: e.target.checked,
                    })
                  }
                  className='h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded'
                />
              </div>

              <div className='col-span-1 text-sm font-medium text-gray-500'>
                CTA 색상
              </div>
              <div className='col-span-2'>
                <select
                  value={mainPageInfo.ctaColor}
                  onChange={(e) =>
                    setMainPageInfo({
                      ...mainPageInfo,
                      ctaColor: e.target.value,
                    })
                  }
                  className='w-full px-3 py-2 border border-gray-300 rounded-md text-sm text-gray-900'
                >
                  <option value=''>선택하세요</option>
                  <option value='red'>Red</option>
                  <option value='pink'>Pink</option>
                  <option value='white'>White</option>
                  <option value='gold'>Gold</option>
                  <option value='gray'>Gray</option>
                </select>
              </div>

              <div className='col-span-1 text-sm font-medium text-gray-500'>
                CTA 탭
              </div>
              <div className='col-span-2'>
                <input
                  type='number'
                  value={mainPageInfo.ctaTab}
                  onChange={(e) =>
                    setMainPageInfo({
                      ...mainPageInfo,
                      ctaTab: parseInt(e.target.value, 10),
                    })
                  }
                  className='w-full px-3 py-2 border border-gray-300 rounded-md text-sm text-gray-900'
                  placeholder='CTA 탭 번호를 입력하세요'
                />
              </div>

              <div className='col-span-1 text-sm font-medium text-gray-500'>
                CTA 텍스트
              </div>
              <div className='col-span-2'>
                <input
                  type='text'
                  value={mainPageInfo.ctaText}
                  onChange={(e) =>
                    setMainPageInfo({
                      ...mainPageInfo,
                      ctaText: e.target.value,
                    })
                  }
                  className='w-full px-3 py-2 border border-gray-300 rounded-md text-sm text-gray-900'
                  placeholder='CTA 텍스트를 입력하세요'
                />
              </div>

              <div className='col-span-1 text-sm font-medium text-gray-500'>
                이벤트 페이지
              </div>
              <div className='col-span-2'>
                <div className='space-y-4'>
                  {eventPageInfo.map((page, index) => (
                    <div key={index} className='flex gap-4 items-start'>
                      <div className='flex-1'>
                        <input
                          type='text'
                          value={page.title}
                          onChange={(e) => {
                            const newEventPageInfo = [...eventPageInfo]
                            newEventPageInfo[index] = {
                              ...newEventPageInfo[index],
                              title: e.target.value,
                            }
                            setEventPageInfo(newEventPageInfo)
                          }}
                          className='w-full px-3 py-2 border border-gray-300 rounded-md text-sm text-gray-900'
                          placeholder='이벤트 페이지 제목'
                        />
                      </div>
                      <div className='flex-1'>
                        <input
                          type='text'
                          value={page.eventId}
                          onChange={(e) => {
                            const newEventPageInfo = [...eventPageInfo]
                            newEventPageInfo[index] = {
                              ...newEventPageInfo[index],
                              eventId: e.target.value,
                            }
                            setEventPageInfo(newEventPageInfo)
                          }}
                          className='w-full px-3 py-2 border border-gray-300 rounded-md text-sm text-gray-900'
                          placeholder='이벤트 ID'
                        />
                      </div>
                      <button
                        type='button'
                        onClick={() => {
                          const newEventPageInfo = eventPageInfo.filter(
                            (_, i) => i !== index,
                          )
                          setEventPageInfo(newEventPageInfo)
                        }}
                        className='px-3 py-2 text-red-600 hover:text-red-700'
                      >
                        삭제
                      </button>
                    </div>
                  ))}
                  <button
                    type='button'
                    onClick={() => {
                      setEventPageInfo([
                        ...eventPageInfo,
                        { title: '', eventId: '', type: 'event' },
                      ])
                    }}
                    className='text-blue-500 hover:text-blue-700'
                  >
                    + 이벤트 페이지 추가
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='mt-6 flex justify-end'>
          <button
            type='button'
            className='inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
            onClick={async () => {
              if (
                !eventTitle ||
                !displayStartedAt ||
                !displayEndedAt ||
                !quicklinkInfo.title ||
                !quicklinkInfo.imageUri ||
                !quicklinkInfo.imageType ||
                !mainPageInfo.title ||
                !mainPageInfo.mainImageUri
              ) {
                alert('필수 항목을 모두 입력해주세요.')
                return
              }

              const result = await altoonCMSBackendApis.registerMainEvent({
                eventTitle,
                displayStartedAt: new Date(displayStartedAt).toISOString(),
                displayEndedAt: new Date(displayEndedAt).toISOString(),
                quicklinkInfo,
                mainPageInfo,
                eventPageInfo,
              })

              if (result?.status === 200) {
                alert('등록되었습니다.')
                fetchData()
                // 폼 초기화
                setDescription('')
                setEventTitle('')
                setDisplayStartedAt('')
                setDisplayEndedAt('')
                setQuicklinkInfo({
                  imageType: 'gif',
                  imageUri: '',
                  title: '',
                })
                setMainPageInfo({
                  topBannerUri: '',
                  mainImageUri: '',
                  title: '',
                  coinEvent: false,
                  ctaColor: 'red',
                  ctaTab: 1,
                  ctaText: '',
                })
                setEventPageInfo([])
              } else {
                alert(
                  '오류가 발생하였습니다. 오기입 위험이 있으니 DB를 확인해주세요.',
                )
              }
            }}
          >
            등록하기
          </button>
        </div>
      </div>
    )
  }

  return (
    <div className='w-full my-10 min-w-7xl max-w-10xl select-text'>
      {/* 탭 네비게이션 */}
      <div className='mb-6 border-b'>
        <div className='flex gap-4'>
          <button
            type='button'
            className={`py-2 px-4 ${
              currentTab === 'list'
                ? 'border-b-2 border-blue-500 text-blue-500'
                : 'text-gray-500'
            }`}
            onClick={() => setCurrentTab('list')}
          >
            메인이벤트 조회
          </button>
          <button
            type='button'
            className={`py-2 px-4 ${
              currentTab === 'register'
                ? 'border-b-2 border-blue-500 text-blue-500'
                : 'text-gray-500'
            }`}
            onClick={() => setCurrentTab('register')}
          >
            메인이벤트 등록
          </button>
        </div>
      </div>

      {/* 탭 컨텐츠 */}
      {renderContent()}

      {isLoading && <LoadingIndicator />}
      {selectedEvent && (
        <MainEventDetailModal
          event={selectedEvent}
          onClose={() => setSelectedEvent(null)}
          onUpdate={fetchData}
        />
      )}
    </div>
  )
})

export default MainPromotionAdminScreen
