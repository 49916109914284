import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { ClipLoader } from 'react-spinners'

import ToonChapterInfoManagement from './components/ToonChapterInfoManagement'
import ToonInfoManagement from './components/ToonInfoManagement'
import ToonReviewManagement from './components/ToonReviewManagement'

const ToonAdminScreen = observer(() => {
  const [activeTab, setActiveTab] = useState('toonInfo') // toonInfo, chapterInfo, review
  const [isLoading, setIsLoading] = useState(false)

  const renderContent = () => {
    switch (activeTab) {
      case 'toonInfo':
        return <ToonInfoManagement setIsLoading={setIsLoading} />
      case 'chapterInfo':
        return <ToonChapterInfoManagement setIsLoading={setIsLoading} />
      case 'review':
        return <ToonReviewManagement setIsLoading={setIsLoading} />
      default:
        return null
    }
  }

  const LoadingIndicator = () => {
    return (
      <div>
        <div className='fixed inset-0 z-30 w-full h-full bg-gray-800 opacity-70' />
        <div style={{ left: '50%', top: '40%' }} className='fixed z-20'>
          <ClipLoader
            color='#ff3e3e'
            loading
            size={40}
            aria-label='Loading Spinner'
            data-testid='loader'
          />
        </div>
      </div>
    )
  }

  return (
    <div className='w-full my-10 min-w-7xl max-w-10xl select-text'>
      <div className='flex space-x-4 mb-6'>
        <button
          type='button'
          className={`px-6 py-3 rounded-lg ${
            activeTab === 'toonInfo'
              ? 'bg-blue-500 text-white'
              : 'bg-gray-200 text-gray-700'
          }`}
          onClick={() => setActiveTab('toonInfo')}
        >
          작품 정보 관리
        </button>
        <button
          type='button'
          className={`px-6 py-3 rounded-lg ${
            activeTab === 'chapterInfo'
              ? 'bg-blue-500 text-white'
              : 'bg-gray-200 text-gray-700'
          }`}
          onClick={() => setActiveTab('chapterInfo')}
        >
          회차 정보 관리
        </button>
        <button
          type='button'
          className={`px-6 py-3 rounded-lg ${
            activeTab === 'review'
              ? 'bg-blue-500 text-white'
              : 'bg-gray-200 text-gray-700'
          }`}
          onClick={() => setActiveTab('review')}
        >
          작품 검수
        </button>
      </div>

      <div className='p-4 bg-white rounded-xl'>{renderContent()}</div>

      {isLoading && <LoadingIndicator />}
    </div>
  )
})

export default ToonAdminScreen
