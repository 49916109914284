import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React from 'react'

import TreasureHuntAction from '../../actions/TreasureHuntAction'
import THBottomSheetStore from '../../stores/treasureHunt/THBottomSheetStore'
import THUserStore from '../../stores/treasureHunt/THUserStore'
import { safeCodepushVersionCompare } from '../../utils/useConvertCodePushVersion'
import BottomButtons from './molecules/BottomButtons'
import DailyMission from './molecules/DailyMission'
import DailyMissionList from './molecules/DailyMissionList'

const THMission = observer(() => {
  if (safeCodepushVersionCompare(THUserStore?.codepushVersion, '4.1.9') < 0) {
    return (
      <div
        className={`default relative justify-center items-center w-full pb-4 text-[#313138] p-4 `}
      >
        <div className='text-[#313138] font-bold text-left w-full text-[5vw] mb-1'>
          데일리 미션
        </div>
        <DailyMissionList />

        {/* <div className='text-[#313138] font-bold text-left w-full text-[5vw] my-2'>
        챌린지 미션
      </div>
      <div className='border-2 h-[50vh] w-full '>
        <div />
      </div> */}
      </div>
    )
  }

  return (
    <div className='default relative justify-center items-center w-full pb-4 text-[#313138] p-4 max-h-[60vh] overflow-y-scroll'>
      <div className='text-[#313138] font-bold text-left w-full text-[5vw] mb-1'>
        발견하기 미션
      </div>
      {/* <DailyMissionList /> */}
      <div className='flex w-full'>
        <BottomButtons />
      </div>
      <div className='text-[#313138] font-bold text-left w-full text-[5vw] my-4 mb-1'>
        데일리 미션
      </div>
      <div className='flex flex-col w-full justify-start items-center overflow-x-scroll py-2'>
        <DailyMission
          mission={{
            rewardType: 'energy',
            rewardAmount: 3,
            status: 'action',
            type: 'linkSharePoint',
            actionText: '공유하기',
            title: '매일 공유하기',
            actionHandler: () => {
              TreasureHuntAction.kakaoLinkShare({
                shareType: 'TreasureHunt_linkSharePoint',
                title: '[보물찾기] 3,000원이 지급되었어요!',
                description: '캐시를 모으면 매달 귀걸이를 무료로 받아요!',
                imageUrl:
                  'https://assets.ilevit.com/80ec3d27-37c3-456d-a487-ec5650ff3546.png',
                payload: {},
                templateId: 99743,
              })
              THBottomSheetStore.close()
            },
            rewardHandler: () => {
              TreasureHuntAction.giveMissionReward({ type: 'linkSharePoint' })
            },
            ...THUserStore?.dailyMission?.linkSharePoint,
          }}
        />
        {/* <DailyMission
          mission={
            THUserStore?.dailyMission?.searchTenTimes ?? {
              rewardType: 'point',
              rewardAmount: 20,
              status: 'action',
              type: 'searchTenTimes',
              actionText: '탐색하기',
              title: '탐색 10번 하기',
            }
          }
        />
        <DailyMission
          mission={
            THUserStore?.dailyMission?.findFriend ?? {
              rewardType: 'point',
              rewardAmount: 20,
              status: 'action',
              type: 'findFriend',
              actionText: '탐색하기',
              title: '친구 3번 발견하기',
            }
          }
        /> */}
        <DailyMission
          mission={{
            rewardType: 'energy',
            rewardAmount: 5,
            status: 'action',
            type: 'purchaseMission',
            actionText: '구매하기',
            title: '상품 구매하기',
            rewardFixed: true,
            actionHandler: () => {
              const payload = {
                enteringComponent: 'TreasureHunt_PurchaseMissionEnergy',
                text: '보물찾기',
                source:
                  'https://assets.ilevit.com/ac215e7a-e3c6-4b3f-a480-83fb030eac66.png',
                rewardText: `상품을 구매하고 번개 5개를 받아보세요`,
              }

              TreasureHuntAction.navigateRN({
                screen: 'TreasureHuntMissionPurchaseScreen',
                payload,
              })
              THBottomSheetStore.close()
            },
            rewardHandler: () => {
              TreasureHuntAction.giveMissionReward({ type: 'purchaseMission' })
            },
            ...THUserStore?.dailyMission?.purchaseMission,
          }}
        />

        <DailyMission
          mission={{
            rewardType: 'energy',
            rewardAmount: 3,
            status: 'action',
            type: 'browsingMission',
            actionText: '둘러보기',
            title: '상품 둘러보기',
            actionHandler: () => {
              const payload = {
                enteringComponent: 'TreasureHunt_BrowsingMissionEnergy',
                text: '보물찾기',
                source:
                  'https://assets.ilevit.com/ac215e7a-e3c6-4b3f-a480-83fb030eac66.png',
                rewardText: `상품을 구매하고 번개 5개를 받아보세요`,
                isTimerOn: true,
                timeStamp: moment().add(30, 'seconds').toDate(),
                timerText: `초 구경하면 번개를 최대 3개까지 받아요!`,
                timerMinutes: 0.5,
              }

              TreasureHuntAction.navigateRN({
                screen: 'TreasureHuntMissionPurchaseScreen',
                payload,
              })
              THBottomSheetStore.close()
            },
            rewardHandler: () => {
              TreasureHuntAction.giveMissionReward({ type: 'browsingMission' })
            },
            ...THUserStore?.dailyMission?.browsingMission,
          }}
        />
      </div>

      {/* <div className='text-[#313138] font-bold text-left w-full text-[5vw] my-2'>
        챌린지 미션
      </div>
      <div className='border-2 h-[50vh] w-full '>
        <div />
      </div> */}
    </div>
  )
})

export default THMission
