import { AppScreen } from '@stackflow/plugin-basic-ui'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { FiChevronLeft } from 'react-icons/fi'
import { ClipLoader } from 'react-spinners'

import AltoonAction from '../actions/AltoonAction'
import ToonModal from '../components/templates/ToonModal'
import { useMyFlow } from '../hooks/altoon/useMyFlow.ts'
import AltoonUserStore from '../stores/AltoonUserStore'
import backendApis from '../utils/backendApis.ts'
import AltoonEventPage from './AltoonEventPage'

const AltoonMainEventPage = observer(({ params }) => {
  const { pop } = useMyFlow()
  const eventId = params?.eventId

  const [isLoading, setIsLoading] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const [openModal, setOpenModal] = useState(false)
  const [openCloseModal, setOpenCloseModal] = useState(false)
  const [eventInfo, setEventInfo] = useState()

  useEffect(() => {
    const fetchEventInfo = async () => {
      const result = await backendApis.getMainEventInfo()
      if (result?.status === 200 && result?.data) {
        setEventInfo(result?.data)
      } else {
        setOpenCloseModal(true)
      }
    }

    fetchEventInfo()

    if (isLoading) {
      const timer = setTimeout(() => {
        setIsLoading(false)
      }, 300)

      return () => clearTimeout(timer)
    }
  }, [activeTab, isLoading])

  useEffect(() => {
    if (eventId) {
      const targetIndex = eventList.findIndex(
        (event) => event.eventId === eventId,
      )
      if (targetIndex !== -1) {
        setActiveTab(targetIndex)
      }
    }
  }, [eventId])

  const eventList = [
    // ? 여기에서의 type은 무조건 image || event
    // ? event 중 다양한 type은 'eventType'으로 이벤트 정보에서 분기처리
    {
      type: 'image',
      title: eventInfo?.mainPageInfo?.title || '혜택미리보기',
      imageUri: eventInfo?.mainPageInfo?.mainImageUri,
      renderingComponent: () => (
        <div className='relative flex flex-col items-center justify-center bg-[#424242] font-bold text-[5vw]'>
          {eventInfo?.mainPageInfo?.coinEvent && (
            <button
              type='button'
              className='absolute -top-[46vw] w-[70vw] py-[5vw]'
              style={{
                backgroundImage:
                  "url('/images/toon/eventPage/whiteButton.png')",
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
              onClick={() => {
                setOpenModal(true)
              }}
            >
              코인 충전하기
            </button>
          )}
          <button
            type='button'
            className='absolute -top-[26vw] w-[70vw] py-[5vw] text-white'
            style={{
              backgroundImage: `url('/images/toon/eventPage/${
                eventInfo?.mainPageInfo?.ctaColor || 'red'
              }Button.png')`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
            onClick={() => {
              setActiveTab(eventInfo?.mainPageInfo?.ctaTab || 1)
            }}
          >
            {eventInfo?.mainPageInfo?.ctaText || '미션하러 가기'}
          </button>
        </div>
      ),
    },
    // eventInfo.eventPageInfo 배열 렌더하기
    ...(eventInfo?.eventPageInfo?.map((item) => ({
      type: item.type,
      eventId: item.eventId,
      title: item.title,
    })) || []),
  ]

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const HeaderComponent = () => {
    return (
      <section className='fixed top-0 w-full bg-white z-20 flex flex-col'>
        <div className='py-2 flex flex-row items-center justify-between overflow-hidden font-bold'>
          <button
            type='button'
            aria-label='back'
            className='px-3 py-2 whitespace-nowrap'
            onClick={() => pop()}
          >
            <FiChevronLeft className='w-8 h-8 stroke-[0.2vw]' />
          </button>
          <div className='text-[4.2vw] py-2 whitespace-nowrap'>
            {eventInfo?.eventTitle || '이벤트'}
          </div>
          <button
            type='button'
            className='px-[4vw] text-[3.6vw]'
            onClick={() => {
              backendApis.logToonAction('AltoonMainEventPage', 'linkShare', '')

              AltoonAction.linkShare({
                shareType: 'Altoon_mainEventShare',
                title: `[올툰 프로모션]`,
                description: `올툰에서 이벤트를 진행하고 있어요. 그동안 보고 싶었던 작품들이 할인중이래요!`,
                imageUrl:
                  'https://assets.ilevit.com/8a6787fd-acc0-4b28-aa59-993fe40a9240.png',
                payload: {
                  isMainEvent: true,
                },
              })
            }}
          >
            <img
              src='/images/toon/shareIcon.png'
              alt='share'
              className='w-[5vw] h-[5vw]'
            />
          </button>
        </div>
      </section>
    )
  }

  const MainImageSection = () => {
    return (
      <div className='mt-[16vw]'>
        <img
          src={eventInfo?.mainPageInfo?.topBannerUri}
          alt=''
          className='w-full h-full object-cover'
        />
      </div>
    )
  }

  const StickyHeader = ({ eventList }) => {
    const headerRef = useRef(null)

    useEffect(() => {
      if (activeTab >= 3 && headerRef.current) {
        headerRef.current.scrollLeft = headerRef.current.scrollWidth
      }
    }, [activeTab])

    return (
      <div
        ref={headerRef}
        className='sticky top-[16vw] z-20 bg-white text-[4vw] text-[#411308] flex overflow-x-auto sticky-header'
      >
        {eventList?.map((item, index) => (
          <button
            type='button'
            key={`${Math.random() + index}`}
            className={`flex-shrink-0 px-[4vw] pt-[5vw] pb-[4vw] text-[3.8vw] font-bold whitespace-nowrap
            ${
              activeTab === index
                ? 'border-b-2 border-[#411308]'
                : 'text-[#41130830]'
            }`}
            onClick={() => {
              if (index === activeTab) {
                return
              }

              setIsLoading(true)
              setActiveTab(index)
              scrollToTop()
            }}
          >
            {item?.title}
          </button>
        ))}
      </div>
    )
  }

  return (
    <AppScreen>
      <div
        className={
          isLoading || openModal
            ? 'fixed w-full min-h-screen bg-gray-50'
            : 'w-full min-h-screen bg-gray-50'
        }
      >
        <HeaderComponent />
        {activeTab === 0 && <MainImageSection />}
        <StickyHeader eventList={eventList} />

        {/* 첫 번째 탭의 컨텐츠는 display로 제어 */}
        <div style={{ display: activeTab === 0 ? 'block' : 'none' }}>
          <img
            src={eventList[0]?.imageUri}
            alt=''
            className='w-full h-full object-cover'
          />
          {eventList[0]?.renderingComponent?.()}
        </div>

        {/* AltoonEventPage를 조건부로 렌더링하지 않고, key prop으로 제어 - AltoonEventPage가 Unmount할 때 버그를 발생시키기 때문 */}
        <div
          className='pt-[15vw]'
          style={{ display: activeTab === 0 ? 'none' : 'block' }}
        >
          <AltoonEventPage
            key={eventList[activeTab]?.eventId}
            params={{
              eventId: eventList[activeTab]?.eventId || 'none',
              isFrom: 'AltoonMainEventPage',
            }}
          />
        </div>
      </div>

      {isLoading && (
        <div>
          <div className='fixed inset-0 z-10 w-full h-full bg-gray-800 opacity-70' />
          <div style={{ left: '40%', top: '40%' }} className='fixed z-20'>
            <ClipLoader
              color='#ff3e3e'
              loading={isLoading}
              size='20vw'
              aria-label='Loading Spinner'
              data-testid='loader'
            />
          </div>
        </div>
      )}
      {openModal && (
        <ToonModal
          setOpenTicketModal={setOpenModal}
          modalName='ticketExchange'
          modalProp={{
            goods: 'toonCoin',
          }}
          modalTop='80'
        />
      )}
      {openCloseModal && (
        <ToonModal
          setOpenTicketModal={setOpenCloseModal}
          modalName='closeEvent'
          modalProp={{}}
          modalTop='80'
        />
      )}
    </AppScreen>
  )
})

export default AltoonMainEventPage
