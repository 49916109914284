import React from 'react'

import { useMyFlow } from '../../hooks/altoon/useMyFlow.ts'
import AltoonUserStore from '../../stores/AltoonUserStore'

const QuicklinkComponent = ({ mainEventInfo }) => {
  const { push } = useMyFlow()
  const quicklinkInfo = mainEventInfo?.quicklinkInfo

  const QuicklinkButton = ({
    path,
    prop,
    selectTab,
    src,
    srcType = 'png',
    label,
    children,
  }) => {
    return (
      <button
        type='button'
        className='relative w-full flex flex-col justify-center items-center '
        onClick={() => {
          push(path, {
            prop: {
              ...prop,
            },
            component: 'main_quicklink',
          })
          if (selectTab) {
            window.localStorage.setItem('toon_default_tab', selectTab)
          }
        }}
      >
        <img
          src={
            path === 'AltoonMainEventPage'
              ? src
              : `/images/toon/quicklink/mainQuicklink_${src}.${srcType}`
          }
          className={
            srcType === 'gif'
              ? 'w-[18vw] h-[18vw] object-cover'
              : 'w-[14vw] h-[14vw]'
          }
          alt=''
        />
        <div className='absolute -bottom-[5vw] text-[3vw]'>{label}</div>
      </button>
    )
  }

  return (
    <div className='w-full px-[2vw] py-[4vw] flex flex-row justify-between'>
      {quicklinkInfo && quicklinkInfo?.imageUri && quicklinkInfo?.title && (
        // AltoonUserStore?.userData?.isAdmin === true &&
        <QuicklinkButton
          path='AltoonMainEventPage'
          src={quicklinkInfo?.imageUri}
          srcType={quicklinkInfo?.imageType || 'png'}
          label={quicklinkInfo?.title}
          prop={{
            path: 'AltoonMainEventPage',
            label: quicklinkInfo?.title,
            index: 0,
          }}
        />
      )}
      <QuicklinkButton
        path='AltoonToonPage'
        src='recommend'
        label='웹툰'
        prop={{
          path: 'AltoonToonPage',
          label: '웹툰',
          index: 1,
        }}
      />
      {/* 메인이벤트 퀵링크가 있는 동안에는 웹툰으로 통합 운영 */}
      {/* <QuicklinkButton
        path='AltoonToonPage'
        selectTab='daily'
        src='daily'
        label='요일연재'
        prop={{
          path: 'AltoonToonPage',
          label: '요일연재',
          index: 0,
        }}
      />
      <QuicklinkButton
        path='AltoonToonPage'
        selectTab='recommend'
        src='recommend'
        label='완결작품'
        prop={{
          path: 'AltoonToonPage',
          label: '완결작품',
          index: 1,
        }}
      /> */}
      <QuicklinkButton
        path='AltoonNovelPage'
        src='novel'
        label='웹소설'
        prop={{
          path: 'AltoonNovelPage',
          label: '웹소설',
          index: 2,
        }}
      />
      <QuicklinkButton
        path='AltoonTicketPage'
        src='coin'
        label='무료코인'
        prop={{
          path: 'AltoonTicketPage',
          label: '무료코인',
          index: 3,
        }}
      />
      <QuicklinkButton
        path='AltoonEventListPage'
        src='event'
        label='이벤트'
        prop={{
          path: 'AltoonEventListPage',
          label: '이벤트',
          index: 4,
        }}
      />
      {/* {AltoonUserStore?.userData?.isAdmin && ( */}
      <QuicklinkButton
        path='AltoonWaitForFreePage'
        src='waitForFree'
        label='기다무'
        prop={{
          path: 'AltoonWaitForFreePage',
          label: '기다무',
          index: 5,
        }}
      />
      {/* )} */}
    </div>
  )
}
export default QuicklinkComponent
