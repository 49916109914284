import { observer } from 'mobx-react-lite'
import React from 'react'

import AltoonCMSUserStore from '../../stores/AltoonCMS/AltoonCMSUserStore'

const Sidebar = observer(() => {
  const profile = 'profile image'
  const menus = [
    {
      title: '정산 관리',
      url: 'main',
    },
    {
      title: '내 정보 관리',
      url: 'myInfo',
    },
    {
      title: '웹툰 관리',
      url: 'toonAdmin',
      type: 'admin',
    },
    {
      title: '웹소설 관리',
      url: 'novelAdmin',
      type: 'admin',
    },
    {
      title: '댓글 관리',
      url: 'commentAdmin',
      type: 'admin',
    },
    {
      title: 'CP사 관리',
      url: 'providerAdmin',
      type: 'admin',
    },
    {
      title: '배너 관리',
      url: 'bannerAdmin',
      type: 'admin',
    },
    {
      title: '이벤트 관리',
      url: 'toonEventAdminScreen',
      type: 'admin',
    },
    {
      title: '메인프로모션 관리',
      url: 'mainPromotionAdminScreen',
      type: 'admin',
    },
    {
      title: '콘텐츠탭 관리',
      url: 'contentTabAdminScreen',
      type: 'admin',
    },
    {
      title: '노출구좌 관리',
      url: 'toonRecommendPromotionAdminScreen',
      type: 'admin',
    },
    {
      title: 'CTR 데이터',
      url: 'toonPromotionAdminScreen',
      type: 'admin',
    },
  ]

  const ProfileSection = () => {
    return (
      <div className='flex flex-col items-center justify-center mb-10 text-xl font-bold'>
        <div className='pt-10'>
          <button
            type='button'
            onClick={() => {
              AltoonCMSUserStore?.setCurrentScreen('main')
            }}
          >
            <img
              alt={profile}
              src={AltoonCMSUserStore?.providerInfo?.providerImageUri}
              className='w-28 rounded-full h-[auto]'
            />
          </button>
        </div>
        <div className='pt-6'>
          <h1>{AltoonCMSUserStore?.providerInfo?.providerDisplayName}</h1>
        </div>
        <div className='text-sm pt-2 px-4'>
          {AltoonCMSUserStore?.providerInfo?.address}
        </div>
        <div />
      </div>
    )
  }

  const MenuSection = ({ currentScreen }) => {
    return (
      <div className='flex flex-col'>
        {menus
          ?.filter((menu) => menu?.type !== 'admin')
          .map((menu) => {
            return (
              <button
                type='button'
                onClick={() => {
                  AltoonCMSUserStore?.setCurrentScreen(menu?.url)
                }}
                key={menu?.url}
                className={`text-sm py-3 pl-8 text-start hover:bg-gray-500
                  ${
                    currentScreen === menu?.url &&
                    ' bg-gray-600 border-r-2 border-white'
                  }`}
              >
                {menu?.title}
              </button>
            )
          })}
        {menus
          ?.filter(
            (menu) =>
              menu?.type === 'admin' &&
              AltoonCMSUserStore?.isMasterAccount === true,
          )
          .map((menu) => {
            return (
              <button
                type='button'
                onClick={() => {
                  AltoonCMSUserStore?.setCurrentScreen(menu?.url)
                }}
                key={menu?.url}
                className={`text-sm py-3 pl-8 text-start hover:bg-gray-500
                  ${
                    currentScreen === menu?.url &&
                    ' bg-gray-600 border-r-2 border-white'
                  }`}
              >
                {menu?.title}
              </button>
            )
          })}
      </div>
    )
  }

  return (
    <div>
      <div className='mb-40 overflow-y-auto text-white bg-dashboard-sidebar no-scrollbar '>
        {AltoonCMSUserStore?.providerInfo && (
          <nav className='flex flex-col h-screen select-none'>
            <ProfileSection />
            <MenuSection currentScreen={AltoonCMSUserStore?.currentScreen} />
            <div className='flex flex-col justify-end flex-1 pb-5 text-gray-400'>
              <div className='flex flex-row justify-between px-5 pt-5 text-gray-500'>
                <button
                  type='button'
                  className='text-xs'
                  onClick={() => {
                    window.open(
                      'https://levitinc.notion.site/72eed1be3a584a5b85c7335225a98ced',
                      '_blank',
                    )
                  }}
                >
                  이용약관
                </button>
              </div>
            </div>
          </nav>
        )}
      </div>
    </div>
  )
})

export default Sidebar
