import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'

import DailyCheckInAction from '../../actions/DailyCheckInAction'
import backendApis from '../../utils/backendApis'

const DailyCheckInHiddenPointNudgeModal = observer(
  ({ visible, close, ...params }) => {
    useEffect(() => {
      backendApis.recordEventLog(
        'DAILYCHECKIN_HIDDEN_POINT_AFTER_CPM',
        'modalview',
      )
    }, [])
    return (
      <div
        className='fixed top-0 bottom-0 left-0 right-0 z-10 flex items-center justify-center'
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
        }}
      >
        <div className='w-[80vw] p-[20px] rounded-[20px] bg-white flex flex-col items-center justify-center gap-4'>
          <img
            src='https://assets.ilevit.com/4d55e053-2a08-4176-8a7b-b0599b7c3432.png'
            alt=''
            className='w-full'
          />
          <div className='flex items-center justify-center gap-[12px] w-full font-bold text-[20px]'>
            <button
              type='button'
              className='w-full bg-[#EEEFF3] text-[#BCBDC3] rounded-[16px] p-[12px]'
              onClick={() => {
                close()
              }}
            >
              닫기
            </button>
            <button
              type='button'
              className='w-full bg-[#F8323E] text-white rounded-[16px] p-[12px]'
              onClick={() => {
                DailyCheckInAction.showRewardedAd(
                  'DAILYCHECKIN_HIDDEN_POINT_REWARD',
                )
                backendApis.recordEventLog(
                  'DAILYCHECKIN_HIDDEN_POINT_AFTER_CPM',
                  'click',
                )
                close()
              }}
            >
              받기
            </button>
          </div>
        </div>
      </div>
    )
  },
)

export default DailyCheckInHiddenPointNudgeModal
