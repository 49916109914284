import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'

import AltoonUserStore from '../../stores/AltoonUserStore'
import ToonModal from '../templates/ToonModal'

const MyTicketSection = observer(() => {
  const [modalType, setModalType] = useState('')
  const [modalProp, setModalProp] = useState([])
  const [openTicketModal, setOpenTicketModal] = useState(false)

  const freeCoin = AltoonUserStore?.userData?.freeCoin || 0
  const paidCoin = AltoonUserStore?.userData?.paidCoin || 0
  const totalCoin = freeCoin + paidCoin

  const GoodsComponent = observer(() => {
    return (
      <button
        type='button'
        className='p-4 w-full relative bg-[#F6F6F6] rounded-lg text-[4vw]'
        onClick={() => {
          setModalType('ticketExchange')
          setModalProp({ goods: 'toonCoin' })
          setOpenTicketModal(true)
        }}
      >
        <div className='items-start w-full flexCol'>
          <div className='items-center justify-between w-full flexRow'>
            <div className='flexRow justify-center items-center pl-[4vw]'>
              <div className='items-center justify-between flexRow whitespace-nowrap'>
                <img
                  src='/images/toon/toonCoin.png'
                  alt='toonCoin'
                  className='w-[6vw] h-[6vw] mr-2'
                />
                보유 코인
              </div>
              <div className='text-[#FF8317] pl-3'>
                {commaNumber(totalCoin)}C
              </div>
            </div>
            <div>
              <div className='absolute top-[6vw] right-[4vw] px-[4vw] py-[3vw] text-white text-[3.6vw] bg-[#FF8317] rounded-lg'>
                충전하기
                {/* <div className='absolute -top-[2vw] -right-[4vw] px-[2vw] py-[1vw] text-white text-[2.4vw] bg-[#FF3E3E] rounded-full rotate-12'>
                  이벤트!
                </div> */}
              </div>
            </div>
          </div>
          <div className='pl-[12vw] pt-2 text-start text-gray-500 text-[3vw]'>
            <div>무료 코인 : {commaNumber(freeCoin)}C</div>
            <div>유료 결제 코인 : {commaNumber(paidCoin)}C</div>
          </div>
        </div>
      </button>
    )
  })

  return (
    <>
      <div className='flex flex-col items-center w-full px-4 bg-white'>
        <div className='items-center w-full p-2 mb-2 font-semibold flexCol'>
          <GoodsComponent data={AltoonUserStore?.userData} />
        </div>
      </div>
      {openTicketModal && (
        <ToonModal
          modalName={modalType}
          coinCount={totalCoin}
          modalProp={modalProp}
          setOpenTicketModal={setOpenTicketModal}
          setModalType={setModalType}
          setModalProp={setModalProp}
        />
      )}
    </>
  )
})
export default MyTicketSection
