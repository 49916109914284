import { AppScreen } from '@stackflow/plugin-basic-ui'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { FiChevronLeft } from 'react-icons/fi'

import LoadingIndicator from '../components/atoms/loadingIndicator'
import THImageText from '../components/treasureHunt/THImageText'
import { useMyFlow } from '../hooks/altoon/useMyFlow.ts'
import backendApis from '../utils/backendApis.ts'
import ThemeSaleEventPage from './AltoonEventPage/ThemeSaleEventPage'

/**
 * 올툰 이벤트 페이지 (id기반 이벤트 정보 조회)
 */
const AltoonWaitForFreePage = observer(() => {
  const eventId = '67b83cabfcf096c9f4c9f699'

  const { pop } = useMyFlow()

  const [isLoading, setIsLoading] = useState(false)
  const [eventInfo, setEventInfo] = useState()
  const [toonsInfo, setToonsInfo] = useState()

  useEffect(() => {
    const fetchData = async () => {
      if (eventId === 'none') {
        return
      }

      const eventInfo = await backendApis.loadToonEventInfo(eventId)
      if (eventInfo?.status === 200) {
        setEventInfo(eventInfo?.data)
        if (eventInfo?.data?.toonIdList?.length > 0) {
          const toonInfo = await backendApis.loadToonInfoByIds(
            eventInfo?.data?.toonIdList,
          )
          setToonsInfo(toonInfo?.data)
        }
      }
    }
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'allowiPhoneGesture',
        boolean: false,
      }),
    )

    setIsLoading(true)
    fetchData()
    backendApis.logToonAction('AltoonWaitForFreePage', 'enteredScreen', '')
    setIsLoading(false)
  }, [])

  const HeaderComponent = () => {
    return (
      <section className='fixed top-0 w-full bg-white z-20 py-2 flex flex-row items-center justify-between overflow-hidden font-bold'>
        <button
          type='button'
          aria-label='back'
          className='px-3 py-2 whitespace-nowrap'
          onClick={async () => {
            pop()
          }}
        >
          <FiChevronLeft className='w-8 h-8 stroke-[0.2vw]' />
        </button>
        <div className='text-[4.2vw] py-2 whitespace-nowrap'>
          {eventInfo?.eventTitle?.length > 20
            ? `${eventInfo?.eventTitle?.slice(0, 20)}..`
            : eventInfo?.eventTitle}
        </div>
        <div className='w-[10vw]' />
      </section>
    )
  }

  const MainImageSection = () => {
    if (!eventInfo?.eventImageUri) {
      return
    }
    return (
      <div className='pt-[16vw]'>
        <img
          src={eventInfo?.eventImageUri}
          alt=''
          className='w-full h-full object-cover'
        />
      </div>
    )
  }

  const SubImageSection = () => {
    return (
      <div className='pt-[16vw]'>
        <img
          src={eventInfo?.subImageUri}
          alt=''
          className='w-full h-full object-cover'
        />
      </div>
    )
  }

  const NoticeSection = () => {
    return (
      <div className='w-full px-6 pt-4 pb-[10vw] bg-gray-200'>
        <div className='text-[3.6vw] font-semibold'>유의사항</div>
        <THImageText
          fontSize={2.8}
          style={{
            color: '#8E8D9B',
          }}
          text={`${eventInfo?.notice}`}
        />
      </div>
    )
  }

  return (
    <AppScreen>
      <div
        style={{
          backgroundColor: `#${eventInfo?.backgroundColor}`,
        }}
      >
        {eventInfo && (
          <>
            <HeaderComponent />
            <MainImageSection />

            {eventInfo?.toonIdList?.length > 0 && (
              <ThemeSaleEventPage eventInfo={eventInfo} toonsInfo={toonsInfo} />
            )}

            {eventInfo?.subImageUri && <SubImageSection />}
            {eventInfo?.notice && <NoticeSection />}
          </>
        )}
        {isLoading && <LoadingIndicator isLoading={isLoading} />}
      </div>
    </AppScreen>
  )
})

export default AltoonWaitForFreePage
