import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useMemo, useRef, useState } from 'react'

import MainToonComponent from '../../components/toon/mainToonComponent'
import ToastStore from '../../stores/ToastStore'
import backendApis from '../../utils/backendApis.ts'

/**
 * 올툰 이벤트 페이지 (id기반 이벤트 정보 조회)
 */
const PollEventPage = observer(({ eventInfo, toonsInfo }) => {
  const [selectedToonId, setSelectedToonId] = useState()
  const [currentPage, setCurrentPage] = useState(() => {
    const savedPage = window.localStorage.getItem('pollCurrentPage')
    return savedPage ? parseInt(savedPage, 10) : 0
  })
  const [myPollData, setMyPollData] = useState()
  const [isPollSubmitted, setIsPollSubmitted] = useState(
    moment(window.localStorage.getItem('pollSubmitted')).format(
      'YYYY-MM-DD',
    ) === moment().format('YYYY-MM-DD'),
  )
  const getPageCount = (items) => Math.ceil(items.length / 4)
  const shuffleArray = (array, seed) => {
    const shuffled = [...array]

    // 시드 기반 난수 생성기
    const seededRandom = () => {
      const x = Math.sin(seed++) * 10000
      return x - Math.floor(x)
    }

    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(seededRandom() * (i + 1))
      ;[shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]
    }

    return shuffled
  }

  const getShuffledToonsList = () => {
    // toonsInfo가 없거나 비어있으면 빈 배열 반환
    if (!toonsInfo || toonsInfo.length === 0) {
      return []
    }

    const today = moment().format('YYYY-MM-DD')
    const storedData = window.localStorage.getItem(
      `shuffledToons_${moment().format('DD')}`,
    )

    // 깊은 복사 함수
    const deepClone = (obj) => {
      try {
        return JSON.parse(JSON.stringify(obj))
      } catch (e) {
        console.error('Cloning error:', e)
        return obj
      }
    }

    // localStorage에서 데이터를 불러올 때
    if (storedData) {
      try {
        const { date, toons } = JSON.parse(storedData)
        if (date === today && toons && toons.length > 0) {
          return deepClone(toons)
        }
      } catch (e) {
        window.localStorage.removeItem('shuffledToonsList')
      }
    }

    // 새로운 셔플 데이터 생성
    const toonsToShuffle = deepClone(toonsInfo)
    const shuffled = shuffleArray(toonsToShuffle, getTodaySeed())

    if (shuffled && shuffled.length > 0) {
      window.localStorage.setItem(
        'shuffledToonsList',
        JSON.stringify({
          date: today,
          toons: shuffled,
        }),
      )
    }

    return shuffled
  }

  // 오늘 날짜를 시드로 사용
  const getTodaySeed = () => {
    const today = new Date()
    return (
      today.getFullYear() * 10000 +
      (today.getMonth() + 1) * 100 +
      today.getDate()
    )
  }

  const shuffledToonsInfo = useMemo(() => {
    return getShuffledToonsList()
  }, [toonsInfo])

  useEffect(() => {
    const fetchPollData = async () => {
      const pollData = await backendApis.loadPollData(eventInfo?._id)
      if (pollData?.status === 200) {
        setMyPollData(pollData?.data)
      }
    }
    console.log(toonsInfo, 'toonsInfo', eventInfo, 'eventInfo')

    fetchPollData()
  }, [isPollSubmitted])

  // 페이지 변경 시 로컬스토리지에 저장하는 함수
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage)
    window.localStorage.setItem('pollCurrentPage', newPage.toString())
  }

  const containerRef = useRef(null)
  const isScrolling = useRef(false)

  // 컴포넌트 마운트 또는 currentPage 변경 시 저장된 페이지로 스크롤
  useEffect(() => {
    const scrollToSavedPage = () => {
      if (containerRef.current && currentPage > 0) {
        const targetScroll = currentPage * window.innerWidth
        containerRef.current.scrollTo({
          left: targetScroll,
          behavior: 'instant', // 초기 스크롤은 즉시 이동
        })
      }
    }

    // DOM이 완전히 렌더링된 후 스크롤 실행
    const timer = setTimeout(scrollToSavedPage, 100)
    return () => clearTimeout(timer)
  }, [currentPage])

  const handleScroll = (e) => {
    if (isScrolling.current) return

    const container = e.target
    const scrollPosition = container.scrollLeft
    const pageWidth = window.innerWidth
    const newPage = Math.round(scrollPosition / pageWidth)

    if (newPage !== currentPage) {
      isScrolling.current = true
      const targetScroll = newPage * pageWidth

      container.scrollTo({
        left: targetScroll,
        behavior: 'smooth',
      })

      handlePageChange(newPage)

      setTimeout(() => {
        isScrolling.current = false
      }, 100)
    }
  }

  return (
    <div className='pb-10'>
      <div
        className='w-[100vw] h-[100vw] flex flex-row justify-around items-center px-4 pt-6 pb-4 font-bold text-[4.8vw] text-white'
        style={{
          backgroundImage: `url('https://assets.ilevit.com/71ebe9e1-1f3a-435a-8670-7dfb641c9329.png')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className='w-[40vw] py-[4vw] rounded-lg text-center bg-black'>
          <div className='text-[3.8vw]'>나의 투표 횟수</div>
          <div className='text-[6vw]'>{myPollData?.length || 0}번</div>
        </div>
        <div className='w-[40vw] py-[4vw] rounded-lg text-center bg-black'>
          <div className='text-[3.8vw]'>받은 코인</div>
          <div className='text-[6vw]'>
            {myPollData?.length > 15 ? '450' : (myPollData?.length || 0) * 10}C
          </div>
        </div>
      </div>

      <div className='relative'>
        <div
          ref={containerRef}
          className='overflow-x-auto scrollbar-hide'
          style={{
            scrollSnapType: 'x mandatory',
            WebkitOverflowScrolling: 'touch',
            scrollBehavior: 'smooth',
          }}
          onScroll={handleScroll}
        >
          <div className='inline-flex'>
            {Array(getPageCount(toonsInfo || []))
              .fill()
              .map((_, pageIndex) => (
                <div
                  key={`page-${pageIndex}`}
                  className='w-screen flex-shrink-0'
                  style={{
                    scrollSnapAlign: 'center',
                    scrollSnapStop: 'always',
                  }}
                >
                  <div className='grid grid-cols-2 gap-4 px-4'>
                    {shuffledToonsInfo
                      ?.slice(pageIndex * 4, (pageIndex + 1) * 4)
                      .map((toon, index) => (
                        <button
                          type='button'
                          key={toon?._id}
                          className={`relative flex flex-col items-center rounded-lg border bg-white ${
                            selectedToonId === toon?._id
                              ? 'border-red-500 border-[1vw]'
                              : ''
                          }`}
                          onClick={() => {
                            // 현재 선택된 작품이 있는 페이지 찾기
                            const itemsPerPage = 4
                            const toonIndex = shuffledToonsInfo?.findIndex(
                              (t) => t._id === toon._id,
                            )
                            const page = Math.floor(toonIndex / itemsPerPage)

                            // 페이지 정보 저장
                            window.localStorage.setItem(
                              'pollCurrentPage',
                              page.toString(),
                            )
                            setCurrentPage(page)
                            setSelectedToonId(toon?._id)
                          }}
                        >
                          <img
                            src={
                              selectedToonId === toon?._id
                                ? '/images/toon/checkRed.png'
                                : '/images/toon/checkGray.png'
                            }
                            alt='poll'
                            className={`absolute top-[1vw] right-[1vw] z-10 ${
                              selectedToonId === toon?._id
                                ? 'w-[9vw] h-[9vw]'
                                : 'w-[10vw] h-[10vw]'
                            }`}
                          />
                          <MainToonComponent
                            toon={toon}
                            index={index}
                            type='pollEvent'
                            component='pollEventPage_toon'
                          />
                        </button>
                      ))}
                  </div>
                </div>
              ))}
          </div>
        </div>

        {/* Dot Indicator */}
        <div className='flex justify-center gap-2 mt-4'>
          {Array(getPageCount(toonsInfo || []))
            .fill()
            .map((_, index) => {
              const pageToonsKey = shuffledToonsInfo
                ?.slice(index * 4, (index + 1) * 4)
                .map((toon) => toon._id)
                .join('-')
              return (
                <div
                  key={`dot-${pageToonsKey}`}
                  className={`w-2 h-2 rounded-full ${
                    currentPage === index ? 'bg-[#FF3E3E]' : 'bg-gray-300'
                  }`}
                />
              )
            })}
        </div>
      </div>
      <div className='px-4 mt-6'>
        <button
          type='button'
          className={
            selectedToonId && !isPollSubmitted
              ? 'w-full py-4 bg-[#FF3E3E] rounded-lg font-bold text-[5vw] text-white'
              : 'w-full py-4 bg-[#E2E2E7] rounded-lg font-bold text-[5vw] text-[#9C9DA4]'
          }
          disabled={isPollSubmitted}
          onClick={async () => {
            if (!selectedToonId) {
              ToastStore.toastOn({
                type: 'emoji',
                emoji: '🎟️',
                message: '투표할 작품을 선택해주세요!',
                duration: 2000,
              })
            }
            if (selectedToonId) {
              const result = await backendApis.submitPoll(
                eventInfo?._id,
                selectedToonId,
              )
              if (result?.status === 200) {
                ToastStore.toastOn({
                  type: 'success',
                  message: '투표가 완료되었어요!',
                  duration: 2000,
                })
                window.localStorage.setItem('pollSubmitted', new Date())
                setIsPollSubmitted(true)
                setSelectedToonId(null)
              } else if (result?.status === 204) {
                ToastStore.toastOn({
                  type: 'error',
                  message: '오늘 이미 투표하셨어요!',
                  duration: 2000,
                })
              } else {
                ToastStore.toastOn({
                  type: 'error',
                  message:
                    '알 수 없는 오류가 발생했어요.\n 잠시후 다시 시도해주세요',
                  duration: 2000,
                })
              }
            }
          }}
        >
          {isPollSubmitted ? '오늘 투표 완료' : '투표하기'}
        </button>
      </div>
    </div>
  )
})

export default PollEventPage
