import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'

import altoonCMSBackendApis from '../../../utils/altoonCMSBackendApis'
import FormInput from './FormInput'

const EVENT_TYPES = [
  { value: 'basic', label: '기본이벤트' },
  { value: 'mission', label: '미션이벤트' },
  { value: 'themeSale', label: '기획전' },
  { value: 'timeSale', label: '타임특가' },
  { value: 'poll', label: '투표이벤트' },
  { value: 'leaderboard', label: '리더보드 이벤트' },
]

const ToonEventRegisterSection = observer(() => {
  const [activeTab, setActiveTab] = useState('basic')
  const [formData, setFormData] = useState({
    eventType: '',
    eventTitle: '',
    description: '',
    eventImageUri: '',
    backgroundColor: '',
    toonListTitle: '',
    toonIdList: '',
    novelIdList: '',
    notice: '',
    isDisplayed: true,
    displayStartedAt: '',
    displayEndedAt: '',
    priority: 0,
    missionInfo: {
      goodsName: '',
      goodsNameKor: '',
      iconUri: '',
      goodsNormalGifUri: '',
      goodsPopGifUri: '',
      keyColor: '',
      subColor: '',
      backgroundImageUri: '',
      notice: '',
    },
    themeSaleInfo: {
      keyColor: '',
      isMultiTab: false,
      columnNumber: 0,
      tabInfo: {
        tabCount: 0,
        tabTitle: [],
        tabToonIdList: [],
      },
    },
  })

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }))
  }

  const handleNestedInputChange = (parentField, field, value) => {
    setFormData((prev) => ({
      ...prev,
      [parentField]: {
        ...prev[parentField],
        [field]: value,
      },
    }))
  }

  const renderCommonFields = () => (
    <div className='grid grid-cols-2 gap-4'>
      <div className='space-y-4'>
        <div className='col-span-2'>
          <FormInput
            label='이벤트 제목'
            value={formData.eventTitle}
            onChange={(e) => handleInputChange('eventTitle', e.target.value)}
            id='event-title'
          />
        </div>

        <div className='col-span-4'>
          <FormInput
            label='우선순위'
            value={formData.priority}
            onChange={(e) =>
              handleInputChange('priority', Number(e.target.value))
            }
            type='number'
            id='event-priority'
          />
        </div>

        <div className='col-span-4'>
          <FormInput
            label='설명'
            value={formData.description}
            onChange={(e) => handleInputChange('description', e.target.value)}
            id='event-description'
          />
        </div>

        <div className='col-span-4'>
          <FormInput
            label='이벤트 이미지 URL'
            value={formData.eventImageUri}
            onChange={(e) => handleInputChange('eventImageUri', e.target.value)}
            id='event-image-uri'
          />
        </div>

        <div className='col-span-4'>
          <FormInput
            label='배경색'
            value={formData.backgroundColor}
            onChange={(e) =>
              handleInputChange('backgroundColor', e.target.value)
            }
            id='event-background-color'
          />
        </div>

        <div className='col-span-4'>
          <FormInput
            label='유의사항'
            value={formData.notice}
            onChange={(e) => handleInputChange('notice', e.target.value)}
            type='textarea'
            rows={3}
            id='event-notice'
          />
        </div>
      </div>
      <div className='space-y-4'>
        <div className='col-span-4'>
          <div className='flex items-center'>
            <div className='relative flex items-start'>
              <div className='mr-2 text-sm'>
                <span
                  id='event-is-displayed-label'
                  className='font-medium text-gray-900'
                >
                  노출 여부
                </span>
              </div>
              <div className='flex h-5 items-center'>
                <input
                  type='checkbox'
                  checked={formData.isDisplayed}
                  onChange={(e) =>
                    handleInputChange('isDisplayed', e.target.checked)
                  }
                  id='event-is-displayed'
                  name='event-is-displayed'
                  className='h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded'
                  aria-labelledby='event-is-displayed-label'
                />
              </div>
            </div>
          </div>
        </div>

        <div className='col-span-4'>
          <FormInput
            label='노출 시작일'
            value={formData.displayStartedAt}
            onChange={(e) =>
              handleInputChange('displayStartedAt', e.target.value)
            }
            type='datetime-local'
            id='event-display-started-at'
          />
        </div>

        <div className='col-span-4'>
          <FormInput
            label='노출 종료일'
            value={formData.displayEndedAt}
            onChange={(e) =>
              handleInputChange('displayEndedAt', e.target.value)
            }
            type='datetime-local'
            id='event-display-ended-at'
          />
        </div>

        <div className='col-span-4'>
          <FormInput
            label='작품목록 제목'
            value={formData.toonListTitle}
            onChange={(e) => handleInputChange('toonListTitle', e.target.value)}
            id='event-toon-list-title'
          />
        </div>

        <div className='col-span-4'>
          <FormInput
            label='작품 ID 목록'
            value={formData.toonIdList}
            onChange={(e) => handleInputChange('toonIdList', e.target.value)}
            type='textarea'
            rows={3}
            id='event-toon-id-list'
          />
        </div>

        <div className='col-span-4'>
          <FormInput
            label='웹소설 ID 목록'
            value={formData.novelIdList}
            onChange={(e) => handleInputChange('novelIdList', e.target.value)}
            type='textarea'
            rows={3}
            id='event-novel-id-list'
          />
        </div>
      </div>
    </div>
  )

  const renderMissionFields = () => (
    <div className='space-y-4'>
      <div className='col-span-2'>
        <FormInput
          label='재화명 (e.g. heart)'
          value={formData.missionInfo.goodsName}
          onChange={(e) =>
            handleNestedInputChange('missionInfo', 'goodsName', e.target.value)
          }
          id='mission-goods-name'
        />
      </div>

      <div className='col-span-2'>
        <FormInput
          label='재화명 (한글) (e.g. 하트)'
          value={formData.missionInfo.goodsNameKor}
          onChange={(e) =>
            handleNestedInputChange(
              'missionInfo',
              'goodsNameKor',
              e.target.value,
            )
          }
          id='mission-goods-name-kor'
        />
      </div>

      <div className='col-span-2'>
        <FormInput
          label='재화이미지(아이콘)'
          value={formData.missionInfo.iconUri}
          onChange={(e) =>
            handleNestedInputChange('missionInfo', 'iconUri', e.target.value)
          }
          id='mission-icon-uri'
        />
      </div>

      <div className='col-span-2'>
        <FormInput
          label='일반상태재화 GIF'
          value={formData.missionInfo.goodsNormalGifUri}
          onChange={(e) =>
            handleNestedInputChange(
              'missionInfo',
              'goodsNormalGifUri',
              e.target.value,
            )
          }
          id='mission-goods-normal-gif-uri'
        />
      </div>

      <div className='col-span-2'>
        <FormInput
          label='터지는재화 GIF'
          value={formData.missionInfo.goodsPopGifUri}
          onChange={(e) =>
            handleNestedInputChange(
              'missionInfo',
              'goodsPopGifUri',
              e.target.value,
            )
          }
          id='mission-goods-pop-gif-uri'
        />
      </div>

      <div className='col-span-2'>
        <FormInput
          label='주요 색상 (e.g. FE6564)'
          value={formData.missionInfo.keyColor}
          onChange={(e) =>
            handleNestedInputChange('missionInfo', 'keyColor', e.target.value)
          }
          id='mission-key-color'
        />
      </div>

      <div className='col-span-2'>
        <FormInput
          label='보조 색상 (e.g. F9C7C7)'
          value={formData.missionInfo.subColor}
          onChange={(e) =>
            handleNestedInputChange('missionInfo', 'subColor', e.target.value)
          }
          id='mission-sub-color'
        />
      </div>

      <div className='col-span-2'>
        <FormInput
          label='배경 이미지 URL'
          value={formData.missionInfo.backgroundImageUri}
          onChange={(e) =>
            handleNestedInputChange(
              'missionInfo',
              'backgroundImageUri',
              e.target.value,
            )
          }
          id='mission-background-image-uri'
        />
      </div>

      <div className='col-span-2'>
        <FormInput
          label='미션 유의사항'
          value={formData.missionInfo.notice}
          onChange={(e) =>
            handleNestedInputChange('missionInfo', 'notice', e.target.value)
          }
          type='textarea'
          rows={3}
          id='mission-notice'
        />
      </div>
    </div>
  )

  const renderThemeSaleFields = () => (
    <div className='space-y-4'>
      <div className='col-span-2'>
        <FormInput
          label='주요 색상 (e.g. FF3E3E)'
          value={formData.themeSaleInfo.keyColor}
          onChange={(e) =>
            handleNestedInputChange('themeSaleInfo', 'keyColor', e.target.value)
          }
          id='theme-sale-keyColor'
        />
      </div>
      <div className='col-span-2'>
        <FormInput
          label='열 개수'
          value={formData.themeSaleInfo.columnNumber}
          onChange={(e) =>
            handleNestedInputChange(
              'themeSaleInfo',
              'columnNumber',
              e.target.value,
            )
          }
          id='theme-sale-columnNumber'
        />
      </div>

      <div className='col-span-2'>
        <FormInput
          label='다중탭 여부'
          type='checkbox'
          value={formData.themeSaleInfo.isMultiTab}
          onChange={(e) =>
            handleNestedInputChange(
              'themeSaleInfo',
              'isMultiTab',
              e.target.checked,
            )
          }
          id='theme-sale-isMultiTab'
        />
      </div>

      {formData.themeSaleInfo.isMultiTab && (
        <div className='col-span-2 space-y-4'>
          <div className='flex justify-between items-center'>
            <h3 className='text-lg font-medium text-gray-900'>탭 관리</h3>
            <button
              type='button'
              onClick={() => {
                const newTabCount = formData.themeSaleInfo.tabInfo.tabCount + 1
                if (newTabCount <= 5) {
                  handleNestedInputChange('themeSaleInfo', 'tabInfo', {
                    ...formData.themeSaleInfo.tabInfo,
                    tabCount: newTabCount,
                    tabTitle: [...formData.themeSaleInfo.tabInfo.tabTitle, ''],
                    tabToonIdList: [
                      ...formData.themeSaleInfo.tabInfo.tabToonIdList,
                      [],
                    ],
                  })
                }
              }}
              className='px-3 py-1 text-sm bg-blue-600 text-white rounded-md hover:bg-blue-700'
            >
              탭 추가
            </button>
          </div>

          {Array.from({ length: formData.themeSaleInfo.tabInfo.tabCount }).map(
            (_, index) => (
              <div
                key={index}
                className='p-4 border border-gray-200 rounded-lg space-y-4'
              >
                <div className='flex justify-between items-center'>
                  <h4 className='text-md font-medium text-gray-900'>
                    탭 {index + 1}
                  </h4>
                  <button
                    type='button'
                    onClick={() => {
                      const newTabTitles = [
                        ...formData.themeSaleInfo.tabInfo.tabTitle,
                      ]
                      const newTabToonIdLists = [
                        ...formData.themeSaleInfo.tabInfo.tabToonIdList,
                      ]
                      newTabTitles.splice(index, 1)
                      newTabToonIdLists.splice(index, 1)

                      handleNestedInputChange('themeSaleInfo', 'tabInfo', {
                        ...formData.themeSaleInfo.tabInfo,
                        tabCount: formData.themeSaleInfo.tabInfo.tabCount - 1,
                        tabTitle: newTabTitles,
                        tabToonIdList: newTabToonIdLists,
                      })
                    }}
                    className='px-2 py-1 text-sm bg-red-600 text-white rounded-md hover:bg-red-700'
                  >
                    탭 삭제
                  </button>
                </div>

                <FormInput
                  label='탭 제목'
                  value={formData.themeSaleInfo.tabInfo.tabTitle[index] || ''}
                  onChange={(e) => {
                    const newTabTitles = [
                      ...formData.themeSaleInfo.tabInfo.tabTitle,
                    ]
                    newTabTitles[index] = e.target.value
                    handleNestedInputChange('themeSaleInfo', 'tabInfo', {
                      ...formData.themeSaleInfo.tabInfo,
                      tabTitle: newTabTitles,
                    })
                  }}
                  id={`theme-sale-tab-title-${index}`}
                />

                <FormInput
                  label='작품 ID 목록 (줄바꿈으로 구분)'
                  type='textarea'
                  value={
                    formData.themeSaleInfo.tabInfo.tabToonIdList[index] || ''
                  }
                  onChange={(e) => {
                    const newTabToonIdLists = [
                      ...formData.themeSaleInfo.tabInfo.tabToonIdList,
                    ]
                    newTabToonIdLists[index] = e.target.value

                    handleNestedInputChange('themeSaleInfo', 'tabInfo', {
                      ...formData.themeSaleInfo.tabInfo,
                      tabToonIdList: newTabToonIdLists,
                    })
                  }}
                  id={`theme-sale-tab-toon-ids-${index}`}
                  rows={5}
                  placeholder='작품 ID를 줄바꿈으로 구분하여 입력하세요'
                />
              </div>
            ),
          )}
        </div>
      )}
    </div>
  )

  const renderEventFields = () => {
    switch (activeTab) {
      case 'basic':
        return renderCommonFields()
      case 'mission':
        return (
          <>
            {renderCommonFields()}
            <div className='h-10' />
            {renderMissionFields()}
          </>
        )
      case 'themeSale':
        return (
          <>
            {renderCommonFields()}
            <div className='h-10' />
            {renderThemeSaleFields()}
          </>
        )
      case 'timeSale':
        return <>{renderCommonFields()}</>
      case 'poll':
        return <>{renderCommonFields()}</>
      case 'leaderboard':
        return <>{renderCommonFields()}</>
      default:
        return null
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const baseData = {
      type: formData.eventType,
      description: formData.description,
      eventTitle: formData.eventTitle,
      backgroundColor: formData.backgroundColor,
      toonListTitle: formData.toonListTitle,
      imageUri: formData.eventImageUri,
      toonIdList: formData.toonIdList,
      novelIdList: formData.novelIdList,
      isDisplayed: formData.isDisplayed,
      displayStartedAt: formData.displayStartedAt,
      displayEndedAt: formData.displayEndedAt,
      priority: formData.priority,
      notice: formData.notice,
    }

    let eventData = baseData

    switch (formData.eventType) {
      case 'mission':
        eventData = {
          ...baseData,
          missionInfo: formData.missionInfo,
        }
        break
      case 'themeSale':
        // API 호출 전에 tabToonIdList의 각 요소를 배열로 변환
        const processedThemeSaleInfo = {
          ...formData.themeSaleInfo,
          tabInfo: {
            ...formData.themeSaleInfo.tabInfo,
            tabToonIdList: formData.themeSaleInfo.tabInfo.tabToonIdList.map(
              (value) => value.split('\n').filter((id) => id.trim()),
            ),
          },
        }
        eventData = {
          ...baseData,
          themeSaleInfo: processedThemeSaleInfo,
        }
        break
      case 'timeSale':
        eventData = {
          ...baseData,
          timeSaleInfo: formData.timeSaleInfo,
        }
        break
      case 'poll':
        eventData = {
          ...baseData,
          pollInfo: formData.pollInfo,
        }
        break
      case 'leaderboard':
        eventData = {
          ...baseData,
          leaderboardInfo: formData.leaderboardInfo,
        }
        break
      default:
        eventData = baseData
    }

    const result = await altoonCMSBackendApis.registerToonPromotion(eventData)
    if (result?.status === 200) {
      alert('이벤트 등록 성공')
    } else {
      alert('이벤트 등록 실패')
    }
    console.log('Form submitted:', eventData)
  }

  return (
    <form onSubmit={handleSubmit} className='space-y-6'>
      {/* 탭 네비게이션 */}
      <div className='border-b border-gray-200'>
        <nav className='-mb-px flex space-x-8' aria-label='Tabs'>
          {EVENT_TYPES.map((type) => (
            <button
              key={type.value}
              type='button'
              onClick={() => {
                setActiveTab(type.value)
                handleInputChange('eventType', type.value)
              }}
              className={`${
                activeTab === type.value
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              } whitespace-nowrap py-4 px-1 border-b-2`}
            >
              {type.label}
            </button>
          ))}
        </nav>
      </div>

      {/* 폼 필드 */}
      <div>{renderEventFields()}</div>

      {/* 제출 버튼 */}
      <button
        type='submit'
        className='w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
      >
        이벤트 등록
      </button>
    </form>
  )
})

export default ToonEventRegisterSection
