import { observer } from 'mobx-react-lite'
import moment from 'moment-timezone'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

import altoonCMSBackendApis from '../../../utils/altoonCMSBackendApis'
import SearchForm from '../SearchForm'
import PromotionEditor from './PromotionEditor'

moment.tz.setDefault('Asia/Seoul')

// TagEditor 컴포넌트 추가
const TagEditor = ({ tagList, setTagList, isReadOnly = false }) => {
  const [mainGenreInput, setMainGenreInput] = useState('')
  const [subGenreInput, setSubGenreInput] = useState('')

  const handleAddMainGenre = () => {
    if (mainGenreInput.trim()) {
      setTagList({
        ...tagList,
        mainGenre: [...tagList.mainGenre, mainGenreInput.trim()],
      })
      setMainGenreInput('')
    }
  }

  const handleAddSubGenre = () => {
    if (subGenreInput.trim()) {
      setTagList({
        ...tagList,
        subGenre: [...tagList.subGenre, subGenreInput.trim()],
      })
      setSubGenreInput('')
    }
  }

  const handleKeyPress = (e, addFunction) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      addFunction()
    }
  }

  if (isReadOnly) {
    return (
      <table className='w-full border-collapse border border-gray-300'>
        <tbody>
          <tr className='border-b border-gray-300'>
            <td className='p-2 border-r border-gray-300'>
              <span className='font-bold'>메인 장르</span>
            </td>
            <td className='p-2'>{tagList?.mainGenre?.join(', ') || '없음'}</td>
          </tr>
          <tr className='border-b border-gray-300'>
            <td className='p-2 border-r border-gray-300'>
              <span className='font-bold'>서브 장르</span>
            </td>
            <td className='p-2'>{tagList?.subGenre?.join(', ') || '없음'}</td>
          </tr>
          <tr className='border-b border-gray-300'>
            <td className='p-2 border-r border-gray-300'>
              <span className='font-bold'>신작</span>
            </td>
            <td className='p-2'>{tagList?.new ? 'O' : ''}</td>
          </tr>
          <tr>
            <td className='p-2 border-r border-gray-300'>
              <span className='font-bold'>인기</span>
            </td>
            <td className='p-2'>{tagList?.hot ? 'O' : ''}</td>
          </tr>
        </tbody>
      </table>
    )
  }

  return (
    <div className='space-y-4'>
      <div>
        <div className='font-bold mb-2'>메인 장르</div>
        <div className='space-y-2'>
          <div className='flex items-center space-x-2'>
            <input
              type='text'
              value={mainGenreInput}
              onChange={(e) => setMainGenreInput(e.target.value)}
              onKeyPress={(e) => handleKeyPress(e, handleAddMainGenre)}
              className='flex-1 p-2 border rounded'
              placeholder='메인 장르 입력 후 Enter 또는 추가 버튼 클릭'
            />
            <button
              type='button'
              onClick={handleAddMainGenre}
              className='px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600'
            >
              추가
            </button>
          </div>

          {tagList.mainGenre.map((genre, index) => (
            <div
              key={`main-genre-${index}`}
              className='flex items-center space-x-2'
            >
              <div className='flex-1 p-2 border rounded bg-gray-50'>
                {genre}
              </div>
              <button
                type='button'
                onClick={() => {
                  const newGenres = [...tagList.mainGenre]
                  newGenres.splice(index, 1)
                  setTagList({
                    ...tagList,
                    mainGenre: newGenres,
                  })
                }}
                className='px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600'
              >
                삭제
              </button>
            </div>
          ))}
        </div>
      </div>
      <div>
        <div className='font-bold mb-2'>서브 장르</div>
        <div className='space-y-2'>
          <div className='flex items-center space-x-2'>
            <input
              type='text'
              value={subGenreInput}
              onChange={(e) => setSubGenreInput(e.target.value)}
              onKeyPress={(e) => handleKeyPress(e, handleAddSubGenre)}
              className='flex-1 p-2 border rounded'
              placeholder='서브 장르 입력 후 Enter 또는 추가 버튼 클릭'
            />
            <button
              type='button'
              onClick={handleAddSubGenre}
              className='px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600'
            >
              추가
            </button>
          </div>

          {tagList.subGenre.map((genre, index) => (
            <div
              key={`sub-genre-${index}`}
              className='flex items-center space-x-2'
            >
              <div className='flex-1 p-2 border rounded bg-gray-50'>
                {genre}
              </div>
              <button
                type='button'
                onClick={() => {
                  const newGenres = [...tagList.subGenre]
                  newGenres.splice(index, 1)
                  setTagList({
                    ...tagList,
                    subGenre: newGenres,
                  })
                }}
                className='px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600'
              >
                삭제
              </button>
            </div>
          ))}
        </div>
      </div>
      <div className='flex space-x-4'>
        <label
          className='flex items-center space-x-2'
          htmlFor='tag-new-checkbox'
        >
          <input
            type='checkbox'
            checked={tagList.new}
            onChange={(e) => {
              setTagList({
                ...tagList,
                new: e.target.checked,
              })
            }}
            className='form-checkbox h-5 w-5'
            id='tag-new-checkbox'
          />
          <span>신작</span>
        </label>
        <label
          className='flex items-center space-x-2'
          htmlFor='tag-hot-checkbox'
        >
          <input
            type='checkbox'
            checked={tagList.hot}
            onChange={(e) => {
              setTagList({
                ...tagList,
                hot: e.target.checked,
              })
            }}
            className='form-checkbox h-5 w-5'
            id='tag-hot-checkbox'
          />
          <span>인기</span>
        </label>
      </div>
    </div>
  )
}

const ToonInfoManagement = observer(({ setIsLoading }) => {
  const [activeSection, setActiveSection] = useState('search')
  const [filterCurrentToonData, setFilterCurrentToonData] = useState(false)
  const [filterPromotionData, setFilterPromotionData] = useState(false)
  const [tempData, setTempData] = useState([])
  const [promotionToonData, setPromotionToonData] = useState()
  const [sortBy, setSortBy] = useState('priority')
  const [sortOrder, setSortOrder] = useState('asc')
  const [isReordering, setIsReordering] = useState(false)
  const [activeSearch, setActiveSearch] = useState({ query: '', type: 'title' })

  // 작품 등록 관련 state
  const [title, setTitle] = useState('')
  const [author, setAuthor] = useState('')
  const [displayStartedAt, setDisplayStartedAt] = useState('') // 공개 시작 날짜 추가
  const [thumbnail, setThumbnail] = useState('')
  const [description, setDescription] = useState('')
  const [toonType, setToonType] = useState('')
  const [genre, setGenre] = useState('')
  const [providerId, setProviderId] = useState('')
  const [providerDisplayName, setProviderDisplayName] = useState('')
  const [settlementFeeRate, setSettlementFeeRate] = useState('')
  const [verticalThumbnail, setVerticalThumbnail] = useState(false)
  const [weekday, setWeekday] = useState([])
  const [tagList, setTagList] = useState({
    mainGenre: [],
    subGenre: [],
    new: false,
    hot: false,
  })
  const [providerList, setProviderList] = useState([])
  // 상태 정보 관련 state 추가
  const [isDisplayed, setIsDisplayed] = useState(true)
  const [isAdmin, setIsAdmin] = useState(false)
  const [isAdultOnly, setIsAdultOnly] = useState(false) // 성인 여부 state 추가
  const [priority, setPriority] = useState(0)
  const [toonPrice, setToonPrice] = useState(0)
  const [notice, setNotice] = useState('')
  const [bestComment, setBestComment] = useState('')
  const [promotionInfo, setPromotionInfo] = useState({})
  const [previewDays, setPreviewDays] = useState(0) // 미리보기 기간 state 추가

  // 페이지네이션 관련 state
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(30)
  const [selectedToon, setSelectedToon] = useState(null)
  const [showModal, setShowModal] = useState(false)

  const selectedGenres = 'hot'

  useEffect(() => {
    if (activeSection === 'search') {
      fetchData()
      loadPromotionToons()
    } else if (activeSection === 'create') {
      loadProviderList()
    }
  }, [activeSection, filterCurrentToonData, filterPromotionData])

  const loadPromotionToons = async () => {
    const promotionData = await altoonCMSBackendApis.loadRecommendingToons({
      selectedGenres,
    })
    if (promotionData?.status === 200) {
      setPromotionToonData(promotionData?.data)
    }
  }

  const loadProviderList = async () => {
    const tempToken = localStorage.getItem('@altoon@provider@token@')
    const result = await altoonCMSBackendApis.loadProviderList(
      tempToken ?? localStorage.getItem('@altoon@token@'),
    )
    if (result?.status === 200) {
      setProviderList(result?.data)
    }
  }

  const fetchData = async () => {
    setIsLoading(true)
    const result = await altoonCMSBackendApis.getToonData()
    if (result?.status === 200) {
      let filteredData = result?.data

      if (filterCurrentToonData) {
        filteredData = filteredData.filter(
          (item) => item.isDisplayed === true && item.isAdmin !== true,
        )
      }

      if (filterPromotionData && promotionToonData) {
        filteredData = filteredData.filter((item) =>
          promotionToonData.some(
            (e) => e._id?.toString() === item._id?.toString(),
          ),
        )
      }

      setTempData(filteredData)
    }
    setIsLoading(false)
  }

  const getSortedData = (data) => {
    if (!data) return []

    return [...data].sort((a, b) => {
      let comparison = 0
      switch (sortBy) {
        case 'createdAt':
          comparison =
            moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()
          break
        case 'priority':
          comparison = (b.priority || 0) - (a.priority || 0)
          break
        case 'publishedAt':
          comparison =
            moment(b.publishedAt || 0).valueOf() -
            moment(a.publishedAt || 0).valueOf()
          break
        case 'displayStartedAt':
          comparison =
            moment(b.displayStartedAt || 0).valueOf() -
            moment(a.displayStartedAt || 0).valueOf()
          break
        default:
          comparison = 0
      }
      return sortOrder === 'asc' ? -comparison : comparison
    })
  }

  const getFilteredData = (data) => {
    if (!activeSearch.query || !data) return data

    return data.filter((item) => {
      const query = activeSearch.query.toLowerCase()
      switch (activeSearch.type) {
        case 'title':
          return item.title?.toLowerCase().includes(query)
        case 'id':
          return item._id?.toString().includes(query)
        case 'provider':
          return item.providerInfo?.providerDisplayName
            ?.toLowerCase()
            .includes(query)
        default:
          return true
      }
    })
  }

  const onDragEnd = async (result) => {
    if (!result.destination) return

    const items = Array.from(getSortedData(tempData))
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    const aboveItemPriority =
      result.destination.index > 0
        ? items[result.destination.index - 1].priority || 0
        : 0

    const newPriority = aboveItemPriority + 1

    const updateResult = await altoonCMSBackendApis.updateToonInfo({
      toonId: reorderedItem._id,
      field: 'priority',
      value: newPriority,
    })

    if (updateResult?.status === 200) {
      const updatedItems = items.map((item) =>
        item._id === reorderedItem._id
          ? { ...item, priority: newPriority }
          : item,
      )
      setTempData(updatedItems)
    } else {
      alert('순서 변경에 실패했습니다.')
    }
  }

  const updateAllPriorities = async () => {
    setIsLoading(true)

    const sortedData = getSortedData(tempData)
    let success = true
    const updatedItems = [...sortedData]

    for (let i = 0; i < sortedData.length; i += 1) {
      const newPriority = (i + 1) * 10

      const result = await altoonCMSBackendApis.updateToonInfo({
        toonId: sortedData[i]._id,
        field: 'priority',
        value: newPriority,
      })

      if (result?.status !== 200) {
        success = false
        break
      }

      updatedItems[i] = { ...updatedItems[i], priority: newPriority }
    }

    if (success) {
      setTempData(updatedItems)
      alert('모든 작품의 순서가 업데이트되었습니다.')
    } else {
      alert('순서 업데이트 중 오류가 발생했습니다.')
    }

    setIsReordering(false)
    setIsLoading(false)
  }

  const handleRegisterToon = async () => {
    if (
      !title ||
      !author ||
      !displayStartedAt ||
      !thumbnail ||
      !description ||
      !toonType ||
      !genre ||
      !providerId ||
      !settlementFeeRate ||
      weekday === undefined ||
      weekday === '' // weekday가 null인 경우는 유효함
    ) {
      alert('모든 필수 필드를 입력해주세요.')
      return
    }

    try {
      setIsLoading(true)
      const result = await altoonCMSBackendApis.registerToon({
        title,
        author,
        displayStartedAt,
        thumbnail,
        description,
        toonType,
        genre,
        providerId,
        providerDisplayName,
        settlementFeeRate: Number(settlementFeeRate),
        verticalThumbnail,
        weekday,
        tagList,
        isDisplayed,
        isAdmin,
        isAdultOnly,
        priority: Number(priority),
        toonPrice: Number(toonPrice),
        notice,
        bestComment,
        promotionInfo, // 프로모션 정보 추가
      })

      if (result?.status === 200) {
        alert('등록되었습니다.')
        setTitle('')
        setAuthor('')
        setDisplayStartedAt('') // 공개 시작 날짜 초기화
        setThumbnail('')
        setDescription('')
        setToonType('')
        setGenre('')
        setProviderId('')
        setProviderDisplayName('')
        setSettlementFeeRate('')
        setVerticalThumbnail(false)
        setWeekday([])
        setTagList({ mainGenre: [], subGenre: [], new: false, hot: false })
        setIsDisplayed(true)
        setIsAdmin(false)
        setIsAdultOnly(false) // 성인 여부 초기화 추가
        setPriority(0)
        setToonPrice(0)
        setNotice('')
        setBestComment('')
        setPromotionInfo('')
        setPreviewDays(0) // 미리보기 기간 초기화
        setActiveSection('search')
        fetchData()
      } else if (result?.status === 204) {
        alert(result?.data)
      } else {
        alert('오류가 발생하였습니다. 오기입 위험이 있으니 DB를 확인해주세요.')
      }
    } catch (error) {
      alert('오류가 발생했습니다. 오기입 위험이 있으니 DB를 확인해주세요.')
    }
    setIsLoading(false)
  }

  const handleRowClick = (toon) => {
    setSelectedToon(toon)
    setShowModal(true)
  }

  const ToonDetailModal = ({ toon, onClose }) => {
    const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [editedData, setEditedData] = useState({})
    const [validationErrors, setValidationErrors] = useState({})
    const [tagList, setTagList] = useState({
      mainGenre: [],
      subGenre: [],
      new: false,
      hot: false,
    })

    if (!toon) return null

    useEffect(() => {
      document.body.style.overflow = 'hidden'
      return () => {
        document.body.style.overflow = 'unset'
      }
    }, [])

    // 태그 정보 초기화
    useEffect(() => {
      if (toon.tagList) {
        setTagList({
          mainGenre: toon.tagList.mainGenre || [],
          subGenre: toon.tagList.subGenre || [],
          new: toon.tagList.new || false,
          hot: toon.tagList.hot || false,
        })
      }
    }, [toon.tagList])

    const handleBackgroundClick = (e) => {
      if (e.target === e.currentTarget) {
        onClose()
      }
    }

    const validateField = (field, value) => {
      // _id, createdAt, promotionInfo, notice 필드는 검증 제외
      if (
        field === 'notice' ||
        field === '_id' ||
        field === 'createdAt' ||
        field === 'promotionInfo'
      )
        return null
      if (value === undefined || value === '') return '필수 입력 항목입니다.'

      switch (field) {
        case 'title':
          if (value.length < 2) return '제목은 2자 이상이어야 합니다.'
          break
        case 'author':
          if (value.length < 2) return '작가명은 2자 이상이어야 합니다.'
          break
        case 'toonPrice':
          if (Number.isNaN(Number(value)) || Number(value) < 0)
            return '0 이상의 숫자만 입력 가능합니다.'
          break
        case 'priority':
          if (Number.isNaN(Number(value)) || Number(value) < 0)
            return '0 이상의 숫자만 입력 가능합니다.'
        case 'previewDays':
          if (Number.isNaN(Number(value)) || Number(value) < 0)
            return '0 이상의 정수만 입력 가능합니다.'
          break
        case 'settlementFeeRate':
          if (isNaN(value) || value < 0 || value > 1)
            return '0~1 사이의 숫자만 입력 가능합니다.'
          break
        case 'genre':
          if (
            ![
              'romance',
              'romanceFantasy',
              'BL',
              'instatoon',
              'drama',
              'daily',
              'comedy',
              'action',
              'thriller',
            ].includes(value)
          )
            return '유효한 장르를 선택해주세요.'
          break
        case 'toonType':
          if (!['VerticalScroll', 'HorizontalScroll'].includes(value))
            return '유효한 작품 형태를 선택해주세요.'
          break
        case 'displayStartedAt':
          if (!moment(value).isValid()) {
            return '유효한 날짜를 입력해주세요.'
          }
          break
        case 'weekday':
          if (
            value !== null &&
            (!Array.isArray(value) || value.length === 0) &&
            !Array.isArray(value)
          )
            return '연재 요일을 선택해주세요.'
          const validDays = [
            'Mon',
            'Tue',
            'Wed',
            'Thu',
            'Fri',
            'Sat',
            'Sun',
            'Free',
          ]
          if (
            value !== null &&
            Array.isArray(value) &&
            value.some((day) => !validDays.includes(day))
          )
            return '유효하지 않은 연재 요일이 포함되어 있습니다.'
          break
      }
      return null
    }

    const handleEdit = (field, value) => {
      const error = validateField(field, value)
      setValidationErrors((prev) => ({
        ...prev,
        [field]: error,
      }))

      setEditedData((prev) => ({
        ...prev,
        [field]: value,
      }))
    }

    const handleSave = async () => {
      const hasErrors = Object.values(validationErrors).some(
        (error) => error !== null,
      )
      if (hasErrors) {
        alert('입력값을 확인해주세요.')
        return
      }

      setIsLoading(true)

      // 변경된 필드만 업데이트 요청
      for (const [field, value] of Object.entries(editedData)) {
        // _id, createdAt, promotionInfo 필드는 수정 제외
        if (
          field === 'promotionInfo' ||
          field === '_id' ||
          field === 'createdAt'
        )
          continue

        let processedValue = value
        // 날짜 형식이나 숫자 형식의 데이터 처리
        if (field === 'displayStartedAt') {
          processedValue = moment(value).format()
        } else if (field === 'priority' || field === 'toonPrice') {
          processedValue = Number(value)
        } else if (field === 'settlementFeeRate') {
          processedValue = parseFloat(value)
        } else if (typeof value === 'string') {
          processedValue = value.trim()
        }

        const result = await altoonCMSBackendApis.updateToonInfo({
          toonId: toon._id,
          field,
          value: JSON.stringify(processedValue),
          isDate: field === 'displayStartedAt',
        })

        if (result?.status !== 200) {
          alert(`${field} 필드 업데이트 실패`)
          setIsLoading(false)
          return
        }
      }

      // tagList 업데이트
      if (JSON.stringify(tagList) !== JSON.stringify(toon.tagList)) {
        const result = await altoonCMSBackendApis.updateToonInfo({
          toonId: toon._id,
          field: 'tagList',
          value: JSON.stringify(tagList),
          isDate: false,
        })

        if (result?.status !== 200) {
          alert('태그 정보 업데이트 실패')
          setIsLoading(false)
          return
        }
      }

      alert('수정이 완료되었습니다.')
      fetchData() // 데이터 새로고침
      onClose()
      setIsLoading(false)
    }

    const renderEditableField = (label, field, value, type = 'text') => {
      if (field === 'promotionInfo') {
        return (
          <tr>
            <td className='px-4 py-2 font-medium'>프로모션 정보</td>
            <td className='px-4 py-2'>
              <PromotionEditor
                promotionInfo={editedData[field] ?? value}
                setPromotionInfo={(newValue) => handleEdit(field, newValue)}
                isReadOnly={!isEditing}
                toonId={toon._id}
                handleSave={handleSave}
              />
            </td>
          </tr>
        )
      }

      // 프로모션 정보, _id, createdAt은 수정 불가
      if (
        // field === 'promotionInfo' ||
        field === '_id' ||
        field === 'createdAt'
      ) {
        return (
          <tr>
            <td className='px-4 py-2 font-medium'>{label}</td>
            <td className='px-4 py-2'>{value}</td>
          </tr>
        )
      }

      return (
        <tr>
          <td className='px-4 py-2 font-medium'>{label}</td>
          <td className='px-4 py-2'>
            {isEditing ? (
              <div>
                {type === 'checkbox' ? (
                  <input
                    type='checkbox'
                    checked={editedData[field] ?? value}
                    onChange={(e) => handleEdit(field, e.target.checked)}
                    className='form-checkbox h-5 w-5'
                  />
                ) : type === 'textarea' ? (
                  <textarea
                    value={editedData[field] ?? value}
                    onChange={(e) => handleEdit(field, e.target.value)}
                    className='w-full p-2 border rounded'
                    rows={4}
                  />
                ) : type === 'select-genre' ? (
                  <select
                    value={editedData[field] ?? value}
                    onChange={(e) => handleEdit(field, e.target.value)}
                    className='w-full p-2 border rounded'
                  >
                    {[
                      'romance',
                      'romanceFantasy',
                      'BL',
                      'instatoon',
                      'drama',
                      'daily',
                      'comedy',
                      'action',
                      'thriller',
                    ].map((genre) => (
                      <option key={genre} value={genre}>
                        {genre}
                      </option>
                    ))}
                  </select>
                ) : type === 'select-toonType' ? (
                  <select
                    value={editedData[field] ?? value}
                    onChange={(e) => handleEdit(field, e.target.value)}
                    className='w-full p-2 border rounded'
                  >
                    {['VerticalScroll', 'HorizontalScroll'].map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                ) : type === 'tagList' ? (
                  <TagEditor tagList={tagList} setTagList={setTagList} />
                ) : type === 'select-weekday' ? (
                  <div className='flex flex-wrap gap-2'>
                    <label className='flex items-center space-x-2 mb-2 w-full'>
                      <input
                        type='checkbox'
                        checked={editedData[field] === null}
                        onChange={(e) => {
                          if (e.target.checked) {
                            handleEdit(field, null)
                          } else {
                            handleEdit(field, [])
                          }
                        }}
                        className='form-checkbox h-4 w-4'
                      />
                      <span>연재 요일 없음</span>
                    </label>
                    <label className='flex items-center space-x-2 mb-2 w-full'>
                      <input
                        type='checkbox'
                        checked={
                          editedData[field] !== undefined
                            ? Array.isArray(editedData[field]) &&
                              editedData[field][0] === 'Free'
                            : Array.isArray(value) && value[0] === 'Free'
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            handleEdit(field, ['Free'])
                          } else {
                            handleEdit(field, [])
                          }
                        }}
                        className='form-checkbox h-4 w-4'
                      />
                      <span>자유연재</span>
                    </label>
                    <table className='w-full border-collapse'>
                      <tbody>
                        <tr>
                          {[
                            { value: 'Mon', label: '월요일' },
                            { value: 'Tue', label: '화요일' },
                            { value: 'Wed', label: '수요일' },
                            { value: 'Thu', label: '목요일' },
                            { value: 'Fri', label: '금요일' },
                            { value: 'Sat', label: '토요일' },
                            { value: 'Sun', label: '일요일' },
                          ].map((day) => (
                            <td key={day.value} className='text-center p-2'>
                              <label className='flex flex-col items-center space-y-1'>
                                <input
                                  type='checkbox'
                                  checked={
                                    editedData[field] === null
                                      ? false
                                      : Array.isArray(editedData[field])
                                      ? editedData[field].includes(day.value)
                                      : Array.isArray(value)
                                      ? value.includes(day.value)
                                      : false
                                  }
                                  onChange={(e) => {
                                    const currentValue = Array.isArray(
                                      editedData[field],
                                    )
                                      ? editedData[field]
                                      : Array.isArray(value)
                                      ? [...value]
                                      : []

                                    let newValue
                                    if (e.target.checked) {
                                      newValue = [...currentValue, day.value]
                                      handleEdit(field, newValue)
                                    } else {
                                      newValue = currentValue.filter(
                                        (v) => v !== day.value,
                                      )
                                      handleEdit(
                                        field,
                                        newValue.length > 0 ? newValue : null,
                                      )
                                    }
                                  }}
                                  className='form-checkbox h-4 w-4'
                                  disabled={editedData[field] === null}
                                />
                                <span>{day.label}</span>
                              </label>
                            </td>
                          ))}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <input
                    type={type}
                    value={editedData[field] ?? value}
                    onChange={(e) => handleEdit(field, e.target.value)}
                    className='w-full p-2 border rounded'
                  />
                )}
                {validationErrors[field] && (
                  <div className='text-red-500 text-sm mt-1'>
                    {validationErrors[field]}
                  </div>
                )}
              </div>
            ) : type === 'select-weekday' ? (
              value === null ? (
                '연재 요일 없음'
              ) : Array.isArray(value) && value[0] === 'Free' ? (
                '자유연재'
              ) : Array.isArray(value) ? (
                value
                  .map((day) => {
                    const dayMap = {
                      Mon: '월요일',
                      Tue: '화요일',
                      Wed: '수요일',
                      Thu: '목요일',
                      Fri: '금요일',
                      Sat: '토요일',
                      Sun: '일요일',
                    }
                    return dayMap[day]
                  })
                  .join(', ')
              ) : (
                ''
              )
            ) : type === 'tagList' ? (
              <TagEditor tagList={value} isReadOnly={true} />
            ) : (
              value
            )}
          </td>
        </tr>
      )
    }

    const handleDelete = async () => {
      if (
        window.confirm(
          '정말로 이 작품을 삭제하시겠습니까? 이 작업은 되돌릴 수 없습니다.',
        )
      ) {
        setIsLoading(true)
        const result = await altoonCMSBackendApis.deleteToon(toon._id)
        setIsLoading(false)

        if (result?.status === 200) {
          alert('작품이 성공적으로 삭제되었습니다.')
          onClose()
          fetchData() // 목록 새로고침
        } else {
          alert('작품 삭제 중 오류가 발생했습니다.')
        }
      }
    }

    return (
      <div
        className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-4 overflow-y-auto'
        onClick={handleBackgroundClick}
      >
        <div className='bg-white rounded-xl p-6 w-[80vw] max-w-[1200px] max-h-[90vh] overflow-y-auto ml-[15vw]'>
          <div className='flex justify-between items-center mb-4'>
            <h2 className='text-2xl font-bold'>{toon.title}</h2>
            <button
              type='button'
              onClick={onClose}
              className='text-gray-500 hover:text-gray-700 text-3xl font-bold w-10 h-10 flex items-center justify-center'
            >
              ✕
            </button>
          </div>
          <div className='flex flex-col gap-6'>
            <div className='grid grid-cols-3 gap-6'>
              <div className='col-span-1'>
                {isEditing ? (
                  <div className='space-y-4'>
                    <div>
                      <label className='block mb-2'>썸네일 URL</label>
                      <input
                        type='text'
                        value={editedData.thumbnail ?? toon.thumbnail}
                        onChange={(e) =>
                          handleEdit('thumbnail', e.target.value)
                        }
                        className='w-full p-2 border rounded'
                      />
                    </div>
                    <div className='flex items-center space-x-2'>
                      <input
                        type='checkbox'
                        checked={
                          editedData.verticalThumbnail ?? toon.verticalThumbnail
                        }
                        onChange={(e) =>
                          handleEdit('verticalThumbnail', e.target.checked)
                        }
                        className='form-checkbox h-5 w-5'
                      />
                      <label>세로형 썸네일</label>
                    </div>
                  </div>
                ) : (
                  <img
                    src={toon.thumbnail}
                    alt={toon.title}
                    className='w-full h-auto object-cover rounded shadow-lg'
                  />
                )}
                <div className='mt-4 border rounded-lg'>
                  <div className='px-4 py-2 bg-gray-50 font-semibold'>
                    작품 설명
                  </div>
                  <div className='p-4'>
                    {isEditing ? (
                      <textarea
                        value={editedData.description ?? toon.description}
                        onChange={(e) =>
                          handleEdit('description', e.target.value)
                        }
                        className='w-full p-2 border rounded'
                        rows={6}
                      />
                    ) : (
                      <>
                        <div
                          className={`whitespace-pre-wrap ${
                            !isDescriptionExpanded ? 'line-clamp-3' : ''
                          }`}
                        >
                          {toon.description}
                        </div>
                        <button
                          type='button'
                          onClick={() =>
                            setIsDescriptionExpanded(!isDescriptionExpanded)
                          }
                          className='text-blue-500 hover:text-blue-700 mt-2'
                        >
                          {isDescriptionExpanded ? '접기' : '더 보기'}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className='col-span-2 space-y-6'>
                <div className='border rounded-lg overflow-hidden'>
                  <table className='w-full'>
                    <thead className='bg-gray-50'>
                      <tr>
                        <th
                          className='px-4 py-2 text-left font-semibold'
                          colSpan='2'
                        >
                          기본 정보
                        </th>
                      </tr>
                    </thead>
                    <tbody className='divide-y'>
                      {renderEditableField('작품 ID', '_id', toon._id)}
                      {renderEditableField('제목 *', 'title', toon.title)}
                      {renderEditableField('작가 *', 'author', toon.author)}
                      {renderEditableField(
                        '장르 *',
                        'genre',
                        toon.genre,
                        'select-genre',
                      )}
                      {renderEditableField(
                        '작품 형태 *',
                        'toonType',
                        toon.toonType,
                        'select-toonType',
                      )}
                      {renderEditableField(
                        '연재 요일 *',
                        'weekday',
                        toon.weekday,
                        'select-weekday',
                      )}
                      {renderEditableField(
                        '미리보기 기간',
                        'previewDays',
                        toon.previewDays,
                        'number',
                      )}
                      {renderEditableField(
                        '등록일',
                        'createdAt',
                        moment(toon.createdAt).utc().format('YYYY-MM-DD HH:mm'),
                        'datetime-local',
                      )}
                      {renderEditableField(
                        '공개일 *',
                        'displayStartedAt',
                        toon.displayStartedAt
                          ? moment(toon.displayStartedAt).format(
                              'YYYY-MM-DD HH:mm',
                            )
                          : '',
                        'datetime-local',
                      )}
                      {renderEditableField(
                        '태그',
                        'tagList',
                        toon.tagList,
                        'tagList',
                      )}
                    </tbody>
                  </table>
                </div>

                <div className='border rounded-lg overflow-hidden'>
                  <table className='w-full'>
                    <thead className='bg-gray-50'>
                      <tr>
                        <th
                          className='px-4 py-2 text-left font-semibold'
                          colSpan='2'
                        >
                          CP사 정보
                        </th>
                      </tr>
                    </thead>
                    <tbody className='divide-y'>
                      {isEditing ? (
                        <>
                          <tr>
                            <td className='px-4 py-2 font-medium'>CP사 선택</td>
                            <td className='px-4 py-2'>
                              <select
                                value={providerId}
                                onChange={(e) => {
                                  const selectedProvider = providerList.find(
                                    (p) => p._id === e.target.value,
                                  )
                                  if (selectedProvider) {
                                    setProviderId(selectedProvider._id)
                                    setProviderDisplayName(
                                      selectedProvider.providerDisplayName,
                                    )
                                    setSettlementFeeRate(
                                      selectedProvider.settlementFeeRate || 0.5,
                                    )
                                  } else {
                                    setProviderId('')
                                    setProviderDisplayName('')
                                    setSettlementFeeRate('')
                                  }
                                }}
                                className='w-full p-2 border rounded'
                                id='provider-select'
                              >
                                <option value=''>CP사를 선택해주세요</option>
                                {providerList.map((provider) => (
                                  <option
                                    key={provider._id}
                                    value={provider._id}
                                  >
                                    {provider.providerDisplayName} (
                                    {provider.providerName})
                                  </option>
                                ))}
                              </select>
                            </td>
                          </tr>
                          {providerId && (
                            <>
                              <tr>
                                <td
                                  colSpan='2'
                                  className='px-4 py-2 bg-gray-50 font-semibold'
                                >
                                  선택된 CP사 정보
                                </td>
                              </tr>
                              {providerList
                                .filter((p) => p._id === providerId)
                                .map((provider) => (
                                  <React.Fragment key={provider._id}>
                                    <tr>
                                      <td className='px-4 py-2 font-medium'>
                                        고유아이디
                                      </td>
                                      <td className='px-4 py-2'>
                                        {provider._id}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className='px-4 py-2 font-medium'>
                                        CP사명
                                      </td>
                                      <td className='px-4 py-2'>
                                        {provider.providerDisplayName}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className='px-4 py-2 font-medium'>
                                        로그인 ID
                                      </td>
                                      <td className='px-4 py-2'>
                                        {provider.providerName}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className='px-4 py-2 font-medium'>
                                        배분율
                                      </td>
                                      <td className='px-4 py-2'>
                                        <input
                                          type='number'
                                          step='0.1'
                                          min='0'
                                          max='1'
                                          value={settlementFeeRate}
                                          onChange={(e) =>
                                            setSettlementFeeRate(
                                              parseFloat(e.target.value),
                                            )
                                          }
                                          className='w-24 p-1 border rounded'
                                          id='settlement-fee-rate-input'
                                        />
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                ))}
                            </>
                          )}
                        </>
                      ) : (
                        toon.providerInfo && (
                          <>
                            <tr>
                              <td className='px-4 py-2 font-medium'>
                                고유아이디
                              </td>
                              <td className='px-4 py-2'>
                                {toon.providerInfo.providerId}
                              </td>
                            </tr>
                            <tr>
                              <td className='px-4 py-2 font-medium'>CP사명</td>
                              <td className='px-4 py-2'>
                                {toon.providerInfo.providerDisplayName}
                              </td>
                            </tr>
                            <tr>
                              <td className='px-4 py-2 font-medium'>배분율</td>
                              <td className='px-4 py-2'>
                                {toon.providerInfo.settlementFeeRate}
                              </td>
                            </tr>
                          </>
                        )
                      )}
                    </tbody>
                  </table>
                </div>

                <div className='border rounded-lg overflow-hidden'>
                  <table className='w-full'>
                    <thead className='bg-gray-50'>
                      <tr>
                        <th
                          className='px-4 py-2 text-left font-semibold'
                          colSpan='2'
                        >
                          상태 정보
                        </th>
                      </tr>
                    </thead>
                    <tbody className='divide-y'>
                      {renderEditableField(
                        '노출 여부',
                        'isDisplayed',
                        isEditing
                          ? toon.isDisplayed
                          : toon.isDisplayed
                          ? 'O'
                          : 'X',
                        'checkbox',
                      )}
                      {renderEditableField(
                        '관리자 작품 여부',
                        'isAdmin',
                        isEditing ? toon.isAdmin : toon.isAdmin ? 'O' : 'X',
                        'checkbox',
                      )}
                      {renderEditableField(
                        '성인 여부',
                        'isAdultOnly',
                        isEditing
                          ? toon.isAdultOnly
                          : toon.isAdultOnly
                          ? 'O'
                          : 'X',
                        'checkbox',
                      )}
                      {renderEditableField(
                        '노출 순서 *',
                        'priority',
                        toon.priority,
                        'number',
                      )}
                      {renderEditableField(
                        '에피소드 가격 *',
                        'toonPrice',
                        toon.toonPrice,
                        'number',
                      )}
                      {renderEditableField(
                        '공지사항',
                        'notice',
                        toon.notice,
                        'textarea',
                      )}
                      {renderEditableField(
                        '프로모션 정보',
                        'promotionInfo',
                        toon.promotionInfo,
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className='flex justify-end space-x-4 mt-8'>
            {!isEditing && (
              <button
                type='button'
                onClick={handleDelete}
                className='px-6 py-2 bg-red-500 hover:bg-red-600 text-white rounded-lg'
              >
                삭제하기
              </button>
            )}
            {isEditing && (
              <button
                type='button'
                onClick={() => {
                  setIsEditing(false)
                  setEditedData({})
                  setValidationErrors({})
                }}
                className='px-6 py-2 bg-gray-200 hover:bg-gray-300 text-gray-700 rounded-lg'
              >
                취소
              </button>
            )}
            <button
              type='button'
              onClick={() => {
                if (isEditing) {
                  if (Object.keys(editedData).length === 0) {
                    alert('수정할 내용이 없습니다.')
                    return
                  }

                  handleSave()
                } else {
                  setIsEditing(true)
                  setEditedData({})
                  setValidationErrors({})
                  // 태그 정보 초기화
                  if (toon.tagList) {
                    setTagList({
                      mainGenre: toon.tagList.mainGenre || [],
                      subGenre: toon.tagList.subGenre || [],
                      new: toon.tagList.new || false,
                      hot: toon.tagList.hot || false,
                    })
                  }
                }
              }}
              className='px-6 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg'
            >
              {isEditing ? '수정 완료' : '수정하기'}
            </button>
          </div>
        </div>
      </div>
    )
  }

  const Pagination = ({ totalItems }) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage)
    const pageNumbers = []

    for (let i = 1; i <= totalPages; i += 1) {
      pageNumbers.push(i)
    }

    return (
      <div className='flex justify-center items-center space-x-2 mt-4'>
        <button
          type='button'
          onClick={() => setCurrentPage(1)}
          disabled={currentPage === 1}
          className='px-3 py-1 rounded bg-gray-200 disabled:opacity-50'
        >
          {'<<'}
        </button>
        <button
          type='button'
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
          className='px-3 py-1 rounded bg-gray-200 disabled:opacity-50'
        >
          {'<'}
        </button>
        {pageNumbers.map((number) => (
          <button
            key={number}
            type='button'
            onClick={() => setCurrentPage(number)}
            className={`px-3 py-1 rounded ${
              currentPage === number ? 'bg-blue-500 text-white' : 'bg-gray-200'
            }`}
          >
            {number}
          </button>
        ))}
        <button
          type='button'
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
          className='px-3 py-1 rounded bg-gray-200 disabled:opacity-50'
        >
          {'>'}
        </button>
        <button
          type='button'
          onClick={() => setCurrentPage(totalPages)}
          disabled={currentPage === totalPages}
          className='px-3 py-1 rounded bg-gray-200 disabled:opacity-50'
        >
          {'>>'}
        </button>
      </div>
    )
  }

  const renderSearchSection = () => {
    const indexOfLastItem = currentPage * itemsPerPage
    const indexOfFirstItem = indexOfLastItem - itemsPerPage
    const currentItems = getSortedData(getFilteredData(tempData))?.slice(
      indexOfFirstItem,
      indexOfLastItem,
    )

    return (
      <div className='w-full p-4'>
        <div className='py-4 flexRow justify-between items-center'>
          <SearchForm
            onSearch={({ searchQuery, searchType }) => {
              setActiveSearch({ query: searchQuery, type: searchType })
              setCurrentPage(1) // 검색 시 첫 페이지로 이동
            }}
            activeSearchQuery={activeSearch.query}
            activeSearchType={activeSearch.type}
          />
          {sortBy === 'priority' && activeSearch.query === '' && (
            <div className='flexRow space-x-2'>
              <button
                type='button'
                className='px-4 py-2 bg-blue-500 text-white rounded'
                onClick={() => setIsReordering(!isReordering)}
              >
                {isReordering ? '순서 조정 완료' : '작품 순서 조정하기'}
              </button>

              <button
                type='button'
                className='px-4 py-2 bg-green-500 text-white rounded'
                onClick={updateAllPriorities}
              >
                순서 재할당
              </button>
            </div>
          )}
        </div>

        <div className='flexRow justify-between items-center'>
          <div className='pb-10 flexRow justify-start items-center font-bold'>
            <div className='pr-2'>제공중인 작품들만 보기</div>
            <input
              type='checkbox'
              checked={filterCurrentToonData}
              onChange={(e) => {
                setFilterCurrentToonData(e.target.checked)
                setCurrentPage(1)
              }}
            />
          </div>
          <div className='pb-10 flexRow justify-start items-center font-bold'>
            <div className='pr-2'>프로모션 작품들만 보기</div>
            <input
              type='checkbox'
              checked={filterPromotionData}
              onChange={(e) => {
                setFilterPromotionData(e.target.checked)
                setCurrentPage(1)
              }}
            />
          </div>
          <div className='pb-10 flexRow items-center space-x-4'>
            <div className='flexRow items-center'>
              <div className='font-bold mr-2'>페이지당 표시:</div>
              <select
                value={itemsPerPage}
                onChange={(e) => {
                  setItemsPerPage(Number(e.target.value))
                  setCurrentPage(1)
                }}
                className='pl-[1vw] py-1 border rounded'
              >
                <option value={10}>10개</option>
                <option value={30}>30개</option>
                <option value={50}>50개</option>
                <option value={100}>100개</option>
              </select>
            </div>
            <div className='flexRow'>
              <div className='font-bold'>정렬 기준:</div>
              <select
                value={sortOrder}
                onChange={(e) => setSortOrder(e.target.value)}
                className='mx-2 pr-8 py-1 border rounded'
              >
                <option value='desc'>내림차순</option>
                <option value='asc'>오름차순</option>
              </select>
              <div className='flexCol items-start'>
                {[
                  { value: 'createdAt', label: '등록일(createdAt)' },
                  { value: 'priority', label: '우선순위(priority)' },
                ].map((option) => (
                  <div
                    key={option.value}
                    className='flexRow justify-start items-center space-x-3'
                  >
                    <div>{option.label}</div>
                    <input
                      type='radio'
                      name='sort'
                      value={option.value}
                      checked={sortBy === option.value}
                      onChange={(e) => setSortBy(e.target.value)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {tempData?.length > 0 &&
          promotionToonData?.length > 0 &&
          (isReordering ? (
            <>
              <div className='mb-4 p-4 bg-blue-50 border border-blue-200 rounded-lg text-blue-700 font-medium flex items-center justify-center'>
                <svg
                  className='w-6 h-6 mr-2'
                  fill='none'
                  stroke='currentColor'
                  viewBox='0 0 24 24'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4'
                  ></path>
                </svg>
                순서 조정 중 - 드래그하여 순서를 변경할 수 있습니다
              </div>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='toons'>
                  {(provided) => (
                    <table
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className='w-full border-collapse'
                    >
                      <thead className='whitespace-nowrap text-[1.4vh] border-b bg-gray-100'>
                        <tr>
                          <th className='px-4 py-3 text-[1vh] text-center'>
                            ID
                          </th>
                          <th className='px-4 py-3 text-center'>썸네일</th>
                          <th className='px-4 py-3'>작품제목</th>
                          <th className='px-4 py-3'>작가</th>
                          <th className='px-4 py-3 text-center'>연재 시작일</th>
                          <th className='px-4 py-3 text-center'>노출 여부</th>
                          <th className='px-4 py-3 text-center'>노출순서</th>
                          <th className='px-4 py-3 text-center'>
                            에피소드 가격
                          </th>
                          <th className='px-4 py-3 text-center'>
                            프로모션 여부
                          </th>
                          <th className='px-4 py-3 text-center'>
                            프로모션하기
                          </th>
                        </tr>
                      </thead>
                      <tbody className='text-sm divide-y divide-gray-100'>
                        {currentItems.map((item, index) => (
                          <Draggable
                            key={item._id}
                            draggableId={item._id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <tr
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={`cursor-pointer hover:bg-gray-50 ${
                                  index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                                } ${snapshot.isDragging ? 'bg-blue-50' : ''}`}
                              >
                                <td
                                  className='px-4 py-3 text-[4px] text-gray-500 min-w-[60px] text-center cursor-text select-all'
                                  onClick={(e) => {
                                    e.stopPropagation()
                                  }}
                                >
                                  {item._id}
                                </td>
                                <td className='px-4 py-3 text-center'>
                                  <img
                                    src={item.thumbnail}
                                    alt='itemImage'
                                    className='w-[50px] h-[50px] object-cover rounded mx-auto'
                                  />
                                </td>
                                <td className='px-4 py-3'>
                                  <div className='text-base font-medium break-words min-w-[200px] max-w-[250px]'>
                                    {item.title}
                                  </div>
                                </td>
                                <td className='px-4 py-3 text-[10px] min-w-[50px] max-w-[100px]'>
                                  {item.author}
                                </td>
                                <td className='px-4 py-3 min-w-[120px] whitespace-nowrap text-center'>
                                  {moment(item.createdAt).format(
                                    'YYYY-MM-DD HH:mm',
                                  )}
                                </td>
                                <td className='px-4 py-3 min-w-[80px] text-center'>
                                  {item.isDisplayed === true ? (
                                    <span className='text-green-600 font-medium'>
                                      노출 중
                                    </span>
                                  ) : (
                                    <span className='text-red-600 font-medium'>
                                      X
                                    </span>
                                  )}
                                  {item.isAdmin === true && (
                                    <span className='ml-1'>(admin)</span>
                                  )}
                                </td>
                                <td className='px-4 py-3 min-w-[60px] text-center'>
                                  {item.priority}
                                </td>
                                <td className='px-4 py-3 min-w-[60px] text-center'>
                                  {item.toonPrice}C
                                </td>
                                <td className='px-4 py-3 min-w-[60px] text-center'>
                                  {promotionToonData?.some(
                                    (e) =>
                                      e._id?.toString() ===
                                      item._id?.toString(),
                                  )
                                    ? 'O'
                                    : '-'}
                                </td>
                                <td className='px-4 py-3 text-center'>
                                  <button
                                    type='button'
                                    className='border rounded whitespace-nowrap px-2 py-1 bg-gray-500 text-white'
                                    onClick={async (e) => {
                                      e.stopPropagation()
                                      const isPromoted =
                                        promotionToonData?.some(
                                          (e) =>
                                            e._id?.toString() ===
                                            item._id?.toString(),
                                        )
                                      if (isPromoted) {
                                        const result =
                                          await altoonCMSBackendApis.dePromoteToon(
                                            {
                                              toonId: item._id,
                                            },
                                          )
                                        if (result?.status === 200) {
                                          alert('취소되었습니다')
                                        } else {
                                          alert(
                                            '오류가 발생했습니다. 담당자에게 문의해주세요.',
                                          )
                                        }
                                        loadPromotionToons()
                                      } else {
                                        const result =
                                          await altoonCMSBackendApis.promoteToon(
                                            {
                                              toonId: item._id,
                                            },
                                          )
                                        if (result?.status === 200) {
                                          alert('프로모션 등록되었습니다')
                                        } else {
                                          alert(
                                            '오류가 발생했습니다. 담당자에게 문의해주세요.',
                                          )
                                        }
                                        loadPromotionToons()
                                      }
                                    }}
                                  >
                                    {promotionToonData?.some(
                                      (e) =>
                                        e._id?.toString() ===
                                        item._id?.toString(),
                                    )
                                      ? '취소하기'
                                      : '프로모션하기'}
                                  </button>
                                </td>
                              </tr>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </tbody>
                    </table>
                  )}
                </Droppable>
              </DragDropContext>
            </>
          ) : (
            <table className='w-full border-collapse'>
              <thead className='whitespace-nowrap text-[1.4vh] border-b bg-gray-100'>
                <tr>
                  <th className='px-4 py-3 text-[1vh] text-center'>ID</th>
                  <th className='px-4 py-3 text-center'>썸네일</th>
                  <th className='px-4 py-3'>작품제목</th>
                  <th className='px-4 py-3'>작가</th>
                  <th className='px-4 py-3 text-center'>연재 시작일</th>
                  <th className='px-4 py-3 text-center'>노출 여부</th>
                  <th className='px-4 py-3 text-center'>노출순서</th>
                  <th className='px-4 py-3 text-center'>에피소드 가격</th>
                  <th className='px-4 py-3 text-center'>프로모션 여부</th>
                  <th className='px-4 py-3 text-center'>프로모션하기</th>
                </tr>
              </thead>
              <tbody className='text-sm divide-y divide-gray-100'>
                {currentItems.map((item, index) => (
                  <tr
                    key={item._id}
                    className={`cursor-pointer hover:bg-gray-50 ${
                      index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                    }`}
                    onClick={() => handleRowClick(item)}
                  >
                    <td
                      className='px-4 py-3 text-[4px] text-gray-500 min-w-[60px] text-center cursor-text select-all'
                      onClick={(e) => {
                        e.stopPropagation()
                      }}
                    >
                      {item._id}
                    </td>
                    <td className='px-4 py-3 text-center'>
                      <img
                        src={item.thumbnail}
                        alt='itemImage'
                        className='w-[50px] h-[50px] object-cover rounded mx-auto'
                      />
                    </td>
                    <td className='px-4 py-3'>
                      <div className='text-base font-medium break-words min-w-[200px] max-w-[250px]'>
                        {item.title}
                      </div>
                    </td>
                    <td className='px-4 py-3 text-[10px] min-w-[50px] max-w-[100px]'>
                      {item.author}
                    </td>
                    <td className='px-4 py-3 min-w-[120px] whitespace-nowrap text-center'>
                      {moment(item.displayStartedAt).format('YYYY-MM-DD')}
                      <br />
                      {moment(item.displayStartedAt).format('HH:mm')}
                    </td>
                    <td className='px-4 py-3 min-w-[80px] text-center'>
                      {item.isDisplayed === true ? (
                        <span className='text-green-600 font-medium'>
                          노출 중
                        </span>
                      ) : (
                        <span className='text-red-600 font-medium'>X</span>
                      )}
                      {item.isAdmin === true && (
                        <span className='ml-1'>(admin)</span>
                      )}
                    </td>
                    <td className='px-4 py-3 min-w-[60px] text-center'>
                      {item.priority}
                    </td>
                    <td className='px-4 py-3 min-w-[60px] text-center'>
                      {item.toonPrice}C
                    </td>
                    <td className='px-4 py-3 min-w-[60px] text-center'>
                      {promotionToonData?.some(
                        (e) => e._id?.toString() === item._id?.toString(),
                      )
                        ? 'O'
                        : '-'}
                    </td>
                    <td className='px-4 py-3 text-center'>
                      <button
                        type='button'
                        className='border rounded whitespace-nowrap px-2 py-1 bg-gray-500 text-white'
                        onClick={async (e) => {
                          e.stopPropagation()
                          const isPromoted = promotionToonData?.some(
                            (e) => e._id?.toString() === item._id?.toString(),
                          )
                          if (isPromoted) {
                            const result =
                              await altoonCMSBackendApis.dePromoteToon({
                                toonId: item._id,
                              })
                            if (result?.status === 200) {
                              alert('취소되었습니다')
                            } else {
                              alert(
                                '오류가 발생했습니다. 담당자에게 문의해주세요.',
                              )
                            }
                            loadPromotionToons()
                          } else {
                            const result =
                              await altoonCMSBackendApis.promoteToon({
                                toonId: item._id,
                              })
                            if (result?.status === 200) {
                              alert('프로모션 등록되었습니다')
                            } else {
                              alert(
                                '오류가 발생했습니다. 담당자에게 문의해주세요.',
                              )
                            }
                            loadPromotionToons()
                          }
                        }}
                      >
                        {promotionToonData?.some(
                          (e) => e._id?.toString() === item._id?.toString(),
                        )
                          ? '취소하기'
                          : '프로모션하기'}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ))}

        <Pagination
          totalItems={getSortedData(getFilteredData(tempData))?.length || 0}
        />
        {showModal && (
          <ToonDetailModal
            toon={selectedToon}
            onClose={() => setShowModal(false)}
          />
        )}
      </div>
    )
  }

  const renderCreateSection = () => {
    return (
      <div className='p-4'>
        <div className='py-4 font-bold text-[2.6vh]'>작품 등록</div>
        <div className='flex flex-col gap-6'>
          <div className='grid grid-cols-3 gap-6'>
            <div className='col-span-1'>
              <div className='space-y-4'>
                <div>
                  <label className='block mb-2 font-medium'>
                    썸네일 URL
                    <span className='text-red-500'> *</span>
                  </label>
                  <input
                    type='text'
                    value={thumbnail}
                    onChange={(e) => setThumbnail(e.target.value)}
                    className='w-full p-2 border rounded'
                    id='thumbnail-input'
                  />
                </div>
                <div className='flex items-center space-x-2'>
                  <input
                    type='checkbox'
                    checked={verticalThumbnail}
                    onChange={(e) => setVerticalThumbnail(e.target.checked)}
                    className='form-checkbox h-5 w-5'
                    id='vertical-thumbnail-input'
                  />
                  <label htmlFor='vertical-thumbnail-input'>
                    세로형 썸네일
                  </label>
                </div>
              </div>
              <div className='mt-4 border rounded-lg'>
                <div className='px-4 py-2 bg-gray-50 font-semibold'>
                  작품 설명
                </div>
                <div className='p-4'>
                  <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className='w-full p-2 border rounded'
                    rows={6}
                    id='description-input'
                  />
                </div>
              </div>
            </div>
            <div className='col-span-2 space-y-6'>
              <div className='border rounded-lg overflow-hidden'>
                <table className='w-full'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th
                        className='px-4 py-2 text-left font-semibold'
                        colSpan='2'
                      >
                        기본 정보<span className='text-red-500'> *</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y'>
                    <tr>
                      <td className='px-4 py-2 font-medium'>제목</td>
                      <td className='px-4 py-2'>
                        <input
                          type='text'
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          className='w-full p-2 border rounded'
                          id='title-input'
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='px-4 py-2 font-medium'>작가</td>
                      <td className='px-4 py-2'>
                        <input
                          type='text'
                          value={author}
                          onChange={(e) => setAuthor(e.target.value)}
                          className='w-full p-2 border rounded'
                          id='author-input'
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='px-4 py-2 font-medium'>장르</td>
                      <td className='px-4 py-2'>
                        <select
                          value={genre}
                          onChange={(e) => setGenre(e.target.value)}
                          className='w-full p-2 border rounded'
                          id='genre-input'
                        >
                          <option value=''>장르를 선택해주세요</option>
                          {[
                            'romance',
                            'romanceFantasy',
                            'BL',
                            'instatoon',
                            'drama',
                            'daily',
                            'comedy',
                            'action',
                            'thriller',
                          ].map((g) => (
                            <option key={g} value={g}>
                              {g}
                            </option>
                          ))}
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td className='px-4 py-2 font-medium'>작품 형태</td>
                      <td className='px-4 py-2'>
                        <select
                          value={toonType}
                          onChange={(e) => setToonType(e.target.value)}
                          className='w-full p-2 border rounded'
                          id='toon-type-input'
                        >
                          <option value=''>작품 형태를 선택해주세요</option>
                          {['VerticalScroll', 'HorizontalScroll'].map(
                            (type) => (
                              <option key={type} value={type}>
                                {type}
                              </option>
                            ),
                          )}
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td className='px-4 py-2 font-medium'>연재 요일</td>
                      <td className='px-4 py-2'>
                        <div className='flex flex-col space-y-2'>
                          <div className='flex items-center space-x-2'>
                            <input
                              type='checkbox'
                              checked={weekday === null}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setWeekday(null)
                                } else {
                                  setWeekday([])
                                }
                              }}
                              className='form-checkbox h-4 w-4'
                            />
                            <span>연재 요일 없음</span>
                          </div>
                          <div className='flex items-center space-x-2'>
                            <input
                              type='checkbox'
                              checked={
                                Array.isArray(weekday) && weekday[0] === 'Free'
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setWeekday(['Free'])
                                } else {
                                  setWeekday([])
                                }
                              }}
                              className='form-checkbox h-4 w-4'
                            />
                            <span>자유연재</span>
                          </div>
                          <div className='grid grid-cols-7 gap-2'>
                            {[
                              { value: 'Mon', label: '월' },
                              { value: 'Tue', label: '화' },
                              { value: 'Wed', label: '수' },
                              { value: 'Thu', label: '목' },
                              { value: 'Fri', label: '금' },
                              { value: 'Sat', label: '토' },
                              { value: 'Sun', label: '일' },
                            ].map((day) => (
                              <div
                                key={day.value}
                                className='flex items-center space-x-2'
                              >
                                <input
                                  type='checkbox'
                                  checked={
                                    Array.isArray(weekday) &&
                                    weekday.includes(day.value)
                                  }
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setWeekday([
                                        ...(weekday || []),
                                        day.value,
                                      ])
                                    } else {
                                      setWeekday(
                                        weekday.filter((d) => d !== day.value),
                                      )
                                    }
                                  }}
                                  disabled={
                                    weekday === null ||
                                    (Array.isArray(weekday) &&
                                      weekday[0] === 'Free')
                                  }
                                  className='form-checkbox h-4 w-4'
                                />
                                <span>{day.label}</span>
                              </div>
                            ))}
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className='px-4 py-2 font-medium'>미리보기 기간</td>
                      <td className='px-4 py-2'>
                        <input
                          type='number'
                          value={previewDays}
                          onChange={(e) => setPreviewDays(e.target.value)}
                          className='w-24 p-2 border rounded'
                          id='preview-days-input'
                          min='0'
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='px-4 py-2 font-medium'>공개일</td>
                      <td className='px-4 py-2'>
                        <input
                          type='datetime-local'
                          value={displayStartedAt}
                          onChange={(e) => setDisplayStartedAt(e.target.value)}
                          className='w-full p-2 border rounded'
                          id='display-started-at-input'
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className='border rounded-lg overflow-hidden'>
                <table className='w-full'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th
                        className='px-4 py-2 text-left font-semibold'
                        colSpan='2'
                      >
                        태그 정보
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y'>
                    <tr>
                      <td className='px-4 py-2 font-medium'>태그 목록</td>
                      <td className='px-4 py-2'>
                        <TagEditor tagList={tagList} setTagList={setTagList} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className='border rounded-lg overflow-hidden'>
                <table className='w-full'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th
                        className='px-4 py-2 text-left font-semibold'
                        colSpan='2'
                      >
                        CP사 정보<span className='text-red-500'> *</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y'>
                    <tr>
                      <td className='px-4 py-2 font-medium'>CP사 선택</td>
                      <td className='px-4 py-2'>
                        <select
                          value={providerId}
                          onChange={(e) => {
                            const selectedProvider = providerList.find(
                              (p) => p._id === e.target.value,
                            )
                            if (selectedProvider) {
                              setProviderId(selectedProvider._id)
                              setProviderDisplayName(
                                selectedProvider.providerDisplayName,
                              )
                              setSettlementFeeRate(
                                selectedProvider.settlementFeeRate || 0.5,
                              )
                            } else {
                              setProviderId('')
                              setProviderDisplayName('')
                              setSettlementFeeRate('')
                            }
                          }}
                          className='w-full p-2 border rounded'
                          id='provider-select'
                        >
                          <option value=''>CP사를 선택해주세요</option>
                          {providerList.map((provider) => (
                            <option key={provider._id} value={provider._id}>
                              {provider.providerDisplayName} (
                              {provider.providerName})
                            </option>
                          ))}
                        </select>
                      </td>
                    </tr>
                    {providerId && (
                      <>
                        <tr>
                          <td
                            colSpan='2'
                            className='px-4 py-2 bg-gray-50 font-semibold'
                          >
                            선택된 CP사 정보
                          </td>
                        </tr>
                        {providerList
                          .filter((p) => p._id === providerId)
                          .map((provider) => (
                            <React.Fragment key={provider._id}>
                              <tr>
                                <td className='px-4 py-2 font-medium'>
                                  고유아이디
                                </td>
                                <td className='px-4 py-2'>{provider._id}</td>
                              </tr>
                              <tr>
                                <td className='px-4 py-2 font-medium'>
                                  CP사명
                                </td>
                                <td className='px-4 py-2'>
                                  {provider.providerDisplayName}
                                </td>
                              </tr>
                              <tr>
                                <td className='px-4 py-2 font-medium'>
                                  로그인 ID
                                </td>
                                <td className='px-4 py-2'>
                                  {provider.providerName}
                                </td>
                              </tr>
                              <tr>
                                <td className='px-4 py-2 font-medium'>
                                  배분율
                                </td>
                                <td className='px-4 py-2'>
                                  <input
                                    type='number'
                                    step='0.1'
                                    min='0'
                                    max='1'
                                    value={settlementFeeRate}
                                    onChange={(e) =>
                                      setSettlementFeeRate(
                                        parseFloat(e.target.value),
                                      )
                                    }
                                    className='w-24 p-1 border rounded'
                                    id='settlement-fee-rate-input'
                                  />
                                </td>
                              </tr>
                            </React.Fragment>
                          ))}
                      </>
                    )}
                  </tbody>
                </table>
              </div>

              <div className='border rounded-lg overflow-hidden'>
                <table className='w-full'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th
                        className='px-4 py-2 text-left font-semibold'
                        colSpan='2'
                      >
                        상태 정보
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y'>
                    <tr>
                      <td className='px-4 py-2 font-medium'>노출 여부</td>
                      <td className='px-4 py-2'>
                        <input
                          type='checkbox'
                          checked={isDisplayed}
                          onChange={(e) => setIsDisplayed(e.target.checked)}
                          className='form-checkbox h-5 w-5'
                          id='is-displayed-checkbox'
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='px-4 py-2 font-medium'>
                        관리자 작품 여부
                      </td>
                      <td className='px-4 py-2'>
                        <input
                          type='checkbox'
                          checked={isAdmin}
                          onChange={(e) => setIsAdmin(e.target.checked)}
                          className='form-checkbox h-5 w-5'
                          id='is-admin-checkbox'
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='px-4 py-2 font-medium'>성인 여부</td>
                      <td className='px-4 py-2'>
                        <input
                          type='checkbox'
                          checked={isAdultOnly}
                          onChange={(e) => setIsAdultOnly(e.target.checked)}
                          className='form-checkbox h-5 w-5'
                          id='is-adult-checkbox'
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='px-4 py-2 font-medium'>
                        노출 순서
                        <span className='text-red-500'> *</span>
                      </td>
                      <td className='px-4 py-2'>
                        <input
                          type='number'
                          value={priority}
                          onChange={(e) => setPriority(Number(e.target.value))}
                          className='w-24 p-2 border rounded'
                          id='priority-input'
                          min='0'
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='px-4 py-2 font-medium'>
                        에피소드 가격
                        <span className='text-red-500'> *</span>
                      </td>
                      <td className='px-4 py-2'>
                        <input
                          type='number'
                          value={toonPrice}
                          onChange={(e) => setToonPrice(Number(e.target.value))}
                          className='w-24 p-2 border rounded'
                          id='toon-price-input'
                          min='0'
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='px-4 py-2 font-medium'>공지사항</td>
                      <td className='px-4 py-2'>
                        <textarea
                          value={notice}
                          onChange={(e) => setNotice(e.target.value)}
                          className='w-full p-2 border rounded'
                          rows={4}
                          id='notice-textarea'
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='px-4 py-2 font-medium'>베스트댓글</td>
                      <td className='px-4 py-2'>
                        <textarea
                          value={bestComment}
                          onChange={(e) => setBestComment(e.target.value)}
                          className='w-full p-2 border rounded'
                          rows={4}
                          id='best-comment-textarea'
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className='flex justify-end mt-8'>
            <button
              type='button'
              onClick={handleRegisterToon}
              className='px-6 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg'
            >
              작품 등록하기
            </button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='w-full'>
      <div className='flex space-x-4 mb-6'>
        <button
          type='button'
          className={`px-4 py-2 rounded-lg ${
            activeSection === 'search'
              ? 'bg-blue-500 text-white'
              : 'bg-gray-200 text-gray-700'
          }`}
          onClick={() => setActiveSection('search')}
        >
          작품 조회
        </button>
        <button
          type='button'
          className={`px-4 py-2 rounded-lg ${
            activeSection === 'create'
              ? 'bg-blue-500 text-white'
              : 'bg-gray-200 text-gray-700'
          }`}
          onClick={() => setActiveSection('create')}
        >
          작품 등록
        </button>
      </div>

      {activeSection === 'search' && renderSearchSection()}
      {activeSection === 'create' && renderCreateSection()}
    </div>
  )
})

export default ToonInfoManagement
