import moment from 'moment'
import React, { useEffect, useState } from 'react'

import altoonCMSBackendApis from '../utils/altoonCMSBackendApis'

const RecommendSlotModal = ({
  isOpen,
  onClose,
  onSubmit,
  initialData = null,
  slotTypes,
}) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [formData, setFormData] = useState({
    toonId: '',
    toonTitle: '',
    isDisplayed: true,
    priority: 0,
    displayStartedAt: '',
    displayEndedAt: '',
    recommendType: {
      mainNew: false,
      toonPick: false,
      preview: false,
      commentRecommend: false,
    },
  })
  const [searchType, setSearchType] = useState('title')

  // 날짜 포맷팅 함수
  const formatDate = (dateString) => {
    if (!dateString) return '-'
    return moment(dateString).format('YYYY-MM-DD HH:mm')
  }

  useEffect(() => {
    if (initialData) {
      setFormData({
        ...initialData,
        displayStartedAt: initialData.displayStartedAt
          ? formatDate(initialData.displayStartedAt)
          : '',
        displayEndedAt: initialData.displayEndedAt
          ? formatDate(initialData.displayEndedAt)
          : '',
      })
    }
  }, [initialData])

  const handleSearch = async () => {
    try {
      const result = await altoonCMSBackendApis.getToonData()
      if (result?.status === 200) {
        let filteredResults = result.data

        if (searchQuery.trim()) {
          const query = searchQuery.toLowerCase()
          filteredResults = filteredResults.filter((toon) => {
            if (searchType === 'title') {
              return toon.title.toLowerCase().includes(query)
            } else if (searchType === 'id') {
              return toon._id.toLowerCase().includes(query)
            } else if (searchType === 'provider') {
              return toon.providerId?.toLowerCase().includes(query)
            }
            return false
          })
        }

        setSearchResults(filteredResults)
      } else {
        alert('작품 검색 중 오류가 발생했습니다.')
      }
    } catch (error) {
      console.error('Error searching toons:', error)
      alert('작품 검색 중 오류가 발생했습니다.')
    }
  }

  const handleSelectToon = (toon) => {
    setFormData({
      ...formData,
      toonId: toon._id,
      toonTitle: toon.title,
    })
    setSearchResults([])
    setSearchQuery('')
  }

  const handleSubmit = () => {
    // 유효성 검사
    if (!formData.toonId || !formData.toonTitle) {
      alert('작품을 선택해주세요.')
      return
    }

    if (!formData.displayStartedAt || !formData.displayEndedAt) {
      alert('노출 기간을 설정해주세요.')
      return
    }

    const selectedSlots = Object.entries(formData?.recommendType).filter(
      ([_, value]) => value,
    )
    if (selectedSlots.length === 0) {
      alert('최소 하나의 노출 구좌를 선택해주세요.')
      return
    }

    // 선택된 recommendType만 포함하도록 데이터 가공
    const submitData = {
      ...formData,
      recommendType: Object.fromEntries(selectedSlots),
    }

    onSubmit(submitData)
  }

  if (!isOpen) return null

  return (
    <div className='fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50'>
      <div className='bg-white rounded-xl w-[800px] max-h-[80vh] overflow-y-auto'>
        <div className='px-6 py-4 border-b border-gray-200 flex justify-between items-center'>
          <h3 className='text-lg font-medium text-gray-900'>
            {initialData ? '노출구좌 수정' : '노출구좌 등록'}
          </h3>
          <button
            type='button'
            className='text-gray-400 hover:text-gray-500'
            onClick={onClose}
          >
            <span className='sr-only'>닫기</span>×
          </button>
        </div>

        <div className='p-6 space-y-6'>
          {/* 작품 검색 */}
          <div className='space-y-4'>
            <label
              htmlFor='toonSearch'
              className='block text-sm font-medium text-gray-700'
            >
              작품 검색
            </label>
            <div className='flex gap-2'>
              <input
                id='toonSearch'
                type='text'
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className='flex-1 px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500'
                placeholder='작품 제목으로 검색...'
              />
              <button
                type='button'
                onClick={handleSearch}
                className='px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
              >
                검색
              </button>
            </div>

            {/* 검색 결과 */}
            {searchResults.length > 0 && (
              <div className='mt-2 border border-gray-200 rounded-md divide-y'>
                {searchResults.map((toon) => (
                  <div
                    key={toon._id}
                    className='p-2 hover:bg-gray-50 cursor-pointer flex items-center gap-4'
                    onClick={() => handleSelectToon(toon)}
                  >
                    {toon.thumbnail && (
                      <div className='w-16 h-16 flex-shrink-0'>
                        <img
                          src={toon.thumbnail}
                          alt={`${toon.title} 썸네일`}
                          className='w-full h-full object-cover rounded'
                        />
                      </div>
                    )}
                    <div className='flex-1 min-w-0'>
                      <div className='font-medium truncate'>{toon.title}</div>
                      <div className='text-sm text-gray-500 truncate'>
                        {toon._id}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* 선택된 작품 정보 */}
          {formData.toonId && (
            <div className='space-y-4'>
              <div className='grid grid-cols-2 gap-4'>
                <div>
                  <label
                    htmlFor='toonId'
                    className='block text-sm font-medium text-gray-700'
                  >
                    작품 ID
                  </label>
                  <input
                    id='toonId'
                    type='text'
                    value={formData.toonId}
                    readOnly
                    className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50'
                  />
                </div>
                <div>
                  <label
                    htmlFor='toonTitle'
                    className='block text-sm font-medium text-gray-700'
                  >
                    작품 제목
                  </label>
                  <input
                    id='toonTitle'
                    type='text'
                    value={formData.toonTitle}
                    readOnly
                    className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-50'
                  />
                </div>
              </div>
            </div>
          )}

          {/* 노출 구좌 선택 */}
          <div className='space-y-4'>
            <label className='block text-sm font-medium text-gray-700'>
              노출 구좌 선택
            </label>
            <div className='grid grid-cols-2 gap-4'>
              {slotTypes?.map((type) => (
                <div key={type.value} className='flex items-center'>
                  <input
                    type='checkbox'
                    id={`slot-${type?.value}`}
                    checked={formData.recommendType?.[type?.value]}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        recommendType: {
                          ...formData?.recommendType,
                          [type.value]: e.target.checked,
                        },
                      })
                    }
                    className='h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded'
                  />
                  <label
                    htmlFor={`slot-${type.value}`}
                    className='ml-2 block text-sm text-gray-900'
                  >
                    {type.label}
                  </label>
                </div>
              ))}
            </div>
          </div>

          {/* 노출 설정 */}
          <div className='space-y-4'>
            <div className='grid grid-cols-2 gap-4'>
              <div>
                <label
                  htmlFor='isDisplayed'
                  className='block text-sm font-medium text-gray-700'
                >
                  노출 여부
                </label>
                <select
                  id='isDisplayed'
                  value={formData.isDisplayed}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      isDisplayed: e.target.value === 'true',
                    })
                  }
                  className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500'
                >
                  <option value='true'>노출</option>
                  <option value='false'>비노출</option>
                </select>
              </div>
              <div>
                <label
                  htmlFor='priority'
                  className='block text-sm font-medium text-gray-700'
                >
                  우선순위
                </label>
                <input
                  id='priority'
                  type='number'
                  min='0'
                  value={formData.priority}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      priority: parseInt(e.target.value) || 0,
                    })
                  }
                  className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500'
                />
              </div>
            </div>
          </div>

          {/* 노출 기간 설정 */}
          <div className='space-y-4'>
            <div className='grid grid-cols-2 gap-4'>
              <div>
                <label
                  htmlFor='displayStartedAt'
                  className='block text-sm font-medium text-gray-700'
                >
                  노출 시작일
                </label>
                <input
                  id='displayStartedAt'
                  type='datetime-local'
                  value={formatDate(formData.displayStartedAt)}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      displayStartedAt: e.target.value,
                    })
                  }
                  className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500'
                />
              </div>
              <div>
                <label
                  htmlFor='displayEndedAt'
                  className='block text-sm font-medium text-gray-700'
                >
                  노출 종료일
                </label>
                <input
                  id='displayEndedAt'
                  type='datetime-local'
                  value={formatDate(formData.displayEndedAt)}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      displayEndedAt: e.target.value,
                    })
                  }
                  className='mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500'
                />
              </div>
            </div>
          </div>
        </div>

        <div className='px-6 py-4 border-t border-gray-200 flex justify-end gap-2'>
          <button
            type='button'
            onClick={onClose}
            className='px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
          >
            취소
          </button>
          <button
            type='button'
            onClick={handleSubmit}
            className='px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
          >
            {initialData ? '수정' : '등록'}
          </button>
        </div>
      </div>
    </div>
  )
}

export default RecommendSlotModal
