import * as ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { ClipLoader } from 'react-spinners'

import altoonCMSBackendApis from '../../../utils/altoonCMSBackendApis'
import NovelChapterDetailModal from './NovelChapterDetailModal'

const NovelChapterManagementTab = () => {
  const [novelId, setNovelId] = useState('')
  const [chapterData, setChapterData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [activeSearch, setActiveSearch] = useState({ query: '', type: 'title' })
  const [selectedNovel, setSelectedNovel] = useState(null)
  const [novelList, setNovelList] = useState([])
  const [metaDataInfo, setMetaDataInfo] = useState()
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(30)
  const [totalPages, setTotalPages] = useState(1)
  const [sortBy, setSortBy] = useState('episode')
  const [sortOrder, setSortOrder] = useState('asc')
  const [showModal, setShowModal] = useState(false)
  const [selectedChapter, setSelectedChapter] = useState(null)
  const [showOnlyDisplayed, setShowOnlyDisplayed] = useState(false)

  useEffect(() => {
    const loadNovelList = async () => {
      try {
        const result = await altoonCMSBackendApis.getNovelData()
        if (result?.status === 200) {
          setNovelList(result.data)
        }

        if (novelId) {
          const metaResult = await altoonCMSBackendApis.getNovelMetaData({
            novelId,
          })
          if (metaResult?.status === 200) {
            setMetaDataInfo(metaResult?.data)
          }
        }
      } catch (error) {
        console.error('작품 목록 로딩 중 오류 발생:', error)
      }
    }
    loadNovelList()
  }, [novelId])

  const LoadingIndicator = () => {
    return (
      <div className='fixed inset-0 z-30 w-full h-full bg-gray-800 bg-opacity-70 flex items-center justify-center'>
        <ClipLoader
          color='#ff3e3e'
          loading
          size={40}
          aria-label='Loading Spinner'
          data-testid='loader'
        />
      </div>
    )
  }

  const getFilteredNovels = () => {
    if (!activeSearch.query) return novelList

    return novelList.filter((novel) => {
      const query = activeSearch.query.toLowerCase()
      switch (activeSearch.type) {
        case 'title':
          return novel.title?.toLowerCase().includes(query)
        case 'id':
          return novel._id?.toString().includes(query)
        default:
          return true
      }
    })
  }

  const getSortedData = (data) => {
    if (!data) return []

    return [...data].sort((a, b) => {
      let comparison = 0
      switch (sortBy) {
        case 'episode':
          comparison = (a.episode || 0) - (b.episode || 0)
          break
        case 'publishedAt':
          comparison = new Date(a.publishedAt) - new Date(b.publishedAt)
          break
        default:
          comparison = 0
      }
      return sortOrder === 'asc' ? comparison : -comparison
    })
  }

  const getPaginatedData = (data) => {
    const startIndex = (currentPage - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage
    return data?.slice(startIndex, endIndex)
  }

  const fetchChapters = async (id) => {
    if (!id) {
      alert('작품 ID를 입력해주세요.')
      return
    }

    setIsLoading(true)
    try {
      const result = await altoonCMSBackendApis.getChapterDataByNovelId({
        novelId: id,
      })
      if (result?.status === 200) {
        if (result.data.length > 0) {
          setChapterData(result.data)
          setTotalPages(Math.ceil(result.data.length / itemsPerPage))
        } else {
          alert('등록된 회차가 없습니다.')
        }
      }
    } catch (error) {
      console.error('회차 데이터 로딩 중 오류 발생:', error)
      alert('회차 데이터를 불러오는데 실패했습니다.')
    } finally {
      setIsLoading(false)
    }
  }

  const handleRowClick = (chapter) => {
    setSelectedChapter(chapter)
    setShowModal(true)
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value, 10)
    setItemsPerPage(newItemsPerPage)
    setTotalPages(Math.ceil(chapterData.length / newItemsPerPage))
    setCurrentPage(1)
  }

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    } else {
      setSortBy(column)
      setSortOrder('asc')
    }
  }

  const sortedData = getSortedData(chapterData)
  const paginatedData = getPaginatedData(sortedData)

  const getChapterIds = () => {
    const filteredChapters = showOnlyDisplayed
      ? chapterData.filter((chapter) => chapter.isDisplayed)
      : chapterData

    return JSON.stringify(filteredChapters.map((chapter) => chapter._id))
  }

  const handleDownloadExcel = async () => {
    const filteredChapters = showOnlyDisplayed
      ? chapterData.filter((chapter) => chapter.isDisplayed)
      : chapterData

    const excelData = filteredChapters.map((chapter) => ({
      ID: chapter._id,
      회차: chapter.episode,
      제목: chapter.chapterTitle,
      공개일: moment(chapter.publishedAt).format('YYYY-MM-DD HH:mm'),
      가격: chapter.chapterPrice,
      무료여부: chapter.isFree ? '무료' : '유료',
      공개여부: chapter.isDisplayed ? '공개' : '비공개',
      썸네일: chapter.detailThumbnail,
      작가의말: chapter.authorComment || '',
    }))

    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('회차 목록')

    // 컬럼 정의
    worksheet.columns = [
      { header: 'ID', key: 'ID', width: 20 },
      { header: '회차', key: '회차', width: 10 },
      { header: '제목', key: '제목', width: 30 },
      { header: '공개일', key: '공개일', width: 20 },
      { header: '가격', key: '가격', width: 10 },
      { header: '무료여부', key: '무료여부', width: 10 },
      { header: '공개여부', key: '공개여부', width: 10 },
      { header: '썸네일', key: '썸네일', width: 30 },
      { header: '작가의말', key: '작가의말', width: 30 },
    ]

    // 데이터 추가
    worksheet.addRows(excelData)

    // 헤더 스타일 적용
    const headerRow = worksheet.getRow(1)
    headerRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFEA3F49' },
    }
    headerRow.font = {
      color: { argb: 'FFFFFFFF' },
      bold: true,
    }

    // 엑셀 파일 생성 및 다운로드
    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    saveAs(blob, `${selectedNovel.title}_회차목록.xlsx`)
  }

  return (
    <div className='p-4 bg-white rounded-xl shadow-sm'>
      <div className='py-4 font-bold text-[2.2vh]'>회차 조회/수정</div>

      {/* 작품 검색 섹션 */}
      <div className='mb-6'>
        <div className='flex items-center space-x-4 mb-4'>
          <select
            value={activeSearch.type}
            onChange={(e) =>
              setActiveSearch((prev) => ({ ...prev, type: e.target.value }))
            }
            className='pl-2 border rounded'
            id='search-type'
          >
            <option value='title'>제목</option>
            <option value='id'>ID</option>
          </select>
          <input
            type='text'
            value={activeSearch.query}
            onChange={(e) =>
              setActiveSearch((prev) => ({ ...prev, query: e.target.value }))
            }
            className='flex-1 p-2 border rounded'
            placeholder='작품 검색...'
            id='search-query'
          />
        </div>

        {/* 검색된 작품 목록 */}
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
          {!selectedNovel &&
            getFilteredNovels()
              ?.slice(0, 9)
              .map((novel) => (
                <div
                  key={novel._id}
                  className='border rounded-lg p-4 cursor-pointer hover:bg-gray-50'
                  onClick={() => {
                    setSelectedNovel(novel)
                    setNovelId(novel._id)
                    fetchChapters(novel._id)
                  }}
                >
                  <div className='flex items-center'>
                    <img
                      src={novel.thumbnail}
                      alt={novel.title}
                      className='w-20 h-auto rounded mr-4'
                    />
                    <div>
                      <h4 className='font-medium'>{novel.title}</h4>
                      <p className='text-sm text-gray-500'>{novel.author}</p>
                      <div className='h-4' />
                      <p className='text-xs text-gray-400'>ID: {novel._id}</p>
                      <p className='text-xs text-gray-400'>
                        공개일자:{' '}
                        {moment(novel.displayStartedAt).format('YY.MM.DD')}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
        </div>
      </div>

      {/* 선택된 작품 정보 */}
      {selectedNovel && (
        <div className='mb-6 p-4 border rounded-lg'>
          <div className='flex justify-between items-center'>
            <div className='flex items-center'>
              <img
                src={selectedNovel.thumbnail}
                alt={selectedNovel.title}
                className='w-16 h-16 object-cover rounded mr-4'
              />
              <div>
                <h3 className='text-lg font-semibold'>{selectedNovel.title}</h3>
                <p className='text-sm text-gray-500'>{selectedNovel.author}</p>
                <p className='text-xs text-gray-400'>ID: {selectedNovel._id}</p>
              </div>
            </div>
            <div className='flex items-center space-x-4'>
              <div className='flex items-center space-x-2'>
                <input
                  type='checkbox'
                  checked={showOnlyDisplayed}
                  onChange={(e) => setShowOnlyDisplayed(e.target.checked)}
                  className='form-checkbox h-5 w-5'
                  id='show-only-displayed'
                />
                <label htmlFor='show-only-displayed'>공개된 회차만</label>
              </div>
              <button
                type='button'
                onClick={handleDownloadExcel}
                className='px-4 py-2 bg-green-500 hover:bg-green-600 text-white rounded'
              >
                엑셀 다운로드
              </button>
              <button
                type='button'
                onClick={() => {
                  setSelectedNovel(null)
                  setNovelId('')
                  setChapterData([])
                }}
                className='px-4 py-2 bg-gray-200 hover:bg-gray-300 text-gray-700 rounded'
              >
                다른 작품 선택
              </button>
            </div>
          </div>
          <div className='mt-4'>
            <div className='flex items-center space-x-2 mb-2'>
              <label className='font-medium'>회차 ID 목록:</label>
              <button
                type='button'
                onClick={() => {
                  navigator.clipboard.writeText(getChapterIds())
                  alert('클립보드에 복사되었습니다.')
                }}
                className='text-sm text-blue-500 hover:text-blue-600'
              >
                복사
              </button>
            </div>
            <textarea
              value={getChapterIds()}
              readOnly
              className='w-full p-2 border rounded bg-gray-50'
              rows={2}
            />
          </div>
        </div>
      )}

      {/* 등록된 회차 목록 */}
      {selectedNovel && (
        <div className='border rounded-lg overflow-hidden'>
          <div className='px-4 py-2 bg-gray-50 font-semibold flex justify-between items-center'>
            <span>등록된 회차 목록</span>
            <div className='flex items-center space-x-4'>
              <div className='text-sm'>페이지당 항목 수:</div>
              <select
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
                className='pl-2 border rounded'
                id='items-per-page'
              >
                <option value={10}>10개</option>
                <option value={30}>30개</option>
                <option value={50}>50개</option>
                <option value={100}>100개</option>
              </select>
            </div>
          </div>
          <div className='overflow-x-auto'>
            <table className='w-full'>
              <thead>
                <tr className='bg-gray-50'>
                  <th
                    className='px-4 py-2 cursor-pointer hover:bg-gray-100'
                    onClick={() => handleSort('episode')}
                  >
                    회차{' '}
                    {sortBy === 'episode' && (sortOrder === 'asc' ? '↑' : '↓')}
                  </th>
                  <th className='px-4 py-2'>제목</th>
                  <th className='px-4 py-2'>썸네일</th>
                  <th
                    className='px-4 py-2 cursor-pointer hover:bg-gray-100'
                    onClick={() => handleSort('publishedAt')}
                  >
                    공개일
                    {sortBy === 'publishedAt' &&
                      (sortOrder === 'asc' ? '↑' : '↓')}
                  </th>
                  <th className='px-4 py-2'>회차 가격</th>
                  <th className='px-4 py-2'>유/무료</th>
                  <th className='px-4 py-2'>공개여부</th>
                  <th className='px-4 py-2'>메타데이터</th>
                </tr>
              </thead>
              <tbody>
                {paginatedData.length > 0 ? (
                  paginatedData.map((chapter) => (
                    <tr
                      key={chapter._id}
                      className='hover:bg-gray-50 cursor-pointer text-center text-[14px]'
                      onClick={() => handleRowClick(chapter)}
                    >
                      <td className='px-4 py-2'>{chapter.episode}</td>
                      <td className='px-4 py-2 min-w-[150px] max-w-[200px] overflow-hidden text-ellipsis'>
                        {chapter.chapterTitle}
                      </td>
                      <td className='px-4 py-2 flex justify-center'>
                        <img
                          src={chapter.detailThumbnail}
                          alt='thumbnail'
                          className='w-16 h-16'
                        />
                      </td>
                      <td className='px-4 py-2 text-center'>
                        {moment(chapter.publishedAt).format('YY.MM.DD')}
                        <br />
                        {moment(chapter.publishedAt).format('HH:mm')}
                      </td>
                      <td className='px-4 py-2 text-center'>
                        {chapter.chapterPrice}C
                      </td>
                      <td
                        className={`px-4 py-3 text-center font-medium ${
                          chapter.isFree ? 'text-green-500' : 'text-red-500'
                        }`}
                      >
                        {chapter.isFree ? '무료' : '유료'}
                      </td>
                      <td className='px-4 py-3 text-center'>
                        {chapter.isDisplayed ? (
                          <span className='text-green-600 font-medium'>Y</span>
                        ) : (
                          <span className='text-red-600 font-medium'>N</span>
                        )}
                      </td>
                      <td className='px-4 py-3 text-center'>
                        {metaDataInfo?.some(
                          (meta) => meta.chapterId === chapter._id,
                        ) ? (
                          <span className='text-green-600 font-medium'>Y</span>
                        ) : (
                          <span className='text-red-600 font-medium'>N</span>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={9}
                      className='px-4 py-8 text-center text-gray-500'
                    >
                      등록된 회차가 없습니다.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* 페이지네이션 */}
          <div className='flex justify-center mt-6 pb-4'>
            <div className='flex space-x-2'>
              <button
                type='button'
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1}
                className={`px-3 py-1 rounded ${
                  currentPage === 1
                    ? 'bg-gray-200 text-gray-500'
                    : 'bg-gray-300 hover:bg-gray-400 text-gray-700'
                }`}
              >
                처음
              </button>
              <button
                type='button'
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className={`px-3 py-1 rounded ${
                  currentPage === 1
                    ? 'bg-gray-200 text-gray-500'
                    : 'bg-gray-300 hover:bg-gray-400 text-gray-700'
                }`}
              >
                이전
              </button>

              {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
                let pageNum
                if (totalPages <= 5) {
                  pageNum = i + 1
                } else if (currentPage <= 3) {
                  pageNum = i + 1
                } else if (currentPage >= totalPages - 2) {
                  pageNum = totalPages - 4 + i
                } else {
                  pageNum = currentPage - 2 + i
                }

                if (pageNum > 0 && pageNum <= totalPages) {
                  return (
                    <button
                      type='button'
                      key={pageNum}
                      onClick={() => handlePageChange(pageNum)}
                      className={`px-3 py-1 rounded ${
                        currentPage === pageNum
                          ? 'bg-blue-500 text-white'
                          : 'bg-gray-300 hover:bg-gray-400 text-gray-700'
                      }`}
                    >
                      {pageNum}
                    </button>
                  )
                }
                return null
              })}

              <button
                type='button'
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className={`px-3 py-1 rounded ${
                  currentPage === totalPages
                    ? 'bg-gray-200 text-gray-500'
                    : 'bg-gray-300 hover:bg-gray-400 text-gray-700'
                }`}
              >
                다음
              </button>
              <button
                type='button'
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
                className={`px-3 py-1 rounded ${
                  currentPage === totalPages
                    ? 'bg-gray-200 text-gray-500'
                    : 'bg-gray-300 hover:bg-gray-400 text-gray-700'
                }`}
              >
                마지막
              </button>
            </div>
          </div>

          <div className='text-center text-sm text-gray-500 pb-4'>
            총 {chapterData.length}개 중 {(currentPage - 1) * itemsPerPage + 1}-
            {Math.min(currentPage * itemsPerPage, chapterData.length)}개 표시
          </div>
        </div>
      )}

      {/* 회차 상세 모달 */}
      {showModal && selectedChapter && (
        <NovelChapterDetailModal
          chapter={selectedChapter}
          onClose={() => {
            setShowModal(false)
            setSelectedChapter(null)
          }}
          onUpdate={() => {
            fetchChapters(selectedNovel._id)
          }}
        />
      )}

      {isLoading && <LoadingIndicator />}
    </div>
  )
}

export default NovelChapterManagementTab
