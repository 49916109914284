import moment from 'moment'
import React, { useState } from 'react'

import SearchForm from '../SearchForm'
import NovelDetailModal from './NovelDetailModal'
import NovelRegisterSection from './NovelRegisterSection'

const NovelManagementTab = ({
  tempData,
  filterCurrentNovelData,
  setFilterCurrentNovelData,
  itemsPerPage,
  setItemsPerPage,
  currentPage,
  setCurrentPage,
  sortBy,
  setSortBy,
  sortOrder,
  setSortOrder,
  isReordering,
  setIsReordering,
  activeSearch,
  setActiveSearch,
  fetchNovels,
  getPaginatedData,
  getSortedData,
  getFilteredData,
}) => {
  const [activeWorkTab, setActiveWorkTab] = useState('search')
  const [selectedNovel, setSelectedNovel] = useState(null)
  const [showNovelDetailModal, setShowNovelDetailModal] = useState(false)

  // totalPages 계산
  const totalPages = Math.ceil(getFilteredData(tempData).length / itemsPerPage)

  const handleRowClick = (novel) => {
    setSelectedNovel(novel)
    setShowNovelDetailModal(true)
  }

  return (
    <div className='p-4 bg-white rounded-xl shadow-sm'>
      {/* 작품 관리 서브 탭 */}
      <div className='flex space-x-4 mb-6 border-b'>
        <button
          type='button'
          onClick={() => setActiveWorkTab('search')}
          className={`px-4 py-2 font-medium ${
            activeWorkTab === 'search'
              ? 'border-b-2 border-blue-500 text-blue-500'
              : 'text-gray-500 hover:text-gray-700'
          }`}
        >
          작품 조회
        </button>
        <button
          type='button'
          onClick={() => setActiveWorkTab('register')}
          className={`px-4 py-2 font-medium ${
            activeWorkTab === 'register'
              ? 'border-b-2 border-blue-500 text-blue-500'
              : 'text-gray-500 hover:text-gray-700'
          }`}
        >
          작품 등록
        </button>
      </div>

      {/* 작품 조회 섹션 */}
      {activeWorkTab === 'search' && (
        <div className='w-full p-4'>
          <div className='py-4 flex justify-between items-center'>
            <div className='font-bold text-[2.2vh]'>작품 조회</div>
            <div className='flex space-x-2'>
              {sortBy === 'priority' && activeSearch.query === '' && (
                <button
                  type='button'
                  className='px-4 py-2 bg-blue-500 text-white rounded'
                  onClick={() => setIsReordering(!isReordering)}
                >
                  {isReordering ? '순서 조정 완료' : '작품 순서 조정하기'}
                </button>
              )}
              <button
                type='button'
                onClick={fetchNovels}
                className='px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600'
              >
                새로고침
              </button>
            </div>
          </div>

          <SearchForm
            onSearch={({ searchQuery, searchType }) => {
              setActiveSearch({ query: searchQuery, type: searchType })
            }}
            activeSearchQuery={activeSearch.query}
            activeSearchType={activeSearch.type}
          />

          {/* 필터/정렬 기능 */}
          <div className='flex justify-between items-center mb-4'>
            <div className='flex items-center space-x-4'>
              <div className='flex items-center space-x-2'>
                <input
                  type='checkbox'
                  id='filterCurrentNovelData'
                  checked={filterCurrentNovelData}
                  onChange={(e) => setFilterCurrentNovelData(e.target.checked)}
                />
                <label htmlFor='filterCurrentNovelData'>
                  제공중인 작품들만 보기
                </label>
              </div>
            </div>
            <div className='flex items-center space-x-4'>
              <div className='flex items-center space-x-2'>
                <span className='text-sm text-gray-600'>페이지당 표시:</span>
                <select
                  value={itemsPerPage}
                  onChange={(e) => {
                    setItemsPerPage(Number(e.target.value))
                    setCurrentPage(1)
                  }}
                  className='border rounded pl-2 py-1 text-sm'
                >
                  <option value={10}>10</option>
                  <option value={30}>30</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
              <div className='flex items-center space-x-2'>
                <span className='text-sm text-gray-600'>정렬 기준:</span>
                <select
                  value={sortOrder}
                  onChange={(e) => setSortOrder(e.target.value)}
                  className='border rounded pl-2 py-1 text-sm'
                >
                  <option value='desc'>내림차순</option>
                  <option value='asc'>오름차순</option>
                </select>
              </div>
              <div className='flex items-center space-x-2'>
                <select
                  value={sortBy}
                  onChange={(e) => setSortBy(e.target.value)}
                  className='border rounded px-2 py-1 text-sm'
                >
                  <option value='createdAt'>등록일</option>
                  <option value='priority'>우선순위</option>
                  <option value='publishedAt'>발행일</option>
                  <option value='displayStartedAt'>노출 시작일</option>
                </select>
              </div>
            </div>
          </div>

          <div className='overflow-x-auto mt-4'>
            <table className='w-full'>
              <thead>
                <tr className='bg-gray-50 text-center'>
                  <th className='px-4 py-2'>ID</th>
                  <th className='px-4 py-2'>제목</th>
                  <th className='px-4 py-2'>작가</th>
                  <th className='px-4 py-2'>썸네일</th>
                  <th className='px-4 py-2'>등록일</th>
                  <th className='px-4 py-2'>노출 여부</th>
                  <th className='px-4 py-2'>관리자</th>
                </tr>
              </thead>
              <tbody>
                {getPaginatedData(getSortedData(getFilteredData(tempData))).map(
                  (item) => (
                    <tr
                      key={item._id}
                      onClick={() => handleRowClick(item)}
                      className='hover:bg-gray-50 cursor-pointer'
                    >
                      <td className='px-4 py-2 max-w-[50px] text-[4px] text-gray-500 text-center'>
                        {item._id}
                      </td>
                      <td className='px-4 py-2 max-w-[200px] overflow-hidden text-ellipsis'>
                        {item.title}
                      </td>
                      <td className='px-4 py-2 text-center text-[10px]'>
                        {item.author}
                      </td>
                      <td className='px-4 py-2 flex justify-center'>
                        <img
                          src={item.thumbnail}
                          alt='thumbnail'
                          className='w-16 h-16'
                        />
                      </td>
                      <td className='px-4 py-2 text-center text-[14px]'>
                        {moment(item.createdAt).format('YY.MM.DD')}
                        <br />
                        {moment(item.createdAt).format('HH:mm')}
                      </td>
                      <td className='px-4 py-2 text-center'>
                        {item.isDisplayed === true ? 'O' : 'X'}
                      </td>
                      <td className='px-4 py-2 text-center'>
                        {item.isAdmin === true ? 'O' : 'X'}
                      </td>
                    </tr>
                  ),
                )}
              </tbody>
            </table>
          </div>

          {/* 페이지네이션 */}
          <div className='flex justify-center mt-6 pb-4'>
            <div className='flex space-x-2'>
              <button
                type='button'
                onClick={() => setCurrentPage(1)}
                disabled={currentPage === 1}
                className={`px-3 py-1 rounded ${
                  currentPage === 1
                    ? 'bg-gray-200 text-gray-500'
                    : 'bg-gray-300 hover:bg-gray-400 text-gray-700'
                }`}
              >
                처음
              </button>
              <button
                type='button'
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 1}
                className={`px-3 py-1 rounded ${
                  currentPage === 1
                    ? 'bg-gray-200 text-gray-500'
                    : 'bg-gray-300 hover:bg-gray-400 text-gray-700'
                }`}
              >
                이전
              </button>

              {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
                let pageNum
                if (totalPages <= 5) {
                  pageNum = i + 1
                } else if (currentPage <= 3) {
                  pageNum = i + 1
                } else if (currentPage >= totalPages - 2) {
                  pageNum = totalPages - 4 + i
                } else {
                  pageNum = currentPage - 2 + i
                }

                if (pageNum > 0 && pageNum <= totalPages) {
                  return (
                    <button
                      type='button'
                      key={pageNum}
                      onClick={() => setCurrentPage(pageNum)}
                      className={`px-3 py-1 rounded ${
                        currentPage === pageNum
                          ? 'bg-blue-500 text-white'
                          : 'bg-gray-300 hover:bg-gray-400 text-gray-700'
                      }`}
                    >
                      {pageNum}
                    </button>
                  )
                }
                return null
              })}

              <button
                type='button'
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={currentPage === totalPages}
                className={`px-3 py-1 rounded ${
                  currentPage === totalPages
                    ? 'bg-gray-200 text-gray-500'
                    : 'bg-gray-300 hover:bg-gray-400 text-gray-700'
                }`}
              >
                다음
              </button>
              <button
                type='button'
                onClick={() => setCurrentPage(totalPages)}
                disabled={currentPage === totalPages}
                className={`px-3 py-1 rounded ${
                  currentPage === totalPages
                    ? 'bg-gray-200 text-gray-500'
                    : 'bg-gray-300 hover:bg-gray-400 text-gray-700'
                }`}
              >
                마지막
              </button>
            </div>
          </div>

          <div className='text-center text-sm text-gray-500 pb-4'>
            총 {tempData.length}개 중 {(currentPage - 1) * itemsPerPage + 1}-
            {Math.min(currentPage * itemsPerPage, tempData.length)}개 표시
          </div>

          {showNovelDetailModal && (
            <NovelDetailModal
              novel={selectedNovel}
              onClose={() => setShowNovelDetailModal(false)}
              onUpdate={fetchNovels}
            />
          )}
        </div>
      )}

      {/* 작품 등록 섹션 */}
      {activeWorkTab === 'register' && (
        <div>
          <div className='py-4 font-bold text-[2.2vh]'>작품 등록</div>
          <NovelRegisterSection />
        </div>
      )}
    </div>
  )
}

export default NovelManagementTab
