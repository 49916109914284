import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import RecommendSlotModal from '../../components/RecommendSlotModal'
import AltoonCMSUserStore from '../../stores/AltoonCMS/AltoonCMSUserStore'
import altoonCMSBackendApis from '../../utils/altoonCMSBackendApis'

const ToonRecommendPromotionAdminScreen = observer(() => {
  // 상태 관리
  const [recommendList, setRecommendList] = useState([])
  const [selectedSlot, setSelectedSlot] = useState(null)
  const [isEditMode, setIsEditMode] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(10)
  const [filterSlotType, setFilterSlotType] = useState('all')
  const [filterDisplayStatus, setFilterDisplayStatus] = useState('all')
  const [searchType, setSearchType] = useState('title')
  const [searchQuery, setSearchQuery] = useState('')
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false)

  // 구좌 타입 정의
  const slotTypes = [
    { value: 'mainNew', label: '메인 신규', maxCount: 6 },
    { value: 'toonPick', label: '올툰픽', maxCount: 4 },
    { value: 'preview', label: '미리보기', maxCount: 4 },
    { value: 'commentRecommend', label: '독자픽', maxCount: 4 },
  ]

  // 데이터 로드
  const fetchData = async () => {
    try {
      const result = await altoonCMSBackendApis.loadRecommendSlots()
      if (result?.status === 200) {
        setRecommendList(result.data)
      }
    } catch (error) {
      console.error('Error fetching recommend slots:', error)
      alert('데이터 로드 중 오류가 발생했습니다.')
    }
  }

  useEffect(() => {
    AltoonCMSUserStore.setIsLoading(true)
    fetchData()
    setTimeout(() => {
      AltoonCMSUserStore.setIsLoading(false)
    }, 500)
  }, [])

  // 현재 노출 상태 확인
  const getDisplayStatus = (item) => {
    if (!item.isDisplayed) return 'hidden'

    const now = moment()
    const startDate = item.displayStartedAt
      ? moment(item.displayStartedAt)
      : null
    const endDate = item.displayEndedAt ? moment(item.displayEndedAt) : null

    if (!startDate || !endDate) return 'active'
    if (now.isBefore(startDate)) return 'scheduled'
    if (now.isAfter(endDate)) return 'expired'
    return 'active'
  }

  // 상태에 따른 스타일 클래스
  const getStatusStyle = (status) => {
    switch (status) {
      case 'active':
        return 'bg-green-100 text-green-800'
      case 'scheduled':
        return 'bg-yellow-100 text-yellow-800'
      case 'expired':
        return 'bg-red-100 text-red-800'
      default:
        return 'bg-gray-100 text-gray-800'
    }
  }

  // 상태에 따른 표시 텍스트
  const getStatusText = (status) => {
    switch (status) {
      case 'active':
        return '노출중'
      case 'scheduled':
        return '노출예정'
      case 'expired':
        return '노출종료'
      default:
        return '비노출'
    }
  }

  // 등록/수정 처리
  const handleSubmit = async (formData) => {
    try {
      let result
      if (isEditMode) {
        result = await altoonCMSBackendApis.updateRecommendSlot(
          selectedSlot?._id,
          formData,
        )
      } else {
        result = await altoonCMSBackendApis.registerRecommendSlot(formData)
      }

      if (result?.status === 200 || result?.status === 201) {
        alert(isEditMode ? '수정되었습니다.' : '등록되었습니다.')
        setIsRegisterModalOpen(false)
        setSelectedSlot(null)
        setIsEditMode(false)
        fetchData()
      } else {
        alert(result?.message || '오류가 발생했습니다.')
      }
    } catch (error) {
      console.error('Error submitting recommend slot:', error)
      alert('처리 중 오류가 발생했습니다.')
    }
  }

  // 삭제 처리
  const handleDelete = async (id) => {
    if (!window.confirm('정말 삭제하시겠습니까?')) return

    try {
      const result = await altoonCMSBackendApis.deleteRecommendSlot(id)
      if (result?.status === 200) {
        alert('삭제되었습니다.')
        fetchData()
      } else {
        alert(result?.message || '삭제 중 오류가 발생했습니다.')
      }
    } catch (error) {
      console.error('Error deleting recommend slot:', error)
      alert('삭제 중 오류가 발생했습니다.')
    }
  }

  // 필터링 및 정렬
  const filteredAndSortedList = recommendList
    .filter((item) => {
      // 검색 필터
      if (searchQuery) {
        if (searchType === 'title') {
          if (
            !item.toonTitle?.toLowerCase().includes(searchQuery.toLowerCase())
          ) {
            return false
          }
        } else if (searchType === 'id') {
          if (!item.toonId?.toString().includes(searchQuery)) {
            return false
          }
        }
      }

      // 구좌 타입 필터
      if (filterSlotType !== 'all') {
        if (!item.recommendType?.[filterSlotType]) {
          return false
        }
      }

      // 노출 상태 필터
      if (filterDisplayStatus !== 'all') {
        const status = getDisplayStatus(item)
        if (status !== filterDisplayStatus) {
          return false
        }
      }

      return true
    })
    .sort(
      (a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf(),
    )

  // 페이지네이션
  const totalPages = Math.ceil(filteredAndSortedList.length / itemsPerPage)
  const paginatedList = filteredAndSortedList.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  )

  // 날짜 포맷팅 함수
  const formatDate = (dateString) => {
    if (!dateString) return '-'
    return moment(dateString).format('YYYY-MM-DD HH:mm')
  }

  return (
    <div className='w-full my-10 min-w-7xl max-w-10xl select-text'>
      <div className='p-4 bg-white rounded-xl'>
        <div className='py-4 font-bold text-[2.6vh]'>노출구좌 관리</div>

        {/* 필터 및 검색 영역 */}
        <div className='mb-6 p-6 bg-white rounded-xl shadow-sm border border-gray-100'>
          <div className='flex gap-6 items-center mb-6'>
            <div className='flex-1'>
              <div className='flex gap-3 items-center'>
                <select
                  id='searchType'
                  value={searchType}
                  onChange={(e) => {
                    setSearchType(e.target.value)
                    setSearchQuery('')
                  }}
                  className='pl-3 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent'
                >
                  <option value='title'>제목</option>
                  <option value='id'>ID</option>
                </select>
                <div className='flex-1 flex gap-2'>
                  <input
                    id='searchQuery'
                    type='text'
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder={
                      searchType === 'title'
                        ? '제목으로 검색...'
                        : 'ID로 검색...'
                    }
                    className='flex-1 px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent'
                  />
                </div>
              </div>
            </div>
            <button
              type='button'
              onClick={() => {
                setIsEditMode(false)
                setSelectedSlot(null)
                setIsRegisterModalOpen(true)
              }}
              className='px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
            >
              신규 등록
            </button>
          </div>

          {/* 필터 영역 */}
          <div className='flex gap-6 flex-wrap items-center'>
            <div className='flex items-center'>
              <select
                id='slotTypeFilter'
                value={filterSlotType}
                onChange={(e) => setFilterSlotType(e.target.value)}
                className='pl-3 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent'
              >
                <option value='all'>전체</option>
                {slotTypes.map((type) => (
                  <option key={type.value} value={type.value}>
                    {type.label}
                  </option>
                ))}
              </select>
            </div>
            <div className='flex items-center'>
              <select
                id='displayStatusFilter'
                value={filterDisplayStatus}
                onChange={(e) => setFilterDisplayStatus(e.target.value)}
                className='pl-3 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent'
              >
                <option value='all'>전체</option>
                <option value='active'>노출중</option>
                <option value='scheduled'>노출예정</option>
                <option value='expired'>노출종료</option>
                <option value='hidden'>비노출</option>
              </select>
            </div>
          </div>
        </div>

        {/* 결과 카운트 */}
        <div className='mb-4 text-sm text-gray-600 font-medium'>
          총 {filteredAndSortedList.length}개의 항목 ({currentPage} /{' '}
          {totalPages} 페이지)
        </div>

        {/* 테이블 */}
        <div className='overflow-hidden rounded-xl border border-gray-200 bg-white shadow-sm'>
          <div className='overflow-x-auto'>
            <table className='min-w-full divide-y divide-gray-200'>
              <thead className='bg-gray-50'>
                <tr>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    작품 ID
                  </th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    제목
                  </th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    노출 구좌
                  </th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    노출 기간
                  </th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    노출 상태
                  </th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                    관리
                  </th>
                </tr>
              </thead>
              <tbody className='bg-white divide-y divide-gray-200'>
                {paginatedList.map((item) => (
                  <tr key={item._id} className='hover:bg-gray-50'>
                    <td className='px-6 py-4 text-sm text-gray-900 font-mono'>
                      {item.toonId}
                    </td>
                    <td className='px-6 py-4 text-sm text-gray-900'>
                      {item.toonTitle}
                    </td>
                    <td className='px-6 py-4'>
                      <div className='flex gap-2 flex-wrap'>
                        {slotTypes.map(
                          (type) =>
                            item.recommendType?.[type.value] && (
                              <span
                                key={type.value}
                                className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800'
                              >
                                {type.label}
                              </span>
                            ),
                        )}
                      </div>
                    </td>
                    <td className='px-6 py-4 text-sm text-gray-900 whitespace-pre-line'>
                      {formatDate(item.displayStartedAt)}
                      {'\n~\n'}
                      {formatDate(item.displayEndedAt)}
                    </td>
                    <td className='px-6 py-4'>
                      <span
                        className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusStyle(
                          getDisplayStatus(item),
                        )}`}
                      >
                        {getStatusText(getDisplayStatus(item))}
                      </span>
                    </td>
                    <td className='px-6 py-4 text-sm font-medium space-x-2'>
                      <button
                        type='button'
                        className='inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-lg text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                        onClick={() => {
                          setSelectedSlot(item)
                          setIsEditMode(true)
                          setIsRegisterModalOpen(true)
                        }}
                      >
                        수정
                      </button>
                      <button
                        type='button'
                        className='inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-lg text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
                        onClick={() => handleDelete(item._id)}
                      >
                        삭제
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* 페이지네이션 */}
        <div className='mt-6 flex justify-center gap-2'>
          <button
            type='button'
            onClick={() => setCurrentPage(1)}
            disabled={currentPage === 1}
            className='inline-flex items-center px-3 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50'
          >
            {'<<'}
          </button>
          <button
            type='button'
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
            className='inline-flex items-center px-3 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50'
          >
            {'<'}
          </button>
          <span className='inline-flex items-center px-4 py-2 text-sm text-gray-700'>
            {currentPage} / {totalPages}
          </span>
          <button
            type='button'
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
            className='inline-flex items-center px-3 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50'
          >
            {'>'}
          </button>
          <button
            type='button'
            onClick={() => setCurrentPage(totalPages)}
            disabled={currentPage === totalPages}
            className='inline-flex items-center px-3 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50'
          >
            {'>>'}
          </button>
        </div>

        {/* 등록/수정 모달 */}
        <RecommendSlotModal
          isOpen={isRegisterModalOpen}
          onClose={() => {
            setIsRegisterModalOpen(false)
            setSelectedSlot(null)
            setIsEditMode(false)
          }}
          onSubmit={handleSubmit}
          initialData={selectedSlot}
          slotTypes={slotTypes}
        />
      </div>
    </div>
  )
})

export default ToonRecommendPromotionAdminScreen
