import moment from 'moment'
import React, { useEffect, useState } from 'react'

import altoonCMSBackendApis from '../../../utils/altoonCMSBackendApis'

const NovelChapterDetailModal = ({ chapter, onClose, onUpdate }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [editedChapter, setEditedChapter] = useState(chapter)
  const [validationErrors, setValidationErrors] = useState({})

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [])

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose()
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setEditedChapter((prev) => ({
      ...prev,
      [name]: value,
    }))
    // 유효성 검사 에러 초기화
    if (validationErrors[name]) {
      setValidationErrors((prev) => ({
        ...prev,
        [name]: '',
      }))
    }
  }

  const validateForm = () => {
    const errors = {}
    if (!editedChapter.chapterTitle) errors.chapterTitle = '제목을 입력해주세요'
    if (!editedChapter.episode) errors.episode = '회차를 입력해주세요'
    if (!editedChapter.detailThumbnail)
      errors.detailThumbnail = '썸네일 URL을 입력해주세요'
    if (!editedChapter.publishedAt) errors.publishedAt = '발행일을 입력해주세요'
    if (!editedChapter.chapterPrice)
      errors.chapterPrice = '회차 가격을 입력해주세요'
    setValidationErrors(errors)
    return Object.keys(errors).length === 0
  }

  const handleSave = async () => {
    if (!validateForm()) return

    try {
      const updates = []

      for (const [field, value] of Object.entries(editedChapter)) {
        let processedValue = value

        if (field === '_id' || field === 'createdAt') continue

        if (field === 'publishedAt') {
          try {
            processedValue = moment(value).format()
          } catch (error) {
            console.error('날짜 변환 오류:', error)
            alert(`날짜 형식이 올바르지 않습니다: ${value}`)
            return
          }
        } else if (field === 'episode' || field === 'chapterPrice') {
          processedValue = Number(value)
        } else if (typeof value === 'string') {
          processedValue = value.trim()
        }

        try {
          const result = await altoonCMSBackendApis.updateNovelChapterInfo({
            chapterId: chapter._id,
            field,
            value: processedValue,
            isDate: field === 'publishedAt',
          })

          if (result?.status !== 200) {
            alert(
              `${field} 필드 업데이트 실패: ${
                result?.message || '알 수 없는 오류'
              }`,
            )
            return
          }
          updates.push(field)
        } catch (error) {
          console.error(`${field} 업데이트 중 오류:`, error)
          alert(
            `${field} 필드 업데이트 중 오류가 발생했습니다: ${
              error.message || '알 수 없는 오류'
            }`,
          )
          return
        }
      }

      if (updates.length > 0) {
        alert('수정이 완료되었습니다.')
        onUpdate()
        onClose()
      }

      setIsEditing(false)
    } catch (error) {
      console.error('회차 정보 저장 중 오류 발생:', error)
      alert('회차 정보 저장에 실패했습니다.')
    }
  }

  return (
    <div
      className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[9999]'
      onClick={handleBackgroundClick}
    >
      <div className='bg-white rounded-xl p-6 w-[80vw] max-w-[1200px] h-[90vh] overflow-y-auto ml-[15vw]'>
        <div className='flex justify-between items-center mb-4'>
          <h2 className='text-2xl font-bold'>
            {chapter.episode}화: {chapter.chapterTitle}
          </h2>
          <button
            type='button'
            onClick={onClose}
            className='text-gray-500 hover:text-gray-700 text-3xl font-bold w-10 h-10 flex items-center justify-center'
          >
            ✕
          </button>
        </div>
        <div className='flex flex-col gap-6'>
          <div className='grid grid-cols-3 gap-6'>
            <div className='col-span-1'>
              <img
                src={chapter.detailThumbnail}
                alt={chapter.chapterTitle}
                className='w-full h-auto object-cover rounded shadow-lg'
              />
            </div>
            <div className='col-span-2 space-y-6'>
              <div className='border rounded-lg overflow-hidden'>
                <table className='w-full'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th
                        className='px-4 py-2 text-left font-semibold'
                        colSpan='2'
                      >
                        기본 정보 <span className='text-red-500'>*</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y'>
                    <tr>
                      <td className='px-4 py-2 font-medium'>회차 ID</td>
                      <td className='px-4 py-2'>{chapter._id}</td>
                    </tr>
                    <tr>
                      <td className='px-4 py-2 font-medium'>작품 ID</td>
                      <td className='px-4 py-2'>
                        {isEditing ? (
                          <input
                            type='text'
                            name='novelId'
                            value={editedChapter.novelId}
                            onChange={handleChange}
                            className='w-full p-2 border rounded'
                          />
                        ) : (
                          <div className='p-2 bg-gray-50 rounded'>
                            {chapter.novelId}
                          </div>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className='px-4 py-2 font-medium'>회차 제목</td>
                      <td className='px-4 py-2'>
                        {isEditing ? (
                          <input
                            type='text'
                            name='chapterTitle'
                            value={editedChapter.chapterTitle}
                            onChange={handleChange}
                            className='w-full p-2 border rounded'
                          />
                        ) : (
                          <div className='p-2 bg-gray-50 rounded'>
                            {chapter.chapterTitle}
                          </div>
                        )}
                        {validationErrors.chapterTitle && (
                          <p className='text-red-500 text-sm mt-1'>
                            {validationErrors.chapterTitle}
                          </p>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className='px-4 py-2 font-medium'>회차 번호</td>
                      <td className='px-4 py-2'>
                        {isEditing ? (
                          <input
                            type='number'
                            name='episode'
                            value={editedChapter.episode}
                            onChange={handleChange}
                            className='w-full p-2 border rounded'
                          />
                        ) : (
                          <div className='p-2 bg-gray-50 rounded'>
                            {chapter.episode}
                          </div>
                        )}
                        {validationErrors.episode && (
                          <p className='text-red-500 text-sm mt-1'>
                            {validationErrors.episode}
                          </p>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className='px-4 py-2 font-medium'>공개 일시</td>
                      <td className='px-4 py-2'>
                        {isEditing ? (
                          <input
                            type='datetime-local'
                            name='publishedAt'
                            value={moment(editedChapter.publishedAt).format(
                              'YYYY-MM-DDTHH:mm',
                            )}
                            onChange={handleChange}
                            className='w-full p-2 border rounded'
                          />
                        ) : (
                          <div className='p-2 bg-gray-50 rounded'>
                            {moment(chapter.publishedAt).format(
                              'YYYY-MM-DD HH:mm',
                            )}
                          </div>
                        )}
                        {validationErrors.publishedAt && (
                          <p className='text-red-500 text-sm mt-1'>
                            {validationErrors.publishedAt}
                          </p>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className='px-4 py-2 font-medium'>회차 가격</td>
                      <td className='px-4 py-2'>
                        {isEditing ? (
                          <input
                            type='number'
                            name='chapterPrice'
                            value={editedChapter.chapterPrice}
                            onChange={handleChange}
                            className='w-full p-2 border rounded'
                          />
                        ) : (
                          <div className='p-2 bg-gray-50 rounded'>
                            {chapter.chapterPrice}
                          </div>
                        )}
                        {validationErrors.chapterPrice && (
                          <p className='text-red-500 text-sm mt-1'>
                            {validationErrors.chapterPrice}
                          </p>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className='px-4 py-2 font-medium'>썸네일 URL</td>
                      <td className='px-4 py-2'>
                        {isEditing ? (
                          <input
                            type='text'
                            name='thumbnail'
                            value={editedChapter.detailThumbnail}
                            onChange={handleChange}
                            className='w-full p-2 border rounded'
                          />
                        ) : (
                          <div className='p-2 bg-gray-50 rounded'>
                            {chapter.detailThumbnail}
                          </div>
                        )}
                        {validationErrors.detailThumbnail && (
                          <p className='text-red-500 text-sm mt-1'>
                            {validationErrors.detailThumbnail}
                          </p>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className='px-4 py-2 font-medium'>작가의 말</td>
                      <td className='px-4 py-2'>
                        {isEditing ? (
                          <textarea
                            name='authorComment'
                            value={editedChapter.authorComment}
                            onChange={handleChange}
                            className='w-full p-2 border rounded'
                            rows={4}
                          />
                        ) : (
                          <div className='p-2 bg-gray-50 rounded whitespace-pre-wrap'>
                            {chapter.authorComment}
                          </div>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className='border rounded-lg overflow-hidden'>
                <table className='w-full'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th
                        className='px-4 py-2 text-left font-semibold'
                        colSpan='2'
                      >
                        상태 정보
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y'>
                    <tr>
                      <td className='px-4 py-2 font-medium'>무료 여부</td>
                      <td className='px-4 py-2'>
                        {isEditing ? (
                          <select
                            name='isFree'
                            value={editedChapter.isFree}
                            onChange={handleChange}
                            className='w-full p-2 border rounded'
                          >
                            <option value='false'>유료</option>
                            <option value='true'>무료</option>
                          </select>
                        ) : (
                          <div className='p-2 bg-gray-50 rounded'>
                            {chapter.isFree ? '무료' : '유료'}
                          </div>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className='px-4 py-2 font-medium'>노출 여부</td>
                      <td className='px-4 py-2'>
                        {isEditing ? (
                          <select
                            name='isDisplayed'
                            value={editedChapter.isDisplayed}
                            onChange={handleChange}
                            className='w-full p-2 border rounded'
                          >
                            <option value='true'>노출</option>
                            <option value='false'>비노출</option>
                          </select>
                        ) : (
                          <div className='p-2 bg-gray-50 rounded'>
                            {chapter.isDisplayed ? '노출' : '비노출'}
                          </div>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className='flex justify-end space-x-4 mt-8'>
          {isEditing && (
            <button
              type='button'
              onClick={() => {
                setIsEditing(false)
                setEditedChapter(chapter)
                setValidationErrors({})
              }}
              className='px-6 py-2 bg-gray-200 hover:bg-gray-300 text-gray-700 rounded-lg'
            >
              취소
            </button>
          )}
          <button
            type='button'
            onClick={() => {
              if (isEditing) {
                handleSave()
              } else {
                setIsEditing(true)
                setEditedChapter(chapter)
                setValidationErrors({})
              }
            }}
            className='px-6 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg'
          >
            {isEditing ? '수정 완료' : '수정하기'}
          </button>
          {!isEditing && (
            <button
              type='button'
              onClick={async () => {
                if (
                  window.confirm(
                    '정말로 이 회차를 삭제하시겠습니까? 이 작업은 되돌릴 수 없습니다.',
                  )
                ) {
                  try {
                    // TODO: API 호출 구현
                    onClose()
                  } catch (error) {
                    console.error('회차 삭제 중 오류 발생:', error)
                    alert('회차 삭제에 실패했습니다.')
                  }
                }
              }}
              className='px-6 py-2 bg-red-500 hover:bg-red-600 text-white rounded-lg'
            >
              삭제하기
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default NovelChapterDetailModal
