import React, { useEffect, useState } from 'react'

import altoonCMSBackendApis from '../../../utils/altoonCMSBackendApis'

const NovelRegisterSection = () => {
  const [title, setTitle] = useState('')
  const [author, setAuthor] = useState('')
  const [thumbnail, setThumbnail] = useState('')
  const [verticalThumbnail, setVerticalThumbnail] = useState(false)
  const [description, setDescription] = useState('')
  const [genre, setGenre] = useState('')
  const [providerId, setProviderId] = useState('')
  const [settlementFeeRate, setSettlementFeeRate] = useState('')
  const [displayStartedAt, setDisplayStartedAt] = useState('')
  const [isDisplayed, setIsDisplayed] = useState(true)
  const [isAdultOnly, setIsAdultOnly] = useState(false)
  const [priority, setPriority] = useState(0)
  const [rentPeriod, setRentPeriod] = useState(30)
  const [providerList, setProviderList] = useState([])

  useEffect(() => {
    const loadProviderList = async () => {
      try {
        const tempToken = localStorage.getItem('@altoon@provider@token@')
        const result = await altoonCMSBackendApis.loadProviderList(
          tempToken ?? localStorage.getItem('@altoon@token@'),
        )

        if (result?.status === 200) {
          setProviderList(result.data)
        }
      } catch (error) {
        console.error('CP사 목록 로딩 중 오류 발생:', error)
      }
    }
    loadProviderList()
  }, [])

  return (
    <div className='p-4 bg-white rounded-xl'>
      <div className='py-4 font-bold text-[2.6vh]'>작품 등록</div>
      <div className='grid grid-cols-3 gap-6'>
        <div className='col-span-1'>
          <div className='space-y-4'>
            <div>
              <label
                htmlFor='thumbnail-input'
                className='block mb-2 font-medium'
              >
                썸네일 URL
                <span className='text-red-500'> *</span>
              </label>
              <input
                id='thumbnail-input'
                type='text'
                value={thumbnail}
                onChange={(e) => setThumbnail(e.target.value)}
                className='w-full p-2 border rounded'
              />
            </div>
            <div className='flex items-center space-x-2'>
              <input
                type='checkbox'
                checked={verticalThumbnail}
                onChange={(e) => setVerticalThumbnail(e.target.checked)}
                className='form-checkbox h-5 w-5'
                id='vertical-thumbnail-input'
              />
              <label htmlFor='vertical-thumbnail-input'>세로형 썸네일</label>
            </div>
          </div>
          <div className='mt-4 border rounded-lg'>
            <div className='px-4 py-2 bg-gray-50 font-semibold'>작품 설명</div>
            <div className='p-4'>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className='w-full p-2 border rounded'
                rows={6}
                id='description-input'
              />
            </div>
          </div>
        </div>
        <div className='col-span-2 space-y-6'>
          <div className='border rounded-lg overflow-hidden'>
            <table className='w-full'>
              <thead className='bg-gray-50'>
                <tr>
                  <th className='px-4 py-2 text-left font-semibold' colSpan='2'>
                    기본 정보<span className='text-red-500'> *</span>
                  </th>
                </tr>
              </thead>
              <tbody className='divide-y'>
                <tr>
                  <td className='px-4 py-2 font-medium'>제목</td>
                  <td className='px-4 py-2'>
                    <input
                      type='text'
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      className='w-full p-2 border rounded'
                      id='title-input'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='px-4 py-2 font-medium'>작가</td>
                  <td className='px-4 py-2'>
                    <input
                      type='text'
                      value={author}
                      onChange={(e) => setAuthor(e.target.value)}
                      className='w-full p-2 border rounded'
                      id='author-input'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='px-4 py-2 font-medium'>장르</td>
                  <td className='px-4 py-2'>
                    <select
                      value={genre}
                      onChange={(e) => setGenre(e.target.value)}
                      className='w-full p-2 border rounded'
                      id='genre-input'
                    >
                      <option value=''>장르를 선택해주세요</option>
                      {[
                        'romance',
                        'romanceFantasy',
                        'BL',
                        'instatoon',
                        'drama',
                        'daily',
                        'comedy',
                        'action',
                        'thriller',
                      ].map((g) => (
                        <option key={g} value={g}>
                          {g}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td className='px-4 py-2 font-medium'>대여 기간 (일)</td>
                  <td className='px-4 py-2'>
                    <input
                      type='number'
                      value={rentPeriod}
                      onChange={(e) => setRentPeriod(Number(e.target.value))}
                      className='w-24 p-2 border rounded'
                      id='rent-period-input'
                      min='1'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='px-4 py-2 font-medium'>시작 날짜</td>
                  <td className='px-4 py-2'>
                    <input
                      type='datetime-local'
                      value={displayStartedAt}
                      onChange={(e) => setDisplayStartedAt(e.target.value)}
                      className='w-full p-2 border rounded'
                      id='display-started-at-input'
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className='border rounded-lg overflow-hidden'>
            <table className='w-full'>
              <thead className='bg-gray-50'>
                <tr>
                  <th className='px-4 py-2 text-left font-semibold' colSpan='2'>
                    CP사 정보<span className='text-red-500'> *</span>
                  </th>
                </tr>
              </thead>
              <tbody className='divide-y'>
                <tr>
                  <td className='px-4 py-2 font-medium'>CP사 선택</td>
                  <td className='px-4 py-2'>
                    <select
                      value={providerId}
                      onChange={(e) => {
                        const selectedProvider = providerList.find(
                          (p) => p._id === e.target.value,
                        )
                        if (selectedProvider) {
                          setProviderId(selectedProvider._id)
                          setSettlementFeeRate(
                            selectedProvider.settlementFeeRate || 0.5,
                          )
                        }
                      }}
                      className='w-full p-2 border rounded'
                      id='provider-select'
                    >
                      <option value=''>CP사를 선택해주세요</option>
                      {providerList.map((provider) => (
                        <option key={provider._id} value={provider._id}>
                          {provider.providerDisplayName} (
                          {provider.providerName})
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td className='px-4 py-2 font-medium'>분배율 (0~1)</td>
                  <td className='px-4 py-2'>
                    <input
                      type='number'
                      step='0.1'
                      min='0'
                      max='1'
                      value={settlementFeeRate}
                      onChange={(e) =>
                        setSettlementFeeRate(parseFloat(e.target.value))
                      }
                      className='w-24 p-2 border rounded'
                      id='settlement-fee-rate-input'
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className='border rounded-lg overflow-hidden'>
            <table className='w-full'>
              <thead className='bg-gray-50'>
                <tr>
                  <th className='px-4 py-2 text-left font-semibold' colSpan='2'>
                    상태 정보
                  </th>
                </tr>
              </thead>
              <tbody className='divide-y'>
                <tr>
                  <td className='px-4 py-2 font-medium'>노출 여부</td>
                  <td className='px-4 py-2'>
                    <input
                      type='checkbox'
                      checked={isDisplayed}
                      onChange={(e) => setIsDisplayed(e.target.checked)}
                      className='form-checkbox h-5 w-5'
                      id='is-displayed-checkbox'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='px-4 py-2 font-medium'>성인 여부</td>
                  <td className='px-4 py-2'>
                    <input
                      type='checkbox'
                      checked={isAdultOnly}
                      onChange={(e) => setIsAdultOnly(e.target.checked)}
                      className='form-checkbox h-5 w-5'
                      id='is-adult-only-checkbox'
                    />
                  </td>
                </tr>
                <tr>
                  <td className='px-4 py-2 font-medium'>우선순위</td>
                  <td className='px-4 py-2'>
                    <input
                      type='number'
                      value={priority}
                      onChange={(e) => setPriority(Number(e.target.value))}
                      className='w-24 p-2 border rounded'
                      id='priority-input'
                      min='0'
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className='flex justify-end mt-8'>
        <button
          type='button'
          onClick={async () => {
            if (
              !title ||
              !author ||
              !displayStartedAt ||
              !thumbnail ||
              !description ||
              !genre ||
              !providerId ||
              !settlementFeeRate ||
              !rentPeriod
            ) {
              alert('필수 항목을 모두 입력해주세요.')
              return
            }

            const novelData = {
              title,
              author,
              thumbnail,
              description,
              genre,
              providerId,
              settlementFeeRate,
              verticalThumbnail,
              isDisplayed,
              isAdultOnly,
              priority,
              rentPeriod,
              displayStartedAt,
            }
            const result = await altoonCMSBackendApis.registerNovel(novelData)

            if (result?.status === 200) {
              alert('등록되었습니다.')
              setTitle('')
              setAuthor('')
              setDisplayStartedAt('')
              setThumbnail('')
              setDescription('')
              setGenre('')
              setProviderId('')
              setSettlementFeeRate('')
              setVerticalThumbnail(false)
              setIsDisplayed(true)
              setIsAdultOnly(false)
              setPriority(0)
              setRentPeriod(30)
            } else if (result?.status === 204) {
              alert(result?.data)
            } else {
              alert(
                '오류가 발생하였습니다. 오기입 위험이 있으니 DB를 확인해주세요.',
              )
            }
          }}
          className='px-6 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg'
        >
          작품 등록하기
        </button>
      </div>
    </div>
  )
}

export default NovelRegisterSection
