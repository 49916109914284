import { actions } from '../stackflow.ts'

function toonBackHandler() {
  const currentStack = actions.getStack()
  const currentActivity = currentStack?.activities[0]

  if (window.location.pathname.includes('/altoon-novel-')) {
    window.history.back()
  } else if (
    currentActivity?.name === 'AltoonMainPage' &&
    currentActivity.isTop
  ) {
    // if (confirmExit) {
    window.location.href = '#goBack'
    // window.ReactNativeWebView?.postMessage(
    //   JSON.stringify({
    //     type: 'goBack',
    //   }),
    // )
    // }
    // confirmExit = true
    // ToastStore.toastOn({
    //   message: '뒤로가기 버튼을 한번 더 누르면 올웨이즈로 돌아가요',
    //   duration: 1500,
    //   type: 'emoji',
    //   emoji: '⬅️',
    // })
    // setTimeout(() => {
    //   confirmExit = false
    // }, 2000)
  } else {
    actions.pop()
  }
}

export default toonBackHandler
