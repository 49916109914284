import { AppScreen } from '@stackflow/plugin-basic-ui'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'

import ToonModal from '../components/templates/ToonModal'
import BottomBarComponent from '../components/toon/BottomBarComponent'
import FreepassComponent from '../components/toon/FreepassComponent'
import CoinHistorySection from '../components/toon/coinHistorySection'
import MyTicketSection from '../components/toon/myTicketSection'
import TicketMissionSection from '../components/toon/ticketMissionSection'
import THImageText from '../components/treasureHunt/THImageText'
import { useMyFlow } from '../hooks/altoon/useMyFlow.ts'
import AltoonUserStore from '../stores/AltoonUserStore'
import ToastStore from '../stores/ToastStore'
import AB_V3 from '../utils/ab_v3'
import backendApis from '../utils/backendApis.ts'

/**
 * 올툰 열람권 페이지 (미션 및 사용법)
 */
const AltoonTicketPage = observer(({ params }) => {
  const { pop, push, replace } = useMyFlow()

  const toonListRef = useRef(null)
  const purchased = params?.purchased
  const [selectedTab, setSelectedTab] = useState('mission')
  const [clickCount, setClickCount] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [showNoticeModal, setShowNoticeModal] = useState(false)
  const [showFreepassModal, setShowFreepassModal] = useState(false)
  const [showRequestModal, setShowRequestModal] = useState(false)
  const [freepassInfo, setFreepassInfo] = useState()

  const freepassTester =
    AB_V3(AltoonUserStore?.userData?.userId, 'free0306', [50]) === 'b' &&
    moment(AltoonUserStore?.userData?.createdAt).isAfter('2025-03-06')

  useEffect(() => {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'allowiPhoneGesture',
        boolean: false,
      }),
    )

    backendApis.logToonAction('AltoonTicketPage', 'enteredScreen', '')

    if (purchased) {
      setShowModal(true)
    }
  }, [purchased])

  useEffect(() => {
    const freepassLog = async () => {
      const freepassLog = await backendApis.loadFreepassLog()
      if (freepassLog?.status === 200) {
        if (freepassLog?.data?.status === 200) {
          setFreepassInfo(freepassLog?.data)
          AltoonUserStore.set('userData.freepassInfo', freepassLog?.data?.data)
        } else if (freepassLog?.data?.status === 400) {
          setFreepassInfo([])
        }
      }
    }

    if (freepassTester) {
      freepassLog()
    }
  }, [showFreepassModal])

  const HeaderComponent = () => {
    return (
      <section className='z-10 py-2 flex flex-row items-center justify-between overflow-hidden bg-white'>
        <button
          aria-label='goBack'
          type='button'
          className='mx-2 p-2 whitespace-nowrap'
          onClick={async () => {
            pop()
          }}
        >
          <FiChevronLeft className='w-8 h-8 stroke-[0.2vw]' />
        </button>
        <button
          type='button'
          className='flex font-bold'
          onClick={() => {
            if (clickCount < 3) {
              setClickCount(clickCount + 1)
            } else if (
              clickCount === 3 &&
              AltoonUserStore.userData?.isAdmin === true
            ) {
              alert('안녕하세요')
            }
          }}
        >
          내 코인
        </button>
        <button
          type='button'
          className='font-bold px-[4vw]'
          onClick={() => {
            window.location.href = `#navigate.${JSON.stringify({
              screen: 'QuestionTabNavigator',
              prop: {
                initialRouteName: '문의하기',
              },
            })}`
          }}
        >
          <img
            src='/images/toon/headsetIcon.png'
            alt='headphone'
            className='w-[5vw] h-[5vw] mb-[1vw]'
          />
        </button>
      </section>
    )
  }

  const MenuTab = forwardRef(() => {
    const [borderStyle, setBorderStyle] = useState({})
    const missionRef = useRef(null)
    const usedRef = useRef(null)
    const gainedRef = useRef(null)

    useEffect(() => {
      let activeRef
      switch (selectedTab) {
        case 'mission':
          activeRef = missionRef
          break
        case 'used':
          activeRef = usedRef
          break
        case 'gained':
          activeRef = gainedRef
          break
        default:
          break
      }

      if (activeRef && activeRef.current) {
        const rect = activeRef.current.getBoundingClientRect()
        setBorderStyle({
          width: rect.width,
          left: rect.left,
        })
      }
    }, [selectedTab])

    const getButtonStyle = (tab) => {
      if (tab === selectedTab) {
        if (tab === 'mission') {
          return 'px-[10vw] font-bold text-[#FF5951]'
        }
        return 'px-[4vw] font-bold text-[#FF5951]' // 선택된 장르일 때의 스타일
      }
      if (tab === 'mission') {
        return 'px-[10vw] text-gray-500'
      }
      return 'px-[4vw] text-gray-500' // 기본 스타일
    }

    return (
      <div className='flex flex-col items-center pb-2 bg-white'>
        <div className='w-full flex flex-row justify-around px-4 py-2 text-[3.8vw] border-b-[0.6vw] border-[#F3F3F3] relative overflow-y-hidden overflow-scroll whitespace-nowrap'>
          <div
            style={borderStyle}
            className='absolute -bottom-[0.2vh] h-[0.6vh] bg-[#FF5951] transition-all duration-300'
          />
          <button
            ref={missionRef}
            type='button'
            className={getButtonStyle('mission')}
            onClick={() => setSelectedTab('mission')}
          >
            미션
          </button>
          <button
            ref={usedRef}
            type='button'
            className={getButtonStyle('used')}
            onClick={() => setSelectedTab('used')}
          >
            사용/소멸내역
          </button>
          <button
            ref={gainedRef}
            type='button'
            className={getButtonStyle('gained')}
            onClick={() => setSelectedTab('gained')}
          >
            획득/구매내역
          </button>
        </div>
      </div>
    )
  })

  const PurchasedWelcomeModal = () => {
    return (
      <>
        <div className='bg-[#43434350] w-full h-full fixed top-0' />
        <div className='absolute w-[80vw] h-[30vh] top-[30vh] left-[10vw] rounded-lg text-center font-bold bg-white'>
          <div className='relative px-2 whitespace-normal top-[6vh] text-[5vw]'>
            <THImageText
              fontSize='4vw'
              text={'구매 완료!\n\n올툰의 재밌는 작품들을 즐겨보세요'}
            />
          </div>
          <button
            type='button'
            className='w-full py-3 text-white bg-red-500 absolute rounded-b-lg bottom-0 left-0'
            onClick={() => {
              setShowModal(false)
              replace('AltoonMainPage', {
                component: 'ticketPage_purchasedWelcomeModal',
              })
            }}
          >
            확인
          </button>
        </div>
      </>
    )
  }

  const NoticeModal = () => {
    return (
      <>
        <div className='bg-[#43434350] w-full h-full fixed top-0' />
        <div className='absolute w-[80vw] h-[50vh] px-[4vw] top-[20vh] left-[10vw] rounded-lg text-center font-bold bg-white'>
          <div className='h-[8vw]' />
          <div className='text-[6vw] font-bold'>공지</div>
          <div className='h-[2vw]' />
          <div className='text-[3.6vw] text-[#71737C] leading-[1.8]'>
            코인 관련된 정책이 변경되었어요 <br /> <br />
            1. 유/무료 코인의 소진 순서가 변경되었어요. <br />
            앞으로는 무료코인이 유료코인보다 먼저 사용돼요.
            <br /> <br />
            2. 무료코인은 30일의 유효기간이 있어요. <br /> 단, 2월 14일 이전
            획득한 코인은 소멸되지 않아요. (6개월간 미접속 시 예외소멸)
            <br /> <br /> 앞으로도 많은 사랑 부탁드려요
          </div>
          <button
            type='button'
            className='w-full py-3 text-white bg-red-500 absolute rounded-b-lg bottom-0 left-0'
            onClick={() => {
              setShowNoticeModal(false)
            }}
          >
            확인
          </button>
        </div>
      </>
    )
  }

  const FreepassModal = () => {
    return (
      <>
        <div className='bg-[#43434350] w-full h-full fixed top-0' />
        <div className='absolute flexCol justify-start items-center w-[80vw] h-[50vh] top-[20vh] left-[10vw] rounded-lg text-center font-bold bg-white'>
          <div className='h-[8vw]' />
          <div className='text-[6vw] font-bold'>무제한 패스란?</div>
          <div className='h-[1vw]' />

          <img
            src='/images/toon/freepassIcon.png'
            alt='freepassModal'
            className='w-[40vw] h-auto'
          />
          <div className='text-[3.4vw] text-[#71737C] leading-[1.8]'>
            패스를 활성화하면, 유료코인 전용 회차를 제외한 <br /> 모든 회차를
            정해진 시간동안 무료로 볼수 있어요. <br />
            단, 패스의 유효기간 내에 활성화하지 않으면 <br />
            소멸되어 사용할 수 없으니 유의해주세요
          </div>
          <button
            type='button'
            className='w-[50%] py-3 text-white bg-gray-400 absolute rounded-bl-lg bottom-0 left-0'
            onClick={() => {
              setShowFreepassModal(false)
            }}
          >
            닫기
          </button>
          <button
            type='button'
            className='w-[50%] py-3 text-white bg-red-500 absolute rounded-br-lg bottom-0 right-0'
            onClick={async () => {
              setShowFreepassModal(false)
              if (AltoonUserStore?.userData?.freepassInfo?.activatedAt) {
                ToastStore.toastOn({
                  type: 'error',
                  message: '이미 활성화된 패스가 있어요!',
                  duration: 2000,
                })
                return
              }
              const result = await backendApis.activateFreepass()
              if (result?.status === 200) {
                ToastStore.toastOn({
                  message: '패스가 활성화되었어요!',
                  duration: 2000,
                  type: 'emoji',
                  emoji: '👍',
                })
                const freepassLog = await backendApis.loadFreepassLog()
                if (freepassLog?.status === 200) {
                  setFreepassInfo(freepassLog?.data)
                  AltoonUserStore.set(
                    'userData.freepassInfo',
                    freepassLog?.data?.data,
                  )
                }
              }
            }}
          >
            활성화하기
          </button>
        </div>
      </>
    )
  }

  const NoticeComponent = () => {
    return (
      <button
        type='button'
        className='flexCol w-full py-[4vw] mb-[2vw] text-[3.4vw] font-bold bg-[#F6F6F6] rounded-lg'
        onClick={() => {
          setShowNoticeModal(true)
        }}
      >
        <div className='flexRow items-center'>
          <img
            src='/images/toon/megaphoneBlack.png'
            alt='megaphoneBlack'
            className='w-[4vw] h-[4vw] mr-2'
          />
          코인 정책이 변경되었어요
          <FiChevronRight className='w-[4vw] h-[4vw] stroke-[0.4vw]' />
        </div>
      </button>
    )
  }

  return (
    <AppScreen>
      <div className='pb-20 bg-white'>
        <HeaderComponent />
        {/* <button
          type='button'
          className='w-full h-full'
          onClick={() => {
            push('AltoonMainEventPage', {
              component: 'ticketPage_eventBanner',
              eventId: '67866c77119a6d392c35f3ad',
            })
          }}
        >
          <img
            src='https://assets.ilevit.com/eff16850-1dd3-4bef-a28c-e3d245e2a860.jpeg'
            alt='ticketPageBg'
            className='w-full h-auto'
          />
        </button> */}

        <NoticeComponent />
        <MyTicketSection />
        {freepassTester && freepassInfo && (
          <div className='w-full bg-white px-6 pb-[4vw]'>
            <FreepassComponent
              freepassInfo={freepassInfo}
              setShowFreepassModal={setShowFreepassModal}
              setFreepassInfo={setFreepassInfo}
            />
          </div>
        )}

        <MenuTab ref={toonListRef} />
        {selectedTab === 'mission' && (
          <div className='bg-[#EEEFF3]'>
            <TicketMissionSection ref={toonListRef} />
            <div className='w-full flex flex-col items-start px-[4vw] pt-[4vw] pb-[20vw] text-[3vw] text-[#9C9DA4]'>
              <button
                type='button'
                className='flex flex-row items-center justify-between w-full px-[5vw] py-[4vw] mt-[2vw] mb-[5vw] text-black bg-white rounded-xl'
                onClick={() => {
                  setShowRequestModal(true)
                }}
              >
                <div className='flex flex-row items-center'>
                  <img
                    src='/images/toon/mailBoxIcon.png'
                    alt='mailBox'
                    className='w-[5vw] h-[5vw] mr-[2vw]'
                  />
                  <div className='text-[3.4vw] font-bold'>올툰 건의함</div>
                </div>
                <FiChevronRight className='w-[6vw] h-[6vw]' />
              </button>
              <button
                type='button'
                onClick={() => {
                  push('AltoonTeenProtectionTerms', {
                    component: 'ticketPage_teenProtectionTerms',
                  })
                }}
              >
                청소년보호정책
              </button>
              <div className='h-[3vw]' />
              <button
                type='button'
                onClick={() => {
                  push('AltoonTermsOfUse', {
                    component: 'ticketPage_termsOfUse',
                  })
                }}
              >
                이용약관
              </button>
            </div>
          </div>
        )}
        {selectedTab === 'used' && (
          <CoinHistorySection ref={toonListRef} type={selectedTab} />
        )}
        {selectedTab === 'gained' && (
          <CoinHistorySection ref={toonListRef} type={selectedTab} />
        )}
        <BottomBarComponent />
        {showModal && <PurchasedWelcomeModal />}
        {showFreepassModal && <FreepassModal />}
        {showNoticeModal && <NoticeModal />}
        {showRequestModal && (
          <ToonModal
            setOpenTicketModal={setShowRequestModal}
            modalName='write_request'
          />
        )}
      </div>
    </AppScreen>
  )
})

export default AltoonTicketPage
