import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import React from 'react'

import ToastStore from '../../stores/ToastStore'
import UserStore from '../../stores/UserStore'
import backendApis from '../../utils/backendApis.ts'

/**
 * 올툰 이벤트 페이지 (id기반 이벤트 정보 조회)
 */
const LeaderboardEventPage = observer(
  ({ participated, setParticipated, eventInfo, leaderboardInfo }) => {
    return (
      <div className='pb-4' style={{ backgroundColor: '#151517' }}>
        <img
          src='https://assets.ilevit.com/e8d82daa-22ce-48ac-8aba-e1da128405a7.png'
          alt='leaderboard'
          className='w-full h-full object-cover'
        />
        <div className='flex flex-col justify-center items-center mx-[4vw] mb-[4vw] pt-6 pb-4 font-bold text-[3.8vw] bg-white text-center rounded-lg '>
          내 순위
          <div
            className={
              participated
                ? 'w-full text-[6vw] text-center rounded-full bg-white px-[3vw] py-[1vw] mt-[2vw] '
                : 'w-full text-[4.2vw] text-gray-500 text-center rounded-full bg-white px-[3vw] py-[1vw] mt-[2vw]'
            }
          >
            {participated
              ? `${
                  (leaderboardInfo?.find(
                    (e) =>
                      e?.userId.toString() ===
                      UserStore?.userInfo?._id.toString(),
                  )?.rank > 98
                    ? '+99'
                    : leaderboardInfo?.find(
                        (e) =>
                          e?.userId.toString() ===
                          UserStore?.userInfo?._id.toString(),
                      )?.rank) || '- '
                }
            위`
              : '이벤트 참여 전입니다'}
          </div>
        </div>
        <div className='bg-white rounded-lg mx-4 p-4'>
          <div className='mb-[2vw] text-[3.6vw] font-bold text-start'>
            {new Date().getDate()}일 {new Date().getHours()}시 기준
          </div>
          <div className='flex flex-row justify-between px-4 py-3 text-[3.6vw] font-bold text-center text-gray-600 border-b border-gray-100'>
            <div className='w-[10vw]'>순위</div>
            <div className='w-[50vw]'>닉네임</div>
            <div className='w-[20vw]'>읽은 회차</div>
          </div>
          {[...Array(10)].map((_, index) => {
            const leaderboard = leaderboardInfo?.[index]

            return (
              <div
                key={leaderboard?._id || `empty-${index}`}
                className='flex flex-row justify-between px-4 py-3 text-[3.4vw] border-b border-gray-100'
              >
                <div className='w-[10vw] text-center'>{index + 1}</div>
                <div
                  className={
                    index + 1 < 4
                      ? 'font-bold w-[50vw] text-center'
                      : 'w-[50vw] text-center'
                  }
                >
                  {leaderboard
                    ? `${
                        leaderboard?.userName?.length > 10
                          ? `${leaderboard?.userName?.slice(0, 10)}..`
                          : leaderboard?.userName
                      } (${leaderboard?.phoneNumber?.slice(7, 11) || '****'})`
                    : '-'}
                </div>
                <div className='w-[20vw] text-center text-[#FF3E3E] font-bold'>
                  {leaderboard ? commaNumber(leaderboard.count) || 0 : '-'}
                </div>
              </div>
            )
          })}
        </div>
        <div className='px-4 mt-6 pb-[20vw]'>
          <button
            type='button'
            className={
              participated
                ? 'w-full py-4 bg-[#E2E2E7] rounded-lg font-bold text-[5vw] text-[#9C9DA4]'
                : 'w-full py-4 bg-[#FF3E3E] rounded-lg font-bold text-[5vw] text-white'
            }
            disabled={participated}
            onClick={async () => {
              const result = await backendApis.participateLeaderboard(
                eventInfo?._id,
              )

              if (result?.status === 200) {
                setParticipated(true)
              } else if (result?.status === 204) {
                ToastStore.toastOn({
                  type: 'error',
                  message: '이미 참여중이에요!',
                  duration: 2000,
                })
                setParticipated(true)
              } else {
                ToastStore.toastOn({
                  type: 'error',
                  message:
                    '알 수 없는 오류가 발생했어요.\n 잠시후 다시 시도해주세요',
                  duration: 2000,
                })
              }
            }}
          >
            {participated ? '참여 완료' : '이벤트 참여하기'}
          </button>
        </div>
      </div>
    )
  },
)

export default LeaderboardEventPage
