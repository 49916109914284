import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React from 'react'

import AltoonUserStore from '../../stores/AltoonUserStore'
import ToastStore from '../../stores/ToastStore'
import backendApis from '../../utils/backendApis.ts'
import BlackTextTimer from '../BlackTextTimer'

const FreepassComponent = observer(
  ({ freepassInfo, setShowFreepassModal, setFreepassInfo }) => {
    if (freepassInfo) {
      if (freepassInfo.msg === 'unused') {
        return (
          <div className='flexRow items-center justify-between px-[4vw] py-[4vw] bg-[#FFE3F2] rounded-lg'>
            <button
              type='button'
              className='flexCol items-start'
              onClick={() => {
                setShowFreepassModal(true)
              }}
            >
              <div className='w-[14vw] py-[1vw] text-[3.2vw] font-bold text-[#F13B61] bg-white rounded-xl'>
                보유중
              </div>
              <div className='h-[1vw]' />
              <div className='text-[4.6vw] font-bold'>2시간 무제한 패스</div>
              <div className='h-[1vw]' />
              <div className='text-[3.4vw] text-[#71737C]'>
                모든 회차를 무료로 볼수 있어요
              </div>
            </button>
            <div className='flexCol items-center'>
              <div className='text-[3.2vw] text-[#DD3792] pb-[1vw]'>
                {moment(freepassInfo.data?.expiresAt).format('M월 D일')} 이후
                소멸
              </div>
              <button
                type='button'
                className='px-[3vw] py-[2vw] text-white text-[4.4vw] font-bold rounded-lg'
                style={{
                  backgroundImage: 'url(/images/toon/purpleGradientButton.png)',
                  backgroundSize: '100% 100%',
                }}
                onClick={async () => {
                  const result = await backendApis.activateFreepass()
                  if (result?.status === 200) {
                    ToastStore.toastOn({
                      message: '패스가 활성화되었어요!',
                      duration: 2000,
                      type: 'emoji',
                      emoji: '👍',
                    })
                    const freepassLog = await backendApis.loadFreepassLog()
                    if (freepassLog?.status === 200) {
                      setFreepassInfo(freepassLog?.data)
                      AltoonUserStore.set(
                        'userData.freepassInfo',
                        freepassLog?.data?.data,
                      )
                    }
                  }
                }}
              >
                패스 활성화
              </button>
            </div>
          </div>
        )
      }
      if (freepassInfo.msg === 'active') {
        return (
          <div className='flexRow items-center justify-between px-[4vw] py-[4vw] bg-[#FFE3F2] rounded-lg'>
            <button
              type='button'
              className='flexCol items-start'
              onClick={() => {
                setShowFreepassModal(true)
              }}
            >
              <div className='w-[14vw] py-[1vw] text-[3.2vw] font-bold text-[#F13B61] bg-white rounded-xl'>
                사용중
              </div>
              <div className='h-[1vw]' />
              <div className='text-[4.6vw] font-bold'>2시간 무제한 패스</div>
              <div className='h-[1vw]' />
              <div className='text-[3.4vw] text-[#71737C]'>
                모든 회차를 무료로 볼수 있어요
              </div>
            </button>
            <div className='flexCol items-center'>
              <div className='text-[3.2vw] text-[#DD3792] pb-[1vw]'>
                <BlackTextTimer
                  timeStamp={
                    new Date(
                      AltoonUserStore?.userData?.freepassInfo?.activatedUntil,
                    )
                  }
                  timerMinutes={0}
                  fontSize={14}
                  marginBottom={0}
                  color='#DD3792'
                  onTimeEnd={async () => {
                    const freepassLog = await backendApis.loadFreepassLog()
                    if (freepassLog?.status === 200) {
                      setFreepassInfo(freepassLog?.data)
                      AltoonUserStore.set(
                        'userData.freepassInfo',
                        freepassLog?.data?.data,
                      )
                    }
                  }}
                />
              </div>
              <div
                className='px-[3vw] py-[2vw] text-white text-[4.4vw] font-bold rounded-lg opacity-30'
                style={{
                  backgroundImage: 'url(/images/toon/purpleGradientButton.png)',
                  backgroundSize: '100% 100%',
                }}
              >
                활성화 완료
              </div>
            </div>
          </div>
        )
      }
      if (freepassInfo.msg === 'expired') {
        return
      }
    }
    return (
      <div className='flexRow items-center justify-between px-[4vw] py-[4vw] bg-[#FFE3F2] rounded-lg'>
        <button
          type='button'
          className='flexCol items-start'
          onClick={() => {
            setShowFreepassModal(true)
          }}
        >
          <div className='w-[24vw] py-[1vw] text-[3.2vw] font-bold text-[#F13B61] bg-white rounded-xl'>
            신규 전용 혜택
          </div>
          <div className='h-[1vw]' />
          <div className='text-[4.6vw] font-bold'>2시간 무제한 패스</div>
          <div className='h-[1vw]' />
          <div className='text-[3.4vw] text-[#71737C]'>
            모든 회차를 무료로 볼수 있어요!
          </div>
        </button>
        <div className='flexCol items-center'>
          <button
            type='button'
            className='px-[3vw] py-[2vw] text-white text-[4.4vw] font-bold rounded-lg animate-pulse'
            style={{
              backgroundImage: 'url(/images/toon/purpleGradientButton.png)',
              backgroundSize: '100% 100%',
            }}
            onClick={async () => {
              const result = await backendApis.giveFreepass({
                freeMinutes: 120,
                validDays: 7,
              })
              if (result?.status === 200) {
                setShowFreepassModal(true)
              }
            }}
          >
            패스 활성화
          </button>
        </div>
      </div>
    )
  },
)
export default FreepassComponent
