import { observer } from 'mobx-react-lite'
import React from 'react'

const DailyCheckInOfferwallCpmAd = observer(
  ({
    visible,
    close,
    title,
    src,
    description,
    openRBSheet,
    onClick = () => {},
  }) => {
    return (
      <>
        <div
          id='ModalBackground'
          className={`fixed top-0 left-0 w-full h-full bg-black opacity-80 z-30 transition-opacity duration-300 `}
        />

        <div className='fixed z-40 top-1/4'>
          <div className='items-center justify-center p-5 bg-white z-40 w-[80%] rounded-2xl mx-auto'>
            <div className='mb-5 text-xl font-bold text-center'>{title}</div>

            {src ? (
              <img alt='main' src={src} className='w-full rounded-xl' />
            ) : null}

            {description && (
              <div className='default text-center text-base text-[#464953] font-[500] mt-5 overflow-y-scroll max-h-[320px]'>
                {description}
              </div>
            )}

            <div className='flex flex-row w-full gap-2.5 mt-5'>
              <button
                type='button'
                className='w-full px-2 py-2.5 text-[#BCBDC3] font-bold shadow-none bg-[#EEEFF3] rounded-xl text-lg active:brightness-90 active:scale-95 duration-200'
                onClick={() => {
                  close()
                  openRBSheet()
                }}
              >
                닫기
              </button>
              <button
                type='button'
                className='w-full px-2 py-2.5 text-white font-bold shadow-none bg-[#F8323E] rounded-xl text-lg active:brightness-90 active:scale-95 duration-200'
                onClick={() => {
                  onClick()
                  close()
                }}
              >
                포인트 받기
              </button>
            </div>
          </div>
        </div>
      </>
    )
  },
)

export default DailyCheckInOfferwallCpmAd
