import yellowCoins from '../images/dailyCheckIn/yellowCoins.png'
import UserStore from '../stores/UserStore'
import THModalStore from '../stores/treasureHunt/THModalStore'
import backendApis from '../utils/backendApis.ts'
import utility from '../utils/utility'

class DailyCheckInAction {
  showRewardedAd(placement = 'DAILYCHECKIN_MISSION_REWARD') {
    window?.ReactNativeWebView?.postMessage?.(
      JSON.stringify({
        type: 'showRewardedAd',
        data: {
          adType: 'rewarded',
          placement,
          product: 'DAILYCHECKIN',
        },
      }),
    )
  }

  goExternalbrowser(url) {
    if (url) {
      window?.ReactNativeWebView?.postMessage?.(
        JSON.stringify({
          type: 'externalBrowser',
          data: {
            url,
          },
        }),
      )
    }
  }

  getIFA() {
    window?.ReactNativeWebView?.postMessage?.(
      JSON.stringify({ type: 'getIFA' }),
    )
  }

  // for manbogi - start
  getUserDataForManbogi = async () => {
    const result = await backendApis.getTodayRewards()
    const manbogiMission = result?.result?.dailyCheckInRewardHistory?.filter(
      (e) => e?.rewardType === 'manbogi-mission',
    )
    UserStore.setReceivedCountByManbogi(manbogiMission?.length || 0)
    return manbogiMission?.length || 0
  }

  receiveManbogiReward = async (stepCount) => {
    const result = await backendApis.receiveManbogiReward({
      stepCount,
    })
    const rewardCount = result?.data?.rewardCount
    const rewardAmount = result?.data?.rewardAmount
    if (result?.status === 200) {
      UserStore.setReceivedCountByManbogi(rewardCount)
      if (rewardAmount > 0) {
        THModalStore.setType({
          type: 'reward',
          config: {
            removeCloseButton: true,
            earnEffect: true,
            removeFont: true,
            rewardName: '올포인트 획득',
            description: `${rewardAmount}원을 받았어요!`,
            src: yellowCoins,
            text: '확인',
            forceOpen: true,
          },
        })
        // 올포인트에 값 더해주기
        UserStore.setAccumulatedPoints(
          UserStore.accumulatedPoints + rewardAmount,
        )
      } else {
        THModalStore.setType({
          type: 'confirm',
          config: {
            forceOpen: true,
            title: '오늘의 보상을 모두 받았어요',
            description:
              '오늘 받을 수 있는 올포인트를 모두 다 받았어요!\n내일 만나요!',
            src: null,
            buttonText: '확인',
            removeCloseButton: true,
            onConfirm: () => {
              THModalStore.close()
            },
            clickable: true,
          },
        })
      }
    }
  }

  logManbogiStep = (steps) => {
    backendApis.logManbogiStep({ steps })
  }

  handleRewardedAdMission = async () => {
    // 1.2초 대기(앱러빈 시청완료 api 오는데 살짝 시간이 걸릴수 있어서)
    await utility.sleep(1200)
    const result = await backendApis.getTodayRewards()
    const rewardedADmission = result?.result?.dailyCheckInRewardHistory?.filter(
      (e) => e?.rewardType === 'rewardedAD-manbogi-mission',
    )
    const rewardAdPoint =
      rewardedADmission?.length > 0
        ? rewardedADmission[rewardedADmission.length - 1]?.rewardAmount
        : undefined

    if (result?.status === 200 && rewardAdPoint > 0) {
      THModalStore.setType({
        type: 'reward',
        config: {
          removeCloseButton: true,
          earnEffect: true,
          removeFont: true,
          rewardName: '광고 시청 완료',
          description: `${rewardAdPoint} 포인트를 받았어요!`,
          src: yellowCoins,
          text: '확인',
          forceOpen: true,
          onClick: () => {
            UserStore.setAccumulatedPoints(
              UserStore.accumulatedPoints + rewardAdPoint,
            )
          },
        },
      })
      UserStore.setIsWatchingAd(false)
    }
  }

  // for manbogi - end
}

export default new DailyCheckInAction()
